import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "calc(100vh - 64px)",
    display: "flex",
    flexDirection: "column",
  },
}));

const ArticleDialog = (props) => {
  const classes = useStyles();
  const { closeDialog, paragraphs = [], title = "" } = props;

  return (
    <div className={classes.root}>
      <AppBar
        elevation={0}
        color="inherit"
        position="fixed"
        style={{ borderBottom: "1px solid #dfdfdf" }}
      >
        <Toolbar>
          <Typography variant="h6" style={{ flex: 1 }}>
            {title}
          </Typography>
          <IconButton onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar />

      <Container
        style={{ display: "flex", flexDirection: "column", marginTop: 32 }}
        maxWidth="md"
      >
        {paragraphs.map((paragraph, index) => {
          const isTitle = paragraph.is_title;
          const text = paragraph.text;
          const isImportant = paragraph.is_important;
          return (
            <React.Fragment key={index}>
              <Typography
                variant={isTitle ? "h6" : "body1"}
                style={{ backgroundColor: isImportant ? "#fff176" : "#fff" }}
              >
                {text}
              </Typography>
              <Typography variant="caption">
                (Relevance Score {paragraph.relevance_score})
              </Typography>
              <br />
            </React.Fragment>
          );
        })}
      </Container>
    </div>
  );
};

export default ArticleDialog;
