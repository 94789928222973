import React, { useContext } from "react";
import PaymentDialog from "./PaymentDialog";
import PaymentIcon from "@material-ui/icons/Payment";
import { connect } from "react-redux";
import { accountTypes } from "../../../../store/constants";
import { ActionContext } from "../../../../contexts";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  btnPrimary: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '1rem',
    fontSize: '13px',
    fontFamily: theme.fontFamily.robotoCondensed,
    color: theme.colors.primaryNavy,
    fontWeight: 700,
    cursor: 'pointer',
    textTransform: 'uppercase',
  },
}));

const Reports = (props) => {
  const { playerId, status, accountType } = props;
  const { dialogActions } = useContext(ActionContext);
  const classes = useStyles();

  let hasAuthorizedToHandlePayments =
    accountType === accountTypes.mmDdiAdmin ||
    accountType === accountTypes.superAdmin;

  const handleClick = () => {
    dialogActions.openDialog(
      <PaymentDialog playerId={playerId} status={status} />,
      {
        maxWidth: "xs",
      }
    );
  };

  if (!hasAuthorizedToHandlePayments) {
    return null;
  }

  return (
    <div
        className={classes.btnPrimary}
        startIcon={<PaymentIcon />}
        onClick={handleClick}
        disableElevation
    >
        Payments
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    accountType: state.account.accountType,
  };
};

export default connect(mapStateToProps)(Reports);
