import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Form, Formik } from "formik";
import React from "react";
import Button from "../Button";

let useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    justifyContent: "space-between",
  },
  btnPrimary: {
    padding: '10px',
    backgroundColor: theme.colors.primaryNavy,
    width: '13rem',
    borderRadius: '7px',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.colors.primaryNavy,
      border: `1px solid ${theme.colors.primaryNavy}`,
    },
    textTransform: 'uppercase',
  },
  btnSecondary: {
    color: theme.palette.secondary.main,
    border: '1px solid',
    width: '13rem',
    padding: '10px',
    marginRight: '10px',
    borderRadius: '7px',
    '&:hover': {
      background: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    textTransform: 'uppercase',
  },
}));

const Stepper = (props) => {
  let { children, onSubmit, loading, className, step, setStep, ...rest } =
    props;
  let classes = useStyles();

  let childrenArray = React.Children.toArray(children);
  let currentChild = childrenArray[step];

  let isLastStep = () => {
    return step === childrenArray.length - 1;
  };

  let handleSubmit = (values, helpers) => {
    if (isLastStep()) {
      onSubmit(values, helpers);
    } else {
      helpers.setTouched({});
      helpers.setSubmitting(false);
      setStep((s) => s + 1);
    }
  };

  return (
    <Formik
      validationSchema={currentChild.props.validationSchema}
      onSubmit={handleSubmit}
      {...rest}
    >
      {(formik) => {
        return (
          <Form autoComplete="off" noValidate>
            {React.cloneElement(currentChild, { formik })}
            <div className={clsx(classes.content, className)}>
              {step > 0 ? (
                <Button
                  className={classes.btnSecondary}
                  type="button"
                  variant="text"
                  color="white"
                  disabled={loading}
                  onClick={() => setStep((s) => s - 1)}
                >
                  Previous
                </Button>
              ) : (
                <div />
              )}

              <Button
                  className={classes.btnPrimary}
                disableElevation
                variant="contained"
                type="submit"
                loading={loading}
              >
                {isLastStep() ? "Submit" : "Next"}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Stepper;
