import React from "react";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { capitalize } from "../../../../utils";
import { makeStyles } from "@material-ui/core/styles";

let useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 5,
  },
  label: {
    textTransform: "uppercase",
    fontSize: 11,
    fontWeight: 700,
    width: 120,
    marginRight: theme.spacing(2),
  },
  value: {
    flex: 1,
    textTransform: "capitalize",
  },
}));

let Item = ({ label, value, render = null }) => {
  let classes = useStyles();

  let renderValue = () => {
    return (
      <Typography variant="body1" className={classes.value}>
        {capitalize(value)}
      </Typography>
    );
  };
  if (value === "" || value === null || value === undefined) {
    return null;
  } else if (value.toLowerCase() === "n/a") {
    return null;
  }

  return (
    <ListItem>
      <Typography
        variant="button"
        color="textSecondary"
        className={classes.label}
      >
        {label}
      </Typography>
      {(render && render(value)) || renderValue()}
    </ListItem>
  );
};

let CandidateDetails = (props) => {
  let classes = useStyles();
  let { player } = props;

  return (
    <div className={classes.root}>
      <div>
        <List>
          <Item
            label="image"
            value={player.image}
            render={(value) => {
              return (
                <Avatar
                  src={player.image}
                  alt="avatar"
                  style={{ height: 32, width: 32 }}
                />
              );
            }}
          />
          <Item label="team" value={player.team} />
          <Item label="birthday" value={player.birthday} />
        </List>
      </div>
    </div>
  );
};

let mapStateToProps = (state, ownProps) => {
  let { playerId } = ownProps;

  return {
    player: state.player.players.byId[playerId],
  };
};

export default connect(mapStateToProps)(CandidateDetails);
