import React, { useContext } from "react";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import { accountTypes, statusTypes } from "../../../../store/constants";
import { ActionContext } from "../../../../contexts";
import { connect } from "react-redux";
import { ToggleButton } from "../../../shared/Button";
import { makeStyles } from "@material-ui/core/styles";
import MarkAsReviewDialoge from "./MarkAsReviewCompleteDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "none",
    width: 48,
    height: 36,
    "&.Mui-selected": {
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
    },
  },
}));

const MarkAsReviewCompleteToggleButton = (props) => {
  let classes = useStyles();
  let { playerId, accountType, loading, status } = props;
  let { playerActions, dialogActions } = useContext(ActionContext);

  let hasAuthorizedToMarkReviewComplete =
    accountType === accountTypes.mmPsychologist || accountType === accountTypes.mmChiefPsychologist;

  let hasStatus =
    (accountType === accountTypes.mmChiefPsychologist) ?
      status === statusTypes.completed ||
      status === statusTypes.consentPending ||
      status === statusTypes.psychReviewPending ||
      status === statusTypes.psychReview :
      status === statusTypes.psychReviewPending ||
      status === statusTypes.psychReview ||
      status === statusTypes.baseReportCompleted


  let enableReview = hasAuthorizedToMarkReviewComplete && hasStatus;

  let nextStatus = statusTypes.completed

  let isSelected =
    status === statusTypes.completed || status === statusTypes.consentPending;
  let phyStatus =
    status === statusTypes.psychReviewPending || status === statusTypes.psychReview || status === statusTypes.baseReportCompleted;

  let handleChange = () => {
    if (accountType === accountTypes.mmChiefPsychologist) {
      if (isSelected) {
        playerActions.markReviewIncomple(
          playerId,
          statusTypes.psychReview
        );
      }
      else if (status === statusTypes.psychReview) {
        dialogActions.openDialog(
          <MarkAsReviewDialoge playerId={playerId} status={statusTypes.completed} accountType={accountTypes.mmChiefPsychologist} />,
          {
            maxWidth: "xs",
          }
        );

      }
      else {
        playerActions.markReviewCompleted(playerId, nextStatus);
      }
    }
    else if (accountType === accountTypes.mmPsychologist && phyStatus) {
      dialogActions.openDialog(
        <MarkAsReviewDialoge playerId={playerId} status={statusTypes.psychReview} accountType={accountTypes.mmPsychologist} />,
        {
          maxWidth: "xs",
        }
      );

    }

  };

  if (enableReview) {
    return (
      <div style={{ marginLeft: 8 }}>
        <ToggleButton
          icon={<AssignmentTurnedInIcon />}
          loading={loading}
          selected={isSelected}
          // disabled = {true}
          onChange={handleChange}
          classes={{ root: classes.root }}
        />
      </div>
    );
  }

  return null;
};

let mapStateToProps = (state, ownProps) => {
  let { playerId } = ownProps;

  return {
    accountType: state.account.accountType,
    loading:
      state.ui.player.loadingMarkAsReviewCompleted[playerId] ||
      state.ui.player.loadingMarkAsReviewIncompleted[playerId],
  };
};

export default connect(mapStateToProps)(MarkAsReviewCompleteToggleButton);
