import React, { useContext } from "react";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Slide from "@material-ui/core/Slide";
import Tooltip from "@material-ui/core/Tooltip";
import ErrorIcon from "@material-ui/icons/ErrorRounded";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PlayerDetails from "../PlayerDetails";
import PlayerCardItem from "./PlayerCardItem";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { statusTypes } from "../../../../store/constants";
import { capitalize } from "../../../../utils";
import { ActionContext } from "../../../../contexts";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="left"
      ref={ref}
      timeout={{ enter: 1000, exit: 1000 }}
      {...props}
    />
  );
});

let useStyles = makeStyles((theme) => ({
  root: {
    height: 80,
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  secondaryAction: {
    display: "flex",
    flexDirection: "column",
  },
  badgeContent: {
    width: 18,
    height: 18,
    backgroundColor: theme.palette.background.paper,
    border: `2px solid #fff`,
  },
}));

const getLabelColor = (status, theme) => {
  switch (status) {
    case statusTypes.completed: {
      return theme.palette.success.main;
    }

    case statusTypes.error:
    case statusTypes.rejected: {
      return theme.palette.error.main;
    }

    case statusTypes.processing: {
      return theme.palette.info.main;
    }

    default: {
      return theme.palette.warning.main;
    }
  }
};

const getStatus = (status) => {
  switch (status) {
    case statusTypes.completed:
    case statusTypes.rejected:
    case statusTypes.processing:
    case statusTypes.error:
    case statusTypes.consentPending: {
      return status;
    }

    case statusTypes.psychReviewPending: {
      return "P/R Pending";
    }

    default:
      return status;
  }
};

const PlayerCard = (props) => {
  let classes = useStyles();
  let actions = useContext(ActionContext);
  let theme = useTheme();
  let { player, selected = false, id } = props;
  let { playerActions, dialogActions } = actions;

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  let handleCardClick = (playerId) => {
    return () => {
      playerActions.getPlayer(playerId);
      playerActions.selectPlayer(playerId);
      if (matches) {
        dialogActions.openDialog(<PlayerDetails playerId={playerId} />, {
          fullScreen: true,
          TransitionComponent: Transition,
        });
      }
    };
  };

  return (
    <PlayerCardItem
      className={classes.root}
      selected={selected}
      onClick={handleCardClick(id)}
      divider
      button
    >
      <ListItemAvatar style={{ marginLeft: 0 }}>
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          badgeContent={
            player.isHighRisk ? (
              <Tooltip title={"High Risk Athlete"}>
                <Avatar className={classes.badgeContent}>
                  <ErrorIcon style={{ color: "#d32f2f" }} />
                </Avatar>
              </Tooltip>
            ) : null
          }
        >
          <Avatar src={player.image} alt="player" className={classes.avatar} />
        </Badge>
      </ListItemAvatar>

      <ListItemText
        primary={capitalize(player.name)}
        secondary={player.team}
        primaryTypographyProps={{
          variant: "subtitle2",
          noWrap: true,
        }}
        secondaryTypographyProps={{
          variant: "subtitle2",
          color: "initial",
          style: { fontWeight: 300 },
        }}
      />
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div style={{ color: getLabelColor(player.status, theme) }}>
          <Typography
            variant="caption"
            color="inherit"
            noWrap
            style={{ fontSize: 11 }}
          >
            {getStatus(player.status)}
          </Typography>
        </div>
      </div>
    </PlayerCardItem>
  );
};

let mapStateToProps = (state, ownProps) => {
  let playerId = ownProps.id;
  let selected = state.ui.player.selectedPlayerId === playerId;

  return {
    player: state.player.players.byId[playerId],
    selected,
  };
};

export default connect(mapStateToProps)(PlayerCard);
