import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { Avatar, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.grey[100],
  },
  AvatarCellDesign: {
    cursor: 'pointer',
    backgroundColor: theme.colors.primaryNavy,
    fontSize: '12px',
    height: '32px',
    width: '32px',
    boxShadow: `3px 3px 8px 0px ${theme.colors.veryLightGrey}`,
    color: theme.colors.white,
    fontWeight: '700'
  },
}));

const SettingsWithTooltip = ({ full_name, onClick }) => {
  let classes = useStyles();
  let handleClick = (e) => onClick(e);
  return (
    <IconButton size="small" onClick={handleClick} className={classes.root}>
      <Avatar className={classes.AvatarCellDesign} style={{ cursor: 'pointer', }} title={full_name}>
        {full_name ? full_name.split(" ")[0][0].toUpperCase() + " " + full_name.split(" ")[1][0].toUpperCase() : ""}
      </Avatar>
    </IconButton>
  );
};

export default SettingsWithTooltip;
