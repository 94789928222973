import React from "react";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Button from "@material-ui/core/Button";
import {makeStyles, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    alert: {
        width: "60%",
        margin: "2rem auto",
        marginBottom: 0,
        backgroundColor: theme.palette.common.white,
        borderRadius: 8,
        padding: '1rem 2rem',
    },
    btnPrimary: {
        color: theme.palette.common.white,
        padding: '5px',
        backgroundColor: theme.colors.primaryNavy,
        width: '8rem',
        borderRadius: '7px',
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            color: theme.colors.primaryNavy,
            border: `1px solid ${theme.colors.primaryNavy}`,
        },
        textTransform: 'uppercase',
    },
    title: {
        color: theme.palette.text.primary,
        fontFamily: theme.fontFamily.prometo,
        fontSize: '1rem',
    },
    subtitle: {
        color: theme.palette.text.primary,
        fontFamily: theme.fontFamily.robotoCondensed,
        fontSize: '0.9rem',
    },
}))

const Message = (props) => {
  const { title, severity, content, show, buttonLabel, onClick } = props;
  const classes = useStyles();

  const handleClick = () => {
    onClick();
  };

  if (!show) {
    return null;
  }

  return (
    <Alert
      severity={severity}
      className={classes.alert}
      action={
        <Button
          className={classes.btnPrimary}
          variant="contained"
          onClick={handleClick}
          disableElevation
        >
          {buttonLabel}
        </Button>
      }
    >
      <AlertTitle className={classes.title}>{title}</AlertTitle>
      <Typography className={classes.subtitle}>{content}</Typography>
    </Alert>
  );
};

export default Message;
