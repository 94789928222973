import React, { useState, useContext, useRef } from "react";
import {Button, Grid, makeStyles, Typography} from "@material-ui/core";
import { connect } from "react-redux";
import CreateCandidateStepper from "./CreateCandidate";
import { ActionContext } from "../../contexts";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import FinalPage from "./CreateCandidate/FinalPage";
import runDashedLogo from "../../assets/RunDashedIcon.svg";
import CircularProgress from "../shared/CircularProgress";
import clsx from "clsx";

function CreateAthlete(props) {
  let { isAthleteFormSubmit, uploadingPlayers } = props;
  const [selectCreateType, setSelectCreateType] = useState("one");
  const [fileData, setFileData] = useState();
  let fileInputRef = useRef();
  let { playerActions } = useContext(ActionContext);

  let useStyles = makeStyles((theme) => ({
    loading: {
      justifyContent: "center",
      alignItems: "center",
    },
    root: {
      backgroundColor: theme.colors.veryLightGrey,
      display: "flex",
      zIndex: theme.zIndex.appBar,
      paddingBottom: '2rem',
      justifyContent: 'center',
      alignContent: 'center',
    },
    container: {
      flexGrow: 1,
      display: "flex",
      flexDirection: 'column',
      margin: "3rem 0px",
      padding: "2rem 1rem",
      backgroundColor: theme.palette.common.white,
      boxShadow: "3px 3px 10px 3px #cdcdcd",
      borderRadius: 8,
      maxWidth: '95%'
    },
    submitAthlete: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: theme.colors.veryLightGrey,
      width: "fit-content",
      borderRadius: "0.5rem",
      padding: "0.2rem 0.2rem",
      marginLeft: 16,
      marginBottom: 16,
    },
    btnType: {
      borderRadius: 6,
      width: "13rem",
      fontFamily: theme.fontFamily.robotoCondensed,
      color: theme.palette.common.white,
    },
    btnType2: {
      borderRadius: "6px",
      color: theme.palette.common.black,
      width: "13rem",
      fontFamily: theme.fontFamily.robotoCondensed,
    },
    fileDisplay: {
      justifyContent: "center",
      alignItems: "center",
      margin: "3rem 0rem 3rem 0rem",
    },
    uploadFileContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "0rem 2rem",
    },
    label: {
      color: theme.palette.text.primary,
      fontFamily: theme.fontFamily.robotoCondensed,
      fontSize: '1rem',
      fontWeight: 600,
    },
    tempBtn: {
      backgroundColor: theme.colors.veryLightGrey,
      width: "35rem",
      padding: "1rem",
      margin: "20px",
      borderRadius: 8,
    },
    text: {
      textAlign: "center",
      paddingBottom: '1rem',
      fontFamily: theme.fontFamily.robotoCondensed,
      color: theme.palette.text.primary,
    },
    fileBtn: {
      color: theme.palette.common.white,
      padding: '10px',
      backgroundColor: theme.colors.primaryNavy,
      width: '13rem',
      borderRadius: '7px',
      textTransform: 'uppercase',
      textAlign: 'center',
    },
    link: {
      color: theme.palette.common.white,
      textDecoration: 'none',
    },
    submitButtonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      width: '61%',
      [theme.breakpoints.down(1440)]: {
        width: '100%',
      },
    },
    btnPrimary: {
      color: theme.palette.common.white,
      background: theme.palette.text.primary,
      padding: 10,
      width: '13rem',
      borderRadius: 8,
      "&:disabled": {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.text.primary}`,
      },
    },
  }));

  let classes = useStyles();

  const handleCreateType = (type) => {
    setSelectCreateType(type);
    if (type === "many") {
      handleUploadPlayerClick();
    }
  };

  let handleUploadPlayerClick = () => {
    fileInputRef.current?.click();
  };

  let handleFileSelect = (e) => {

    if (e.target && e.target.files[0]) {
      setFileData(e.target.files[0]);
    }
  };

  if (uploadingPlayers && uploadingPlayers === true) {
    return (
      <div className={clsx(classes.root, classes.loading)}>
        <CircularProgress size={72} thickness={3} color="primary" />
      </div>
    );
  }

  const handleSubmit = () => {
    let formData = new FormData();
    formData.append("players_file", fileData);
    const cb = () => {
      return () => {
        fileInputRef.current.value = null;
        playerActions.individualAthleteFormSubmitted();
      };
    };

    playerActions.uploadPlayers(formData, cb());
  };

  return (
      <div className={classes.root}>
        <div className={classes.container}>
          {isAthleteFormSubmit === false ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className={classes.submitAthlete}>
                  <Button
                      className={classes.btnType}
                      style={{
                        backgroundColor: selectCreateType === "one" ? '#002839' : "#eeeef0",
                        color: selectCreateType === "one" ? "#ffffff" : "#000000",
                      }}
                      onClick={() => handleCreateType("one")}
                  >
                    Individual
                  </Button>
                  <Button
                      className={classes.btnType2}
                      style={{
                        backgroundColor: selectCreateType === "many" ? '#002839' : "#eeeef0",
                        color: selectCreateType === "many" ? "#ffffff" : "#000000",
                      }}
                      onClick={() => handleCreateType("many")}
                  >
                    Bulk
                  </Button>
                </div>
                <div>
                  {selectCreateType === "one" ? (
                      <CreateCandidateStepper/>
                  ) : (
                      <div className={classes.fileDisplay}>
                        <Grid container spacing={1} style={{display: 'flex'}}>
                          <Grid item xs={8}>
                            <div className={classes.uploadFileContainer}>
                              <label className={classes.label}>Step 1</label>
                              <span className={classes.tempBtn}>
                            <Typography className={classes.text}>
                              Refer to the template below to avoid error when uploading.
                            </Typography>
                            <div style={{display: "flex", justifyContent: "center"}}>
                              <span className={classes.fileBtn}>
                                <a
                                    className={classes.link}
                                    href={
                                        process.env.PUBLIC_URL +
                                        "/Sample_BulkAthlete_File.csv"
                                    }
                                    download="sampleBulkAthlete.csv"
                                >
                                  Download Template
                                </a>
                              </span>
                            </div>
                          </span>
                              <span style={{marginTop: "10px"}}>
                            <ArrowDropDownIcon style={{fontSize: "50px"}}/>
                          </span>
                              <label className={classes.label}>Step 2</label>
                              <span className={classes.tempBtn}>
                            <Typography className={classes.text}>
                              Update information as per the template
                            </Typography>
                          </span>

                              <span>
                            <ArrowDropDownIcon style={{fontSize: "50px"}}/>
                          </span>
                              <label>Step 3</label>

                              <div className={classes.tempBtn}>
                                <div
                                    style={{
                                      display: "flex",
                                      flexDirection: 'column',
                                      justifyContent: "center",
                                      alignItems: 'center',
                                    }}
                                >
                                  <Typography className={classes.text}>
                                    Update registration template.
                                  </Typography>
                                  <label
                                      htmlFor="file-upload"
                                      className={classes.fileBtn}
                                  >
                                    Browse
                                  </label>
                                  <input
                                      type="file"
                                      id="file-upload"
                                      accept=".csv"
                                      ref={fileInputRef}
                                      onChange={handleFileSelect}
                                      style={{display: "none"}}
                                  />
                                </div>
                              </div>
                              <div className={classes.submitButtonContainer}>
                                <Button
                                    className={classes.btnPrimary}
                                    disabled={!fileData}
                                    onClick={handleSubmit}
                                >
                                  Submit
                                </Button>
                              </div>
                            </div>
                          </Grid>
                          <Grid
                              item
                              xs={4}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                          >
                            <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                            >
                              <img
                                  style={{width: "70%"}}
                                  src={runDashedLogo}
                                  alt="logo"
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                  )}
                </div>
              </div>
          ) : (
              <FinalPage selectCreateType={selectCreateType}/>
          )}
        </div>
      </div>
  );
}

let mapStateToProps = (state) => {
  return {
    isAthleteFormSubmit: state.ui.player.isAthleteFormSubmit,
    uploadingPlayers: state.ui.player.uploadingPlayers,
  };
};

export default connect(mapStateToProps)(CreateAthlete);
