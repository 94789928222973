import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import MuiTextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Formik } from "formik";
import { useConfirm } from "material-ui-confirm";
import React, { useContext, useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import { connect } from "react-redux";
import { v4 as uuidV4 } from "uuid";
import { object, string } from "yup";
import { ActionContext } from "../../../../contexts";
import { Button } from "../../../shared/Button";
import CandidateDetails from "./CandidateDetails";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: 550,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  content: {
    display: "flex",
    width: "100%",
  },
  pdf: {
    minWidth: 550,
    width: 550,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: 7,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.background.paper,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  player: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    width: "100%",
    borderLeft: `1px solid ${theme.palette.divider}`,
    maxWidth: 380,
  },
  link: {
    flex: 1,
    padding: theme.spacing(0, 2, 2, 2),
  },
  linkButton: {
    display: "flex",
    flexDirection: "row-reverse",
    marginTop: theme.spacing(2),
  },
  controls: {
    position: "absolute",
    bottom: 0,
    marginTop: 32,
    padding: theme.spacing(2),
    display: "flex",
    flex: 1,
    width: "100%",
    maxWidth: 380,
    justifyContent: "space-between",
  },
  rotate: {
    display: "flex",
  },
}));

let getNextConsetFormId = (consentFormIds, consentFormId) => {
  let nextConsentFormId = null;
  let currentIndex = consentFormIds.indexOf(consentFormId);
  let afterConsentFormIds = consentFormIds.filter((id) => id !== consentFormId);

  if (afterConsentFormIds[currentIndex]) {
    nextConsentFormId = afterConsentFormIds[currentIndex];
  } else if (afterConsentFormIds[--currentIndex]) {
    nextConsentFormId = afterConsentFormIds[currentIndex];
  }

  return nextConsentFormId;
};

let ConsentFormDialog = (props) => {
  let classes = useStyles();
  let {
    consentForm,
    consentFormId,
    pdf,
    loadingPdf,
    closeDialog,
    playersById,
    linkingConsentForm,
    consentFormIds,
  } = props;
  let { consentActions } = useContext(ActionContext);
  let players = Object.values(playersById).filter(
    (player) => player.consent !== "Received"
  );
  let [autoId, setAutoId] = useState("123autoid@qwad2");
  let confirm = useConfirm();

  let isDisabled = loadingPdf || linkingConsentForm;

  let handleCloseDialog = () => {
    closeDialog();
  };

  let handleFormSubmit = (values, helpers) => {
    let data = {
      candidate_id: values.playerId,
      consent_form_id: consentFormId,
    };

    let nextConsentFormId = getNextConsetFormId(consentFormIds, consentFormId);
    let handleSuccess = (f) => f;

    if (nextConsentFormId) {
      handleSuccess = () => {
        helpers.resetForm({
          playerId: null,
        });
        setAutoId(uuidV4());
        consentActions.selectConsentForm(nextConsentFormId);
      };
    } else {
      handleCloseDialog();
    }

    consentActions.linkConsentForm(data, handleSuccess);
  };

  let handleConsentFormDelete = () => {
    confirm({
      description: "Do you really want to delete the consent form?",
      confirmationText: "Yes",
    })
      .then(() => {
        let nextConsentFormId = getNextConsetFormId(
          consentFormIds,
          consentFormId
        );
        let handleSuccess = (f) => f;

        if (nextConsentFormId) {
          handleSuccess = () => {
            setAutoId(uuidV4());
            consentActions.selectConsentForm(nextConsentFormId);
          };
        } else {
          handleCloseDialog();
        }

        let data = {
          consent_form_id: consentFormId,
        };
        consentActions.deleteConsentForm(data, handleSuccess);
      })
      .catch(() => {
        // do nothing
      });
  };

  let handleNext = () => {
    let currentIndex = consentFormIds.indexOf(consentFormId);
    let nextIndex = Math.min(++currentIndex, consentFormIds.length - 1);
    consentActions.selectConsentForm(consentFormIds[nextIndex]);
  };

  let handlePrevious = () => {
    let currentIndex = consentFormIds.indexOf(consentFormId);
    let previousIndex = Math.max(--currentIndex, 0);
    consentActions.selectConsentForm(consentFormIds[previousIndex]);
  };

  let currentIndex = consentFormIds.indexOf(consentFormId);
  let isFirstIndex = currentIndex === 0;
  let isLastIndex = currentIndex === consentFormIds.length - 1;

  useEffect(() => {
    if (consentFormId) {
      consentActions.getConsentForm(consentFormId);
    } else {
      handleCloseDialog();
    }
  }, [consentFormId]);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.pdf}>
          {pdf ? (
            <Document file={pdf}>
              <Page pageNumber={1} />
            </Document>
          ) : (
            <span style={{ padding: 8 }}>Fetching PDF..</span>
          )}
        </div>
        <div className={classes.player}>
          <Formik
            autoComplete="off"
            initialValues={{
              playerId: null,
            }}
            validationSchema={object().shape({
              playerId: string().nullable().required("Select Athlete"),
            })}
            onSubmit={handleFormSubmit}
          >
            {(formik) => {
              let {
                errors,
                touched,
                values,
                handleSubmit,
                handleBlur,
                setFieldValue,
              } = formik;

              return (
                <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                  <div className={classes.header}>
                    <div style={{ flex: 1 }}>
                      <Typography variant="h6" noWrap style={{ maxWidth: 320 }}>
                        {consentForm.name}
                      </Typography>
                    </div>
                    <div>
                      <IconButton onClick={handleCloseDialog}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </div>

                  <div className={classes.link}>
                    <div style={{ marginBottom: 8 }}>
                      <Typography variant="caption" color="textSecondary">
                        Select a athlete to Link
                      </Typography>
                    </div>

                    <Autocomplete
                      key={autoId}
                      id="player-combo"
                      name="playerId"
                      options={players}
                      getOptionLabel={(option) =>
                        option.name ? option.name : ""
                      }
                      onChange={(event, newValue) => {
                        let value = newValue ? newValue.id : null;
                        setFieldValue("playerId", value);
                      }}
                      onBlur={handleBlur}
                      renderInput={(props) => (
                        <MuiTextField
                          variant="outlined"
                          label="Athlete"
                          placeholder="Athlete"
                          error={touched["player"] && !!errors["player"]}
                          helperText={errors["player"]}
                          size="small"
                          required
                          {...props}
                        />
                      )}
                    />

                    {values.playerId && values.playerId.length > 0 ? (
                      <React.Fragment>
                        <div style={{ marginBottom: 8, marginTop: 16 }}>
                          <div style={{ marginBottom: 8 }}>
                            <Typography variant="caption" color="textSecondary">
                              Athlete details
                            </Typography>
                          </div>

                          <CandidateDetails playerId={values.playerId} />

                          <div className={classes.linkButton}>
                            <Button
                              color="primary"
                              type="submit"
                              loading={linkingConsentForm}
                              disableElevation
                            >
                              Link
                            </Button>
                          </div>
                        </div>
                      </React.Fragment>
                    ) : null}
                  </div>
                </form>
              );
            }}
          </Formik>

          <div className={classes.controls}>
            <div className={classes.rotate}>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    color="default"
                    size="small"
                    disabled={isDisabled || isFirstIndex}
                    onClick={handlePrevious}
                    disableElevation
                  >
                    Previous
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="default"
                    size="small"
                    disabled={isDisabled || isLastIndex}
                    onClick={handleNext}
                    disableElevation
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </div>
            <div>
              <Button
                color="primary"
                size="small"
                disableElevation
                disabled={isDisabled}
                onClick={handleConsentFormDelete}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

let mapStateToProps = (state) => {
  let consentFormId = state.ui.consent.selectedConsentForm;
  return {
    loadingPdf: state.ui.consent.loadingPdf[consentFormId] || false,
    consentForm: state.consent.consentForms.byId[consentFormId],
    consentFormIds: state.consent.consentForms.allIds,
    pdf: state.consent.pdfs[consentFormId] || null,
    playersById: state.player.players.byId,
    linkingConsentForm:
      state.ui.consent.linkingConsentForm[consentFormId] || false,
    consentFormId,
  };
};

export default connect(mapStateToProps)(ConsentFormDialog);
