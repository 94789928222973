import React from "react";
import List from "./List";
import ListLoading from "./ListLoading";
import { connect } from "react-redux";

let PlayerList = (props) => {
  let { loading } = props;

  if (loading) {
    return <ListLoading />;
  }
  return <List />;
};

let mapStateToProps = (state) => {
  return {
    loading: state.ui.player.loadingPlayerList,
  };
};
export default connect(mapStateToProps)(PlayerList);
