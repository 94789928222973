import React, { useContext } from "react";
import ReProcessDialog from "./ReProcessDialog";
import PaymentIcon from "@material-ui/icons/Payment";
import { connect } from "react-redux";
import { accountTypes } from "../../../../store/constants";
import { ActionContext } from "../../../../contexts";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    btnPrimary: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '13px',
        fontFamily: theme.fontFamily.robotoCondensed,
        color: theme.colors.primaryNavy,
        fontWeight: 700,
        cursor: 'pointer',
        textTransform: 'uppercase',
    },
}));

const Reprocess = (props) => {
  const { playerId, status, accountType ,playerInfo,type} = props;
  const { dialogActions } = useContext(ActionContext);
  const classes = useStyles();

  let hasAuthorizedToHandlePayments =
    accountType === accountTypes.mmDdiAdmin ||
    accountType === accountTypes.superAdmin;

  const handleClick = () => {
    dialogActions.openDialog(
      <ReProcessDialog playerId={playerId} status={status} playerInfo={playerInfo} type={type} />,
      {
        maxWidth: "sm",
      }
    );
  };

  if (!hasAuthorizedToHandlePayments) {
    return null;
  }

  return (
    <div
        className={classes.btnPrimary}
        startIcon={<PaymentIcon />}
        onClick={handleClick}
        disableElevation
    >
      Re-process
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    accountType: state.account.accountType,
  };
};

export default connect(mapStateToProps)(Reprocess);
