import React, { useState } from 'react'
import {Box, Typography, List} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import ComingSoonImg from '../coach/CommingSoonImg.js';
import {ChildTab, MainTab} from "../shared/Tabs";
import {Article} from "../shared/Article";
import ListItem from "@material-ui/core/ListItem";

let useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        flexDirection: 'column',
    },
    container: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.common.white,
        boxShadow: "3px 3px 10px 3px #cdcdcd",
        borderRadius: 8,
        width: '95%',
        margin: '3rem 0rem',
    },
    indicator: {
        backgroundColor: theme.palette.primary.main,
    },
    subtitle: {
        padding: theme.spacing(1, 2),
        fontFamily: theme.fontFamily.prometo,
    },
    paragraph: {
        padding: theme.spacing(1, 2),
        fontFamily: theme.fontFamily.robotoCondensed,
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function Info() {
    let classes = useStyles();
    const [selectedPage, setSelectedPage] = useState(0)

    const handleChange = (event, newValue) => {
        setSelectedPage(newValue);
    };

    return (
        <div className={classes.root}>
            <Box className={classes.container}>
                <MainTab
                    orientation="horizontal"
                    variant="scrollable"
                    value={selectedPage}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    TabIndicatorProps={{ className: classes.indicator }}
                    style={{ marginLeft: '2rem' }}
                >
                    <ChildTab label="Confidentiality" {...a11yProps(0)}/>
                    <ChildTab label="Process of Analysis" {...a11yProps(1)} />
                    <ChildTab label="Limitations" {...a11yProps(2)} />
                    <ChildTab label="Glossary" {...a11yProps(3)} />
                </MainTab>
                <TabPanel value={selectedPage} index={0}>
                    <Article>
                        <Typography className={classes.paragraph}>
                            We conducted an online search using a range of specialized and
                            proprietary search tools to identify relevant sources of
                            information. Our search approaches included keywords, locations,
                            and limited reviews of the relevant social network connections
                            including:
                        </Typography>
                        <List className={classes.paragraph}>
                            <ListItem>Keyword and search-engine search.</ListItem>
                            <ListItem>Account-based social media account searches.</ListItem>
                            <ListItem>
                                Our methodology ensures compliance with legal and statutory
                                frameworks.
                            </ListItem>
                            <ListItem>
                                Our tools and techniques collect publicly available data. We
                                collect open source and social site data without recourse to any
                                form of friending, linking or hacking. The method of collection
                                and technology used depends on the social networking site or
                                platform. Data availability is influenced by a complex set of
                                variables such as site or social media platform function and
                                design, caching and a complex matrix of user permissions which
                                apply at artefact, post type and account levels.
                            </ListItem>
                            <ListItem>
                                Collection credentials and authentication methods are logged
                                with case data collected.
                            </ListItem>
                            <ListItem>
                                We did not engage with the accounts of concern. To date our
                                activity has been passive in that we have not engaged online
                                with any accounts to test any key questions or provoke changes
                                in behavior that may be illuminating.
                            </ListItem>
                            <ListItem>
                                Our collections are passive and do not announce “follows” to
                                target accounts as is the case when less technically
                                sophisticated approaches are employed. We did not
                                “false-friend”, create identities or “pretext” to collect any
                                data. Our passive data collection activities are not evident to
                                the operators of social media accounts.
                            </ListItem>
                            <ListItem>
                                We did not breach any privacy legislation in our data collection
                                activity.
                            </ListItem>
                            <ListItem>
                                All operations are carried out using secure technology
                                infrastructure hosted within a 'tier one' data center.
                            </ListItem>
                            <ListItem>
                                Data is stored in a form which is compliant with leading data
                                security and privacy standards. All data collected and stored is
                                encrypted and we can provide a full account and production of
                                all data collected if this is required but note that such data
                                requests will incur additional costs.
                            </ListItem>
                        </List>
                    </Article>
                </TabPanel>
                <TabPanel value={selectedPage} index={1}>
                    <Article>
                        <Typography className={classes.paragraph}>
                            Executive Summaries, Personality Insights, Risks, and Other
                            Insights are generated solely based on what a given athlete has
                            posted or uttered.
                        </Typography>
                        <Typography variant="subtitle2" className={classes.subtitle}>
                            Text
                        </Typography>
                        <Typography className={classes.paragraph}>
                            We take into account the things an athlete has posted on his/her
                            social media since the beginning of each account. We do not give
                            weight to the point in time in which the post was made, in other
                            words, we consider all posts as equal.
                        </Typography>
                        <Typography variant="subtitle2" className={classes.subtitle}>
                            Audio/Video
                        </Typography>
                        <Typography className={classes.paragraph}>
                            As of 2020/11, the system supports audio and video extraction and
                            manual tagging. We utilize speech to text models to convert the
                            Audio and Video into text, which is then sent through a manual
                            review/tagging process. During this process, each sentence in the
                            audio/video is tagged as 'Athlete' or 'Other'. The tagged content
                            is stored in the same chronological order, which is then displayed
                            in the videos tab of the Feed section. For insight/risk/etc.
                            generation, only the text portion which is tagged as 'Athlete' is
                            taken into account.
                        </Typography>
                        <Typography variant="subtitle2" className={classes.subtitle}>
                            News Articles
                        </Typography>
                        <Typography className={classes.paragraph}>
                            News articles on a given athlete are automatically extracted and
                            sentences are tagged using machine learning algorithms for
                            relevancy and sent through Sentiment Analysis. Note that News
                            Articles content is not considered when generating any
                            insights/risk/etc. related to an Athlete. Article relevancy
                            tagging is only for the use of the Psychologists who are utilizing
                            the Mental Metrix platform.
                        </Typography>
                        <Typography variant="subtitle2" className={classes.subtitle}>
                            Machine Learning
                        </Typography>
                        <Typography className={classes.paragraph}>
                            The text taken from above to methods are sent through several
                            formatters & machine learning models including
                        </Typography>
                        <List className={classes.paragraph}>
                            <ListItem>
                                Formatters to clean the text, transform elements such as emojis,
                                etc.
                            </ListItem>
                            <ListItem>A model for Sentiment Analysis</ListItem>
                            <ListItem>A series of models for Personality Insights</ListItem>
                            <ListItem>A series of models for Risks</ListItem>
                            <ListItem>A series of models for Potential Psychological Disorders</ListItem>
                        </List>
                        <Typography className={classes.paragraph}>
                            Once the insights are generated, they are timestamped and stored
                            and are not allowed to be changed. This is due to the fact that
                            the analysis is in fact a snapshot at a given point in time. When
                            an update with new posts/videos/audio, is requested for the same
                            athlete, the above-described flow will execute independent of the
                            previous, and the results will replace the old snapshot.
                        </Typography>
                    </Article>
                </TabPanel>
                <TabPanel value={selectedPage} index={2}>
                    <Article>
                        <Typography className={classes.paragraph}>
                            A range of factors limited our activities and the value of our
                            findings including:
                        </Typography>
                        <Typography variant="subtitle2" className={classes.subtitle}>
                            Nature of information collected
                        </Typography>
                        <Typography className={classes.paragraph}>
                            Information collected from online sources is circumstantial. Key
                            questions will always exist regarding the true authors of online
                            activities. Notwithstanding that, a long-term view of online
                            behavior, focusing on consistency, patterns and style typically
                            eliminates many doubts as to the author of online content and
                            activity of the subject of interest. We endeavor to collect posts
                            from multiple authors about the same subject matter to add
                            certainty to conclusions drawn where the data is relied upon.
                        </Typography>
                        <Typography variant="subtitle2" className={classes.subtitle}>
                            Surveillance awareness
                        </Typography>
                        <Typography className={classes.paragraph}>
                            Any communication and interaction with the subject of our
                            investigation prior to our investigation may have changed
                            behaviors including increasing privacy settings, adopting alias’,
                            amendment and deletion of accounts. This may have limited the
                            volume and probity of material available for our collection.
                        </Typography>
                        <Typography variant="subtitle2" className={classes.subtitle}>
                            Technical issues
                        </Typography>
                        <Typography className={classes.paragraph}>
                            Periodically our technology is disrupted by the way that the
                            social sites publish their data. Notwithstanding that, we have
                            collected a significant volume of material.
                        </Typography>
                        <Typography variant="subtitle2" className={classes.subtitle}>
                            Volume of material
                        </Typography>
                        <Typography className={classes.paragraph}>
                            We typically collect a significant amount of material that
                            requires time to search and analyze. Our search and analysis tools
                            accelerate productive assessment of the collected data, but we
                            note the task is significant.
                        </Typography>
                        <Typography variant="subtitle2" className={classes.subtitle}>
                            Misattribution risk
                        </Typography>
                        <Typography className={classes.paragraph}>
                            Any collection of online data includes inherent risks of data
                            being incorrectly attributed to a subject or author. We use proven
                            methods for linking online data to the subject of an investigation
                            but the recipients and users of our reports need to satisfy
                            themselves that data in our report is correctly related to the
                            subject of the data collection.
                        </Typography>
                        <Typography className={classes.paragraph}>
                            The comments made and conclusions drawn in this report are based
                            on information obtained in the course of our investigation. We do
                            not profess to advise you in any way on liability or the conduct
                            of this matter.
                        </Typography>
                        <Typography className={classes.paragraph}>
                            Material collected in online research or investigations is
                            circumstantial and needs to be corroborated by other forms of
                            evidence.
                        </Typography>
                    </Article>
                </TabPanel>
                <TabPanel value={selectedPage} index={3}>
                    <ComingSoonImg/>
                </TabPanel>
            </Box>
        </div>
    )
}

let mapStateToProps = () => {
    return {}
}

export default connect(mapStateToProps)(Info)