import React, { useContext, useEffect } from 'react'
import {
    Button,
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    makeStyles,
    Typography
} from "@material-ui/core"
import { connect } from "react-redux";
import { ActionContext } from "../../../contexts";
import filterDashedLogo from "../../../assets/FilterDashedIcon.svg";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useSelector } from "react-redux";
import { accountTypes } from '../../../store/constants';

let useStyles = makeStyles((theme) => ({
    btnPrimary: {
        color: theme.palette.common.white,
        padding: '10px',
        backgroundColor: theme.colors.primaryNavy,
        width: '13rem',
        marginRight: '10px',
        borderRadius: '7px',
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            color: theme.colors.primaryNavy,
            border: `1px solid ${theme.colors.primaryNavy}`,
        },
        textTransform: 'uppercase',
    },
    btnSecondary: {
        color: theme.palette.secondary.main,
        border: '1px solid',
        width: '13rem',
        padding: '10px',
        marginRight: '10px',
        borderRadius: '7px',
        '&:hover': {
            background: theme.palette.secondary.main,
            color: theme.palette.common.white,
        },
        textTransform: 'uppercase',
    },
    container: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        boxShadow: `3px 3px 10px 3px ${theme.colors.lightGrey}`,
        marginLeft: '-2rem',
        padding: '2rem',
        borderRadius: '0.5rem',
        marginTop: 50,
        width: '100%',
    },
    filterContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up(1240)]: {
            marginLeft: '4rem',
        },
        [theme.breakpoints.up(1440)]: {
            marginLeft: '6rem',
        },
        [theme.breakpoints.up(1680)]: {
            marginLeft: '8rem',
        },
        [theme.breakpoints.up(1920)]: {
            marginLeft: '10rem',
        },
    },
    backBtnContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontWeight: 700,
        fontSize: theme.fontSize.md,
        fontFamily: theme.fontFamily.robotoCondensed,
        color: theme.colors.primaryNavy,
        textTransform: 'uppercase',
        cursor: 'pointer',
        maxWidth: 70,
    },
    title: {
        display: 'flex',
        justifyContent: 'flex-start',
        fontWeight: 800,
        fontSize: theme.fontSize.lg,
        margin: '1.5rem 0rem',
        fontFamily: theme.fontFamily.prometo,
        color: theme.colors.primaryNavy,
        textTransform: 'uppercase',
    },
    subtitle: {
        display: 'flex',
        justifyContent: 'flex-start',
        fontWeight: 600,
        fontSize: theme.fontSize.md,
        fontFamily: theme.fontFamily.robotoCondensed,
        margin: '2rem 0rem',
        color: theme.palette.secondary.main,
    },
    radioBtnContainer: {
        margin: '1rem 0rem',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: theme.fontFamily.robotoCondensed,
    },
    radioBtn: {
        color: theme.colors.primaryNavy,
    },
    dropdown: {
        padding: '8px 15px',
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        paddingLeft: '10px',
        width: "13rem",
        borderRadius: 5,
        color: theme.colors.darkBlue60Percent,
        backgroundColor: theme.colors.lightGrey,
        fontFamily: theme.fontFamily.robotoCondensed,
        fontWeight: 500,
        border: 'none',
        height: 50,
    },
    dropdownOptions: {
        background: theme.colors.white,
        border: `1px solid ${theme.colors.white}`,
        padding: '20px',
        fontSize: 15,
        overflowY: 'auto',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
    },
}));

function Filter(props) {

    const { accountType, isFilteredApply, pageChange, conferenceData, sportsData, unitData, conferenceByType, loadingFilters, updateFilter } = props;
    let classes = useStyles();
    let actions = useContext(ActionContext);
    let { coachActions, sportsActions, conferenceActions, playerActions } = actions;
    let accountState = useSelector((state) => state.account);

    const [state, setState] = React.useState({
        conference: "",
        sport: "",
        unit: "",
        coachType: "All",
        conferenceType: "All",
    });

    useEffect(() => {
        if (updateFilter) {
            setState(updateFilter);
        }
    }, [updateFilter]);

    const handleCoachChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
        coachActions.updateCoachFilterValue(event.target.name, event.target.value)
    };

    const handleConferenceChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
        coachActions.updateCoachFilterValue(event.target.name, event.target.value)
        if (event.target.value === "All") {
            conferenceActions.getConferenceList();
        } else {
            conferenceActions.getConferenceByType(event.target.value);
            setState({
                ...state,
                "conference": "",
            });
            coachActions.updateCoachFilterValue("conference", "")
        }
    };

    useEffect(() => {
        if (accountType === accountTypes.colHeadCoach || accountType === accountTypes.colPositionCoach || accountType === accountTypes.proPositionCoach || accountType === accountTypes.proGeneralManager || accountType === accountTypes.proHeadCoach || accountType === accountTypes.proSecurity || accountType === accountTypes.proPsychologist) {

            if (accountState?.sport) {
                setState({
                    ...state,
                    sport: accountState?.sport ? accountState?.sport : ''

                })
                playerActions.updateFilterValue("sport", accountState?.sport)
                sportsActions.getSportUnitDetails(accountState?.sport);
            } else {
                playerActions.updateFilterValue("sport", "Football")
                sportsActions.getSportUnitDetails("Football");
            }
        }
    }, [accountType]);

    const handleSportChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
            "unit": "",
        });
        coachActions.updateCoachFilterValue(event.target.name, event.target.value)
        sportsActions.getSportUnitDetails(event.target.value);
        coachActions.updateCoachFilterValue("unit", "")
    };

    const handleCancel = () => {
        coachActions.resetCoachFilter()
    };

    const handleSave = () => {
        let model = {
            conference: state.conference ? [state.conference] : [],
            sport: state.sport ? [state.sport] : [],
            unit: state.unit ? [state.unit] : [],
            coachType: state.coachType === "All" ? [] : [state.coachType],
            conferenceType: state.conferenceType === "All" ? [] : [state.conferenceType]
        }
        coachActions.getAllFilterCoaches(model)
    };

    const redirectToCoachPage = () => {
        pageChange('list')
    };

    useEffect(() => {
        conferenceActions.getConferenceList();
        sportsActions.getSportsList();

    }, []);

    useEffect(() => {
        if (loadingFilters === true) {
            pageChange('list')
            coachActions.isCoachFiltered(true)
            isFilteredApply(true)
        }

    }, [loadingFilters]);

    const finalConference = state.conferenceType !== "All" ? conferenceByType : conferenceData;

    return (
        <div className={classes.container}>
            <Grid container spacing={1}>
                <Grid item xs={6} className={classes.filterContainer}>
                    <Box className={classes.backBtnContainer} onClick={redirectToCoachPage}>
                        <ArrowBackIosIcon style={{fontSize: "15px"}}/>
                        Back
                    </Box>
                    <div>
                        <Typography className={classes.title}>
                            Blue Chip Filter
                        </Typography>
                        <Typography className={classes.subtitle}>
                            Select all that are necessary
                        </Typography>
                        <div>
                            {
                                (accountType === accountTypes.superAdmin || accountType === accountTypes.mmDdiAdmin || accountType === accountTypes.mmPsychologist || accountType === accountTypes.mmChiefPsychologist) ?
                                    <div className={classes.radioBtnContainer}>
                                        <Box>
                                            <FormControl>
                                                <FormLabel id="demo-radio-buttons-group-label">League</FormLabel>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue=""
                                                    row
                                                    name="conferenceType"
                                                    value={state.conferenceType}
                                                    className={classes.radioBtn}
                                                    onChange={handleConferenceChange}
                                                >
                                                    <FormControlLabel value="All" control={<Radio/>} label="All"/>
                                                    <FormControlLabel value="Professional" control={<Radio/>}
                                                                      label="Professional"/>
                                                    <FormControlLabel value="Collegiate" control={<Radio/>}
                                                                      label="Collegiate"/>
                                                </RadioGroup>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    :
                                    ""
                            }
                            {
                                (accountType === accountTypes.superAdmin || accountType === accountTypes.mmDdiAdmin || accountType === accountTypes.mmPsychologist || accountType === accountTypes.mmChiefPsychologist || accountType === accountTypes.colAthleticDirector || accountType === accountTypes.colPsychologist || accountType === accountTypes.colHeadCoach || accountType === accountTypes.colPositionCoach || accountType === accountTypes.proPositionCoach || accountType === accountTypes.proGeneralManager || accountType === accountTypes.proHeadCoach || accountType === accountTypes.proSecurity || accountType === accountTypes.proPsychologist
                                ) ?
                                    <div className={classes.radioBtnContainer}>
                                        <FormControl>
                                            <FormLabel id="demo-radio-buttons-group-label">Coach Type</FormLabel>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue=""
                                                row
                                                name="coachType"
                                                value={state.coachType}
                                                onChange={handleCoachChange}
                                                className={classes.radioBtn}
                                            >
                                                <FormControlLabel value="All" control={<Radio/>} label="All"/>
                                                <FormControlLabel value="Current Employee" control={<Radio/>}
                                                                  label="Current Employee"/>
                                                <FormControlLabel value="Prospect Hire" control={<Radio/>}
                                                                  label="Prospect Hire"/>
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    :
                                    ""
                            }
                        </div>
                        <div style={{display: 'flex', marginBottom: '1rem',}}>
                            {
                                (accountType === accountTypes.superAdmin || accountType === accountTypes.mmDdiAdmin || accountType === accountTypes.mmPsychologist || accountType === accountTypes.mmChiefPsychologist) ?
                                    <div style={{marginRight: '1rem'}}>
                                        <select
                                            className={classes.dropdown}
                                            value={state.conference}
                                            onChange={handleCoachChange}
                                            name="conference"
                                        >
                                            <option value="" disabled selected hidden>
                                                Conference
                                            </option>
                                            {
                                                finalConference && finalConference.map(list =>
                                                    <option
                                                        className={classes.dropdownOptions}
                                                        key={list.name}
                                                        value={list.name}
                                                    >
                                                        {list.name}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    :
                                    ""
                            }
                            {
                                (accountType === accountTypes.superAdmin || accountType === accountTypes.mmDdiAdmin || accountType === accountTypes.mmPsychologist || accountType === accountTypes.mmChiefPsychologist || accountType === accountTypes.colAthleticDirector || accountType === accountTypes.colPsychologist) ?
                                    <div style={{margin: '0rem'}}>
                                        <select
                                            className={classes.dropdown}
                                            value={state.sport}
                                            onChange={handleSportChange}
                                            name="sport"
                                        >
                                            {
                                                sportsData && sportsData.map(list =>
                                                    <option
                                                        className={classes.dropdownOptions}
                                                        key={list.name}
                                                        value={list.name}
                                                    >
                                                        {list.name}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    :
                                    ""
                            }
                        </div>
                        <div style={{display: 'flex'}}>
                            {
                                (accountType === accountTypes.superAdmin || accountType === accountTypes.mmDdiAdmin || accountType === accountTypes.mmPsychologist || accountType === accountTypes.mmChiefPsychologist) ?
                                    <div style={{}}>
                                        <select
                                            className={classes.dropdown}
                                            value={state.unit}
                                            onChange={handleCoachChange}
                                            name="unit"
                                        >
                                            <option value="" disabled selected hidden>Unit</option>
                                            {
                                                unitData && unitData.map(list =>
                                                    <option
                                                        className={classes.dropdownOptions}
                                                        key={list.name}
                                                        value={list.unit}
                                                    >
                                                        {list.unit}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    :
                                    ""
                            }
                        </div>
                        <div style={{marginTop: '2rem'}}>
                            <Button className={classes.btnSecondary} onClick={handleCancel}>
                                Clear
                            </Button>
                            <Button className={classes.btnPrimary} onClick={handleSave}>
                                Run Report
                            </Button>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={3} className={classes.filterContainer}>
                    <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                        <img
                            src={filterDashedLogo}
                            alt="logo"
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

let mapStateToProps = (state) => {
    return {
        sportsData: state.sports.sportsListData,
        unitData: state.sports.unitSport,
        conferenceByType: state.conference.allConferenceByType,
        conferenceData: state.conference.allConferenceList.allData,
        filterCandidates: state.coach.filterCoaches,
        loadingFilters: state.ui.coach.loadingCoachFilters,
        updateFilter: state.coach.updateCoachFilter,
        isFiltered: state.coach.isFilterCoaches,
        accountType: state.account.accountType,
    };
};

export default connect(mapStateToProps)(Filter);