import React, { useContext } from "react";
import SummeryDialog from "./SummeryDialog";
import { ActionContext } from "../../../../contexts";
import { connect } from "react-redux";
import { EditorArticle } from "../../../shared/Editor";
import { EditorState, convertFromRaw } from "draft-js";

let Summery = (props) => {
  let { playerId, isEditable, summery, categoryId } = props;
  let { dialogActions } = useContext(ActionContext);

  let editorState = summery.blocks
    ? EditorState.createWithContent(convertFromRaw(summery))
    : EditorState.createEmpty();

  let handleEditClick = () => {
    dialogActions.openDialog(
      <SummeryDialog
        playerId={playerId}
        categoryId={categoryId}
        initialEditorState={editorState}
      />
    );
  };

  return (
    <EditorArticle
      onEdit={handleEditClick}
      editorState={editorState}
      isEditable={isEditable}
      placeholder="Psychologist comments pending"
    />
  );
};

let mapStateToProps = (state, ownProps) => {
  let { playerId, categoryId } = ownProps;
  return {
    summery: state.player.executiveSummery[`${playerId}.${categoryId}`],
  };
};
export default connect(mapStateToProps)(Summery);
