import React, { useState } from "react";
import ExpansionArticle from "../ExpansionArticle";
import ChartAndDetails from "../ChartAndDetails";
import { chartColors } from "../../../../store/constants";
import { connect } from "react-redux";
import { MainTab, ChildTab, TabPanel } from "../../../shared/Tabs";
import Grid from '@material-ui/core/Grid';
import {Box, makeStyles} from "@material-ui/core";

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  container: {
    flexGrow: 1,
    display: "flex",
    margin: "0px",
    backgroundColor: theme.palette.common.white,
    boxShadow: "3px 3px 10px 3px #cdcdcd",
    borderRadius: 8,
  },
  sideBarTitle: {
    fontFamily: theme.fontFamily.robotoCondensed,
    fontSize: '1rem',
    fontWeight: 700,
    color: theme.palette.text.primary,
    textTransform: 'capitalize',
  },
  selectedSideBarTitle: {
    fontFamily: theme.fontFamily.robotoCondensed,
    fontSize: '1rem',
    fontWeight: 700,
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
    backgroundColor: theme.colors.veryLightGrey,
  },
  tabPanel: {
    backgroundColor: theme.palette.common.white,
    width: '85%',
    padding: '2rem 1rem',
    borderRadius: "0px 8px 8px 0px",
  },
  content: {
    backgroundColor: theme.colors.veryLightGrey,
    width: '90%',
    alignSelf: 'center',
    marginBottom: '1rem',
    borderRadius: 5,
  },
}));

const PersonalityTraits = (props) => {
  let [selectedIndex, setSelectedIndex] = useState(0);
  let { traits } = props;
  const classes = useStyles();

  let agreeableness = traits["agreeableness"];
  let agreeablenessSubTraits = agreeableness["sub"];

  let conscientiousness = traits["conscientiousness"];
  let conscientiousnessSubTraits = conscientiousness["sub"];

  let emotionalStability = traits["emotional_range"];
  let emotionalStabilitySubTraits = emotionalStability["sub"];

  let extraversion = traits["extroversion"];
  let extraversionSubTraits = extraversion["sub"];

  let openness = traits["openness"];
  let opennessSubTraits = openness["sub"];

  let handleChange = (e, index) => {
    setSelectedIndex(index);
  };

  let handleColors = (score) => {
    if (0 <= score && score < 25) {
      return chartColors.red;
    } else if (25 <= score && score < 50) {
      return chartColors.orange;
    } else if (50 <= score && score < 75) {
      return chartColors.yellow;
    } else if (75 <= score && score < 100) {
      return chartColors.green;
    }
  };

  const labels= [
        "Agreeableness",
    "Conscientiousness",
    "Emotional Range",
    "Extraversion",
    "Openness",
]

  return (
      <div className={classes.root}>
        <Box className={classes.container}>
          <MainTab
              value={selectedIndex}
              onChange={handleChange}
              orientation="vertical"
              variant="scrollable"
              style={{ width: 250 }}
          >
            {labels.map((label, index) => (
                <ChildTab
                    key={label}
                    label={label}
                    classes={{
                      root: classes.tabRoot,
                      textColorInherit: selectedIndex === index ? classes.selectedSideBarTitle : classes.sideBarTitle,
                    }}
                />
            ))}
          </MainTab>
          <TabPanel value={selectedIndex} index={0} className={classes.tabPanel}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <ExpansionArticle
                    title="Overall Score"
                    details="Agreeableness refers to an individual's desire to get along with and please others. People high in agreeableness put other people's needs before their own, and have a preference for social harmony. Individuals low in agreeableness may exhibit more traits of competitiveness and may push for individual gain over team gain."
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={agreeableness["text"]}
                        details="hey"
                        score={agreeableness["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Consideration for Others"
                    details="Consideration for others refers to an individual's feelings of satisfaction or fulfillment when spending their time towards  helping others. "
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={agreeablenessSubTraits["consideration_for_others"]["text"]}
                        details="hey"
                        score={agreeablenessSubTraits["consideration_for_others"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Straightforwardness"
                    details="Straightforwardness refers to an individuals use of sincerity, genuineness and frankness in their communication style. Straightforward people tell you how it is and express their true feelings. "
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={agreeablenessSubTraits["straightforwardness"]["text"]}
                        details="hey"
                        score={agreeablenessSubTraits["straightforwardness"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Modesty"
                    details="Modesty refers to an individual's tendency to demonstrate humility or underplay their accomplishments. Modesty presents in an unassuming manner, and reflects how one portrays themself. "
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={agreeablenessSubTraits["modesty"]["text"]}
                        details="hey"
                        score={agreeablenessSubTraits["modesty"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Compliance"
                    details="Compliance relates to an individual's reaction to interpersonal conflict. Compliant people defer to others, inhibit aggression, and forgive and forget. The low scorer is aggressive, prefers to compete rather than to cooperate, and has no reluctance to express anger when necessary."
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={agreeablenessSubTraits["compliance"]["text"]}
                        details="hey"
                        score={agreeablenessSubTraits["compliance"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Sympathy"
                    details="Sympathy refers to an individual's tendency to feel compassion, concern, and internalize the feelings of others. Sympathetic people are good at listening to the needs of others without judgement."
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={agreeablenessSubTraits["sympathy"]["text"]}
                        details="hey"
                        score={agreeablenessSubTraits["sympathy"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Trust"
                    details="Trust refers to how an individual tends to view others relating to being fair, honest, or trusting others easily."
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={agreeablenessSubTraits["trust"]["text"]}
                        details="hey"
                        score={agreeablenessSubTraits["trust"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={selectedIndex} index={1} className={classes.tabPanel}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <ExpansionArticle
                    title="Overall Score"
                    details="Conscientiousness involves self-control, a strong work ethic, and a desire for tidiness or organization. Highly conscientious individuals are planners and carry out their assigned responsibilities purposefully. They can be strong willed and determined. Low conscientious individuals might struggle to execute their assigned duties, have lower self-control, and my be disorganized. Conscientiousness is associated with academic and occupational achievement."
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={conscientiousness["text"]}
                        details="hey"
                        score={conscientiousness["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Achievement Driven"
                    details="Achievement driven refers to an individual's ambition or drive and the effort they are willing to exert to reach stated goals. "
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={conscientiousnessSubTraits["achievement_driven"]["text"]}
                        details="hey"
                        score={conscientiousnessSubTraits["achievement_driven"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Deliberate"
                    details="Deliberation refers to the attention to forethought before making or acting on decisions."
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={conscientiousnessSubTraits["deliberate"]["text"]}
                        details="hey"
                        score={conscientiousnessSubTraits["deliberate"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Dependable"
                    details="Dependability refers to how seriously an individual takes is about upholding their obligations."
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={conscientiousnessSubTraits["dependable"]["text"]}
                        details="hey"
                        score={conscientiousnessSubTraits["dependable"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Orderliness"
                    details="Orderliness refers to an individual's need for structure in life. Orderly people are neat, tidy, and well-organized. They keep things in their proper places. People low on orderliness are unable to get organized and describe themselves as unmethodical. "
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={conscientiousnessSubTraits["orderliness"]["text"]}
                        details="hey"
                        score={conscientiousnessSubTraits["orderliness"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Self-Discipline"
                    details="Self-discipline refers to an individual's tendency to persevere through mundane and/or difficult tasks."
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={conscientiousnessSubTraits["self_discipline"]["text"]}
                        details="hey"
                        score={conscientiousnessSubTraits["self_discipline"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Sense of Competence"
                    details="Sense of competence refers to an individual's feelings regarding their own ability to succeed in the tasks they have set out to complete. High self-competence reflects the sense that one is capable, sensible, prudent, and effective."
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={conscientiousnessSubTraits["sense_of_competence"]["text"]}
                        details="hey"
                        score={conscientiousnessSubTraits["sense_of_competence"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={selectedIndex} index={2} className={classes.tabPanel}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <ExpansionArticle
                    title="Overall Score"
                    details="Emotional range refers to how an individual experiences emotions when under stress or faced with adversity. Individuals low in emotional range tend to experience less negative emotions under stressful situations and have higher stress tolerance and capacity for problem solving. Those higher in emotional range tend to be more reactive, experience more negative feelings, and have more difficulty coping."
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={emotionalStability["text"]}
                        details="hey"
                        score={emotionalStability["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Aggressive"
                    details="Aggression refers to an individual's tendency to demonstrate anger or hostility particularly when faced with challenging situations or adversity. This indicates how ready an individual is to experiences anger, the intensity of it, and related states such as frustration and bitterness."
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={emotionalStabilitySubTraits["aggressive"]["text"]}
                        details="hey"
                        score={emotionalStabilitySubTraits["aggressive"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Anxiety Prone"
                    details="Anxiety prone refers to an individual's tendency to experience apprehension, fear, and worry versus staying calm, cool and collected when faced with pressure or uncertainty. Nervousness, jitters and tension are feelings impacted by one's proneness to anxiety."
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={emotionalStabilitySubTraits["anxiety_prone"]["text"]}
                        details="hey"
                        score={emotionalStabilitySubTraits["anxiety_prone"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Pessimism"
                    details="Pessimism refers to how an individual experiences satisfaction and dissatisfaction with life generally speaking. This includes their tendency to experience or display a negative or melancholic body language or expression."
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={emotionalStabilitySubTraits["pessimism"]["text"]}
                        details="hey"
                        score={emotionalStabilitySubTraits["pessimism"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Impulsive"
                    details="Impulsiveness refers to an individual's tendency to submit to temptations. Impulsive individuals have low self-control and struggle preventing themselves from doing things they know they should not do or may regret doing."
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={emotionalStabilitySubTraits["impulsive"]["text"]}
                        details="hey"
                        score={emotionalStabilitySubTraits["impulsive"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Self Conscious"
                    details="Self-consciousness refers to an individual's reaction to how others may perceive them. Self-conscious individuals are uncomfortable around others, sensitive to ridicule, prone to feelings of inferiority, and may be shy and or feel social anxiety."
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={emotionalStabilitySubTraits["self_conscious"]["text"]}
                        details="hey"
                        score={emotionalStabilitySubTraits["self_conscious"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Stress Prone"
                    details="Stress prone refers to how an individual manages the stress in their environment and the level of impact that stress has on one's ability to continue to function at a high level."
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={emotionalStabilitySubTraits["stress_prone"]["text"]}
                        details="hey"
                        score={emotionalStabilitySubTraits["stress_prone"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={selectedIndex} index={3} className={classes.tabPanel}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <ExpansionArticle
                    title="Overall Score"
                    details="Extraversion examines the degree to which a person feels energized or uplifted when interacting with others. It reflects sociability, a tendency to be outwardly positive, friendly, and active, and seek out others. Extraverts are sociable and enjoy interacting with others, assertive, and like to keep busy.  Introverts are not the opposite of extraverts, but lack the strong characteristics of extraverts. Introverts are not cold or unfriendly, but are more reserved and may value being alone to help recharge."
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={extraversion["text"]}
                        details="hey"
                        score={extraversion["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Activity Level"
                    details="Activity-level refers to an individual's enjoyment for action and 'busyness' ,and the need for activity and engagement in their life."
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={extraversionSubTraits["activity_level"]["text"]}
                        details="hey"
                        score={extraversionSubTraits["activity_level"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Assertiveness"
                    details="Assertiveness refers to an individual's tendency to speak up and take charge of situations. Assertive individuals are comfortable expressing their ideas and needs. "
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={extraversionSubTraits["assertiveness"]["text"]}
                        details="hey"
                        score={extraversionSubTraits["assertiveness"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Positive Expression"
                    details="Positive expression refers to an individual's general joy or tendency to experience and demonstrate positive emotions, such as laughter, enthusiasm, joy, and cheerfulness. "
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={extraversionSubTraits["positive_expression"]["text"]}
                        details="hey"
                        score={extraversionSubTraits["positive_expression"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Excitement-Seeking"
                    details="Excitement-seeking refers to an individual's need or desire for thrills. Excitement-seeking individuals are seen as having high energy, enthusiasm, and have a preference for loud, bright environments. "
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={extraversionSubTraits["excitement_seeking"]["text"]}
                        details="hey"
                        score={extraversionSubTraits["excitement_seeking"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Warmth"
                    details="Warmth refers to an individual's willingness to be outgoing, and have positive, friendly interactions with new or unfamiliar people. "
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={extraversionSubTraits["warmth"]["text"]}
                        details="hey"
                        score={extraversionSubTraits["warmth"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Sociable"
                    details="Sociable refers to an individual's need or desire to be surrounded by other people and examines the degree to which an individual seeks out and enjoys social interaction. "
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={extraversionSubTraits["sociable"]["text"]}
                        details="hey"
                        score={extraversionSubTraits["sociable"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={selectedIndex} index={4} className={classes.tabPanel}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <ExpansionArticle
                    title="Overall Score"
                    details="Openness refers to an individual's general willingness to engage in new experiences and learn new things. People high in Openness have an active imagination and are willing to entertain novel ideas. Those low in Openness tend to be more conventional and practical."
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={openness["text"]}
                        details="hey"
                        score={openness["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Willingness to Experiment"
                    details="Willingness to experiment refers to an individual's eagerness to experience new things, and enjoy and seek out adventure. "
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={opennessSubTraits["willingness_to_experiment"]["text"]}
                        details="hey"
                        score={opennessSubTraits["willingness_to_experiment"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Artistic Interests"
                    details="Artistic Interest refers to an individual's desire to seek out creative or beautiful experiences, or appreciate and enjoy the arts. "
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={opennessSubTraits["artistic_interests"]["text"]}
                        details="hey"
                        score={opennessSubTraits["artistic_interests"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Emotionality"
                    details="Emotionality refers to the receptivity one has to acknowledging their inner feelings and emotions, and the value they place on emotion as an important part of life. "
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={opennessSubTraits["emotionality"]["text"]}
                        details="hey"
                        score={opennessSubTraits["emotionality"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Imagination"
                    details="Imagination refers to an individual's openness to fantasy and their inclination to creating a rich, creative life through their imagination. "
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={opennessSubTraits["imagination"]["text"]}
                        details="hey"
                        score={opennessSubTraits["imagination"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Intellectual Curiosity"
                    details="Intellectual curiosity refers to an individual's inclination toward intellectual or academic learning and desire to explore new, abstract ideas and concepts. Intellect does not refer to an individual's aptitude. "
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={opennessSubTraits["intellectual_curiosity"]["text"]}
                        details="hey"
                        score={opennessSubTraits["intellectual_curiosity"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpansionArticle
                    title="Open Mindedness"
                    details="Open-mindedness assesses the readiness to reexamine social, political, religious values; along with views towards tradition and certain social norms or rules.  "
                >
                  <div className={classes.content}>
                    <ChartAndDetails
                        diagnosis={opennessSubTraits["open_mindedness"]["text"]}
                        details="hey"
                        score={opennessSubTraits["open_mindedness"]["score"]}
                        handleColors={handleColors}
                    />
                  </div>
                </ExpansionArticle>
              </Grid>
            </Grid>
          </TabPanel>
        </Box>
      </div>
  );
};

let mapStateToProps = (state, ownProps) => {
  let {coachId} = ownProps;
  return {
    traits: state.coach.traits[coachId],
  };
};

export default connect(mapStateToProps)(PersonalityTraits);
