import ActionPlan from "./components/ActionPlan";
import Attributes from "./components/Attributes";
import ExecutiveSummary from "./components/ExecutiveSummery";
import Feed from "./components/Feed";
import Notes from "./components/Notes";
import PersonalInfo from "./components/PersonalInfo";
import PersonalityTraits from "./components/PersonalityTraits";
import PsychSummary from "./components/PsychSummary";
import Action from '../../assets/Action Plan.svg';
import Executive from '../../assets/Executive Summary.svg';
import DeepProfileIcons from '../../assets/DeepProfile.svg';
import FeedIcon from '../../assets/Feeds.svg';
import NotesIcons from '../../assets/Notes.svg';
import PersnalInfo from '../../assets/Personal Info.svg';
import Psychology from '../../assets/Psychology Summary.svg';
import Traits from '../../assets/Traits.svg';
import BeyondBig5 from "./components/BeyondBig5";
import { accountTypes } from "../../store/constants";

let personalInfo = {
  id: "personalInfo",
  title: "Personal Info",
  description: "Athlete's Basic Information and Contact Details",
  component: PersonalInfo,
  navName: "Personal Info",
  navIcon: PersnalInfo,
  alwaysAllow: true,
};

let executiveSummery = {
  id: "executiveSummery",
  title: "Executive Summary",
  description: "An overview of the Big 5 Personality Traits Analysis",
  component: ExecutiveSummary,
  navName: "Executive Summary",
  navIcon: Executive,
  alwaysAllow: true,
};

let personalityTraits = {
  id: "personalityTraits",
  title: "Personality Traits",
  description: "Big 5 Personality Traits and Subtraits Analysis",
  component: PersonalityTraits,
  navName: "Traits",
  navIcon: Traits,
  alwaysAllow: false,
};

let beyondbig5 = {
  id: "beyondbig5",
  title: "Beyond Big 5",
  description: "Additional measures to support performance and well-being",
  component: BeyondBig5,
  navName: "Beyond Big 5",
  navIcon: DeepProfileIcons,
  alwaysAllow: false,
};

let attributes = {
  id: "attributes",
  title: "Attributes",
  description: "An overview of the Athlete’s attributes",
  component: Attributes,
  navName: "Attributes",
  navIcon: DeepProfileIcons,
  alwaysAllow: false,
};

let psychSummary = {
  id: "psychSummary",
  title: "Psych Summary",
  description: "Recommended Actions by Psychologist(s)",
  component: PsychSummary,
  navName: "Psych Summary",
  navIcon: Psychology,
  alwaysAllow: false,
};

let actionPlan = {
  id: "actionPlan",
  title: "Action Plan",
  description: "Recommended Actions by Psychologist(s)",
  component: ActionPlan,
  navName: "Action Plan",
  navIcon: Action,
  alwaysAllow: false,
};

let feed = {
  id: "feed",
  title: "Feed",
  description: "Athlete's Digital Media Feeds",
  component: Feed,
  navName: "Feed",
  navIcon: FeedIcon,
  alwaysAllow: true,
};

let notes = {
  id: "notes",
  title: "Notes",
  description: "Additional notes about the Athlete",
  component: Notes,
  navName: "Notes",
  navIcon: NotesIcons,
  alwaysAllow: true,
};

const getConfig = (accountType) => {
  switch (accountType) {
    case accountTypes.superAdmin: {
      // allow all pages
      return {
        pages: {
          personalInfo,
          executiveSummery,
          personalityTraits,
          attributes,
          // deepProfile,
          beyondbig5,
          psychSummary,
          actionPlan,
          feed,
          notes,
          // moreInfo,
        },
      };
    }

    case accountTypes.mmDdiAdmin: {
      // allow all pages

      return {
        pages: {
          personalInfo,
          executiveSummery,
          personalityTraits,
          attributes,
          // deepProfile,
          beyondbig5,
          psychSummary,
          actionPlan,
          feed,
          notes,
          // moreInfo,
        },
      };
    }

    case accountTypes.mmPsychologist: {
      // allow all pages
      return {
        pages: {
          personalInfo,
          executiveSummery,
          personalityTraits,
          attributes,
          // deepProfile,
          beyondbig5,
          psychSummary,
          actionPlan,
          feed,
          notes,
          // moreInfo,
        },
      };
    }

    case accountTypes.mmChiefPsychologist: {
      return {
        pages: {
          personalInfo,
          executiveSummery,
          personalityTraits,
          attributes,
          // deepProfile,
          beyondbig5,
          psychSummary,
          actionPlan,
          feed,
          notes,
        },
      };
    }

    case accountTypes.orgPsychologist: {
      return {
        pages: {
          personalInfo,
          executiveSummery,
          personalityTraits,
          attributes,
          // deepProfile,
          beyondbig5,
          psychSummary,
          actionPlan,
          feed,
          notes,
        },
      };
    }

    case "FromTeams": {
      return {
        teamPages: {
          personalInfo,
          executiveSummery,
          psychSummary,
          notes,
        },
      };
    }

    default:
      // remove deep profile for other account types
      return {
        pages: {
          personalInfo,
          executiveSummery,
          personalityTraits,
          attributes,
          beyondbig5,
          psychSummary,
          actionPlan,
          feed,
          notes,
        },
      };
  }
};

export default getConfig;
