import React, { useEffect, useContext } from "react";
import Action from "./Action";
import withPaymentLock from "../withPaymentLock";
import { accountTypes } from "../../../../store/constants";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { ActionContext } from "../../../../contexts";
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: 'column',
  },
  container: {
    flexGrow: 1,
    width: '100%',
    display: "flex",
    margin: "0px",
    backgroundColor: theme.palette.common.white,
    boxShadow: "3px 3px 10px 3px #cdcdcd",
    borderRadius: 8,
    padding: '2rem 1rem',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'right',
    marginBottom: '1rem',
    width: '100%',
    marginRight: -20,
  },
  btnPrimary: {
    color: theme.palette.common.white,
    padding: '10px',
    backgroundColor: theme.colors.primaryNavy,
    width: '6rem',
    marginRight: '10px',
    borderRadius: '7px',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.colors.primaryNavy,
      border: `1px solid ${theme.colors.primaryNavy}`,
    },
    textTransform: 'uppercase',
  },
}));

let ActionPlan = (props) => {
  let classes = useStyles();
  let { playerActions, snackbarActions } = useContext(ActionContext);

  let categories = {
    connectionIsKey: "Connection is Key",
    establishTheEnvironment: "Establish the Environment",
    mentalMindset: "Mental Mindset",
    significantBackgroundInformation: "Significant Background Information",
    symptomsAndInformationCaptured: "Symptoms and Information Captured",
    custom: "Custom"
  };

  let { playerId, readOnly = false, accountType, actionPlan } = props;
  let hasEditPriviledges =
    accountType === accountTypes.mmChiefPsychologist ||
    accountType === accountTypes.superAdmin;
  let isEditable = !readOnly && hasEditPriviledges;

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  function formatDate(date) {
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join('') +
      +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join('')
    );
  }

  const [state, setState] = React.useState({
    openCard: false,
    customCards: []
  })

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      customCards: actionPlan && actionPlan[`${playerId}.${categories.custom}`]
    }))
  }, [actionPlan])
  const date = formatDate(new Date())

  function handleNewCard() {
    let data = {
      candidate_id: playerId,
      category: "Custom",
      content: {
        "blocks": [
          {
            "key": "clrlg",
            "text": "Untitled",
            "type": "header-three",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "8mp07",
            "text": "Psychologist comments pending...",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
            "data": {}
          }
        ],
        "entityMap": {}
      },
      _id: playerId + '-' + date
    };

    let success = () => {
      return snackbarActions.showSnackbar("New Note Added");
    };

    playerActions.addActionPlanContent(data, success, state.customCards);

    setState(prevState => ({
      ...prevState,
      customCards: [...state.customCards, data]
    }))

  }

  return (
      <div className={classes.root}>
        <div className={classes.btnContainer}>
          {(accountType === accountTypes.superAdmin || accountType === accountTypes.mmChiefPsychologist) ?
              <Button className={classes.btnPrimary} onClick={handleNewCard}>Add +</Button> : ''}
        </div>
        <div className={classes.container}>
          <Grid container spacing={1}>
          <Grid item xs={6}>
            <Action
                isEditable={isEditable}
                playerId={playerId}
                categoryId={categories.connectionIsKey}
                content={actionPlan && actionPlan[`${playerId}.${categories.connectionIsKey}`]}

            />
          </Grid>

          <Grid item xs={6}>
            <Action
                isEditable={isEditable}
                playerId={playerId}
                categoryId={categories.establishTheEnvironment}
                content={actionPlan && actionPlan[`${playerId}.${categories.establishTheEnvironment}`]}

            />
          </Grid>

          <Grid item xs={6}>
            <Action
                isEditable={isEditable}
                playerId={playerId}
                categoryId={categories.mentalMindset}
                content={actionPlan && actionPlan[`${playerId}.${categories.mentalMindset}`]}

            />
          </Grid>
          {
            state.customCards && state.customCards.length > 0 ?
                state.customCards?.map(list =>
                    <Grid item xs={6}>
                      <Action
                          isEditable={isEditable}
                          playerId={playerId}
                          categoryId={categories.custom}
                          content={list && list.content}
                          open={true}
                          id={list._id}
                          currentData={state.customCards}
                          customData={list}
                      />
                    </Grid>
                )
                :
                ""
          }
        </Grid>
        </div>
      </div>
  );
};

let mapStateToProps = (state) => {
  return {
    accountType: state.account.accountType,
    actionPlan: state.player.actionPlan
  };
};
export default connect(mapStateToProps)(withPaymentLock(ActionPlan));
