import { makeStyles } from "@material-ui/core/styles";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import WebIcon from "@material-ui/icons/Web";
import YoutubeIcon from "@material-ui/icons/YouTube";
import React, { useState } from "react";
import { connect } from "react-redux";
import { ChildTab, MainTab } from "../../../shared/Tabs";
import { sourceTypes } from "../../../../store/constants";
import FeedPanel from "./FeedPanel";
import GraphPanel from "./GraphPanel";

let useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
    },
    container: {
        flexGrow: 1,
        display: "flex",
        margin: "0px",
        backgroundColor: theme.palette.common.white,
        boxShadow: "3px 3px 10px 3px #cdcdcd",
        borderRadius: 8,
    },
    sideBarTitle: {
        fontFamily: theme.fontFamily.robotoCondensed,
        fontSize: '1rem',
        fontWeight: 700,
        color: theme.palette.text.primary,
        textTransform: 'capitalize',
    },
    selectedSideBarTitle: {
        fontFamily: theme.fontFamily.robotoCondensed,
        fontSize: '1rem',
        fontWeight: 700,
        textTransform: 'capitalize',
        color: theme.palette.primary.main,
        backgroundColor: theme.colors.veryLightGrey,
        minWidth: 150,
    },
    indicator: {
        backgroundColor: theme.palette.primary.main,
    },
    tabPanel: {
        backgroundColor: theme.palette.common.white,
        width: '100%',
    },
    brain: {
        fontFamily: theme.fontFamily.prometo,
        fontSize: 15,
        fontWeight: 700,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "0rem 2rem",
        width: "auto",
        height: "20vh",
        color: theme.palette.primary.main,
    },
}));

const Feed = (props) => {
  let classes = useStyles();
  let { playerId } = props;
  let [selectedIndex, setSelectedIndex] = useState(0);

  let handleTabChange = (e, index) => {
    setSelectedIndex(index);
  };

  const labels= [
      "Twitter",
      "Instagram",
      "Videos",
      "Articles"
  ]

  return (
      <div className={classes.root}>
          <div className={classes.container}>
              <MainTab
                  value={selectedIndex}
                  onChange={handleTabChange}
                  orientation="vertical"
                  variant="scrollable"
                  style={{ width: 250 }}
              >
                  {labels.map((label, index) => (
                      <ChildTab
                          key={label}
                          label={label}
                          classes={{
                              root: classes.tabRoot,
                              textColorInherit: selectedIndex === index ? classes.selectedSideBarTitle : classes.sideBarTitle,
                          }}
                      />
                  ))}
              </MainTab>
              <div style={{ width: '85%', padding: '2rem 1rem' }}>
                  <GraphPanel
                      playerId={playerId}
                      config={{
                          0: {
                              source: sourceTypes.twitter,
                              show: true,
                          },
                          1: {
                              source: sourceTypes.instagram,
                              show: true,
                          },
                          2: {
                              source: sourceTypes.youtube,
                              show: true,
                          },
                          3: {
                              source: sourceTypes.newspaper,
                              show: true,
                          },
                      }}
                      selectedIndex={selectedIndex}
                      className={classes.tabPanel}
                  />
                  <FeedPanel
                      playerId={playerId}
                      source="twitter"
                      index={0}
                      value={selectedIndex}
                      icon={
                          <TwitterIcon
                              style={{marginLeft: 8, marginRight: 8, color: "#1DA1F2"}}
                          />
                      }
                  />
                  <FeedPanel
                      playerId={playerId}
                      source="instagram"
                      index={1}
                      value={selectedIndex}
                      icon={
                          <InstagramIcon
                              style={{marginLeft: 8, marginRight: 8, color: "#C13584"}}
                          />
                      }
                  />
                  <FeedPanel
                      playerId={playerId}
                      source="youtube"
                      index={2}
                      value={selectedIndex}
                      icon={
                          <YoutubeIcon
                              style={{marginLeft: 8, marginRight: 8, color: "#ff2500"}}
                          />
                      }
                  />
                  <FeedPanel
                      playerId={playerId}
                      source="newspaper"
                      index={3}
                      value={selectedIndex}
                      icon={
                          <WebIcon
                              style={{marginLeft: 8, marginRight: 8, color: "#ffc107"}}
                          />
                      }
                  />
              </div>
          </div>
      </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { playerId } = ownProps;

  return {
    stats: state.player.stats[playerId],
  };
};

export default connect(mapStateToProps)(Feed);
