import {Box, FormControl, FormHelperText, Grid, InputLabel, makeStyles, MenuItem} from "@material-ui/core";
import {Field, Form, useFormikContext} from "formik";
import {GlobalCss} from "../../../../config/GlobalCss";
import {Select, TextField} from "formik-material-ui";
import {KeyboardDatePicker} from "formik-material-ui-pickers";
import {Title} from "../../../shared";
import {connect} from "react-redux";
import React, {useContext, useEffect, useState} from "react";
import {ActionContext} from "../../../../contexts";
import CircularProgress from "@material-ui/core/CircularProgress";

let useStyles = makeStyles((theme) => ({
    form: {
        paddingTop: theme.spacing(2),
        display: 'flex',
        flexGrow: 1,
    },
}));

let BasicInfo = (props) => {
  const classes = useStyles();
  const {
      basicInfo,
      allConferenceByType,
      countriesData,
      loadingSportsData,
      loadingSportPositions,
      sportsData,
      sportPositions,
      stateData,
      teams,
  } = props;
  const { values, errors, touched } = useFormikContext();
  let { sportsActions, conferenceActions } = useContext(ActionContext);
  const [positions, setPositions] = useState([]);
  const [units, setUnits] = useState([]);
  const sport = sportsData && sportsData.find(sport => sport.name === basicInfo.sport);

  useEffect(() => {
      if (sport) {
          sportsActions.getSportPositionDetails(sport._id);
      }
      if (basicInfo.homeCountry) {
          conferenceActions.getStatesByCountryName(basicInfo.homeCountry);
      }
      conferenceActions.getConferenceByType(basicInfo.conferenceType);
      if (allConferenceByType) {
          let conference = allConferenceByType && allConferenceByType.find(conference => conference.name === basicInfo.conference);
          if (conference) {
              conferenceActions.getSportsByConferenceId(conference.id);
          }
      }
  }, [basicInfo.conference, basicInfo.conferenceType, basicInfo.homeCountry, conferenceActions, sport, sportsActions]);

  const conferenceTypes = [
      "Professional",
      "Collegiate",
  ];

  const relationshipTypes = [
      "Single",
      "Married",
      "Unknown",
  ];

  const athleteTypes = [
      "Roster",
      "Recruit"
  ];

  if (sportPositions) {
      setUnits(sportPositions.reduce((arr, unit) => {
              if (!arr.includes(unit.unit_name)) {
                  arr.push(unit.unit_name);
              }
              return arr;
          }, []))
  }

  useEffect(() => {
      if (values.homeCountry) {
          conferenceActions.getStatesByCountryName(values.homeCountry);
      }
  }, [values.homeCountry]);

  useEffect(() => {
      if (values.conference_type) {
          conferenceActions.getConferenceByType(values.conferenceType);
      }
  }, [values.conference_type]);

  useEffect(() => {
      if (values.sport) {
          let findSport = sportsData && sportsData.find(sport => sport.name === values.sport);
          sportsActions.getSportPositionDetails(findSport._id);
      }
  }, [values.sport]);

  useEffect(() => {
      if (values.unit) {
          if (sportPositions) {
              setPositions(sportPositions
                  .filter(unit => unit.unit_name === values.unit)
                  .map(position => position.position));
          }
      }
  }, [values.unit]);

  useEffect(() => {
      if (sportPositions) {
          setPositions(sportPositions
              .filter(unit => unit.unit_name === unit)
              .map(position => position.position));
      }
  }, [sportPositions, basicInfo.unit]);

  const renderError = (error) => {
      if (typeof error === 'string') {
          return error;
      } else if (Array.isArray(error)) {
          return error.join(', ');
      } else if (typeof error === 'object' && error !== null) {
          return JSON.stringify(error);
      }
      return '';
  };

  return (
    <Grid container spacing={1}>
        {
            loadingSportsData && loadingSportPositions ? (
                <Box>
                    <CircularProgress />
                </Box>
            ) : (
                <Grid item xs={12} style={{ margin: '0px 1rem' }}>
                    <div style={{marginTop: '1rem'}}>
                    </div>
                    <Form autoComplete='off'>
                        <GlobalCss />
                        <div className={classes.form}>
                            <Grid item xs={6} spacing={2} style={{ marginRight: '2rem', minWidth: 600 }}>
                                <Title title="Basic Info"/>
                                <Grid item xs={12} spacing={1} style={{display: 'flex', marginTop: '1rem', justifyContent: 'space-between'}}>
                                    <Field
                                        name="firstName"
                                        label="First Name"
                                        placeholder="Athlete's First Name"
                                        component={TextField}
                                        variant="outlined"
                                        required
                                        autoFocus
                                        style={{ width: '49%' }}
                                    />
                                    <Field
                                        name="lastName"
                                        label="Last Name"
                                        placeholder="Athlete's Last Name"
                                        component={TextField}
                                        variant="outlined"
                                        required
                                        autoFocus
                                        style={{ width: '49%' }}
                                    />
                                </Grid>
                                <Grid item xs={12} spacing={1} style={{display: 'flex', marginTop: '1rem', justifyContent: 'space-between'}}>
                                    <Field
                                        label="Birthday"
                                        placeholder="MM / DD / YYYY"
                                        name="birthday"
                                        component={KeyboardDatePicker}
                                        views={["year", "month", "date"]}
                                        inputVariant="outlined"
                                        InputAdornmentProps={{ position: "end" }}
                                        openTo="year"
                                        format="MM/DD/YYYY"
                                        orientation="portrait"
                                        variant="inline"
                                        invalidDateMessage="Enter a valid date"
                                        disabled
                                        autoOk
                                        style={{ width: '49%' }}
                                    />
                                    <Field
                                        name="gender"
                                        label="Gender"
                                        placeholder="Gender"
                                        component={TextField}
                                        variant="outlined"
                                        autoFocus
                                        disabled
                                        style={{ width: '49%' }}
                                    />
                                </Grid>
                                <Grid item xs={12} spacing={1} style={{display: 'flex', marginTop: '1rem', justifyContent: 'space-between'}}>
                                    <Field
                                        name="race"
                                        label="Race/Ethnicity"
                                        placeholder="Race/Ethnicity"
                                        component={TextField}
                                        variant="outlined"
                                        autoFocus
                                        disabled
                                        style={{ width: '49%' }}
                                    />
                                    <FormControl variant="outlined" style={{width: '49%'}}>
                                        <InputLabel htmlFor="relationshipStatus-label">
                                            Relationship Status
                                        </InputLabel>
                                        <Field
                                            component={Select}
                                            name="relationshipStatus"
                                            label="Relationship Status"
                                            placeholder="Relationship Status"
                                            inputProps={{
                                                id: "relationshipStatus",
                                            }}
                                            required
                                        >
                                            {relationshipTypes &&
                                                relationshipTypes.map((list) => (
                                                    <MenuItem key={list} value={list}>
                                                        {list}
                                                    </MenuItem>
                                                ))}
                                        </Field>
                                        {touched["relationshipStatus"] && !!errors["relationshipStatus"] && (
                                            <FormHelperText>{renderError(errors["relationshipStatus"])}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} spacing={1} style={{display: 'flex', marginTop: '1rem', justifyContent: 'space-between'}}>
                                    <FormControl variant="outlined" style={{width: '49%'}}>
                                        <InputLabel htmlFor="homeCountry-label">
                                            Home Country
                                        </InputLabel>
                                        <Field
                                            component={Select}
                                            name="homeCountry"
                                            label="Home Country"
                                            placeholder="Home Country"
                                            inputProps={{
                                                id: "homeCountry",
                                            }}
                                            required
                                        >
                                            {countriesData &&
                                                Object.keys(countriesData).map((list) => (
                                                    <MenuItem key={list} value={list}>
                                                        {list}
                                                    </MenuItem>
                                                ))}
                                        </Field>
                                        {touched["homeCountry"] && !!errors["homeCountry"] && (
                                            <FormHelperText>{renderError(errors["homeCountry"])}</FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl variant="outlined" style={{width: '49%'}}>
                                        <InputLabel htmlFor="homeState-label">
                                            Home State
                                        </InputLabel>
                                        <Field
                                            component={Select}
                                            name="homeState"
                                            label="Home State"
                                            placeholder="Home State"
                                            inputProps={{
                                                id: "homeState",
                                            }}
                                            required
                                        >
                                            {stateData &&
                                                Object.keys(stateData).map((list) => (
                                                    <MenuItem key={list} value={list}>
                                                        {list}
                                                    </MenuItem>
                                                ))}
                                        </Field>
                                        {touched["homeState"] && !!errors["homeState"] && (
                                            <FormHelperText>{renderError(errors["homeState"])}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} spacing={1} style={{display: 'flex', marginTop: '1rem', justifyContent: 'space-between'}}>
                                    <Field
                                        name="hometown"
                                        label="Home Town"
                                        placeholder="Home Town"
                                        component={TextField}
                                        variant="outlined"
                                        autoFocus
                                        style={{ width: '49%' }}
                                    />
                                    <Field
                                        name="school"
                                        label="School"
                                        placeholder="School"
                                        component={TextField}
                                        variant="outlined"
                                        autoFocus
                                        style={{ width: '49%' }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={6} spacing={2} style={{ marginRight: '2rem', minWidth: 600 }}>
                                <Title title="Sport"/>
                                <FormControl variant="outlined" style={{marginTop: '1rem', width: '100%'}}>
                                    <InputLabel htmlFor="sport-label">
                                        Sport
                                    </InputLabel>
                                    <Field
                                        component={Select}
                                        name="sport"
                                        label="Sport"
                                        placeholder="Sport"
                                        inputProps={{
                                            id: "sport",
                                        }}
                                        required
                                        fullWidth
                                    >
                                        {sportsData &&
                                            sportsData.map((list) => (
                                                <MenuItem key={list._id} value={list.name}>
                                                    {list.name}
                                                </MenuItem>
                                            ))}
                                    </Field>
                                    {touched["sport"] && !!errors["sport"] && (
                                        <FormHelperText>{renderError(errors["sport"])}</FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl variant="outlined" style={{marginTop: '1rem', width: '100%'}}>
                                    <InputLabel htmlFor="team-label">
                                        Team
                                    </InputLabel>
                                    <Field
                                        component={Select}
                                        name="team"
                                        label="Team"
                                        placeholder="Team"
                                        inputProps={{
                                            id: "team",
                                        }}
                                        required
                                        fullWidth
                                    >
                                        {teams &&
                                            Object.keys(teams).map((list) => (
                                                <MenuItem key={list} value={list}>
                                                    {list}
                                                </MenuItem>
                                            ))}
                                    </Field>
                                    {touched["team"] && !!errors["team"] && (
                                        <FormHelperText>{renderError(errors["team"])}</FormHelperText>
                                    )}
                                </FormControl>
                                <Grid item xs={12} spacing={1} style={{display: 'flex', marginTop: '1rem', justifyContent: 'space-between'}}>
                                    <FormControl variant="outlined" style={{width: '49%'}}>
                                        <InputLabel htmlFor="conferenceType-label">
                                            League
                                        </InputLabel>
                                        <Field
                                            component={Select}
                                            name="conferenceType"
                                            label="Conference Type"
                                            placeholder="Conference Type"
                                            inputProps={{
                                                id: "conferenceType",
                                            }}
                                            required
                                            fullWidth
                                        >
                                            {conferenceTypes &&
                                                conferenceTypes.map((list) => (
                                                    <MenuItem key={list} value={list}>
                                                        {list}
                                                    </MenuItem>
                                                ))}
                                        </Field>
                                        {touched["conferenceType"] && !!errors["conferenceType"] && (
                                            <FormHelperText>{renderError(errors["conferenceType"])}</FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl variant="outlined" style={{width: '49%'}}>
                                        <InputLabel htmlFor="conference-label">
                                            Conference
                                        </InputLabel>
                                        <Field
                                            component={Select}
                                            name="conference"
                                            label="Conference "
                                            placeholder="Conference "
                                            inputProps={{
                                                id: "conference",
                                            }}
                                            required
                                            fullWidth
                                        >
                                            {allConferenceByType &&
                                               allConferenceByType.map((list) => (
                                                    <MenuItem key={list.id} value={list.name}>
                                                        {list.name}
                                                    </MenuItem>
                                                ))}
                                        </Field>
                                        {touched["conference"] && !!errors["conference"] && (
                                            <FormHelperText>{renderError(errors["conference"])}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} spacing={1} style={{display: 'flex', marginTop: '1rem', justifyContent: 'space-between'}}>
                                    <FormControl variant="outlined" style={{width: '49%'}}>
                                        <InputLabel htmlFor="unit-label">
                                            Unit
                                        </InputLabel>
                                        <Field
                                            component={Select}
                                            name="unit"
                                            label="Unit "
                                            placeholder="Unit "
                                            inputProps={{
                                                id: "unit",
                                            }}
                                            required
                                            fullWidth
                                        >
                                            {units &&
                                                units.map((list) => (
                                                    <MenuItem key={list} value={list}>
                                                        {list}
                                                    </MenuItem>
                                                ))}
                                        </Field>
                                        {touched["unit"] && !!errors["unit"] && (
                                            <FormHelperText>{renderError(errors["unit"])}</FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl variant="outlined" style={{width: '49%'}}>
                                        <InputLabel htmlFor="position-label">
                                            Position
                                        </InputLabel>
                                        <Field
                                            component={Select}
                                            name="position"
                                            label="Position "
                                            placeholder="Position "
                                            inputProps={{
                                                id: "position",
                                            }}
                                            required
                                            fullWidth
                                        >
                                            <MenuItem key={1} value={basicInfo.position}>{basicInfo.position}</MenuItem>
                                            {positions &&
                                                positions.map((list) => (
                                                    <MenuItem key={list} value={list}>
                                                        {list}
                                                    </MenuItem>
                                                ))}
                                        </Field>
                                        {touched["position"] && !!errors["position"] && (
                                            <FormHelperText>{renderError(errors["position"])}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} spacing={1} style={{display: 'flex', margin: '1rem 0rem', justifyContent: 'space-between'}}>
                                    <FormControl variant="outlined" style={{width: '49%'}}>
                                        <InputLabel htmlFor="athleteType-label">
                                            Athlete Type
                                        </InputLabel>
                                        <Field
                                            component={Select}
                                            name="athleteType"
                                            label="Athlete Type"
                                            placeholder="Athlete Type"
                                            inputProps={{
                                                id: "athleteType",
                                            }}
                                            required
                                        >
                                            {athleteTypes &&
                                                athleteTypes.map((list) => (
                                                    <MenuItem key={list} value={list}>
                                                        {list}
                                                    </MenuItem>
                                                ))}
                                        </Field>
                                        {touched["athleteType"] && !!errors["athleteType"] && (
                                            <FormHelperText>{renderError(errors["athleteType"])}</FormHelperText>
                                        )}
                                    </FormControl>
                                    <Field
                                        name="risk"
                                        label="Risk"
                                        component={TextField}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        autoFocus
                                        disabled
                                        style={{width: '49%'}}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Form>
                </Grid>
            )
        }
    </Grid>
  );
};

let mapStateToProps = (state) => {
    return {
        loadingSportsData: state.ui.sports.sportsListLoading,
        loadingSportPositions: state.ui.sports.loadingSportsPositions,
        countriesData: state.conference.allCountries,
        stateData: state.conference.allStateByCountry,
        sportPositions: state.sports.sportPosition,
        allConferenceByType: state.conference.allConferenceByType,
        sportsData: state.sports.sportsListData,
        teams: state.ui.player.candidateTeams,
    };
};

export default connect(mapStateToProps)(BasicInfo);
