import React, {useContext, useEffect, useState} from 'react'
import {
    Button,
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputLabel,
    IconButton,
    MenuItem,
    makeStyles,
    Radio,
    RadioGroup,
    Select,
    Typography
} from "@material-ui/core"
import { connect } from "react-redux";
import { ActionContext } from "../../../contexts";
import filterDashedLogo from "../../../assets/FilterDashedIcon.svg";
import ClearIcon from "@material-ui/icons/ClearOutlined";
import { useSelector } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { accountTypes } from "../../../store/constants";

let useStyles = makeStyles((theme) => ({
    btnPrimary: {
        color: theme.palette.common.white,
        padding: '10px',
        backgroundColor: theme.colors.primaryNavy,
        width: '13rem',
        marginRight: '10px',
        borderRadius: '7px',
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            color: theme.colors.primaryNavy,
            border: `1px solid ${theme.colors.primaryNavy}`,
        },
        textTransform: 'uppercase',
    },
    btnSecondary: {
        color: theme.palette.secondary.main,
        border: '1px solid',
        width: '13rem',
        padding: '10px',
        marginRight: '10px',
        borderRadius: '7px',
        '&:hover': {
            background: theme.palette.secondary.main,
            color: theme.palette.common.white,
        },
        textTransform: 'uppercase',
    },
    container: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        boxShadow: `3px 3px 10px 3px ${theme.colors.lightGrey}`,
        marginLeft: '-2rem',
        padding: '2rem',
        borderRadius: 8,
        marginTop: 50,
        width: '100%',
    },
    filterContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up(1240)]: {
            marginLeft: '4rem',
        },
        [theme.breakpoints.up(1440)]: {
            marginLeft: '6rem',
        },
        [theme.breakpoints.up(1680)]: {
            marginLeft: '8rem',
        },
        [theme.breakpoints.up(1920)]: {
            marginLeft: '10rem',
        },
    },
    backBtnContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontWeight: 700,
        fontSize: theme.fontSize.md,
        fontFamily: theme.fontFamily.robotoCondensed,
        color: theme.colors.primaryNavy,
        textTransform: 'uppercase',
        cursor: 'pointer',
        maxWidth: 70,
    },
    title: {
        display: 'flex',
        justifyContent: 'flex-start',
        fontWeight: 800,
        fontSize: theme.fontSize.lg,
        margin: '1.5rem 0rem',
        fontFamily: theme.fontFamily.prometo,
        color: theme.colors.primaryNavy,
        textTransform: 'uppercase',
    },
    subtitle: {
        display: 'flex',
        justifyContent: 'flex-start',
        fontWeight: 600,
        fontSize: theme.fontSize.md,
        fontFamily: theme.fontFamily.robotoCondensed,
        margin: '2rem 0rem',
        color: theme.palette.secondary.main,
    },
    radioBtnContainer: {
        margin: '1rem 0rem',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: theme.fontFamily.robotoCondensed,
    },
    radioBtn: {
        color: theme.colors.primaryNavy,
    },
    dropdown: {
        padding: '8px 15px',
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        paddingLeft: '10px',
        width: "13rem",
        borderRadius: 5,
        color: theme.colors.darkBlue60Percent,
        backgroundColor: theme.colors.lightGrey,
        fontFamily: theme.fontFamily.robotoCondensed,
        fontWeight: 500,
        border: 'none',
        height: 50,
    },
    dropdownOptions: {
        background: theme.colors.white,
        border: `1px solid ${theme.colors.white}`,
        padding: '20px',
        fontSize: 15,
        overflowY: 'auto',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
    },
}));

function Filter(props) {

    const { accountType, isFilteredApply, pageChange, conferenceData, sportsData, unitData, sportPosition, conferenceByType, loadingFilters, updateFilter, teams } = props;
    let classes = useStyles();
    let actions = useContext(ActionContext);
    let { playerActions, sportsActions, conferenceActions } = actions;

    let accountState = useSelector((state) => state.account);

    useEffect(() => {
        playerActions.getTeamByName(accountState.team)
    })

    const [state, setState] = useState({
        conference: "",
        sport: "",
        unit: "",
        position: "",
        athleteType: "All",
        conferenceType: "All",
        attribute: "",
        attributeSort: "",
        team: ""
    });

    const [behavior, setBehavior] = useState('');
    const [behaviorSort, setBehaviorSort] = useState('');

    useEffect(() => {
        if (updateFilter) {
            setState(updateFilter);
            setBehavior(updateFilter.behaviorRisk);
            setBehaviorSort(updateFilter.behaviorRiskSort);
        }
    }, [updateFilter]);

    const handlePlayerChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
        playerActions.updateFilterValue(event.target.name, event.target.value)
    };

    const handleConferenceChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
        playerActions.updateFilterValue(event.target.name, event.target.value)
        if (event.target.value === "All") {
            conferenceActions.getConferenceList();
        } else {
            conferenceActions.getConferenceByType(event.target.value);
            setState({
                ...state,
                "conference": "",
            });
            playerActions.updateFilterValue("conference", "")
        }
    };

    const handleSportChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
            "unit": "",
            "position": ""
        });

        playerActions.updateFilterValue(event.target.name, event.target.value)
        sportsActions.getSportUnitDetails(event.target.value);
        playerActions.updateFilterValue("unit", "")
        playerActions.updateFilterValue("position", "")
        sportsActions.resetSportsApi()
    };

    const handleTeamChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });

        playerActions.updateFilterValue(event.target.name, event.target.value)
    };

    const handleUnitChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });

        playerActions.updateFilterValue(event.target.name, event.target.value)
        sportsActions.getSportPositionDetails(event.target.value, state.sport);
    };

    const handleClearClick = (name) => {
        if (name === "unit") {
            setState({
                ...state,
                "unit": "",
                "position": ""
            });

            playerActions.updateFilterValue("unit", "")
            playerActions.updateFilterValue("position", "")
        } else if (name === "sport") {
            setState({
                ...state,
                "sport": "",
                "unit": "",
                "position": ""
            });

            playerActions.updateFilterValue("sport", "")
            playerActions.updateFilterValue("unit", "")
            playerActions.updateFilterValue("position", "")
        }
        else if (name === "conference") {
            setState({
                ...state,
                "conference": "",
            });

            playerActions.updateFilterValue("conference", "")
        }
        else if (name === "behaviorRisk") {
            setBehavior("")
            setBehaviorSort("")
            playerActions.updateFilterValue("behaviorRisk", "")
            playerActions.updateFilterValue("behaviorRiskSort", "")
        }
        else if (name === "attribute") {
            setState({
                ...state,
                "attribute": "",
                "attributeSort": "",

            });
            playerActions.updateFilterValue("attribute", "")
            playerActions.updateFilterValue("attributeSort", "")

        }
        else if (name === "behaviorRiskSort") {
            setBehaviorSort("")
            playerActions.updateFilterValue("behaviorRiskSort", "")
        }
        else {
            setState({
                ...state,
                [name]: "",
            });
            playerActions.updateFilterValue(name, "")
        }

        if (accountType === accountTypes.colHeadCoach || accountType === accountTypes.colPositionCoach || accountType === accountTypes.proPositionCoach || accountType === accountTypes.proGeneralManager || accountType === accountTypes.proHeadCoach || accountType === accountTypes.proSecurity) {
            if (name === "unit") {
                sportsActions.resetSportsApi()
            }
        } else {
            if (name === "sport") {
                sportsActions.resetUnitsApi()
                sportsActions.resetSportsApi()
            } else if (name === "unit") {
                sportsActions.resetSportsApi()
            }
        }
    };

    const handleCancel = () => {
        playerActions.resetFilterValue()
        if (accountType === accountTypes.colHeadCoach || accountType === accountTypes.colPositionCoach || accountType === accountTypes.proPositionCoach || accountType === accountTypes.proGeneralManager || accountType === accountTypes.proHeadCoach || accountType === accountTypes.proSecurity) {
            sportsActions.resetSportsApi()
        } else {
            sportsActions.resetUnitsApi()
            sportsActions.resetSportsApi()
        }
    };

    const handleSave = () => {
        let model = {
            conference: state.conference ? state.conference : '',
            sport: state.sport ? state.sport : '',
            unit: state.unit ? state.unit : '',
            position: state.position ? state.position : '',
            athleteType: state.athlete_type === "All" ? '' : state.athlete_type,
            conferenceType: state.conference_type === "All" ? '' : state.conference_type,
            attribute: state.attribute ? state.attribute : '',
            behavior: behavior ? behavior : '',
            attributeSort: state.attribute_sort ? state.attribute_sort : '',
            behaviorSort: behaviorSort ? behaviorSort : '',
            team : state.team ? state.team : ''
        }

        playerActions.getAllFiltersCandidates(model)
    };

    useEffect(() => {
        conferenceActions.getConferenceList();
        sportsActions.getSportsList();
        playerActions.getTeamList();
    }, []);

    useEffect(() => {
        if (loadingFilters === true) {
            pageChange('list')
            isFilteredApply(true)
            playerActions.isFilteredData(true)
        }
    }, [loadingFilters]);

    useEffect(() => {
        if (accountType === accountTypes.colHeadCoach || accountType === accountTypes.colPositionCoach || accountType === accountTypes.proPositionCoach || accountType === accountTypes.proGeneralManager || accountType === accountTypes.proHeadCoach || accountType === accountTypes.proSecurity) {

            if (accountState?.sport) {
                playerActions.updateFilterValue("sport", accountState?.sport)
                sportsActions.getSportUnitDetails(accountState?.sport);
            } else {
                playerActions.updateFilterValue("sport", "Football")
                sportsActions.getSportUnitDetails("Football");
            }

        }

    }, [accountType]);

    const finalConference = state.conferenceType !== "All" ? conferenceByType : conferenceData;

    const handleAttributeChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });

        playerActions.updateFilterValue(event.target.name, event.target.value);
    };

    const handleBehaviorChange = (event) => {
        setBehavior(event.target.value)
        playerActions.updateFilterValue(event.target.name, event.target.value);
    };

    const handleBehaviorSortChange = (event) => {
        setBehaviorSort(event.target.value)
        playerActions.updateFilterValue(event.target.name, event.target.value);
    };

    const redirectToAthletePage = () => {
        pageChange('list')
    };

    const attributesTypes = {
        Character: 'Character',
        Coachability: 'Coachability',
        Composure: 'Composure',
        Leadership: 'Leadership'
    };

    return (
        <div className={classes.container}>
            <Grid container spacing={1}>
                <Grid item xs={6} className={classes.filterContainer}>
                    <Box className={classes.backBtnContainer} onClick={redirectToAthletePage}>
                        <ArrowBackIosIcon style={{fontSize: "15px"}}/>
                        Back
                    </Box>
                    <div>
                        <Typography className={classes.title}>
                            Blue Chip Filter
                        </Typography>
                        <Typography className={classes.subtitle}>
                            Select all that are necessary
                        </Typography>
                        <div>
                            {
                                (accountType === accountTypes.superAdmin || accountType === accountTypes.mmDdiAdmin || accountType === accountTypes.mmChiefPsychologist || accountType === accountTypes.mmPsychologist || accountType === accountTypes.proGeneralManager || accountType === accountTypes.proHeadCoach) ?
                                    <div className={classes.radioBtnContainer}>
                                        <Box>
                                            <FormControl>
                                                <FormLabel id="demo-radio-buttons-group-label">League</FormLabel>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue=""
                                                    row
                                                    name="conferenceType"
                                                    value={state.conferenceType}
                                                    className={classes.radioBtn}
                                                    onChange={handleConferenceChange}
                                                >
                                                    <FormControlLabel value="All" control={<Radio/>} label="All"/>
                                                    <FormControlLabel value="Professional" control={<Radio/>}
                                                                      label="Professional"/>
                                                    <FormControlLabel value="Collegiate" control={<Radio/>}
                                                                      label="Collegiate"/>
                                                </RadioGroup>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    :
                                    ""
                            }
                            {
                                (accountType === accountTypes.superAdmin || accountType === accountTypes.mmDdiAdmin || accountType === accountTypes.mmPsychologist || accountType === accountTypes.colAthleticDirector || accountType === accountTypes.colHeadCoach || accountType === accountTypes.colPositionCoach || accountType === accountTypes.proPositionCoach || accountType === accountTypes.proGeneralManager || accountType === accountTypes.proHeadCoach || accountType === accountTypes.colPsychologist || accountType === accountTypes.proPsychologist || accountType === accountTypes.mmChiefPsychologist || accountType === accountTypes.proSecurity
                                ) ?
                                    <div className={classes.radioBtnContainer}>
                                        <FormControl>
                                            <FormLabel id="demo-radio-buttons-group-label">Athlete Type</FormLabel>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue=""
                                                row
                                                name="athleteType"
                                                value={state.athleteType}
                                                onChange={handlePlayerChange}
                                                className={classes.radioBtn}
                                            >
                                                <FormControlLabel value="All" control={<Radio/>} label="All"/>
                                                <FormControlLabel value="Roster" control={<Radio/>} label="Roster"/>
                                                <FormControlLabel value="Recruit" control={<Radio/>} label="Recruit"/>
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    :
                                    ""
                            }
                        </div>
                        <div style={{display: 'flex', marginBottom: '1rem'}}>
                            {
                                (accountType === accountTypes.superAdmin || accountType === accountTypes.mmDdiAdmin || accountType === accountTypes.mmPsychologist || accountType === accountTypes.mmChiefPsychologist) ?
                                    <div style={{marginRight: '1rem'}}>
                                        <select
                                            className={classes.dropdown}
                                            value={state.conference}
                                            onChange={handlePlayerChange}
                                            name="conference"
                                            endAdornment={
                                                state.conference !== "" ?
                                                    <IconButton
                                                        sx={{visibility: state.conference !== "" ? "visible" : "hidden"}}
                                                        onClick={() => handleClearClick("conference")}><ClearIcon/>
                                                    </IconButton>
                                                    :
                                                    ""
                                            }
                                        >
                                            <option value="" disabled selected hidden>Conference</option>
                                            {
                                                finalConference && finalConference.map(list =>
                                                    <option
                                                        className={classes.dropdownOptions}
                                                        key={list.name}
                                                        value={list.name}>
                                                        {list.name}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    :
                                    ""
                            }
                            {
                                (accountType === accountTypes.superAdmin || accountType === accountTypes.mmDdiAdmin || accountType === accountTypes.mmPsychologist || accountType === accountTypes.colAthleticDirector || accountType === accountTypes.colPsychologist || accountType === accountTypes.proPsychologist || accountType === accountTypes.mmChiefPsychologist) ?

                                    <div style={{margin: '0rem'}}>
                                        <select
                                            className={classes.dropdown}
                                            id="demo-simple-select-autowidth"
                                            value={state.sport}
                                            onChange={handleSportChange}
                                            name="sport"
                                            endAdornment={
                                                state.sport !== "" ?
                                                    <IconButton
                                                        sx={{visibility: state.sport !== "" ? "visible" : "hidden"}}
                                                        onClick={() => handleClearClick("sport")}><ClearIcon/>
                                                    </IconButton>
                                                    :
                                                    ""
                                            }
                                        >
                                            <option value="" disabled selected hidden>Conference</option>
                                            {
                                                sportsData && sportsData.map(list =>
                                                    <option
                                                        className={classes.dropdownOptions}
                                                        key={list.name}
                                                        value={list.name}
                                                    >
                                                        {list.name}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    :
                                    ""
                            }
                        </div>
                        <div style={{display: 'flex', marginBottom: '1rem'}}>
                            {
                                (accountType === accountTypes.superAdmin || accountType === accountTypes.mmDdiAdmin || accountType === accountTypes.mmPsychologist || accountType === accountTypes.mmChiefPsychologist) ?
                                    <div style={{marginRight: '1rem'}}>
                                        <select
                                            className={classes.dropdown}
                                            value={state.team}
                                            onChange={handleTeamChange}
                                            name="team"
                                            endAdornment={
                                                state.team !== "" ?
                                                    <IconButton
                                                        sx={{visibility: state.team !== "" ? "visible" : "hidden"}}
                                                        onClick={() => handleClearClick("team")}><ClearIcon/>
                                                    </IconButton>
                                                    :
                                                    ""
                                            }
                                        >
                                            <option value="" disabled selected hidden>Team</option>
                                            {
                                                teams && Object.keys(teams).map(list =>
                                                    <option
                                                        className={classes.dropdownOptions}
                                                        key={list}
                                                        value={list}>
                                                        {list}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    :
                                    ""
                            }
                            {
                                (accountType === accountTypes.superAdmin || accountType === accountTypes.mmDdiAdmin || accountType === accountTypes.mmPsychologist || accountType === accountTypes.colAthleticDirector || accountType === accountTypes.colHeadCoach || accountType === accountTypes.colPositionCoach || accountType === accountTypes.proPositionCoach || accountType === accountTypes.proGeneralManager || accountType === accountTypes.proHeadCoach || accountType === accountTypes.mmChiefPsychologist || accountType === accountTypes.proSecurity
                                ) ?
                                    <div style={{margin: '0rem'}}>
                                        <select
                                            className={classes.dropdown}
                                            value={state.unit}
                                            onChange={handleUnitChange}
                                            name="unit"
                                            endAdornment={
                                                state.unit !== "" ?
                                                    <IconButton
                                                        sx={{visibility: state.unit !== "" ? "visible" : "hidden"}}
                                                        onClick={() => handleClearClick("unit")}><ClearIcon/>
                                                    </IconButton>
                                                    :
                                                    ""
                                            }
                                        >
                                            <option value="" disabled selected hidden>Unit</option>
                                            {
                                                unitData && unitData.map(list =>
                                                    <option
                                                        className={classes.dropdownOptions}
                                                        key={list.unit}
                                                        value={list.unit}
                                                    >
                                                        {list.unit}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    :
                                    ""
                            }
                        </div>
                        <div style={{display: 'flex',}}>
                            {
                                (accountType === accountTypes.superAdmin || accountType === accountTypes.mmDdiAdmin || accountType === accountTypes.mmPsychologist || accountType === accountTypes.colAthleticDirector || accountType === accountTypes.colHeadCoach || accountType === accountTypes.colPositionCoach || accountType === accountTypes.proPositionCoach || accountType === accountTypes.proGeneralManager || accountType === accountTypes.proHeadCoach || accountType === accountTypes.mmChiefPsychologist || accountType === accountTypes.proSecurity
                                ) ?
                                    <div style={{margin: '0rem'}}>
                                        <select
                                            className={classes.dropdown}
                                            value={state.position}
                                            onChange={handlePlayerChange}
                                            name="position"
                                            endAdornment={
                                                state.position !== "" ?
                                                    <IconButton
                                                        sx={{visibility: state.position !== "" ? "visible" : "hidden"}}
                                                        onClick={() => handleClearClick("position")}><ClearIcon/>
                                                    </IconButton>
                                                    :
                                                    ""
                                            }
                                        >
                                            <option value="" disabled selected hidden>Position</option>
                                            {
                                                sportPosition && sportPosition.map(list =>
                                                    <option
                                                        className={classes.dropdownOptions}
                                                        key={list.position}
                                                        value={list.position}
                                                    >
                                                        {list.position}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    :
                                    ""
                            }
                        </div>
                        <div style={{display: 'flex'}}>
                            {
                                (accountType === accountTypes.colPsychologist || accountType === accountTypes.proPsychologist) ?
                                    <div style={{marginBottom: '1rem', marginRight: '1rem'}}>
                                        <select
                                            className={classes.dropdown}
                                            value={state.attribute}
                                            onChange={handleAttributeChange}
                                            name="attribute"
                                            endAdornment={
                                                state.attribute !== "" ?
                                                    <IconButton
                                                        sx={{visibility: state.attribute !== "" ? "visible" : "hidden"}}
                                                        onClick={() => handleClearClick("attribute")}><ClearIcon/>
                                                    </IconButton>
                                                    :
                                                    ""
                                            }
                                        >
                                            <option value="" disabled selected hidden>Attributes</option>
                                            {
                                                attributesTypes && Object.keys(attributesTypes).map(list =>
                                                    <option
                                                        className={classes.dropdownOptions}
                                                        key={list}
                                                        value={list}
                                                    >
                                                        {list}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    :
                                    ""
                            }
                            {
                                (accountType === accountTypes.colPsychologist || accountType === accountTypes.proPsychologist) ?

                                    <div style={{}}>
                                        <FormControl variant="filled" sx={{m: 1, minWidth: 80}}>
                                            <InputLabel id="demo-simple-select-autowidth-label">Attribute
                                                Sort</InputLabel>
                                            <Select
                                                style={{width: "200px"}}
                                                labelId="demo-simple-select-autowidth-label"
                                                id="demo-simple-select-autowidth"
                                                value={state.attributeSort}
                                                onChange={handleAttributeChange}
                                                autoWidth
                                                label="Attribute"
                                                name="attributeSort"
                                                endAdornment={
                                                    state.attributeSort !== "" ?
                                                        <IconButton
                                                            sx={{visibility: state.attributeSort !== "" ? "visible" : "hidden"}}
                                                            onClick={() => handleClearClick("attributeSort")}><ClearIcon/>
                                                        </IconButton>
                                                        :
                                                        ""
                                                }
                                            >
                                                <MenuItem value='High to Low'>High to Low</MenuItem>
                                                <MenuItem value='Low to High'>Low to High</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    :
                                    ""
                            }
                        </div>
                        <div style={{display: 'flex'}}>
                            {
                                (accountType === accountTypes.colPsychologist || accountType === accountTypes.proPsychologist) ?
                                    <div style={{marginBottom: '1rem', marginRight: '1rem'}}>
                                        <FormControl variant="filled" sx={{m: 1, minWidth: 80}}>
                                            <InputLabel id="demo-simple-select-autowidth-label">Behavior
                                                Risk</InputLabel>
                                            <Select
                                                style={{width: "200px"}}
                                                labelId="demo-simple-select-autowidth-label"
                                                id="demo-simple-select-autowidth"
                                                value={behavior}
                                                onChange={handleBehaviorChange}
                                                autoWidth
                                                label="BehaviorRisk"
                                                name="behaviorRisk"
                                                endAdornment={
                                                    behavior !== "" ?
                                                        <IconButton
                                                            sx={{visibility: behavior !== "" ? "visible" : "hidden"}}
                                                            onClick={() => handleClearClick("behaviorRisk")}><ClearIcon/>
                                                        </IconButton>
                                                        :
                                                        ""
                                                }
                                            >
                                                <MenuItem value='Aggression/Violence'>Aggression/Violence</MenuItem>
                                                <MenuItem value='Delinquent Behavior'>Delinquent Behavior</MenuItem>
                                                <MenuItem value='Drug/Alcohol'>Drug & Alcohol</MenuItem>
                                                <MenuItem value='Emotional Distress'>Emotional Distress</MenuItem>
                                                <MenuItem value='Sexual Behavior'>Sexual Behavior</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    :
                                    ""
                            }
                            {
                                (accountType === accountTypes.colPsychologist || accountType === accountTypes.proPsychologist) ?

                                    <div style={{}}>
                                        <FormControl variant="filled" sx={{m: 1, minWidth: 80}}>
                                            <InputLabel id="demo-simple-select-autowidth-label">Behavior Risk
                                                Sort</InputLabel>
                                            <Select
                                                style={{width: "200px"}}
                                                labelId="demo-simple-select-autowidth-label"
                                                id="demo-simple-select-autowidth"
                                                value={behaviorSort}
                                                onChange={handleBehaviorSortChange}
                                                autoWidth
                                                label="BehaviorRisk"
                                                name="behaviorRiskSort"
                                                endAdornment={
                                                    behaviorSort !== "" ?
                                                        <IconButton
                                                            sx={{visibility: behaviorSort !== "" ? "visible" : "hidden"}}
                                                            onClick={() => handleClearClick("behaviorRiskSort")}><ClearIcon/>
                                                        </IconButton>
                                                        :
                                                        ""
                                                }
                                            >
                                                <MenuItem value='High to Low'>High to Low</MenuItem>
                                                <MenuItem value='Low to High'>Low to High</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    :
                                    ""
                            }
                        </div>
                        <div style={{marginTop: '2rem'}}>
                            <Button className={classes.btnSecondary} onClick={handleCancel}>
                                Clear
                            </Button>
                            <Button className={classes.btnPrimary} onClick={handleSave}>
                                Run Report
                            </Button>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={3} className={classes.filterContainer}>
                    <div style={{display: 'flex', alignItems: 'center', height: '100%', maxWidth: 300}}>
                        <img
                            src={filterDashedLogo}
                            alt="logo"
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

let mapStateToProps = (state) => {
    return {
        sportsData: state.sports.sportsListData,
        stateData: state.conference.allStateByCountry,
        unitData: state.sports.unitsBySportId,
        sportPosition: state.sports.sportPosition,
        conferenceByType: state.conference.allConferenceByType,
        conferenceData: state.conference.allConferenceList.allData,
        filterCandidates: state.player.filterCandidates,
        loadingFilters: state.ui.player.loadingFilters,
        updateFilter: state.player.updateFilter,
        isFiltered: state.player.isFiltered,
        accountType: state.account.accountType,
        teamByConference: state.conference.teamByConference,
        sportInConference: state.conference.sportInConference,
        teams: state.ui.player.candidateTeams,
    };
};

export default connect(mapStateToProps)(Filter);