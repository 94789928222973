import ListItem from "@material-ui/core/ListItem";
import { alpha, withStyles } from "@material-ui/core/styles";
import '../../../index.css';

const DrawerItem = withStyles((theme) => ({
 
  root: {
    color: theme.colors.white,
    paddingLeft: 5,
    "&.Mui-selected": {
      backgroundColor: alpha(theme.colors.lightblue, 0.1),
    },
  },
}))(ListItem);

export default DrawerItem;
