import { Article } from "../../../shared/Article";
import BasicInfo from "./BasicInfo";
import ContactInfo from "./ContactInfo";
import { Box, Grid, makeStyles, Tabs, Tab, Typography } from "@material-ui/core";
import clsx from "clsx";
import Button from "../../../shared/Button";
import { Formik } from "formik";
import { number, object, string } from "yup";
import { connect } from "react-redux";
import {useContext, useState} from "react";
import {ActionContext} from "../../../../contexts";

let useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.common.white,
    boxShadow: "3px 3px 10px 3px #cdcdcd",
    padding: '1rem',
    borderRadius: 8,
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
  tab: {
    fontFamily: theme.fontFamily.robotoCondensed,
    fontSize: '1rem',
    fontWeight: 700,
    color: theme.palette.text.primary,
    textTransform: 'capitalize',
  },
  tabSelected: {
    fontFamily: theme.fontFamily.robotoCondensed,
    fontSize: '1rem',
    fontWeight: 700,
    textTransform: 'capitalize',
  },
  content: {
    display: "flex",
    justifyContent: "end",
    width: '100%',
  },
  btnPrimary: {
    color: theme.palette.common.white,
    padding: '10px',
    backgroundColor: theme.colors.primaryNavy,
    width: '13rem',
    borderRadius: '7px',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.colors.primaryNavy,
      border: `1px solid ${theme.colors.primaryNavy}`,
    },
    textTransform: 'uppercase',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
      >
        {value === index && (
            <Box>
              <Typography>{children}</Typography>
            </Box>
        )}
      </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const PersonalInfo = (props) => {
  let classes = useStyles();
  const {basicInfo, contactInfo, playerId} = props;
  const [selectedPage, setSelectedPage] = useState(0);
  const {playerActions} = useContext(ActionContext);

  const handleChange = (event, newValue) => {
    setSelectedPage(newValue);
  };

  const handleFormSubmit = (values) => {
    let data = {
        athlete_type: values.athleteType,
        dob: values.birthday,
        email: values.email,
        facebook: values.facebook,
        first_name: values.firstName,
        gender: values.gender,
        home_country: values.homeCountry,
        home_state: values.homeState,
        home_town: values.hometown,
        instagram: values.instagram,
        last_name: values.lastName,
        full_name: `${ values.firstName} ${values.lastName}`,
        linkedin: values.linkedin,
        position: values.position,
        race: values.race,
        relationship_status: values.relationshipStatus,
        risk: values.risk,
        school: values.school,
        sport: values.sport,
        team: values.team,
        threads: values.threads,
        twitter: values.twitter,
        phone: values.whatsapp,
    }

    playerActions.updateCandidateBasicInfo(playerId, data);
  };

  return (
      <Box className={classes.root}>
        <Grid container spacing={1}>
          <Grid item xs={12} style={{ margin: '0px 1rem' }}>
            <Formik
                initialValues={{
                  // Basic Info
                  firstName: basicInfo.firstName || '',
                  lastName: basicInfo.lastName || '',
                  birthday: basicInfo.birthday || '',
                  gender: basicInfo.gender || '',
                  race: basicInfo.race || '',
                  relationshipStatus: basicInfo.relationshipStatus || '',
                  homeCountry: basicInfo.homeCountry || '',
                  homeState: basicInfo.homeState || '',
                  hometown: basicInfo.hometown || '',
                  school: basicInfo.school || '',
                  // Sport
                  sport: basicInfo.sport || '',
                  unit: basicInfo.unit || '',
                  position: basicInfo.position || '',
                  conference: basicInfo.conference || '',
                  conferenceType: basicInfo.conferenceType || '',
                  team: basicInfo.team || '',
                  risk: basicInfo.risk || '',
                  athleteType: basicInfo.athleteType || '',
                  // Contact Info
                  email: contactInfo.email || '',
                  whatsapp: contactInfo.phone || '',
                  // Social media
                  facebook: contactInfo.facebook || '',
                  instagram: contactInfo.instagram || '',
                  linkedin: contactInfo.linkedin || '',
                  snapchat: contactInfo.snapchat || '',
                  threads: contactInfo.threads || '',
                  twitter: contactInfo.twitter || '',
                }}
                validationSchema={object().shape({
                  firstName: string()
                      .trim()
                      .matches(
                          /^[a-zA-Z0-9]+(([a-zA-Z0-9 ])?[a-zA-Z0-9]*)*$/,
                          "Enter a valid first name, no special characters allowed."
                      ),
                  lastName: string()
                      .trim()
                      .matches(
                          /^[a-zA-Z0-9]+(([a-zA-Z0-9 ])?[a-zA-Z0-9]*)*$/,
                          "Enter a valid last name, no special characters allowed."
                      ),
                  email: string().email("Enter a valid email"),
                  whatsapp: number(),
                })}
                onSubmit={(values) => {
                  handleFormSubmit(values);
                }}
            >
              {({ handleSubmit }) => (
                  <>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Tabs
                          orientation="horizontal"
                          variant="scrollable"
                          value={selectedPage}
                          onChange={handleChange}
                          aria-label="Vertical tabs example"
                          TabIndicatorProps={{ className: classes.indicator }}
                      >
                        <Tab className={selectedPage === 0 ? classes.tabSelected : classes.tab} label="Basic Info" {...a11yProps(0)} />
                        <Tab className={selectedPage === 1 ? classes.tabSelected : classes.tab} label="Contact Info" {...a11yProps(1)} />
                      </Tabs>
                    </div>
                    <TabPanel value={selectedPage} index={0}>
                      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Article>
                              <BasicInfo basicInfo={basicInfo} />
                            </Article>
                          </Grid>
                        </Grid>
                      </div>
                    </TabPanel>
                    <TabPanel value={selectedPage} index={1}>
                      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Article>
                              <ContactInfo type='player'/>
                            </Article>
                          </Grid>
                        </Grid>
                      </div>
                    </TabPanel>
                    <div className={clsx(classes.content)}>
                      <Button
                          className={classes.btnPrimary}
                          type="button"
                          onClick={handleSubmit}
                      >
                        Update
                      </Button>
                    </div>
                  </>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>
  );
};

let mapStateToProps = (state, ownProps) => {
  return {
      basicInfo: state.player.basic[ownProps.playerId],
      contactInfo: state.player.contact[ownProps.playerId],
  };
};

export default connect(mapStateToProps)(PersonalInfo);
