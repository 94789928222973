import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { object, string } from "yup";
import { ActionContext } from "../../../../contexts";
import { Step, Stepper } from "../../../shared/Stepper";
import BackgorundStep from "./BackgorundStep";
import BasicInfoStep from "./BasicInfoStep";
import CreateCoachDialogLayout from "./CreateCoachDialogLayout";
import NotesStep from "./NotesStep";
import SocialMediaStep from "./SocialMediaStep";

let useStyles = makeStyles((theme) => ({
  stepper: {
    paddingTop: theme.spacing(5),
    maxWidth: 600,
  },
}));

const CreateCoachStepper = (props) => {
  let { closeDialog, submittingCoach, teams, updateFilter } = props;
  let classes = useStyles();
  let history = useHistory();
  let [step, setStep] = useState(0);

  let defaultTeam =
    Object.keys(teams).length > 1 ? null : Object.keys(teams)[0];
  let { snackbarActions, playerActions, sportsActions ,coachActions ,conferenceActions } = useContext(ActionContext);

  let getValue = (value) => (value && value.length > 0 ? value : "N/A");

  let handleFormSubmit = (values) => {
    let data = {
      firstName: values.firstName,
      lastName: values.lastName,
      full_name: values.firstName + ' ' + values.lastName,
      team: values.team,
      email: values.email,
      hometown: getValue(values.hometown),
      school: getValue(values.school),
      dob: values.birthday.format("L"),
      race: getValue(values.race),
      relationship_status: getValue(values.relationship),
      whatsapp: getValue(values.whatsapp),
      twitter: getValue(values.twitter),
      twitter_source_ln: values.twitterSourceLn,
      instagram: getValue(values.instagram),
      instagram_source_ln: values.instagramSourceLn,
      linkedin: getValue(values.linkedin),
      linkedin_source_ln: values.linkedinSourceLn,
      youtube: getValue(values.youtube),
      youtube_source_ln: values.youtubeSourceLn,
      printNews: getValue(values.printNews),
      printNews_source_ln: values.printNewsSourceLn,
      notes: values.notes,

      sport: values.sport,
      coach_type: values.coach_type,
      homeCountry: values.homeCountry,
      homeState: values.homeState,
      conference_type: values.conference_type,
      unit: values.unit,
      conference: values.conference,
    };

    let success = () => {
      closeDialog();
      // playerActions.getPlayerList();
      onSuccessApi()
      history.push("/coach");
      snackbarActions.showSnackbar("Coach added successfully");
    };
    playerActions.createCoach(data, success);
  };

  const onSuccessApi = () => {
    let model = {
      conference: updateFilter.conference ? [updateFilter.conference] : [],
      sport: updateFilter.sport ? [updateFilter.sport] : [],
      unit: updateFilter.unit ? [updateFilter.unit] : [],
      coachType: updateFilter.coachType === "All" ? [] : [updateFilter.coachType],
      conferenceType: updateFilter.conferenceType === "All" ? [] : [updateFilter.conferenceType]
    }

    coachActions.getAllFilterCoaches(model)
  }

  useEffect(() => {
    playerActions.getTeamList();
    conferenceActions.getCountries();
    sportsActions.getSportsList();
  }, []);

  return (
    <CreateCoachDialogLayout
      onClose={closeDialog}
      disableClose={submittingCoach}
      step={step}
    >
      <Stepper
        className={classes.stepper}
        step={step}
        setStep={setStep}
        initialValues={{
          notes: "",
          // name: "",
          firstName: "",
          lastName: "",
          team: defaultTeam,
          email: "",
          birthday: null,
          hometown: "",
          school: "",
          race: "",
          relationship: "",
          whatsApp: "",
          twitter: "",
          twitterSourceLn: "en",
          instagram: "",
          instagramSourceLn: "en",
          linkedin: "",
          linkedinSourceLn: "en",
          youtube: "",
          youtubeSourceLn: "en",
          printNews: "",
          printNewsSourceLn: "en",

          sport: "",
          coach_type: "",

          homeCountry: "",
          homeState: "",
          conference_type: "",
          unit: "",
          conference: "",
        }}
        onSubmit={handleFormSubmit}
        loading={submittingCoach}
      >
        <Step
          validationSchema={object().shape({
            firstName: string()
              .trim()
              .matches(
                /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
                "Enter valid First name"
              )
              .required("Enter coach first name"),
            lastName: string()
              .trim()
              .matches(
                /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
                "Enter valid Last name"
              )
              .required("Enter coach last name"),
            team: string().nullable().required("Select team"),
            email: string()
              .email("Enter a valid email")
              .required("Enter email"),
            birthday: string().nullable().required("Select birthday"),
          })}
          render={(props) => <BasicInfoStep {...props} />}
        />

        <Step
          validationSchema={object().shape({
            homeCountry: string().nullable().required("Select Home Country"),
            // homeState: string().nullable().required("Select Home state"),
            race: string().nullable().required("Select Race/Ethnicity"),
            sport: string().nullable().required("Select Sport"),
            coach_type: string().nullable().required("Select coach type"),
            conference_type: string().nullable().required("Select League"),
            conference: string().nullable().required("Select Conference"),
            unit: string().nullable().required("Select Unit"),
          })}
          render={(props) => <BackgorundStep {...props} />} />

        <Step
          validationSchema={object().shape(
            {
              twitter: string().when("instagram", {
                is: (b) => !b,
                then: string().required("Enter at least one"),
              }),
              instagram: string().when("twitter", {
                is: (a) => !a,
                then: string().required("Enter at least one"),
              }),
            },
            ["twitter", "instagram"]
          )}
          render={(props) => <SocialMediaStep {...props} />}
        />
        <Step

          render={(props) => <NotesStep {...props} />} />
      </Stepper>
    </CreateCoachDialogLayout>
  );
};

let mapStateToProps = (state) => {
  return {
    submittingCoach: state.ui.player.submittingCoach,
    teams: state.ui.player.candidateTeams,
    updateFilter: state.coach.updateCoachFilter
  };
};
export default connect(mapStateToProps)(CreateCoachStepper);
