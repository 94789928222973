import React, { useState, useContext, useEffect } from 'react'
import { connect, useDispatch } from "react-redux";
import { ActionContext } from "../../contexts";
import { makeStyles } from "@material-ui/core/styles";
import _ from 'lodash'
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiTextField from "@material-ui/core/TextField";
import { Typography, Button, Slide } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import PlayerInfo from "./PlayerInfo";
import CircularProgress from "modules/shared/CircularProgress";
import AddVideo from "./AddVideo";


const Transition = React.forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction="left"
            ref={ref}
            timeout={{ enter: 1000, exit: 1000 }}
            {...props}
        />
    );
});


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        background: '#f2f7ff',
        padding: '10px',
    },
    container: {
        //display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
    },
    autocompleteContainer: {
        background: '#fff',
        padding: '1rem 3rem',
    },
    dropdownMenu: {
        width: '300px',
    },
    detailsContainer: {
        display: 'flex',
        alignItems: 'stretch',
        marginLeft: '20px',
        flex: '1 1 50%',
    },
    detailItem: {
        marginRight: '10px',
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flex: '1 1 50%',
    },
    buttonRow: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
    },
    button: {
        marginRight: '10px',
    },
    status: {
        marginLeft: '10px',
    },
    buttonRow: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
    },
    button: {
        marginRight: '10px',
    },
    status: {
        marginLeft: '10px',
    },
    tabsContainer: {
        flex: 1,
        marginRight: '20px',
        width: '600px'
    },
    // statsWrapper: {
    //     display: 'flex',
    //     flexDirection: 'column',
    //     marginLeft: '10px'
    // }

}));




function PlayerBrain(props) {
    let classes = useStyles();
    let { playersById, basicInfo, stats, loadingPlayer, contactInfo, possibleDuplicateInfo ,requestUserId } = props;
    let { playerActions, dialogActions, coachActions } = useContext(ActionContext);



    const [selectedPlayerId, setSelectedPlayerId] = useState(null);
    useEffect(() => {
        playerActions.getPlayerList();
    }, []);

    useEffect(() => {
        if (selectedPlayerId !== null && selectedPlayerId !== "") {
            const user_handle = contactInfo[selectedPlayerId]?.twitter
            if (user_handle !== undefined && user_handle !== null) {
                if (user_handle != "" && user_handle != "na" && user_handle != "n/a") {
                    coachActions.getUserIdWithHandle(user_handle);
                }
            }
        }

    }, [selectedPlayerId, contactInfo]);

    let players = Object.values(playersById).filter(
        (player) => player.consent !== "Received"
    );
    const handleButtonClick = (buttonName) => {
        if (buttonName === 'Twitter') {
            playerActions.TwitterInfo(selectedPlayerId,requestUserId);
        }
        else if (buttonName === 'Instagram') {
            playerActions.InstagramInfo(selectedPlayerId);

        }
        else if (buttonName === 'Sentiment') {
            playerActions.SentimentInfo(selectedPlayerId);

        }
        else if (buttonName === 'Big5') {
            playerActions.Big5Info(selectedPlayerId);

        }
        else if (buttonName === 'Review') {
            playerActions.PsychUpdateInfo(selectedPlayerId, "PsychReviewPending");

        }
        else if (buttonName === 'Approve') {
            const model = {
                candidate_id: selectedPlayerId,
                copy_archive: false,
                copy_reviews: false,
                copy_analysis: false
            }
            playerActions.ApprovePlayer(model);

        }
        else if (buttonName === 'PlusReport') {
            playerActions.PlusReport(selectedPlayerId);
        }
        else if (buttonName === 'Video') {

            dialogActions.openDialog(<AddVideo playerId={selectedPlayerId} />, {
                fullScreen: true,
                TransitionComponent: Transition,
            });


        }
        else if (buttonName === 'FeedCleanup') {
            playerActions.removeFeed(selectedPlayerId);
        }
    };
    const handleRefresh = (id) => {
        playerActions.getLitePlayerDetails(id);
    }

    return (
        <React.Fragment>
            <div className={classes.root}>
                <div className={classes.container}>
                    <div className={classes.autocompleteContainer}>
                        <Autocomplete
                            id="player-combo"
                            name="playerId"
                            options={players}
                            getOptionLabel={(option) => (option.name ? option.name : '')}
                            renderInput={(props) => (
                                <MuiTextField
                                    variant="outlined"
                                    label="Athlete"
                                    placeholder="Athlete"
                                    size="small"
                                    required
                                    {...props}
                                />
                            )}
                            classes={{ listbox: classes.dropdownMenu }}
                            onChange={(event, selectedOption) => {
                                if (selectedOption) {
                                    setSelectedPlayerId(selectedOption.id);
                                    playerActions.getLitePlayerDetails(selectedOption.id);
                                }
                            }} />
                    </div>
                </div>
                {loadingPlayer[selectedPlayerId] && loadingPlayer[selectedPlayerId] === true ?
                    "" :
                    selectedPlayerId && selectedPlayerId ?
                        <div className={classes.detailsContainer}>
                            <Typography variant="h6" className={classes.detailItem}>
                                {basicInfo[selectedPlayerId]?.name}
                            </Typography>
                            <Divider orientation="vertical" flexItem className={classes.detailItem} />
                            <Typography className={classes.detailItem}>
                                {basicInfo[selectedPlayerId]?.team}
                            </Typography>
                            <Divider orientation="vertical" flexItem className={classes.detailItem} />
                            <Typography variant="h6" className={classes.detailItem}>
                                {basicInfo[selectedPlayerId]?.sport}
                            </Typography>
                            <Divider orientation="vertical" flexItem className={classes.detailItem} />
                            <Typography className={classes.detailItem}>
                                {basicInfo[selectedPlayerId]?.status}
                            </Typography>
                            <Divider orientation="vertical" flexItem className={classes.detailItem} />
                            <Typography color="primary" className={classes.detailItem}>{basicInfo[selectedPlayerId]?.isPlusReport === true ? "Plus Report" : "Base Report"}
                            </Typography>
                            <Button variant="contained" color="secondary" className={classes.detailItem} onClick={() => handleRefresh(selectedPlayerId)}>
                                Refresh
                            </Button>
                        </div>
                        : ""
                }

            </div>


            {loadingPlayer[selectedPlayerId] && loadingPlayer[selectedPlayerId] === true ?
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress size={72} thickness={3} color="primary" />
                </div> :
                selectedPlayerId && selectedPlayerId ?
                    <div className={classes.detailsContainer}>
                        <div className={classes.tabsContainer}>
                            <PlayerInfo playerId={selectedPlayerId} /></div>
                        <div className={classes.buttonsContainer}>
                            <div className={classes.buttonRow}>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => handleButtonClick('Approve')}
                                    disabled={basicInfo[selectedPlayerId]?.status !== 'Approval Pending'}
                                >
                                    Approve
                                </Button>
                                <div className={classes.statsWrapper}>
                                    <Typography className={classes.status}>Possible Duplicates: <span style={{ color: "blue" }}>{possibleDuplicateInfo[selectedPlayerId]?.isPossibleDuplicate === false ? "False" : "True"}</span></Typography>
                                </div>

                            </div>

                            <div className={classes.buttonRow}>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => handleButtonClick('Twitter')}
                                    disabled={!contactInfo[selectedPlayerId]?.twitter || (basicInfo[selectedPlayerId]?.status !== 'Processing' && basicInfo[selectedPlayerId]?.status !== 'Approval Pending')}
                                >
                                    Download Twitter
                                </Button>
                                <div className={classes.statsWrapper}>
                                    <Typography className={classes.status}>Status: {basicInfo[selectedPlayerId]?.twitterStatus}</Typography>
                                    <Typography variant="body2" color="textSecondary">Total: {stats[selectedPlayerId]?.twitter.total}</Typography>
                                    <Typography variant="body2" color="textSecondary">P: {stats[selectedPlayerId]?.twitter.positivePercentage}</Typography>
                                    <Typography variant="body2" color="textSecondary">Ne: {stats[selectedPlayerId]?.twitter.neutralPercentage}</Typography>
                                    <Typography variant="body2" color="textSecondary">N: {stats[selectedPlayerId]?.twitter.negativePercentage}</Typography>
                                </div>

                            </div>
                            <div className={classes.buttonRow}>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => handleButtonClick('Instagram')}
                                    disabled={!contactInfo[selectedPlayerId]?.instagram || (basicInfo[selectedPlayerId]?.status !== 'Approval Pending' && basicInfo[selectedPlayerId]?.status !== 'Processing')}
                                >
                                    Download Instagram
                                </Button>
                                <div className={classes.statsWrapper}>
                                    <Typography className={classes.status}>Status: {basicInfo[selectedPlayerId]?.instagramStatus} </Typography>
                                    <Typography variant="body2" color="textSecondary">Total: {stats[selectedPlayerId]?.instagram.total}</Typography>
                                    <Typography variant="body2" color="textSecondary">P: {stats[selectedPlayerId]?.instagram.positivePercentage}</Typography>
                                    <Typography variant="body2" color="textSecondary">Ne: {stats[selectedPlayerId]?.instagram.neutralPercentage}</Typography>
                                    <Typography variant="body2" color="textSecondary">N: {stats[selectedPlayerId]?.instagram.negativePercentage}</Typography>
                                </div>
                            </div>
                            <div className={classes.buttonRow}>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => handleButtonClick('Video')}
                                    disabled={basicInfo[selectedPlayerId]?.status !== 'Approval Pending' && basicInfo[selectedPlayerId]?.status !== 'Processing'}
                                >
                                    Add Video Transcription
                                </Button>
                                <Typography variant="body2" color="textSecondary">Total: {stats[selectedPlayerId]?.youtube.total}</Typography>
                            </div>
                            <div className={classes.buttonRow}>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => handleButtonClick('Sentiment')}
                                    disabled={basicInfo[selectedPlayerId]?.status !== 'Approval Pending' && basicInfo[selectedPlayerId]?.status !== 'Processing'}
                                >
                                    Update Sentiment
                                </Button>
                                <div className={classes.statsWrapper}>
                                    <Typography className={classes.status}>Status: {basicInfo[selectedPlayerId]?.sentimentStatus}</Typography>
                                    <Typography variant="body2" color="textSecondary">P: {stats[selectedPlayerId]?.positivePercentage}</Typography>
                                    <Typography variant="body2" color="textSecondary">Ne: {stats[selectedPlayerId]?.neutralPercentage}</Typography>
                                    <Typography variant="body2" color="textSecondary">N: {stats[selectedPlayerId]?.negativePercentage}</Typography>
                                </div>
                            </div>
                            <div className={classes.buttonRow}>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => handleButtonClick('FeedCleanup')}
                                    disabled={basicInfo[selectedPlayerId]?.isPlusReport === true}
                                >
                                    Feed Cleanup
                                </Button>

                            </div>
                            <div className={classes.buttonRow}>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => handleButtonClick('Big5')}
                                //disabled={basicInfo[selectedPlayerId]?.sentimentStatus !== 'completed' || (basicInfo[selectedPlayerId]?.status !== 'Approval Pending' && basicInfo[selectedPlayerId]?.status !== 'Processing')}
                                >
                                    Update Big5
                                </Button>
                                <Typography className={classes.status}>Status:{basicInfo[selectedPlayerId]?.psyStatus} </Typography>

                            </div>
                            <div className={classes.buttonRow}>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => handleButtonClick('Review')}
                                    disabled={basicInfo[selectedPlayerId]?.psyStatus !== 'completed' || (basicInfo[selectedPlayerId]?.status !== 'Approval Pending' && basicInfo[selectedPlayerId]?.status !== 'Processing')}
                                >
                                    Psy Review Pending
                                </Button>
                            </div>
                            <div className={classes.buttonRow}>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => handleButtonClick('PlusReport')}
                                    disabled={basicInfo[selectedPlayerId]?.isPlusReport === true || basicInfo[selectedPlayerId]?.psyStatus !== 'Psych Review Pending'}
                                >
                                    Plus Report
                                </Button>

                            </div>
                        </div></div>
                    : ""

            }
        </React.Fragment>

    );
}

let mapStateToProps = (state, ownProps) => {
    let { playerId } = ownProps;
    return {
        playersById: state.player.players.byId,
        contactInfo: state.player.contact,
        possibleDuplicateInfo: state.player.possibleDuplicateInfo,
        basicInfo: state.player.basic,
        loadingPlayer: state.ui.player.loadingLitePlayer,
        stats: state.player.stats,
        requestUserId: state.coach.requestUserId
    };
};
export default connect(mapStateToProps)(PlayerBrain);
