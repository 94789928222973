import React, { useContext } from "react";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import clsx from "clsx";
import YoutubeDialog from "./YoutubeDialog";
import ArticleDialog from "./ArticleDialog";
import { ActionContext } from "../../../../contexts";
import { formatDate } from "../../../../utils";
import { connect } from "react-redux";
import { alpha, Avatar, IconButton, ListItem, ListItemAvatar, makeStyles, Tooltip, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: alpha(theme.palette.primary.light, 0.2),
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.grey[500],
  },
  text: {
    display: "flex",
    maxWidth: 800,
    flexDirection: "column",
    color: theme.palette.text.primary,
    fontFamily: theme.fontFamily.robotoCondensed,
  },
  subText: {
    display: "flex",
    maxWidth: 800,
    flexDirection: "column",
    color: theme.palette.text.disabled,
    fontFamily: theme.fontFamily.robotoCondensed,
  },
  iconColor: {
    color: theme.palette.text.primary,
  },
  avatarTextRed: {
    color: theme.palette.error.main,
  },
  avatarTextGreen: {
    color: theme.palette.success.main,
  },
}));

let FeedItem = (props) => {
  let classes = useStyles();
  const { dialogActions } = useContext(ActionContext);
  let {
    item,
    type,
    source,
    playerImage,
    style,
    contactInfo
  } = props;

  let handleItemClick = (url) => () => {
    window.open(url, "_blank");
  };

  let isNegatives = type === "negatives";
  let isPositives = type === "positives";
  const isButton = source === "youtube" || source === "newspaper";

  const handleClick = () => {
    if (contactInfo.team !== 'Star Wars') {
      if (source === "youtube") {
        dialogActions.openDialog(
          <YoutubeDialog
            url={item.url}
            dialogs={item.info.dialogs}
            title={item.text}
            playerImage={playerImage}
          />,
          {
            fullScreen: true,
          }
        );
      }

      else if (source === "newspaper") {
        dialogActions.openDialog(
          <ArticleDialog paragraphs={item.info.paragraphs} title={item.text} />,
          { fullScreen: true }
        );
      }
    }
  };

  return (
    <ListItem button={isButton} style={style}>
      <ListItemAvatar>
        <Avatar
          className={clsx(classes.avatar, {
            [classes.avatarTextRed]: isNegatives,
            [classes.avatarTextGreen]: isPositives,
          })}
        >
          {item.score}
        </Avatar>
      </ListItemAvatar>
      <div onClick={handleClick}>
        <Tooltip
          title={item.text}
          enterTouchDelay={300}
          enterDelay={300}
          placement="bottom-end"
          arrow
        >
          <Typography variant="subtitle2" className={classes.text} noWrap>
            {item.text}
          </Typography>
        </Tooltip>
        <Typography variant="caption" className={classes.subText}>
          {formatDate(item.date)}
        </Typography>
      </div>
      {contactInfo.team === 'Star Wars' ? '' :
        <div style={{ display: "flex", flex: 1, flexDirection: "row-reverse" }}>
          <IconButton edge="end" onClick={handleItemClick(item.url)}>
            <OpenInNewIcon className={classes.iconColor}/>
          </IconButton>
        </div>}
    </ListItem>
  );
};

let mapStateToProps = (state, ownProps) => {
  let { data, index, playerId } = ownProps;
  let itemId = data[index];

  return {
    playerImage: state.player.basic[playerId]["image"],
    item: state.player.feed.dataById[itemId],
    contactInfo: state.player.contact[ownProps.playerId],
  };
};

export default connect(mapStateToProps)(FeedItem);
