import React, { useContext } from "react";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import { connect } from "react-redux";
import { ActionContext } from "../../../../contexts";
import { object, string } from "yup";
import { Button } from "../../../shared";
import { Field, Formik, Form } from "formik";
import { TextField, Select } from "formik-material-ui";
import { DialogTitle, FormControl, FormHelperText, Grid, IconButton, InputLabel, makeStyles, MenuItem  } from "@material-ui/core";
import {GlobalCss} from "../../../../config/GlobalCss";

const useStyles = makeStyles((theme) => ({
  title: {
    flex: 1,
    fontSize: theme.fontSize.md,
    fontFamily: theme.fontFamily.prometo,
    fontWeight: 700,
    color: theme.colors.primaryNavy,
    textTransform: 'uppercase',
  },
  iconColor: {
    color: theme.palette.text.primary,
  },
  avatar: {
    backgroundColor: theme.palette.background.primary,
    color: theme.palette.common.white,
  },
  subtitle: {
    color: theme.palette.text.primary,
    fontFamily: theme.fontFamily.robotoCondensed,
  },
  enabled: {
    color: theme.palette.success.main,
    fontFamily: theme.fontFamily.robotoCondensed,
  },
  pending: {
    color: theme.palette.warning.main,
  },
  generate: {
    color: theme.palette.text.primary,
  },
  btnPrimary: {
    color: theme.palette.common.white,
    padding: '10px',
    backgroundColor: theme.colors.primaryNavy,
    width: '8rem',
    borderRadius: '7px',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.colors.primaryNavy,
      border: `1px solid ${theme.colors.primaryNavy}`,
    },
    textTransform: 'uppercase',
  },
}));

const LinksDialog = (props) => {
  const classes = useStyles();
  const { playerActions } = useContext(ActionContext);
  const { closeDialog, playerId, loading } = props;

  const handleCloseDialog = () => {
    closeDialog();
  };

  const handleFormSubmit = (values, actions) => {
    const data = {
      candidate_id: playerId,
      url: values.link,
      category: values.category,
      source_ln: values.sourceLanguage,
    };

    const handleSuccess = () => {
      actions.setSubmitting(false);
      handleCloseDialog();
    };

    const handleFail = () => {
      actions.setSubmitting(false);
    };

    playerActions.addCandidateLink(data, handleSuccess, handleFail);
  };

  return (
    <div>
      <div style={{ display: "flex", width: "100%" }}>
        <DialogTitle id="simple-dialog-title" className={classes.title}>
          Add Link
        </DialogTitle>
        <div style={{ margin: 8 }}>
          <IconButton onClick={handleCloseDialog}>
            <CloseIcon className={classes.iconColor}/>
          </IconButton>
        </div>
      </div>
      <div style={{ padding: 24, width: "100%" }}>
        <Formik
          initialValues={{ category: "", link: "", sourceLanguage: "en" }}
          onSubmit={handleFormSubmit}
          validationSchema={object().shape({
            link: string().url().required("Enter the Link"),
            category: string().required("Select Link Type"),
            sourceLanguage: string().required("Selecr Source Language"),
          })}
        >
          {(formik) => {
            let { errors, touched } = formik;
            return (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={touched["category"] && !!errors["category"]}
                    >
                      <GlobalCss />
                      <InputLabel htmlFor="accountTypeLabel">
                        Category
                      </InputLabel>
                      <Field
                        component={Select}
                        name="category"
                        label="Category"
                        placeholder="Category"
                        labelId="categoryLabel"
                        inputProps={{
                          id: "category",
                        }}
                      >
                        <MenuItem value={""}>None</MenuItem>
                        <MenuItem value={"youtube"}>YouTube</MenuItem>
                        <MenuItem value={"video"}>Video</MenuItem>
                        <MenuItem value={"newspaper"}>Newspaper</MenuItem>
                      </Field>
                      {touched["category"] && !!errors["category"] ? (
                        <FormHelperText>{errors["category"]}</FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="link"
                      label="Link"
                      placeholder="Link"
                      component={TextField}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={
                        touched["sourceLanguage"] && !!errors["sourceLanguage"]
                      }
                    >
                      <InputLabel htmlFor="sourceLanguage">
                        Source Language
                      </InputLabel>
                      <Field
                        component={Select}
                        name="sourceLanguage"
                        label="Source Language"
                        placeholder="Source Language"
                        labelId="sourceLanguage"
                        inputProps={{
                          id: "sourceLanguage",
                        }}
                      >
                        <MenuItem value="en">English</MenuItem>
                        <MenuItem value="es">Spanish</MenuItem>
                        <MenuItem value="fr">French</MenuItem>
                      </Field>
                      {touched["sourceLanguage"] &&
                      !!errors["sourceLanguage"] ? (
                        <FormHelperText>
                          {errors["sourceLanguage"]}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                </Grid>
                <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 16 }}>
                  <Button type="submit" className={classes.btnPrimary} loading={loading}>
                    Save
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { playerId } = ownProps;

  return {
    loading: state.ui.player.addingCandidateLink[playerId] || false,
  };
};

export default connect(mapStateToProps)(LinksDialog);
