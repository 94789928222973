import React, {useContext, useEffect} from "react";
import { connect } from "react-redux";
import {
    makeStyles,
    Grid,
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    FormHelperText
} from "@material-ui/core";
import {Field, Form, useFormikContext} from "formik";
import {ActionContext} from "../../../../contexts";
import CircularProgress from "@material-ui/core/CircularProgress";
import {GlobalCss} from "../../../../config/GlobalCss";
import {Title} from "../../../shared";
import {Select, TextField} from "formik-material-ui";
import {KeyboardDatePicker} from "formik-material-ui-pickers";

let useStyles = makeStyles((theme) => ({
    form: {
        paddingTop: theme.spacing(2),
        display: 'flex',
        flexGrow: 1,
    },
}));

let BasicInfo = (props) => {
  const classes = useStyles();
  const {
      allConferenceByType,
      basicInfo,
      countriesData,
      loadingSportsData,
      stateData,
      sportsData,
      teams,
      unitData,
    } = props;
  const { values, errors, touched } = useFormikContext();
  let { sportsActions, conferenceActions } = useContext(ActionContext);

  useEffect(() => {
      if (basicInfo.homeCountry) {
          conferenceActions.getStatesByCountryName(basicInfo.homeCountry);
      }
      if (basicInfo.conferenceType) {
          conferenceActions.getConferenceByType(basicInfo.conferenceType);
      }
      if (basicInfo.conference && allConferenceByType) {
          let conference = allConferenceByType && allConferenceByType.find(conference => conference.name === basicInfo.conference);
          if (conference) {
              conferenceActions.getSportsByConferenceId(conference.id);
          }
      }
      if (basicInfo.sport) {
          const sport = sportsData && sportsData.find(sport => sport.name === basicInfo.sport);
          if (sport) {
              sportsActions.getSportUnitDetails(sport._id);
          }
      }
  }, [basicInfo.homeCountry, basicInfo.conferenceType, basicInfo.conference, basicInfo.sport]);

  const conferenceTypes = [
      "Professional",
      "Collegiate",
  ];

  const relationshipTypes = [
      "Single",
      "Married",
      "Unknown",
  ];

    const coachTypes = [
        "Current Employee",
        "Prospect Hire"
    ]

  const renderError = (error) => {
      if (typeof error === 'string') {
          return error;
      } else if (Array.isArray(error)) {
          return error.join(', ');
      } else if (typeof error === 'object' && error !== null) {
          return JSON.stringify(error);
      }
      return '';
  };

  useEffect(() => {
      if (values.homeCountry) {
          conferenceActions.getStatesByCountryName(values.homeCountry);
      }
  }, [values.homeCountry]);

  useEffect(() => {
      if (values.conference_type) {
          conferenceActions.getConferenceByType(values.conferenceType);
      }
  }, [values.conference_type]);

  useEffect(() => {
      if (values.sport) {
          const sport = sportsData && sportsData.find(sport => sport.name === values.sport);
          if (sport) {
              sportsActions.getSportUnitDetails(sport._id);
          }
      }
  }, [values.sport]);

  return (
      <Grid container spacing={1}>
          {
              loadingSportsData && loadingSportsData ? (
                  <Box>
                      <CircularProgress />
                  </Box>
              ) : (
                  <Grid item xs={12} style={{ margin: '0px 1rem' }}>
                      <Form autoComplete='off' style={{margin: '0.5rem 0.5rem'}}>
                          <GlobalCss />
                          <div className={classes.form}>
                              <Grid item xs={6} spacing={2} style={{ marginRight: '2rem', minWidth: 600 }}>
                                  <Title title="Basic Info"/>
                                  <Grid item xs={12} spacing={1} style={{display: 'flex', marginTop: '1rem', justifyContent: 'space-between'}}>
                                      <Field
                                          name="firstName"
                                          label="First Name"
                                          placeholder="Coach's First Name"
                                          component={TextField}
                                          variant="outlined"
                                          required
                                          autoFocus
                                          style={{ width: '49%' }}
                                      />
                                      <Field
                                          name="lastName"
                                          label="Last Name"
                                          placeholder="Coach's Last Name"
                                          component={TextField}
                                          variant="outlined"
                                          required
                                          autoFocus
                                          style={{ width: '49%' }}
                                      />
                                  </Grid>
                                  <Grid item xs={12} spacing={1} style={{display: 'flex', marginTop: '1rem', justifyContent: 'space-between'}}>
                                      <Field
                                          label="Birthday"
                                          placeholder="MM / DD / YYYY"
                                          name="birthday"
                                          component={KeyboardDatePicker}
                                          views={["year", "month", "date"]}
                                          inputVariant="outlined"
                                          InputAdornmentProps={{ position: "end" }}
                                          openTo="year"
                                          format="MM/DD/YYYY"
                                          orientation="portrait"
                                          variant="inline"
                                          invalidDateMessage="Enter a valid date"
                                          disabled
                                          autoOk
                                          style={{ width: '49%' }}
                                      />
                                      <Field
                                          name="gender"
                                          label="Gender"
                                          placeholder="Gender"
                                          component={TextField}
                                          variant="outlined"
                                          autoFocus
                                          disabled
                                          style={{ width: '49%' }}
                                      />
                                  </Grid>
                                  <Grid item xs={12} spacing={1} style={{display: 'flex', marginTop: '1rem', justifyContent: 'space-between'}}>
                                      <Field
                                          name="race"
                                          label="Race/Ethnicity"
                                          placeholder="Race/Ethnicity"
                                          component={TextField}
                                          variant="outlined"
                                          autoFocus
                                          disabled
                                          style={{ width: '49%' }}
                                      />
                                      <FormControl variant="outlined" style={{width: '49%'}}>
                                          <InputLabel htmlFor="relationshipStatus-label">
                                              Relationship Status
                                          </InputLabel>
                                          <Field
                                              component={Select}
                                              name="relationshipStatus"
                                              label="Relationship Status"
                                              placeholder="Relationship Status"
                                              inputProps={{
                                                  id: "relationshipStatus",
                                              }}
                                              required
                                          >
                                              {relationshipTypes &&
                                                  relationshipTypes.map((list) => (
                                                      <MenuItem key={list} value={list}>
                                                          {list}
                                                      </MenuItem>
                                                  ))}
                                          </Field>
                                          {touched["relationshipStatus"] && !!errors["relationshipStatus"] && (
                                              <FormHelperText>{renderError(errors["relationshipStatus"])}</FormHelperText>
                                          )}
                                      </FormControl>
                                  </Grid>
                                  <Grid item xs={12} spacing={1} style={{display: 'flex', marginTop: '1rem', justifyContent: 'space-between'}}>
                                      <FormControl variant="outlined" style={{width: '49%'}}>
                                          <InputLabel htmlFor="homeCountry-label">
                                              Home Country
                                          </InputLabel>
                                          <Field
                                              component={Select}
                                              name="homeCountry"
                                              label="Home Country"
                                              placeholder="Home Country"
                                              inputProps={{
                                                  id: "homeCountry",
                                              }}
                                              required
                                          >
                                              {countriesData &&
                                                  Object.keys(countriesData).map((list) => (
                                                      <MenuItem key={list} value={list}>
                                                          {list}
                                                      </MenuItem>
                                                  ))}
                                          </Field>
                                          {touched["homeCountry"] && !!errors["homeCountry"] && (
                                              <FormHelperText>{renderError(errors["homeCountry"])}</FormHelperText>
                                          )}
                                      </FormControl>
                                      <FormControl variant="outlined" style={{width: '49%'}}>
                                          <InputLabel htmlFor="homeState-label">
                                              Home State
                                          </InputLabel>
                                          <Field
                                              component={Select}
                                              name="homeState"
                                              label="Home State"
                                              placeholder="Home State"
                                              inputProps={{
                                                  id: "homeState",
                                              }}
                                              required
                                          >
                                              {stateData &&
                                                  Object.keys(stateData).map((list) => (
                                                      <MenuItem key={list} value={list}>
                                                          {list}
                                                      </MenuItem>
                                                  ))}
                                          </Field>
                                          {touched["homeState"] && !!errors["homeState"] && (
                                              <FormHelperText>{renderError(errors["homeState"])}</FormHelperText>
                                          )}
                                      </FormControl>
                                  </Grid>
                                  <Grid item xs={12} spacing={1} style={{display: 'flex', marginTop: '1rem', justifyContent: 'space-between'}}>
                                      <Field
                                          name="hometown"
                                          label="Home Town"
                                          placeholder="Home Town"
                                          component={TextField}
                                          variant="outlined"
                                          autoFocus
                                          style={{ width: '49%' }}
                                      />
                                      <Field
                                          name="school"
                                          label="School"
                                          placeholder="School"
                                          component={TextField}
                                          variant="outlined"
                                          autoFocus
                                          style={{ width: '49%' }}
                                      />
                                  </Grid>
                              </Grid>
                              <Grid item xs={6} spacing={2} style={{ marginRight: '2rem', minWidth: 600 }}>
                                  <Title title="Sport"/>
                                  <FormControl variant="outlined" style={{marginTop: '1rem', width: '100%'}}>
                                      <InputLabel htmlFor="sport-label">
                                          Sport
                                      </InputLabel>
                                      <Field
                                          component={Select}
                                          name="sport"
                                          label="Sport"
                                          placeholder="Sport"
                                          inputProps={{
                                              id: "sport",
                                          }}
                                          required
                                          fullWidth
                                      >
                                          {sportsData &&
                                              sportsData.map((list) => (
                                                  <MenuItem key={list._id} value={list.name}>
                                                      {list.name}
                                                  </MenuItem>
                                              ))}
                                      </Field>
                                      {touched["sport"] && !!errors["sport"] && (
                                          <FormHelperText>{renderError(errors["sport"])}</FormHelperText>
                                      )}
                                  </FormControl>
                                  <FormControl variant="outlined" style={{marginTop: '1rem', width: '100%'}}>
                                      <InputLabel htmlFor="team-label">
                                          Team
                                      </InputLabel>
                                      <Field
                                          component={Select}
                                          name="team"
                                          label="Team"
                                          placeholder="Team"
                                          inputProps={{
                                              id: "team",
                                          }}
                                          required
                                          fullWidth
                                      >
                                          {teams &&
                                              Object.keys(teams).map((list) => (
                                                  <MenuItem key={list} value={list}>
                                                      {list}
                                                  </MenuItem>
                                              ))}
                                      </Field>
                                      {touched["team"] && !!errors["team"] && (
                                          <FormHelperText>{renderError(errors["team"])}</FormHelperText>
                                      )}
                                  </FormControl>
                                  <Grid item xs={12} spacing={1} style={{display: 'flex', marginTop: '1rem', justifyContent: 'space-between'}}>
                                      <FormControl variant="outlined" style={{width: '49%'}}>
                                          <InputLabel htmlFor="conferenceType-label">
                                              League
                                          </InputLabel>
                                          <Field
                                              component={Select}
                                              name="conferenceType"
                                              label="Conference Type"
                                              placeholder="Conference Type"
                                              inputProps={{
                                                  id: "conferenceType",
                                              }}
                                              required
                                              fullWidth
                                          >
                                              {conferenceTypes &&
                                                  conferenceTypes.map((list) => (
                                                      <MenuItem key={list} value={list}>
                                                          {list}
                                                      </MenuItem>
                                                  ))}
                                          </Field>
                                          {touched["conferenceType"] && !!errors["conferenceType"] && (
                                              <FormHelperText>{renderError(errors["conferenceType"])}</FormHelperText>
                                          )}
                                      </FormControl>
                                      <FormControl variant="outlined" style={{width: '49%'}}>
                                          <InputLabel htmlFor="conference-label">
                                              Conference
                                          </InputLabel>
                                          <Field
                                              component={Select}
                                              name="conference"
                                              label="Conference "
                                              placeholder="Conference "
                                              inputProps={{
                                                  id: "conference",
                                              }}
                                              required
                                              fullWidth
                                          >
                                              {allConferenceByType &&
                                                  allConferenceByType.map((list) => (
                                                      <MenuItem key={list.id} value={list.name}>
                                                          {list.name}
                                                      </MenuItem>
                                                  ))}
                                          </Field>
                                          {touched["conference"] && !!errors["conference"] && (
                                              <FormHelperText>{renderError(errors["conference"])}</FormHelperText>
                                          )}
                                      </FormControl>
                                  </Grid>
                                  <Grid item xs={12} spacing={1} style={{display: 'flex', marginTop: '1rem', justifyContent: 'space-between'}}>
                                      <FormControl variant="outlined" fullWidth>
                                          <InputLabel htmlFor="unit-label">
                                              Unit
                                          </InputLabel>
                                          <Field
                                              component={Select}
                                              name="unit"
                                              label="Unit "
                                              placeholder="Unit "
                                              inputProps={{
                                                  id: "unit",
                                              }}
                                              required
                                              fullWidth
                                          >
                                              {unitData &&
                                                  unitData.map((list) => (
                                                      <MenuItem key={list.unitId} value={list.unitId}>
                                                          {list.unit_name}
                                                      </MenuItem>
                                                  ))}
                                          </Field>
                                          {touched["unit"] && !!errors["unit"] && (
                                              <FormHelperText>{renderError(errors["unit"])}</FormHelperText>
                                          )}
                                      </FormControl>
                                  </Grid>
                                  <Grid item xs={12} spacing={1} style={{display: 'flex', margin: '1rem 0rem', justifyContent: 'space-between'}}>
                                      <FormControl variant="outlined" style={{width: '49%'}}>
                                          <InputLabel htmlFor="athleteType-label">
                                              Coach Type
                                          </InputLabel>
                                          <Field
                                              component={Select}
                                              name="coachType"
                                              label="Coach Type"
                                              placeholder="Coach Type"
                                              inputProps={{
                                                  id: "coachType",
                                              }}
                                              required
                                          >
                                              {coachTypes &&
                                                  coachTypes.map((list) => (
                                                      <MenuItem key={list} value={list}>
                                                          {list}
                                                      </MenuItem>
                                                  ))}
                                          </Field>
                                          {touched["coachType"] && !!errors["coachType"] && (
                                              <FormHelperText>{renderError(errors["coachType"])}</FormHelperText>
                                          )}
                                      </FormControl>
                                      <Field
                                          name="risk"
                                          label="Risk"
                                          component={TextField}
                                          variant="outlined"
                                          required
                                          fullWidth
                                          autoFocus
                                          disabled
                                          style={{width: '49%'}}
                                      />
                                  </Grid>
                              </Grid>
                          </div>
                      </Form>
                  </Grid>
              )
          }
      </Grid>
  )
};

let mapStateToProps = (state) => {
    return {
        loadingSportsData: state.ui.sports.sportsListLoading,
        countriesData: state.conference.allCountries,
        stateData: state.conference.allStateByCountry,
        unitData: state.sports.unitsBySportId,
        allConferenceByType: state.conference.allConferenceByType,
        sportsData: state.sports.sportsListData,
        teams: state.ui.player.candidateTeams,
    };
};

export default connect(mapStateToProps)(BasicInfo);
