import React, { useContext } from "react";
import AssessmentIcon from "@material-ui/icons/Assessment";
import CreateTeamDialog from "./CreateTeamDialog"
import { ActionContext } from "../../../../contexts";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    btnPrimary: {
        fontSize: '13px',
        fontFamily: theme.fontFamily.robotoCondensed,
        color: theme.palette.text.primary,
        fontWeight: 700,
        cursor: 'pointer',
        textTransform: 'uppercase',
        border: 'none',
        marginRight: 20,
        display: "flex",
        alignItems: "center",
    }
}))

const CreateTeam = (props) => {
  const { type } = props;
  const { dialogActions } = useContext(ActionContext);
  const classes = useStyles();

  const handleClick = () => {
    dialogActions.openDialog(
      <CreateTeamDialog type={type}  />,
      {
        maxWidth: "xs",
      }
    );
  };

  return (
    <div style={{ marginLeft: 8 , display : "flex"}}>
      <span
        className={classes.btnPrimary}
        color="default"
        startIcon={<AssessmentIcon />}
        onClick={handleClick}
        disableElevation
      >
        Create Team
      </span>
    </div>
  );
};

export default CreateTeam;
