import React, { useContext } from "react";
import RequestPlusDialog from "./RequestPlusDialog";
import { ActionContext } from "../../../../contexts";
import {HeaderButton} from "../../../shared";

const Reports = (props) => {
  const { coachId, status, basicInfo } = props;
  const { dialogActions } = useContext(ActionContext);

  const handleClick = () => {
    dialogActions.openDialog(
      <RequestPlusDialog coachId={coachId} status={status} basicInfo={basicInfo} />,
      {
        maxWidth: "xs",
      }
    );
  };

  return (
    <div style={{ marginLeft: 8 }}>
      <HeaderButton title='Request Plus Report' onclick={handleClick}/>
    </div>
  );
};

export default Reports;
