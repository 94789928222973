import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

let useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    flex: 1,
    width: "100%",
    overflowY: "hidden",
  },
  card: {
    minHeight: 80,
    width: "100%",
    boxSizing: "boder-box",
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  text: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
}));

const PlayerCardSkeleton = () => {
  const classes = useStyles();
  return (
    <div className={classes.card}>
      <Skeleton
        className={classes.avatar}
        variant="circle"
        width={40}
        height={40}
      />
      <div className={classes.text}>
        <div>
          <Typography variant="body1">
            <Skeleton width={130} />
          </Typography>
          <Typography variant="subtitle2">
            <Skeleton width={130} />
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle2">
            <Skeleton width={100} />
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default function Animations() {
  let classes = useStyles();
  return (
    <div className={classes.root}>
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
      <PlayerCardSkeleton />
    </div>
  );
}
