import MentalMetrixWhite from "../../../../assets/mental-metrix-white-logo.svg";

const MentalMetrixWhiteLogo = ({ width }) => {
  return (
    <img
      src={MentalMetrixWhite}
      alt="mentalMetrixWhite"
      height={45}
      width={ width ?? '100%' }
    />
  );
};

export default MentalMetrixWhiteLogo;
