import React, { useEffect, useContext } from "react";
import clsx from "clsx";
import CircularProgress from "../../../shared/CircularProgress";
import PlayerDetails from "./PlayerDetails";
import getConfig from "../../config";
import { ActionContext } from "../../../../contexts";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

let useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex",
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down("md")]: {
      height: "100vh",
    },
  },
  loading: {
    justifyContent: "center",
    alignItems: "center",
  },
}));

const PlayerDetailsContainer = (props) => {
  let classes = useStyles();
  let { playerActions } = useContext(ActionContext);
  let { playerId, loadingPlayer, accountType, closeDialog = (f) => f } = props;

  let { pages } = getConfig(accountType);

  useEffect(() => {
    return () => {
      // deleselct the selected player on unmount
      playerActions.deselectPlayer();
    };
  }, []);

  if (loadingPlayer[playerId] && loadingPlayer[playerId] === true) {
    return (
      <div className={clsx(classes.root, classes.loading)}>
        <CircularProgress size={72} thickness={3} color="primary" />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <PlayerDetails
        playerId={playerId}
        pages={Object.values(pages)}
        onClose={closeDialog}
      />
    </div>
  );
};

let mapStateToProps = (state, ownProps) => {
  let { playerId } = ownProps;
  return {
    loadingPlayer: state.ui.player.loadingPlayer,
    accountType: state.account.accountType,
    status: state.player.basic[playerId],
  };
};
export default connect(mapStateToProps)(PlayerDetailsContainer);
