import React from "react";
import Dialog from "./Dialog";
import dialogActions from "../state/dialogActions";
import { connect } from "react-redux";

let DialogContainer = ({ dialogs, closeDialog }) => {
  return dialogs.map((id) => {
    return <Dialog key={id} id={id} open={true} closeDialog={closeDialog} />;
  });
};

let mapStateToProps = (state) => {
  return {
    dialogs: state.dialogs.allIds,
  };
};

let mapDispatchToProps = {
  ...dialogActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogContainer);
