import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { round } from "../../../utils";
import { RadialGauge } from "../../shared/Charts";
import { connect } from "react-redux";
import {accountTypes} from "../../../store/constants";

let roundTo1PrecisionPoint = (value) => {
  return round(value, 0);
};

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 16,
  },
  details: {
    display: "flex",
    flex: 1,
    paddingRight: theme.spacing(1),
  },
}));

const ChartAndDetails = (props) => {
  let classes = useStyles();
  let {
    diagnosis,
    score = 0,
    tabName,
    accountType,
  } = props;

  return (
    <div className={classes.root}>
      <div
        style={{
          width:
            tabName === "BeyondBig5" || tabName === "Risk" ? "100%" : "20%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {tabName === "BeyondBig5" ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ position: "relative" }}>
              <div>
                <RadialGauge score={roundTo1PrecisionPoint(score)} />
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: "-20px",
                  left: "0",
                  marginBottom: "1em",
                }}
              >
                Low
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: "-20px",
                  right: "0",
                  marginBottom: "1em",
                }}
              >
                High
              </div>
              <div style={{ marginBottom: "1em" }}></div>
            </div>
          </div>
        ) : tabName === "Risk" ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ position: "relative" }}>
              <div>
                <RadialGauge score={roundTo1PrecisionPoint(score)} />
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: "-20px",
                  left: "0",
                  marginBottom: "1em",
                }}
              >
                Low
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: "-20px",
                  right: "0",
                  marginBottom: "1em",
                }}
              >
                High
              </div>
              <div style={{ marginBottom: "1em" }}></div>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>
              <RadialGauge score={roundTo1PrecisionPoint(score)} />
            </div>
            {accountType === accountTypes.superAdmin ||
            accountType === accountTypes.mmPsychologist ||
            accountType === accountTypes.mmChiefPsychologist ? (
              <div>{roundTo1PrecisionPoint(score)}%</div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>

      <div className={classes.details}>
        <div
          style={{ display: "flex", flex: 1, justifyContent: "space-between" }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle2" style={{ paddingLeft: 16 }}>
              {diagnosis}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    team: state.account.team,
    accountType: state.account.accountType,
  };
};

export default connect(mapStateToProps)(ChartAndDetails);
