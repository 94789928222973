import {Button, makeStyles} from "@material-ui/core";

let useStyles = makeStyles((theme) => ({
    btnPrimary: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '1rem',
        fontSize: '13px',
        fontFamily: theme.fontFamily.robotoCondensed,
        color: theme.palette.text.primary,
        fontWeight: 700,
        cursor: 'pointer',
        textTransform: 'uppercase',
    },
}));

const HeaderButton = ({ onclick, title }) => {
    const classes = useStyles();

    return <Button onClick={onclick} className={classes.btnPrimary}>
        { title }
    </Button>
};

export default HeaderButton;