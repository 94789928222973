import React, { useContext, useState, useEffect } from "react";
import { connect } from "react-redux";
import { object, string } from "yup";
import { ActionContext } from "../../../contexts";
import { Step, Stepper } from "../../shared/Stepper";
import BackgroundStep from "./BackgroundStep";
import BasicInfoStep from "./BasicInfoStep";
import NotesStep from "./NotesStep";
import SocialMediaStep from "./SocialMediaStep";
import runDashedLogo from "../../../assets/RunDashedIcon.svg";
import { Box, Grid, makeStyles } from '@material-ui/core';
import MuiStepper from '@material-ui/core/Stepper';
import MuiStep from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { GlobalCss } from "../../../config/GlobalCss";

let useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        boxShadow: `3px 3px 10px 3px ${theme.colors.lightGrey}`,
        marginLeft: '-1rem',
        padding: '2rem',
        borderRadius: 8,
        width: '100%',
        marginBottom: '2rem',
        backgroundColor: theme.palette.common.white,
    },
    stepperContainer: {
        background: 'none',
        padding: '0px',
        marginLeft: '-10px'
    },
    stepperIcon: {
        color: theme.palette.text.disabled,
    },
    stepper: {
        paddingTop: theme.spacing(5),
    },
}));

const CreateCoachStepper = (props) => {
  let { snackbarActions, playerActions, sportsActions, coachActions, conferenceActions } = useContext(ActionContext);
  let { account, submittingCoach, teams, updateFilter } = props;
  let classes = useStyles();
  let [step, setStep] = useState(0);
  let getValue = (value) => (value && value.length > 0 ? value : "N/A");

  let defaultTeam =
    Object.keys(teams).length > 1 ? null : Object.keys(teams)[0];

  let sport = account.team !== 'MM-DDI' ? account.sport : '';
  let conference = account.team !== 'MM-DDI' ? account.conference : '';
  let conferenceType = account.team !== 'MM-DDI' ? account.conference_type : '';

  let handleFormSubmit = (values) => {
    let data = {
      first_name: values.firstName,
      last_name: values.lastName,
      full_name: values.firstName + ' ' + values.lastName,
      team: values.team,
      email: values.email,
      home_town: getValue(values.hometown),
      dob: values.birthday.format("L"),
      race: getValue(values.race),
      relationship_status: getValue(values.relationship),
      phone: getValue(values.whatsapp),
      twitter: getValue(values.twitter),
      twitter_source_ln: values.twitterSourceLn,
      instagram: getValue(values.instagram),
      instagram_source_ln: values.instagramSourceLn,
      linkedin: getValue(values.linkedin),
      linkedin_source_ln: values.linkedinSourceLn,
      youtube: getValue(values.youtube),
      youtube_source_ln: values.youtubeSourceLn,
      print_news: getValue(values.printNews),
      print_news_source_ln: values.printNewsSourceLn,
      notes: values.notes,
      coach_type: values.coachType,
      home_country: values.homeCountry,
      home_state: values.homeState,
      unit: values.unit,
      gender: values.gender,
    };

    let success = () => {
      onSuccessApi()
      coachActions.individualCoachFormSubmitted()
      snackbarActions.showSnackbar("Coach added successfully");
    };
    playerActions.createCoach(data, success);
  };

  const onSuccessApi = () => {
    let model = {
      conference: updateFilter.conference ? [updateFilter.conference] : [],
      sport: updateFilter.sport ? [updateFilter.sport] : [],
      unit: updateFilter.unit ? [updateFilter.unit] : [],
      coachType: updateFilter.coachType === "All" ? [] : [updateFilter.coachType],
      conferenceType: updateFilter.conferenceType === "All" ? [] : [updateFilter.conferenceType]
    }

    coachActions.getAllFilterCoaches(model)
  }

  useEffect(() => {
    playerActions.getTeamList();
    conferenceActions.getCountries();
    sportsActions.getSportsList();
  }, []);

  const steps = [1, 2, 3, 4];

  const validateMonth = (value) => {
    const currentDate = new Date();
    const selectedDate = new Date(value);
    const currentMonth = currentDate.getMonth() + 1;
    const selectedMonth = selectedDate.getMonth() + 1;
    return selectedMonth >= 1 && selectedMonth <= 12;
  };

  return (
      <Box className={classes.container}>
          <Grid style={{ display: 'flex', flexDirection: 'column', margin: '0rem 2rem' }}>
              <Box sx={{ maxWidth: 750 }}>
                  <GlobalCss />
                  <MuiStepper
                      activeStep={step}
                      orientation="horizontal"
                      className={classes.stepperContainer}
                  >
                      {steps.map((step) => (
                          <MuiStep key={step.label}>
                              <StepLabel
                                  StepIconProps={{
                                      classes: {
                                          root: classes.stepperIcon,
                                          active: classes.stepperIconActive,
                                          completed: classes.stepperIconActive,
                                      },
                                  }}
                              >
                              </StepLabel>
                          </MuiStep>
                      ))}
                  </MuiStepper>
              </Box>
              <Grid item xs={9} flexDirection='column'>
                  <Stepper
                      className={classes.stepper}
                      step={step}
                      setStep={setStep}
                      initialValues={{
                          notes: "",
                          firstName: "",
                          lastName: "",
                          team: defaultTeam,
                          email: "",
                          birthday: null,
                          hometown: "",
                          school: "",
                          race: "",
                          relationship: "",
                          whatsApp: "",
                          twitter: "",
                          twitterSourceLn: "en",
                          instagram: "",
                          instagramSourceLn: "en",
                          linkedin: "",
                          linkedinSourceLn: "en",
                          youtube: "",
                          youtubeSourceLn: "en",
                          printNews: "",
                          printNewsSourceLn: "en",
                          sport: sport,
                          coachType: "",
                          homeCountry: "",
                          homeState: "",
                          conferenceType: conferenceType,
                          unit: "",
                          conference: conference,
                          gender: "",
                      }}
                      onSubmit={handleFormSubmit}
                      loading={submittingCoach}
                  >
                      <Step
                          validationSchema={object().shape({
                              firstName: string()
                                  .trim()
                                  .matches(
                                      /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
                                      "Enter valid First name"
                                  )
                                  .required("Enter coach first name"),
                              lastName: string()
                                  .trim()
                                  .matches(
                                      /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
                                      "Enter valid Last name"
                                  )
                                  .required("Enter coach last name"),
                              team: string().nullable().required("Select team"),
                              email: string()
                                  .email("Enter a valid email"),
                              birthday: string()
                                  .nullable()
                                  .required("Select birthday")
                                  .test("valid-month", "Invalid month", (value) => validateMonth(new Date(value))),
                          })}
                          render={(props) => <BasicInfoStep {...props} />}
                      />
                      <Step
                          validationSchema={object().shape({
                              homeCountry: string().nullable().required("Select Home Country"),
                              race: string().nullable().required("Select Race/Ethnicity"),
                              sport: string().nullable().required("Select Sport"),
                              coachType: string().nullable().required("Select coach type"),
                              conferenceType: string().nullable().required("Select League"),
                              conference: string().nullable().required("Select Conference"),
                              unit: string().nullable().required("Select Unit"),
                              gender: string().nullable().required("Select Gender"),
                          })}
                          render={(props) => <BackgroundStep {...props} />} />
                      <Step
                          validationSchema={object().shape(
                              {
                                  twitter: string().when("instagram", {
                                      is: (b) => !b,
                                      then: string().required("Enter at least one"),
                                  }),
                                  instagram: string().when("twitter", {
                                      is: (a) => !a,
                                      then: string().required("Enter at least one"),
                                  }),
                              },
                              ["twitter", "instagram"]
                          )}
                          render={(props) => <SocialMediaStep {...props} />}
                      />
                      <Step render={(props) => <NotesStep {...props} />} />
                  </Stepper>
              </Grid>
          </Grid>
          <Grid item xs={5} style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                  <img
                      style={{ width: '100%' }}
                      src={runDashedLogo}
                      alt="logo"
                  />
              </div>
          </Grid>
      </Box>
  );
};

let mapStateToProps = (state) => {
  return {
    submittingCoach: state.ui.player.submittingCoach,
    teams: state.ui.player.candidateTeams,
    updateFilter: state.coach.updateCoachFilter,
    account: state.account.accountData,
  };
};

export default connect(mapStateToProps)(CreateCoachStepper);
