import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { round } from "../../../utils";
import { connect } from "react-redux";
import RadialGauge from "modules/shared/Charts/RadialGuage";
import { accountTypes } from "../../../store/constants";

let roundTo1PrecisionPoint = (value) => {
  return round(value, 0);
};

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 16,
  },
  container: {
    display: "flex",
    justifyContent: 'center',
  },
  details: {
    display: "flex",
    flex: 1,
    paddingRight: theme.spacing(1),
    color: theme.palette.text.primary,
    fontSize: 16,
    fontFamily: theme.fontFamily.robotoCondensed,
  },
  roundedScore: {
    fontFamily: theme.fontFamily.robotoCondensed,
    fontSize: 16,
    marginTop: '-15px',
  },
  low: {
    position: "absolute",
    bottom: "-20px",
    left: "0",
    marginBottom: "1em",
    fontFamily: theme.fontFamily.robotoCondensed,
    fontSize: 13,
  },
  high: {
    position: "absolute",
    bottom: "-20px",
    right: "0",
    marginBottom: "1em",
    fontFamily: theme.fontFamily.robotoCondensed,
    fontSize: 13,
  },
}));

const ChartAndDetails = (props) => {
  let classes = useStyles();
  let {
    diagnosis,
    score = 0,
    tabName,
    accountType,
  } = props;

  return (
    <div className={classes.root}>
      <div style={{ width: tabName === "BeyondBig5" || tabName === "Risk" ? "100%" : "20%" }}>
        { tabName === "BeyondBig5" ? (
          <div className={classes.container}>
            <div style={{position: "relative"}}>
              <div>
                <RadialGauge score={roundTo1PrecisionPoint(score)}/>
              </div>
              <div className={classes.low}>
                Low
              </div>
              <div className={classes.high}>
                High
              </div>
              <div style={{marginBottom: "0.5em"}}></div>
            </div>
          </div>
        ) : tabName === "Risk" ? (
          <div className={classes.container}>
            <div style={{position: "relative"}}>
              <div>
                <RadialGauge score={roundTo1PrecisionPoint(score)}/>
              </div>
              <div className={classes.low}>
                Low
              </div>
              <div className={classes.high}>
                High
              </div>
              <div style={{marginBottom: "0.5em"}}></div>
            </div>
          </div>
        ) : (
            <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>
              <RadialGauge score={roundTo1PrecisionPoint(score)} />
            </div>
            {accountType === accountTypes.superAdmin ||
            accountType === accountTypes.mmPsychologist ||
            accountType === accountTypes.mmChiefPsychologist ? (
              <div className={classes.roundedScore}>{roundTo1PrecisionPoint(score)}%</div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>

      <div className={classes.details}>
        <div
          style={{ display: "flex", flex: 1, justifyContent: "space-between" }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle2" style={{ paddingLeft: 16 }}>
              {diagnosis}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    team: state.account.team,
    accountType: state.account.accountType,
  };
};

export default connect(mapStateToProps)(ChartAndDetails);
