import React, { useContext } from "react";
import SummeryDialog from "./SummeryDialog";
import { ActionContext } from "../../../../contexts";
import { connect } from "react-redux";
import { EditorArticle } from "../../../shared/Editor";
import { EditorState, convertFromRaw } from "draft-js";

let Summery = (props) => {
  let { dialogActions, coachActions } = useContext(ActionContext);
  let { coachId, isEditable, categoryId, open, content, id , currentData, accountType } = props;

  let editorState = content && content.blocks
    ? EditorState.createWithContent(convertFromRaw(content))
    : EditorState.createEmpty()

  let handleEditClick = () => {
    dialogActions.openDialog(
      <SummeryDialog
        coachId={coachId}
        categoryId={categoryId}
        initialEditorState={editorState}
        openEdit={open}
        id={id}
        currentData={currentData}
      />
    );
  };

  function handleDelete() {
    let data = {
      coach_id: coachId,
      category: "Custom",
      _id: open ? id : ''
    };
    coachActions.deletePsychSummaryComments(data,currentData);
  }

  return (
    <EditorArticle
      onEdit={handleEditClick}
      editorState={editorState}
      isEditable={isEditable}
      placeholder="Psychologist comments pending"
      isDelete={open}
      playerId={coachId}
      id={id}
      onDelete={handleDelete}
      currentData={currentData}
      accountType={accountType}
    />
  );
};

let mapStateToProps = (state, ownProps) => {
  let { coachId, categoryId } = ownProps;

  return {
    accountType: state.account.accountType,
    summery: state.coach.executiveSummery[`${coachId}.${categoryId}`],
  };
};

export default connect(mapStateToProps)(Summery);
