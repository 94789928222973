import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    iconColor: {
        color: theme.colors.primaryNavy,
    },
}));

const Delete = () => {
    const classes = useStyles();

    return (
        <IconButton edge="end" >
            <DeleteIcon className={classes.iconColor}/>
        </IconButton>
    )
};

export default Delete;
