import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";

// assume private routes should always redirect to /account/login
let PrivateRoute = ({
  path,
  children,
  redirect = "/signin",
  isAuthenticated = false,
  accountType,
  roles = [],
  ...rest
}) => {
  let location = useLocation();

  if (isAuthenticated) {
    if (!roles.includes(accountType)) {
      return <Redirect to="/404" />;
    }
    return <Route path={path} component={children} {...rest} />;
  } else {
    return (
      <Redirect
        to={{
          pathname: redirect,
          state: { from: location.pathname },
          search: `?from=${location.pathname}`,
        }}
      />
    );
  }
};

export default PrivateRoute;
