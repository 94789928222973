import React from 'react'
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CreateCoachStepper from "./CreateCoach"
import FinalPage from './CreateCoach/FinalPage';

let useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.colors.veryLightGrey,
        display: "flex",
        height: `calc(100vh - 50px)`,
        zIndex: theme.zIndex.appBar,
        paddingBottom: '2rem',
    },
    container: {
        padding: 16,
        paddingTop: 48,
        marginLeft: "2rem",
        marginRight: "auto",
        flexWrap: "wrap",
        width: '100%',
    },
}));

function CreateCoachMain(props) {
    let classes = useStyles();
    let { isCoachFormSubmit } = props;

    return (
        <React.Fragment>
            <div className={classes.root}>
                <div className={classes.container}>
                    <div>
                        {
                            (isCoachFormSubmit === false) ?
                                <CreateCoachStepper />
                                :
                                <FinalPage />
                        }
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

let mapStateToProps = (state) => {
    return {
        isCoachFormSubmit: state.ui.coach.isCoachFormSubmit,
    }
}

export default connect(mapStateToProps)(CreateCoachMain)