import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/Accordion";
import ExpansionPanelDetails from "@material-ui/core/AccordionDetails";
import ExpansionPanelSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: 700,
  },
  article: {
    margin: theme.spacing(2, 1, 1, 1),
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderRadius: 8,
  },
  articleHeading: {
    display: "flex",
    flex: 1,
    padding: theme.spacing(2),
    height: 70,
  },
  expansionPanel: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    width: "100%",
  },
  articleHeadingDetails: {
    padding: theme.spacing(0, 2),
  },
}));

const Article = ({ children, title, details }) => {
  let classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={0} variant="outlined" className={classes.article}>
        <ExpansionPanel
          elevation={0}
          classes={{ root: classes.expansionPanel }}
          defaultExpanded={true}
          //   TransitionProps={{ unmountOnExit: true }}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.articleHeading}
            classes={{ root: classes.expansionPanel }}
          >
            <Typography variant="h6">{title}</Typography>
            <div style={{ flex: 1 }}></div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.articleHeadingDetails}>
            <Typography variant="caption" color="textSecondary">
              {details}
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        {children}
      </Paper>
    </div>
  );
};

export default Article;
