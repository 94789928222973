import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import '../../../index.css';

let useStyles = makeStyles((theme) => ({
  headingContainer: {
    display: 'flex',
    margin: '0rem 1rem',
    marginTop: '1rem',
    justifyContent: 'space-between',
  },
  article: {
    borderRadius: '9px',
    fontFamily: theme.fontFamily.prometo,
    marginBottom: '0.8rem',
    color: theme.palette.text.primary,
    borderLeft: `5px solid ${theme.palette.primary.main}`,
  },
  articleHeading: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 2, 1, 2),

  },
}));

const Article = ({
  children,
  title,
  icon = null,
  button = null,
}) => {
  let classes = useStyles();

  return (
      <div>
        <Paper
            square
            elevation={0}
            variant="outlined"
            className={classes.article}
        >
          {title ? (
              <div className={classes.headingContainer}>
                {icon}
                <Typography
                    variant="h6"
                    component="p"
                    align="center"
                >
                  {title}
                </Typography>
                {button}
              </div>
          ) : null}
          {children}
        </Paper>
      </div>
  );
};

export default Article;
