import React from "react";
import MuiTextFeild from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

let useStyles = makeStyles((theme) => ({
  root: {
    // borderRadius: 16,
  },
}));

let TextField = ({
  name = "",
  variant = "outlined",
  InputProps,
  field,
  ...rest
}) => {
  let classes = useStyles();

  return (
    <MuiTextFeild
      name={name}
      variant={variant}
      InputProps={{
        classes: {
          root: classes.root,
        },
        ...InputProps,
      }}
      {...field}
      {...rest}
    />
  );
};

export default TextField;
