import { InputBase, makeStyles } from "@material-ui/core";

let useStyles = makeStyles((theme) => ({
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: '8px 15px',
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        maxWidth: 330,
        borderRadius: 5,
        color: theme.palette.text.disabled,
        backgroundColor: theme.colors.veryLightGrey,
        fontFamily: theme.fontFamily.robotoCondensed,
        marginRight: '1rem',
        fontWeight: 500,
        border: 'none',
    },
}));

const SearchBox = ({ onChange, placeholder, style = {}, value }) => {
    const classes = useStyles();

    return (
        <InputBase
            placeholder={placeholder}
            value={value}
            name="search"
            classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
            onChange={onChange}
            style={{ ...style }}
        />
    );
};

export default SearchBox;
