import { blue } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import React, { useContext } from "react";
import { connect } from "react-redux";
import { ActionContext } from "../../../../contexts";
import { Button, DialogActions } from "@material-ui/core";
import {accountTypes} from "../../../../store/constants";

const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    enabled: {
        color: theme.palette.success.main,
    },
    pending: {
        color: theme.palette.warning.main,
    },
    btnPrimary: {
        color: theme.palette.primary.main,
        border: '1px solid',
        height: '30px',
        padding: '10px',
        marginRight: '10px',
        borderRadius: '20px',
        '&:hover': {
            background: theme.palette.primary.main,
            color: '#fff'
        },
    },
    btnSecondary: {
        color: theme.palette.secondary.main,
        border: '1px solid',
        height: '30px',
        padding: '10px',
        marginRight: '10px',
        borderRadius: '20px',
        '&:hover': {
            background: theme.palette.secondary.main,
            color: '#fff'
        }
    },
}));

const MarkAsReviewDialoge = (props) => {
    const classes = useStyles();
    const { playerActions } = useContext(ActionContext);

    const {
        status,
        closeDialog,
        playerId, accountType
    } = props;

    const handleCloseDialog = () => {
        closeDialog();
    };

    const handleSave = () => {
        if (accountType === accountTypes.mmPsychologist){
            playerActions.markAsReviewSubmitted(playerId, status);
        }else {
            playerActions.markReviewCompleted(playerId, status);
        }
        handleCloseDialog()
    }




    return (
        <React.Fragment>
            <div style={{ display: "flex", background: '#cdcdcd', justifyContent: 'flex-end' }}>
                <div style={{ margin: "8px 16px 8px 8px", display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton onClick={handleCloseDialog}>
                        <CloseIcon style={{ color: "blue" }} />
                    </IconButton>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: 'center' }}>
                {accountType === accountTypes.mmPsychologist ?

                    <h3 style={{
                        display: "flex", justifyContent: 'center', height: '7rem',
                        color: '#0070b9',
                        fontSize: '1.5rem',
                        fontWeight: 700,
                        alignItems: 'center'
                    }}>
                        Are you sure you want to submit for review ?
                    </h3> :
                    <h3 style={{
                        display: "flex", justifyContent: 'center', height: '7rem',
                        color: '#0070b9',
                        fontSize: '1.5rem',
                        fontWeight: 700,
                        alignItems: 'center'
                    }}>Are you sure you want to complete the review ?</h3>}

            </div>


            <div>
                <DialogActions>
                    <Button className={classes.btnSecondary} onClick={handleCloseDialog}>Cancel</Button>
                    <Button className={classes.btnPrimary} onClick={handleSave}>Submit</Button>
                </DialogActions>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = () => {
    return {
    };
};
export default connect(mapStateToProps)(MarkAsReviewDialoge);
