import React, { useContext } from "react";
import Search from "./Search";
import { ActionContext } from "../../../contexts";

const SearchContainer = () => {
  let { playerActions } = useContext(ActionContext);

  let handleSearch = (e) => {
    playerActions.searchPlayer(e.target.value.toLowerCase());
  };

  return <Search onChange={handleSearch} />;
};

export default SearchContainer;
