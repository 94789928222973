import React from "react";
import Button from "../../shared/Button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClearIcon from "@material-ui/icons/Clear";
import { Menu, MenuItem } from "../../shared/Menu";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

let useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "capitalize",
  },
  capitalize: {
    textTransform: "capitalize",
  },
}));

const Filter = ({ filterTypes, filter, onFilterChange, loading = false }) => {
  let classes = useStyles();
  let hasFilter = filter !== filterTypes.default;

  let filterColor = hasFilter ? "primary" : "default";
  let FilterIcon = hasFilter
    ? () => <ClearIcon />
    : () => <ArrowDropDownIcon />;

  let removeFilter = () => {
    onFilterChange(filterTypes.default);
  };

  let handleStatusFilter = (filter) => {
    return () => {
      onFilterChange(filter);
    };
  };

  return (
    <Menu
      AnchorComponent={({ onClick, ...rest }) => {
        return (
          <Button
            disabled={loading}
            variant="text"
            color={filterColor}
            endIcon={<FilterIcon />}
            onClick={(e) => {
              hasFilter ? removeFilter() : onClick(e);
            }}
            className={classes.button}
            disableElevation
            {...rest}
          >
            <Typography variant="body1">{filter}</Typography>
          </Button>
        );
      }}
      PaperProps={{
        style: {
          maxHeight: 300,
        },
      }}
    >
      {Object.keys(filterTypes)
        .sort()
        .map((type, index) => {
          if (type !== "default") {
            return (
              <MenuItem
                onClick={handleStatusFilter(filterTypes[type])}
                className={classes.capitalize}
                key={index}
              >
                {filterTypes[type]}
              </MenuItem>
            );
          }
          return null;
        })}
    </Menu>
  );
};

export default Filter;
