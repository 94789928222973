import {DeleteButtonModal, Title} from "../../shared";
import {connect} from "react-redux";
import {Box, FormControl, FormHelperText, Grid, InputLabel, makeStyles, MenuItem} from "@material-ui/core";
import React, {useContext, useEffect} from "react";
import {ActionContext} from "../../../contexts";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Field, Form, Formik} from "formik";
import {string} from "yup";
import {GlobalCss} from "../../../config/GlobalCss";
import Alert from "@material-ui/lab/Alert";
import {Select, TextField} from "formik-material-ui";
import clsx from "clsx";
import Button from "../../shared/Button";

let useStyles = makeStyles((theme) => ({
    form: {
        paddingTop: theme.spacing(2),
        maxWidth: 800,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    content: {
        display: "flex",
        justifyContent: "end",
        margin: '1rem 0rem'
    },
    btnPrimary: {
        color: theme.palette.common.white,
        padding: '10px',
        backgroundColor: theme.colors.primaryNavy,
        width: '13rem',
        borderRadius: '7px',
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            color: theme.colors.primaryNavy,
            border: `1px solid ${theme.colors.primaryNavy}`,
        },
        textTransform: 'uppercase',
    },
    btnSecondary: {
        color: theme.palette.secondary.main,
        border: '1px solid',
        width: '13rem',
        padding: '10px',
        marginRight: '10px',
        borderRadius: '7px',
        '&:hover': {
            background: theme.palette.secondary.main,
            color: theme.palette.common.white,
        },
        textTransform: 'uppercase',
    },
}));

const PositionForm = (props) => {
    const classes = useStyles();
    const {sportsActions} = useContext(ActionContext);
    const {error, formType, loading, position, sportId, setOpen, sportsData, unitsData} = props;
    const type = formType === 'create';

    useEffect(() => {
        sportsActions.getSportsList();
        sportsActions.getAllUnits();
    }, []);

    const sport = sportsData.find(s => s._id === sportId);

    const handleSubmit = (values) => {
        let data = {
            position: values.position,
            sportId: sportId,
            unitId: values.unitId,
        };

        {type ? sportsActions.createPosition(data, sportId) : sportsActions.updateSportsPosition(position._id, data, sportId)}
        setOpen(false);
    };

    const handleErrorClose = () => {
        sportsActions.resetSportPositionCreationError();
    };

    const handleDelete = (id) => {
        sportsActions.deleteSportsPosition(id);
        setOpen(false);
    };

    const renderError = (error) => {
        if (typeof error === 'string') {
            return error;
        } else if (Array.isArray(error)) {
            return error.join(', ');
        } else if (typeof error === 'object' && error !== null) {
            return JSON.stringify(error);
        }
        return '';
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid container spacing={1} style={{height: type ? 350 : 370}}>
            <Grid item xs={12}>
                <div style={{margin: '0rem 3rem'}}>
                    {
                        loading && loading ? (
                            <Box>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Formik
                                initialValues={{
                                    position: position.position || '',
                                    sportName: sport.name,
                                    unitId: position.unitId || '',
                                }}
                                validator={{
                                    position: string()
                                        .trim()
                                        .matches(
                                            /^[a-zA-Z0-9]+(([a-zA-Z0-9 ])?[a-zA-Z0-9]*)*$/,
                                            "Enter a valid sport position, no special characters allowed."
                                        )
                                        .required('A position is required.'),
                                    unitId: string().nullable().required('Select a unit'),
                                }}
                                onSubmit={(values) => handleSubmit(values)}
                            >
                                {(formik) => {
                                    let {errors, touched} = formik;

                                    return (
                                        <Form autoComplete='off'>
                                            <GlobalCss />
                                            <div className={classes.form}>
                                                {type ? <Title title='Create Position'/> : (
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center'
                                                    }}>
                                                        <Title title='Update' text={`${position.position}`}/>
                                                        <DeleteButtonModal
                                                            title={`${position.position}`}
                                                            handleDelete={() => handleDelete(position._id)}
                                                        />
                                                    </div>
                                                )}
                                                {error && error ? (
                                                    <Grid item>
                                                        <Alert severity='error' onClose={handleErrorClose}>
                                                            {error}
                                                        </Alert>
                                                    </Grid>
                                                ) : null}
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Field
                                                            name="position"
                                                            label="Sport Position "
                                                            placeholder="Sport Position "
                                                            component={TextField}
                                                            variant="outlined"
                                                            required
                                                            fullWidth
                                                            autoFocus
                                                            style={{marginTop: '1rem'}}
                                                        />
                                                        <Field
                                                            name="sportName"
                                                            label="Sport Name "
                                                            component={TextField}
                                                            variant="outlined"
                                                            required
                                                            fullWidth
                                                            autoFocus
                                                            disabled
                                                            style={{marginTop: '1rem'}}
                                                        />
                                                        <FormControl variant="outlined" style={{width: '100%', marginTop: '1rem'}}>
                                                            <InputLabel htmlFor='unitId-lable'>
                                                                Sport Unit
                                                            </InputLabel>
                                                            <Field
                                                                component={Select}
                                                                name='unitId'
                                                                label="Sport Unit"
                                                                placeholder='Sport Unit'
                                                                inputProps={{
                                                                    id: "unitId"
                                                                }}
                                                                required
                                                                fullWidth
                                                            >
                                                                {type ? null : (
                                                                    <MenuItem
                                                                        key={1}
                                                                        value={position.unitId}
                                                                    >
                                                                        {position.unit_name}
                                                                    </MenuItem>
                                                                )}
                                                                {unitsData &&
                                                                    unitsData.map((list) => (
                                                                        <MenuItem key={list._id} value={list._id}>
                                                                            {list.unit}
                                                                        </MenuItem>
                                                                    ))}
                                                            </Field>
                                                            {touched["unitId"] && !!errors["unitId"] && (
                                                                <FormHelperText>{renderError(errors["unitId"])}</FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <div className={clsx(classes.content)}>
                                                    <Button
                                                        className={classes.btnSecondary}
                                                        type="button"
                                                        variant="text"
                                                        onClick={handleClose}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        className={classes.btnPrimary}
                                                        type="submit"
                                                    >
                                                        {formType === 'create' ? 'Create' : ' Update'}
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form>
                                    )
                                }}
                            </Formik>
                        )
                    }
                </div>
            </Grid>
        </Grid>
    )
};

let mapStateToProps = (state) => {
    return {
        error: state.ui.sports.error,
        loading: state.ui.sports.loadingSportsPositionById,
        position: state.sports.sportsPositionById,
        sportsData: state.sports.sportsListData,
        unitsData: state.sports.sportUnits,
        formType: state.ui.sports.selectedFormState,
        sportId: state.ui.sports.selectedSportId,
    };
};

export default connect(mapStateToProps)(PositionForm);