import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        display: "flex",
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.colors.veryLightGrey,
        overflowY: 'auto',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    appBar: {
        position: 'fixed',
        backgroundColor: theme.palette.common.white,
    },
    header: {
        fontFamily: theme.fontFamily.prometo,
        color: theme.palette.text.primary,
        flex: 1,
    },
    iconColor: {
        color: theme.palette.text.primary,
    },
    container: {
        flexGrow: 1,
        display: "flex",
        margin: "2rem",
        backgroundColor: theme.palette.common.white,
        boxShadow: "3px 3px 10px 3px #cdcdcd",
        borderRadius: 8,
        padding: "2rem 1rem",
        justifyContent: 'space-evenly'
    },
    title: {
        paddingLeft: 16,
        paddingTop: 16,
        marginTop: 0,
        color: theme.palette.text.primary,
        fontFamily: theme.fontFamily.prometo,
    },
    caption: {
        paddingLeft: 16,
        paddingTop: 16,
        marginTop: 0,
        color: theme.palette.text.disabled,
        fontFamily: theme.fontFamily.robotoCondensed,
    },
    list: {
        height: "calc(100vh - 130px)",
        overflowY: "scroll",
        width: 500,
        "&::-webkit-scrollbar": {
            width: 0,
        },
    },
}));

const ArticleDialog = (props) => {
  const classes = useStyles();
  const { closeDialog, paragraphs = [], title = "" } = props;

  return (
    <div>
      <AppBar
        elevation={0}
        className={classes.appBar}
      >
        <Toolbar>
          <Typography variant="h6" className={classes.header}>
            {title}
          </Typography>
          <IconButton onClick={closeDialog}>
            <CloseIcon className={classes.iconColor}/>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <div className={classes.root}>
          <Container className={classes.container}>
              {paragraphs.map((paragraph, index) => {
                  const isTitle = paragraph.is_title;
                  const text = paragraph.text;
                  return (
                      <React.Fragment key={index}>
                          <Typography variant={isTitle ? "h6" : "body1"} className={classes.title}>
                              {text}
                          </Typography>
                          <Typography variant="caption" className={classes.caption}>
                              (Relevance Score {paragraph.relevance_score})
                          </Typography>
                          <br />
                      </React.Fragment>
                  );
              })}
          </Container>
      </div>
    </div>
  );
};

export default ArticleDialog;
