import apiHandler, {getAuthHeader} from "./apiHandler";
import {parseFeedResponse} from "./responseParser";

export const getCoaches = async () => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get("/coaches", { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.coaches;
  } catch (err) {
    throw new Error(err);
  }
};

export const getFilterCoaches = async (model) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post('filter_coach', model, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.coaches;
  } catch (err) {
    throw new Error(err);
  }
};

export const getCoachDetails = async (coachId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/load-coach/${coachId}`, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    const coach = response.data.info.coach;

    // rearrange
    const traits = coach.insights["personality traits"];

    // rearrange risk
    const risk = coach.insights["risk"];
    let riskComments = {};

    let riskData = Object.keys(risk).reduce((obj, category) => {
      let comments = risk[category]["comments"].reduce((obj, comment) => {
        obj[comment._id] = {
          id: comment._id,
          author: comment.author,
          content: comment.content,
          timestamp: comment.timestamp,
        };

        return obj;
      }, {});

      riskComments = { ...riskComments, ...comments };

      obj[category] = {
        id: category,
        score: risk[category]["score"],
        text: risk[category]["text"],
        comments: Object.keys(comments),
      };

      return obj;
    }, {});

    const executiveSummery = coach.insights["executive_summary"];

    const executiveSummeryData = Object.keys(executiveSummery).reduce(
      (obj, categoryId) => {
        obj[`${coachId}.${categoryId}`] = executiveSummery[categoryId];
        return obj;
      },
      {}
    );

    const issues = coach.insights["psychological disorders"];

    // possible duplicate info
    const duplicate = coach.possible_duplicate_info;

    const possibleDuplicateInfo = {
      isPossibleDuplicate: duplicate["is_possible_duplicate"],
      possiblePlayerId: duplicate.duplicate_coach["_id"],
      possiblePlayerName: duplicate.duplicate_coach["full_name"],
      possiblePlayerBirthday: duplicate.duplicate_coach["dob"],
      possiblePlayerSchool: duplicate.duplicate_coach["school"],
      possiblePlayerTeam: duplicate.duplicate_coach["team"],
      possiblePlayerPhone: duplicate.duplicate_coach["phone"],
      possiblePlayerEmail: duplicate.duplicate_coach["email"],
      possiblePlayerCreatedOn: duplicate.duplicate_coach["created_on"],
      possiblePlayerReviewedOn: duplicate.duplicate_coach["reviewed_on"],
    };

    const contact = {
      phone: coach.phone,
      email: coach.email,
      twitter: coach.twitter,
      twitterSourceln: coach.twitter_source_ln,
      instagram: coach.instagram,
      instagramSourceln: coach.instagram_source_ln,
      linkedin: coach.linkedin,
      linkedinSourceln: coach.linkedin_source_ln,
    };

    const basic = {
      id: coach._id,
      firstName: coach.first_name,
      lastName: coach.last_name,
      fullName: coach.full_name,
      birthday: coach.dob,
      gender: coach.gender,
      race: coach.race,
      relationshipStatus: coach.relationship_status,
      homeCountry: coach.home_country,
      homeState: coach.home_state,
      hometown: coach.home_town,
      risk: coach.risk,
      school: coach.school,
      status: coach.status,
      unit: coach.unit,
      conference: coach.conference,
      conferenceType: coach.conference_type,
      coachType: coach.coach_type,
      team: coach.team,
      image: coach.image_url,
      consent: coach.consent,
      isPaid: coach.is_paid,
      sport: coach && coach.sport,
      isPlusReport: coach?.isRequestedReport !== undefined ? coach.isRequestedReport : false,
      plusReportData: (coach?.isRequestedReport !== undefined) ? coach?.reqPlusReport : {},
      developmentScore:  coach?.development_score,
      twitterStatus: coach.mim_record.twitter_feed,
      instagramStatus: coach.mim_record.instagram_feed,
      sentimentStatus: coach.mim_record.sentiment_status,
      psyStatus: coach.mim_record.personality_status,
      brain: coach.brain
    };

    const customTraits = coach.insights["custom_traits"];
    const behaviourRisk = coach.insights["risk"];

    const reports = {
      psychologistReport: coach.reports.psychologist_report,
      systemReport: coach.reports.system_report,
    };

    const reportSettings = {
      executiveSummaryReport:
        coach["report_settings"]["executive_summary"]["allowed"],
      fullReport: coach["report_settings"]["full_report"]["allowed"],
    };

    return {
      id: coach._id,
      basic,
      contact,
      traits,
      risk: riskData,
      riskComments,
      executiveSummery: executiveSummeryData,
      customTraits,
      possibleDuplicateInfo,
      issues,
      stats: coach.stats,
      notes: coach.notes,
      links: coach.links,
      message: coach.message,
      reports,
      reportSettings,
      behaviourRisk,
    };
  } catch (err) {
    throw new Error(err);
  }
};

export const markCoachAsPaid = async (coachId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`coach/is-paid/set/${coachId}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const markCoachAsUnpaid = async (coachId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`coach/is-paid/unset/${coachId}`, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const toggleFullReportPaymentStatus = async (
  coachId,
  allowed = false
) => {
  const status = allowed ? "allow" : "restrict";

  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(
      `/set-coach-report-allowed/${coachId}/full_report/${status}`,
      {
        headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const toggleExecutiveSummaryReportPaymentStatus = async (
  coachId,
  allowed = false
) => {
  const status = allowed ? "allow" : "restrict";

  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(
      `/set-coach-report-allowed/${coachId}/executive_summary/${status}`,
      {
        headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const toggleActionPlanReportPaymentStatus = async (
  coachId,
  allowed = false
) => {
  const status = allowed ? "allow" : "restrict";

  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(
      `/set-coach-report-allowed/${coachId}/action_plan/${status}`, { headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const exportFullReport = async (coachId) => {
  try {
    let headers = getAuthHeader();
    let timeStamp = Date.now();
    let response = await apiHandler.get(
      `/export/report/coach/${coachId}/full_report/${timeStamp}`,
      {
        responseType: "blob",
        headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return new Blob([response.data], {type: "application/pdf"});
  } catch (err) {
    throw new Error(err);
  }
};

export const exportExecutiveSummaryReport = async (coachId) => {
  try {
    let headers = getAuthHeader();
    let timeStamp = Date.now();
    let response = await apiHandler.get(
      `/export/report/coach/${coachId}/executive_summary/${timeStamp}`,
      {
        responseType: "blob",
        headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return new Blob([response.data], {type: "application/pdf"});
  } catch (err) {
    throw new Error(err);
  }
};

export const exportActionPlanReport = async (coachId) => {
  try {
    let headers = getAuthHeader();
    let timeStamp = Date.now();
    let response = await apiHandler.get(
      `/export/report/coach/${coachId}/action_plan/${timeStamp}`,
      {
        responseType: "blob",
        headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return new Blob([response.data], {type: "application/pdf"});
  } catch (err) {
    throw new Error(err);
  }
};

export const reGenerateFullReport = async (coachId) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(
      `/generate/report/coach/${coachId}/full_report`,
      {
        headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const reGenerateActionPlanReport = async (coachId) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(
      `/generate/report/coach/${coachId}/action_plan`,
      {
        headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const reGenerateExecutiveSummaryReport = async (coachId) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(
      `/generate/report/coach/${coachId}/executive_summary`,
      {
        headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const postApproveCoachProfile = async (data) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post("/approve-coach", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const postRejectCoachProfile = async (data) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.post("/reject-coach", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const getCoachFeed = async (coachId, source) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/feed/coach/${coachId}/${source}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    const negativeArr = response.data.info.negatives;
    const negatives = parseFeedResponse(negativeArr);

    const neutralsArr = response.data.info.neutrals;
    const neutrals = parseFeedResponse(neutralsArr);

    const positivesArr = response.data.info.positives;
    const positives = parseFeedResponse(positivesArr);

    const data = { ...negatives, ...neutrals, ...positives };

    return {
      negatives: Object.keys(negatives),
      positives: Object.keys(positives),
      neutrals: Object.keys(neutrals),
      data,
      graphs: response.data.info.graphs,
    };
  } catch (err) {
    throw new Error(err);
  }
};

export const updateCoachRiskScore = async (
  data = {
    coach_id: "",
    category: "",
    score: 0,
  }
) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post("/coach/risk/update/score", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    const payload = response.data.info;

    return {
      candidate_id: payload.coach_id,
      risk: {
        ...payload.updated_risk,
      },
    };
  } catch (error) {
    throw new Error(error);
  }
};

export const postCoachRiskComment = async (data) => {
  try {
    let headers = getAuthHeader();
    const response = await apiHandler.post("/coach/insight/add/risk", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.content_id;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteCoachRiskComment = async (data) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post("/coach/insight/delete/risk", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.content_id;
  } catch (err) {
    throw new Error(err);
  }
};

export const markAsReviewSubmitted = async (playerId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`coach/review-submitted/${playerId}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const postCoachReviewCompleted = async (coachId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/coach/review-complete/${coachId}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const markAsCoachReviewIncomplete = async (coachId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/coach/review-incomplete/${coachId}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const postExecutiveSummeryComment = async (data) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post(
      "/coach/insight/add/executive-summary",
      data,
      {
        headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.content_id;
  } catch (err) {
    throw new Error(err);
  }
};

export const postUpdateNotes = async (data) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.post("/coach/insight/update/notes", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const addCoachLink = async (data) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.post("/coach/insight/add/link", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.links;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteCoachLink = async (data) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.post(`/coach/insight/delete/link`, data, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const exportCoachFeed = async (coachId, source) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(`/export/coach/feed/${coachId}/${source}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteExecutiveSummeryContent = async (data) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post(
      "/coach/insight/delete/executive-summary",
      data,
      {
        headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.content_id;
  } catch (err) {
    throw new Error(err);
  }
};

export const postStartAnalyze = async (coachId) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post(`/coach_start_analyze/${coachId}`, {}, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const postAnalyzeResults = async (coachId) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(`/coach_analyzed_results/${coachId}` ,{
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const postSentimentResults = async (coachId) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(`/coach_sentiment_results/${coachId}` ,{
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const coachTwitter = async (coachId,requestUserId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/player_tweets/twitter/${coachId}/coach/${requestUserId}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const coachInstagram = async (coachId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/player_posts/instagram/${coachId}/coach`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const coachSentimentScore = async (coachId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/receptiviti_sentiment_score/${coachId}/coach`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const coachPsychReviewUpdate = async (coachId, statusId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/update_coach_status/${coachId}/${statusId}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const coachBig5Info = async (coachId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/receptiviti_big5_score/${coachId}/coach`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const getCoachVideoTranscript = async (model) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post('/save-coach-transcript', model, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data;
  }
  catch (err) {
    throw new Error(err);
  }
};

export const postRequestPlanReports = async (coach_id) => {
  try {
    let headers = getAuthHeader()
    let response = await apiHandler.post(`/coach/request-plus-report/${coach_id}`, '', { headers })

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    // return response.data
    return response.data.info;
  }
  catch (err) {
    throw new Error(err);
  }
};

export const approveCoach = async (model) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post('/approve-coach', model, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data;
  }
  catch (err) {
    throw new Error(err);
  }
};

export const coachPlusReport = async (coachId) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post(`/coach/request-plus-report/${coachId}`, '', { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data;
  }
  catch (err) {
    throw new Error(err);
  }
};

export const removeCoachFeed = async (coachId) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(`/remove_rt_ad_feed/${coachId}`, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data;
  }
  catch (err) {
    throw new Error(err);
  }
};

export const deleteExecutiveSummeryComment = async (data) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post(
      "/coach/insight/delete/executive-summary",
      data,
      {
        headers,
      }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.content_id;
  } catch (err) {
    throw new Error(err);
  }
};

export const getUserId = async (user_handle) => {
  try {
    let headers = getAuthHeader();
    const response = await apiHandler.get(`/twitter_user_id/${user_handle}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info.userId;
  } catch (err) {
    throw new Error(err);
  }
};


export const updateCoachBasicInfo = async (coach_id, data) => {
  try {
    const headers = getAuthHeader();
    const response = apiHandler.put(`/update_coach/${coach_id}`, data, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return response.data.info;
  } catch (err) {
    throw new Error(err);
  }
};

const coach = {
  getCoaches,
  getFilterCoaches,
  getCoachDetails,
  markCoachAsPaid,
  markCoachAsUnpaid,
  toggleFullReportPaymentStatus,
  toggleExecutiveSummaryReportPaymentStatus,
  toggleActionPlanReportPaymentStatus,
  exportFullReport,
  exportExecutiveSummaryReport,
  exportActionPlanReport,
  reGenerateExecutiveSummaryReport,
  reGenerateActionPlanReport,
  reGenerateFullReport,
  postApproveCoachProfile,
  postRejectCoachProfile,
  getCoachFeed,
  updateCoachRiskScore,
  postCoachRiskComment,
  deleteCoachRiskComment,
  postCoachReviewCompleted,
  markAsCoachReviewIncomplete,
  postExecutiveSummeryComment,
  postUpdateNotes,
  addCoachLink,
  exportCoachFeed,
  markAsReviewSubmitted,
  deleteCoachLink,
  deleteExecutiveSummeryContent,
  postStartAnalyze,
  postAnalyzeResults,
  postSentimentResults,
  removeCoachFeed,
  coachPlusReport,
  approveCoach,
  coachTwitter,
  coachInstagram,
  coachSentimentScore,
  coachPsychReviewUpdate,
  coachBig5Info,
  getCoachVideoTranscript,
  postRequestPlanReports,
  deleteExecutiveSummeryComment,
  getUserId,
  updateCoachBasicInfo,
};

export default coach;