import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { Field } from "formik";
import { TextField, Select } from "formik-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { ActionContext } from "../../../../contexts";
import { Autocomplete } from "formik-material-ui-lab";
import MuiTextField from "@material-ui/core/TextField";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

let useStyles = makeStyles((theme) => ({
    heading: {
        // marginTop: theme.spacing(3),
        //marginBottom: theme.spacing(3),
        maxWidth: 480,
        fontWeight: 500,
        lineHeight: "60px",
    },
    form: {
        paddingTop: theme.spacing(1),
        maxWidth: '100%',
        marginRight: '1rem',
    },
}));

let CreatePlayerBackground = (props) => {
    let classes = useStyles();
    const { errors, touched, sportsData, countriesData, stateData, values, unitData, sportPosition, conferenceByType } = props;
    // console.log("...............props",props,values)
    //   const [val,setVal] = React.useState(null)
    let actions = useContext(ActionContext);
    let { sportsActions, conferenceActions } = actions;

    let organizationSports = sportsData && sportsData.reduce((obj, item) => {
        obj[item.name] = item.name;
        return obj;
    }, {});

    let finalSports = JSON.parse(JSON.stringify(organizationSports));

    let organizationUnit = unitData && unitData.reduce((obj, item) => {
        obj[item.unit] = item.unit;
        return obj;
    }, {});

    let finalUnits = JSON.parse(JSON.stringify(organizationUnit));

    const handleSportChange = (value) => {
        console.log('..................handleSportChange', value)
        values.sport = value;
        values.unit = "";
        sportsActions.getSportUnitDetails(value);
    }

    const handleUnitChange = (value) => {
        console.log('..................handleUnitChange', value)
        values.unit = value;
        sportsActions.getSportPositionDetails(value, values.sport);
    }

    const handleCountryName = (value) => {
        console.log('..................handleCountryName', value)
        values.homeCountry = value;
        conferenceActions.getStatesByCountryName(value);
    }

    const conferenceTypes = {
        "Professional": "Professional",
        "Collegiate": "Collegiate"
    }

    const handleConferenceType = (value) => {
        console.log("...........handleConferenceType", value)
        values.conference_type = value;
        conferenceActions.getConferenceByType(value);
    }

    console.log("..............all", unitData, "sportPosition", sportPosition)


    return (
        <React.Fragment>
            <Typography variant="h4" className={classes.heading}>
                Let's get some backgorund info
            </Typography>
            <div className={classes.form}>
                <Grid container spacing={3}>

                    <Grid item lg={6} sm={12}>
                        <Field
                            name="homeCountry"
                            required
                            component={Autocomplete}
                            options={Object.keys(countriesData)}
                            renderInput={(props) => {
                                return (
                                    <MuiTextField
                                        variant="outlined"
                                        label="Home Country"
                                        placeholder="Home Country"
                                        error={touched["homeCountry"] && !!errors["homeCountry"]}
                                        helperText={errors["homeCountry"]}
                                        required
                                        {...props}
                                    />
                                );
                            }}
                            onChange={(e, value) => {
                                handleCountryName(value)
                            }}
                            renderOption={(value, { inputValue }) => {
                                const matches = match(value, inputValue);
                                const parts = parse(value, matches);

                                return (
                                    <div>
                                        {parts.map((part, index) => (
                                            <span
                                                key={index}
                                                style={{ fontWeight: part.highlight ? 700 : 400 }}
                                            >
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                );
                            }}
                        />
                    </Grid>

                    <Grid item lg={6} sm={12}>
                        <Field
                            name="homeState"
                            component={Autocomplete}
                            options={Object.keys(stateData) || {}}
                            renderInput={(props) => {
                                return (
                                    <MuiTextField
                                        variant="outlined"
                                        label="Home State"
                                        placeholder="Home State"
                                        error={touched["homeState"] && !!errors["homeState"]}
                                        helperText={errors["homeState"]}
                                        {...props}
                                    />
                                );
                            }}
                            renderOption={(value, { inputValue }) => {
                                const matches = match(value, inputValue);
                                const parts = parse(value, matches);

                                return (
                                    <div>
                                        {parts.map((part, index) => (
                                            <span
                                                key={index}
                                                style={{ fontWeight: part.highlight ? 700 : 400 }}
                                            >
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                );
                            }}
                        />
                    </Grid>

                    {/* <Grid item lg={6} sm={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="homeState-label">
                Home State *
              </InputLabel>
              <Field
                component={Select}
                name="homeState"
                label="Home State"
                placeholder="Home State"
                labelId="homeState-label"
                inputProps={{
                  id: "homeState",
                }}
                required
                onChange={(e, value) => {
                  console.log("............value",e.target.value, value)
                  handleCountryName1(value)
                }}
              >
                <MenuItem value="Hyderabad">Hyderabad</MenuItem>
                <MenuItem value="RangaReddy">RangaReddy</MenuItem>
              </Field>
            </FormControl>
          </Grid> */}

                    <Grid item sm={12}>
                        <Field
                            name="hometown"
                            label="Hometown"
                            placeholder="Hometown"
                            component={TextField}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid item lg={6} sm={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="gender-label">
                                Gender *
                            </InputLabel>
                            <Field
                                component={Select}
                                name="gender"
                                label="Gender"
                                placeholder="Gender"
                                labelId="gender-label"
                                inputProps={{
                                    id: "gender",
                                }}
                                required
                            >
                                <MenuItem value="Men's Sports">Men's Sports</MenuItem>
                                <MenuItem value="Women's Sports">Women's Sports</MenuItem>
                            </Field>
                        </FormControl>
                    </Grid>

                    <Grid item lg={6} sm={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="race-label">
                                Race/Ethnicity *
                            </InputLabel>
                            <Field
                                component={Select}
                                name="race"
                                label="Race/Ethnicity *"
                                placeholder="Race/Ethnicity"
                                labelId="race-label"
                                inputProps={{
                                    id: "race",
                                }}
                                required
                            >
                                <MenuItem value="Race">American Indian/Alaska Native</MenuItem>
                                <MenuItem value="Asian">Asian</MenuItem>
                                <MenuItem value="Black/African American">Black/African American</MenuItem>
                                <MenuItem value="Hispanic/Latino">Hispanic/Latino</MenuItem>
                                <MenuItem value="Native Hawaiian/Pacific Islander">Native Hawaiian/Pacific Islander</MenuItem>
                                <MenuItem value="White">White</MenuItem>
                            </Field>
                        </FormControl>
                    </Grid>

                    <Grid item sm={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="relationship-label">
                                Relationship Status
                            </InputLabel>
                            <Field
                                component={Select}
                                name="relationship"
                                label="Relationship Status"
                                placeholder="Status"
                                labelId="relationship-label"
                                inputProps={{
                                    id: "relationship",
                                }}
                            >
                                <MenuItem value="Single">Single</MenuItem>
                                <MenuItem value="Married">Married</MenuItem>
                                <MenuItem value="Complicated">Complicated</MenuItem>
                            </Field>
                        </FormControl>
                    </Grid>
                    {/* 
          <Grid item lg={6} sm={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="conference_type-label">
                Conference Type *
              </InputLabel>
              <Field
                component={Select}
                name="conference_type"
                label="Conference Type *"
                placeholder="Type"
                labelId="conference_type-label"
                inputProps={{
                  id: "conference_type",
                }}
                required
              >
                <MenuItem value="Professional">Professional</MenuItem>
                <MenuItem value="Collegiate">Collegiate</MenuItem>
              </Field>
            </FormControl>
          </Grid> */}

                    <Grid item lg={6} sm={12}>
                        <Field
                            name="conference_type"
                            required
                            component={Autocomplete}
                            options={Object.keys(conferenceTypes)}
                            renderInput={(props) => {
                                return (
                                    <MuiTextField
                                        variant="outlined"
                                        label="League"
                                        placeholder="League"
                                        error={touched["conference_type"] && !!errors["conference_type"]}
                                        helperText={errors["conference_type"]}
                                        required
                                        {...props}
                                    />
                                );
                            }}
                            onChange={(e, value) => {
                                handleConferenceType(value)
                            }}
                            renderOption={(value, { inputValue }) => {
                                const matches = match(value, inputValue);
                                const parts = parse(value, matches);

                                return (
                                    <div>
                                        {parts.map((part, index) => (
                                            <span
                                                key={index}
                                                style={{ fontWeight: part.highlight ? 700 : 400 }}
                                            >
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                );
                            }}
                        />
                    </Grid>

                    <Grid item lg={6} sm={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="conference-label">
                                Conference *
                            </InputLabel>
                            <Field
                                component={Select}
                                name="conference"
                                label="Conference *"
                                placeholder="Conference"
                                labelId="conference-label"
                                inputProps={{
                                    id: "conference",
                                }}
                                required
                            >
                                {
                                    conferenceByType && conferenceByType.map(list =>
                                        <MenuItem value={list.name}>{list.name}</MenuItem>
                                    )
                                }
                            </Field>
                        </FormControl>
                    </Grid>

                    <Grid item lg={6} sm={12}>
                        <Field
                            name="sport"
                            required
                            component={Autocomplete}
                            options={Object.keys(finalSports)}
                            renderInput={(props) => {
                                return (
                                    <MuiTextField
                                        variant="outlined"
                                        label="Sport"
                                        placeholder="Sport"
                                        error={touched["sport"] && !!errors["sport"]}
                                        helperText={errors["sport"]}
                                        required
                                        {...props}
                                    />
                                );
                            }}
                            onChange={(e, value) => {
                                handleSportChange(value)
                            }}
                            renderOption={(value, { inputValue }) => {
                                const matches = match(value, inputValue);
                                const parts = parse(value, matches);

                                return (
                                    <div>
                                        {parts.map((part, index) => (
                                            <span
                                                key={index}
                                                style={{ fontWeight: part.highlight ? 700 : 400 }}
                                            >
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                );
                            }}
                        />
                    </Grid>

                    {/* <Grid item lg={6} sm={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="sport-label">
                Sport *
              </InputLabel>
              <Field
                component={Select}
                name="sport"
                label="Sport *"
                placeholder="Sport"
                labelId="sport-label"
                required
                inputProps={{
                  id: "sport",
                }}
                onChange={handleSportChange}
              >
                {
                  sportsData && sportsData.map(list =>
                    <MenuItem value={list.name}>{list.name}</MenuItem>
                  )
                }
              </Field>
            </FormControl>
          </Grid> */}

                    <Grid item lg={6} sm={12}>
                        <Field
                            name="unit"
                            required
                            component={Autocomplete}
                            options={Object.keys(finalUnits)}
                            renderInput={(props) => {
                                return (
                                    <MuiTextField
                                        variant="outlined"
                                        label="Unit"
                                        placeholder="Unit"
                                        error={touched["unit"] && !!errors["unit"]}
                                        helperText={errors["unit"]}
                                        required
                                        {...props}
                                    />
                                );
                            }}
                            onChange={(e, value) => {
                                handleUnitChange(value)
                            }}
                            renderOption={(value, { inputValue }) => {
                                const matches = match(value, inputValue);
                                const parts = parse(value, matches);

                                return (
                                    <div>
                                        {parts.map((part, index) => (
                                            <span
                                                key={index}
                                                style={{ fontWeight: part.highlight ? 700 : 400 }}
                                            >
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                );
                            }}
                        />
                    </Grid>

                    {/* <Grid item lg={6} sm={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="unit-label">
                                Unit
                            </InputLabel>
                            <Field
                                component={Select}
                                name="unit"
                                label="Unit *"
                                placeholder="Unit"
                                labelId="unit-label"
                                inputProps={{
                                    id: "unit",
                                }}
                                onChange={handleUnitChange}
                            >
                                {
                                    sportsData && sportsData.map(list =>
                                        <MenuItem value={list._id}>{list.name}</MenuItem>
                                    )
                                }
                                {
                  unitData && unitData.map(list =>
                    <MenuItem value={list._id}>{list.name}</MenuItem>
                  )
                }
                            </Field>
                        </FormControl>
                    </Grid> */}



                    <Grid item lg={6} sm={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="positions-label">
                                Positions
                            </InputLabel>
                            <Field
                                component={Select}
                                name="positions"
                                label="Positions *"
                                placeholder="Positions"
                                labelId="positions-label"
                                inputProps={{
                                    id: "positions",
                                }}
                            >
                                {
                                    sportPosition && sportPosition.map(list =>
                                        <MenuItem value={list.position}>{list.position}</MenuItem>
                                    )
                                }
                            </Field>
                        </FormControl>
                    </Grid>

                    <Grid item lg={6} sm={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="athleteType-label">
                                Athlete Type *
                            </InputLabel>
                            <Field
                                component={Select}
                                name="athleteType"
                                label="Athlete Type *"
                                placeholder="Athlete Type *"
                                labelId="athleteType-label"
                                inputProps={{
                                    id: "athleteType",
                                }}
                            >
                                <MenuItem value="Roster">Roster</MenuItem>
                                <MenuItem value="Recruit">Recruit</MenuItem>
                            </Field>
                        </FormControl>
                    </Grid>



                </Grid>
            </div>
        </React.Fragment>
    );
};
// export default CreatePlayerBackground;
let mapStateToProps = (state) => {
    return {
        sportsData: state.sports.sportsListData,
        countriesData: state.conference.allCountries,
        stateData: state.conference.allStateByCountry,
        unitData: state.sports.unitSport,
        sportPosition: state.sports.sportPosition,
        conferenceByType: state.conference.allConferenceByType,
    };
};
export default connect(mapStateToProps)(CreatePlayerBackground);
