import { AppBar, Avatar, makeStyles, Toolbar, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { capitalize } from "../../../../utils";
import { IconButton } from "../../../shared/Button";
import ApprovePanel from "./ApprovePanel";
import MarkAsReviewCompleteToggleButton from "./MarkAsReviewCompleteToggleButton";
import Message from "./Message";
import Nav from "./Nav";
import Payments from "./Payments";
import Reports from "./Reports";
import RequestPlusReport from "./RequestPlus";
import { ActionContext } from "../../../../contexts";
import Reprocess from "./ReProcess";
import useStatusSelector from "../../../../utils/useStatusSelector";
import { accountTypes } from "../../../../store/constants";

const PlayerDetails = (props) => {
  let { playerId, pages, accountType, onClose, fromTeam, basicInfo, message } = props;
  const { getBackgroundColor, getColor } = useStatusSelector();

  let useStyles = makeStyles((theme) => ({
    appBar: {
      background: theme.palette.common.white,
      padding: '1rem .5rem',
      paddingLeft: 0,
      width: '100%',
      "& .MuiToolbar-regular": {
        minHeight: '30px',
      },
    },
    content: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      backgroundColor: theme.palette.background.paper,
    },
    avatar: {
      width: 30,
      height: 30,
      marginRight: 8,
      backgroundColor: theme.palette.background.primary,
    },
    nameAlignData: {
      color: theme.colors.darkBlue,
      fontFamily: theme.fontFamily.prometo,
      fontWeight: 800,
      fontSize: 16,
      margin: '0px 10px',
    },
    teamALign: {
      color: theme.palette.primary.main,
      fontSize: 14,
      fontFamily: theme.fontFamily.robotoCondensed,
      fontWeight: 700,
      backgroundColor: theme.colors.blue30Percent,
      marginRight: 10,
      padding: '5px 10px',
      borderRadius: 4,
    },
    reportStatus: {
      color: getColor(basicInfo.status),
      backgroundColor: getBackgroundColor(basicInfo.status),
      marginRight: 10,
      padding: '5px 10px',
      borderRadius: 4,
      fontSize: 14,
      fontFamily: theme.fontFamily.robotoCondensed,
      fontWeight: 700,
    },
    plusReport: {
      color: '#00ae4d',
      backgroundColor: '#e0f5ea',
      marginRight: 10,
      padding: '5px 10px',
      borderRadius: 4,
      fontSize: 14,
      fontFamily: theme.fontFamily.robotoCondensed,
      fontWeight: 700,
    },
    baseReport: {
      color: theme.colors.slate,
      backgroundColor: theme.colors.veryLightGrey,
      marginRight: 10,
      padding: '5px 10px',
      borderRadius: 4,
      fontSize: 14,
      fontFamily: theme.fontFamily.robotoCondensed,
      fontWeight: 700,
    },
    root: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      alignItems: "center",
      overflowY: "scroll",
      scrollbarWidth: 7,
      background: theme.colors.veryLightGrey,
      scrollbarColor: theme.palette.grey[200],
      "&::-webkit-scrollbar": {
        width: 8,
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: theme.palette.background.default,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.grey[300],
        height: 50,
      },
      padding: theme.spacing(2),
    },
    nav: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      borderRadius: '8px',
      boxShadow: '0 2px 11px 0 rgb(0 0 0 / 16%)',
      backgroundColor: theme.palette.common.white,
      position: 'absolute',
      top: '5.9rem',
      zIndex: 1,
      borderLeft: `5px solid ${theme.palette.primary.main}`,
      width: '97%'
    },
    avatarCellDesign: {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.main,
      fontSize: 12,
      height: 32,
      width: 32,
      boxShadow: '3px 3px 8px 0px #cdcdcd',
      color: theme.palette.common.white,
      fontWeight: '700'
    },
  }));

  let classes = useStyles();
  let [selectedIndex, setSelectedIndex] = useState(1);
  let [showMessage, setShowMessage] = useState(message.show);
  const { playerActions } = useContext(ActionContext);

  useEffect(() => {
    if (basicInfo?.isPlusReport) {
      playerActions.submittedPlusReport(basicInfo?.isPlusReport)
      playerActions.savePlusReportData(basicInfo?.plusReportData)
    }
  }, [basicInfo])

  useEffect(() => {
    return () => {
      playerActions.submittedPlusReport(false)
      playerActions.savePlusReportData({})
    }
  }, [])

  let page = pages[selectedIndex];

  if (!page) {
    return null;
  }

  let Page = page.component;

  if (!message) {
    setShowMessage(message.show)
  }

  let hasAnalyzed = true;
  let status = basicInfo.status;

  let showErrorMessage = true;

  let handleSelect = (e, index) => {
    setSelectedIndex(index);
  };

  let handleMessage = () => {
    setShowMessage(false);
  };

  const playerName = basicInfo.name.split(" ")

  return (
      <div className={classes.content}>
        <AppBar
            position="relative"
            color="inherit"
            elevation={0}
            className={classes.appBar}
        >
          <Toolbar>
            <IconButton icon={<ArrowBackIcon/>} onClick={onClose}/>
            {
              basicInfo.image ?
                  <Avatar src={basicInfo.image} alt="player" className={classes.avatar}/>
                  :
                  <Avatar className={classes.avatarCellDesign} style={{cursor: 'pointer',}} title={basicInfo.name}>
                    {
                      basicInfo.name ?
                          (
                              playerName.length > 1 ?
                                  basicInfo.name.split(" ")[0][0].toUpperCase() + basicInfo.name.split(" ")[1][0].toUpperCase()
                                  :
                                  basicInfo.name.split(" ")[0][0].toUpperCase()
                          )
                          :
                          ""
                    }
                  </Avatar>
            }
            <Typography variant="h6" className={classes.nameAlignData}>
              {capitalize(basicInfo.name)}
            </Typography>
            <Typography className={classes.teamALign}>
              {capitalize(basicInfo.team)}
            </Typography>
            <Typography className={classes.teamALign}>
              {capitalize(basicInfo.sport) || "NA"}
            </Typography>
            <Typography className={classes.reportStatus}>
              {capitalize(basicInfo.status)}
            </Typography>
            {
              (basicInfo?.isPlusReport === true) ?
                  <Typography className={classes.plusReport}>
                    Plus Report
                  </Typography>
                  :
                  <Typography className={classes.baseReport}>
                    Base Report
                  </Typography>
            }
            <div style={{flex: 1}}/>
            <div style={{ display: "flex" }}>
              {
                <MarkAsReviewCompleteToggleButton
                    playerId={playerId}
                    consent={basicInfo.consent}
                    status={basicInfo.status}
                />
              }
            </div>
            {
              (accountType === accountTypes.superAdmin || accountType === accountTypes.mmDdiAdmin || accountType === accountTypes.mmPsychologist || accountType === accountTypes.mmChiefPsychologist) ?
                  ""
                  :
                  <RequestPlusReport
                      playerId={playerId}
                      status={status}
                      accountType={accountType}
                      basicInfo={basicInfo}
                  />
            }
            {(accountType === accountTypes.superAdmin || accountType === accountTypes.mmPsychologist || accountType === accountTypes.mmChiefPsychologist) ?
                <Reports
                    playerId={playerId}
                    status={status}
                    accountType={accountType}
                /> : ''}
                <Payments
                    playerId={playerId}
                    status={status}
                    accountType={accountType}
                />
            {
              (accountType === accountTypes.superAdmin || accountType === accountTypes.mmDdiAdmin) ?
                  <Reprocess
                      playerId={playerId}
                      status={status}
                      accountType={accountType}
                      playerInfo={basicInfo}
                      type='player'
                  /> : ''
            }
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          {showErrorMessage && (
              <div style={{width: "100%", marginTop: "40px"}}>
                <Message
                    show={showMessage}
                    title={message.title}
                    content={message.content}
                    buttonLabel={message.buttonLabel}
                    severity={message.severity}
                    onClick={handleMessage}
                />
              </div>
          )}
          <div className={classes.nav}>
            <Nav
                selectedIndex={selectedIndex}
                onSelect={handleSelect}
                hasAnalyzed={hasAnalyzed}
                status={basicInfo.status}
                fromTeam={fromTeam}
                basicInfo={basicInfo}
            />
          </div>
          <div style={{marginTop: '2rem', width: '100%'}}>
            <Page playerId={playerId}/>
          </div>
        </div>
        <ApprovePanel playerId={playerId}/>
      </div>
  );
};

let mapStateToProps = (state, ownProps) => {
  let {playerId} = ownProps;

  return {
    basicInfo: state.player.basic[playerId],
    accountType: state.account.accountType,
    message: state.player.message[playerId],
    alreadyRequestedPlusReport: state.ui.player.alreadyRequestedPlusReport,
    requestedReportData: state.player.requestedReportData,
  };
};

export default connect(mapStateToProps)(PlayerDetails);
