import React from "react";
import MuiTabs from "@material-ui/core/Tabs";
import { withStyles } from "@material-ui/core/styles";

const Tabs = withStyles((theme) => ({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: 4,
    "& > div": {
      width: "100%",
      backgroundColor: theme.palette.primary.main,
    },
  },
}))((props) => (
  <MuiTabs {...props} TabIndicatorProps={{ children: <div /> }} />
));

export default Tabs;
