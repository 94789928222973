import { Grid, makeStyles } from "@material-ui/core";
import { Field, Form} from "formik";
import { GlobalCss } from "../../../../config/GlobalCss";
import { TextField } from "formik-material-ui";
import { Title } from "../../../shared";

let useStyles = makeStyles((theme) => ({
    form: {
        paddingTop: theme.spacing(2),
        display: 'flex',
        flexGrow: 1,
    },
}));

const ContactInfo = ({ type }) => {
    const classes = useStyles();
    const coach = type === 'coach'

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} style={{ margin: '0px 1rem' }}>
                <Form autoComplete='off'>
                    <GlobalCss />
                    <div className={classes.form}>
                        <Grid item xs={6} spacing={2} style={{ marginRight: '2rem', minWidth: 600 }}>
                            <Title title="Contact Info" />
                            <Field
                                name="email"
                                label="Email"
                                placeholder="Email"
                                component={TextField}
                                variant="outlined"
                                required
                                fullWidth
                                autoFocus
                                style={{ marginTop: '1rem' }}
                            />
                            <Field
                                name="whatsapp"
                                label="Phone Number"
                                placeholder="Phone Number"
                                component={TextField}
                                variant="outlined"
                                fullWidth
                                style={{ marginTop: '1rem' }}
                            />
                        </Grid>
                        <Grid item xs={6} spacing={2} style={{ minWidth: 600 }}>
                            <Title title='Socials' />
                            {
                                !coach && <Field
                                    name="facebook"
                                    label="Facebook"
                                    placeholder="Facebook"
                                    component={TextField}
                                    variant="outlined"
                                    fullWidth
                                    style={{ marginTop: '1rem' }}
                                />
                            }
                            <Field
                                name="instagram"
                                label="Instagram"
                                placeholder="Instagram"
                                component={TextField}
                                variant="outlined"
                                fullWidth
                                style={{ marginTop: '1rem' }}
                            />
                            <Field
                                name="linkedin"
                                label="LinkedIn"
                                placeholder="LinkedIn"
                                component={TextField}
                                variant="outlined"
                                fullWidth
                                style={{ marginTop: '1rem' }}
                            />
                            {
                                !coach &&
                                <>
                                    <Field
                                        name="snapchat"
                                        label="Snapchat"
                                        placeholder="Snapchat"
                                        component={TextField}
                                        variant="outlined"
                                        fullWidth
                                        style={{ marginTop: '1rem' }}
                                    />
                                    <Field
                                        name="threads"
                                        label="Threads"
                                        placeholder="Threads"
                                        component={TextField}
                                        variant="outlined"
                                        fullWidth
                                        style={{ marginTop: '1rem' }}
                                    />
                                </>
                            }
                            <Field
                                name="twitter"
                                label="Twitter"
                                placeholder="Twitter"
                                component={TextField}
                                variant="outlined"
                                fullWidth
                                style={{ margin: '1rem 0rem' }}
                            />
                        </Grid>
                    </div>
                </Form>
            </Grid>
        </Grid>
    );
};

export default ContactInfo;
