import React, { useContext } from "react";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import PersonIcon from "@material-ui/icons/Person";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { ActionContext } from "../../../../contexts";
import { connect } from "react-redux";
import { Avatar, DialogTitle, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, makeStyles, Switch } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    flex: 1,
    fontSize: theme.fontSize.md,
    fontFamily: theme.fontFamily.prometo,
    fontWeight: 700,
    color: theme.colors.primaryNavy,
    textTransform: 'uppercase'
  },
  closeIcon: {
    color: theme.colors.primaryNavy,
  },
  avatar: {
    backgroundColor: theme.palette.background.primary,
    color: theme.palette.common.white,
  },
  subtitle: {
    color: theme.palette.text.primary,
    fontFamily: theme.fontFamily.robotoCondensed,
  },
  enabled: {
    color: theme.palette.success.main,
    fontFamily: theme.fontFamily.robotoCondensed,
  },
  pending: {
    color: theme.palette.warning.main,
  },
}));

const ReportDialog = (props) => {
  const classes = useStyles();
  const {  coachActions } = useContext(ActionContext);

  const {
    coachId,
    closeDialog,
    isProfileAnalysisEnabled,
    loadingProfileAnalysis,
    reportSettings,
    loadingFullReportPaymentStatus,
    loadingExecutiveSummaryReportPaymentStatus,
  } = props;

  const isFullReportReady = true;
  const isExecutiveSummeryReportReady = true;

  const handleCloseDialog = () => {
    closeDialog();
  };

  const handleProfileAnalysis = () => {
    return isProfileAnalysisEnabled
      ? coachActions.markCoachAsUnpaid(coachId)
      : coachActions.markCoachAsPaid(coachId);
  };

  const handleFullReportPaymentStatus = () => {
    const status = reportSettings?.fullReport;
    coachActions.toggleFullReport(coachId, !status);
  };

  const handleExecutiveSummaryReportPaymentStatus = () => {
    const status = reportSettings?.executiveSummaryReport;
    coachActions.toggleExecutiveSummaryReport(coachId, !status);
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex" }}>
        <DialogTitle id="simple-dialog-title" className={classes.title}>
          Select Payment Status
        </DialogTitle>
        <div style={{ margin: "8px 16px 8px 8px" }}>
          <IconButton onClick={handleCloseDialog}>
            <CloseIcon className={classes.closeIcon}/>
          </IconButton>
        </div>
      </div>
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <PersonIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Action Plan" className={classes.subtitle}/>
          <ListItemSecondaryAction>
            <Switch
              checked={isProfileAnalysisEnabled}
              onChange={handleProfileAnalysis}
              disabled={loadingProfileAnalysis}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <AssessmentIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            className={classes.subtitle}
            primary="Full Report"
            secondary={isFullReportReady ? "Ready" : "Pending"}
            secondaryTypographyProps={{
              className: isFullReportReady ? classes.enabled : classes.pending,
            }}
          />
          <ListItemSecondaryAction>
            <Switch
              checked={reportSettings?.fullReport}
              onChange={handleFullReportPaymentStatus}
              disabled={loadingFullReportPaymentStatus}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <AssessmentIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            className={classes.subtitle}
            primary="Executive Summary Report"
            secondary={isExecutiveSummeryReportReady ? "Ready" : "Pending"}
            secondaryTypographyProps={{
              className: isExecutiveSummeryReportReady
                ? classes.enabled
                : classes.pending,
            }}
          />
          <ListItemSecondaryAction>
            <Switch
              checked={reportSettings?.executiveSummaryReport}
              onChange={handleExecutiveSummaryReportPaymentStatus}
              disabled={loadingExecutiveSummaryReportPaymentStatus}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { coachId } = ownProps;

  return {
    isProfileAnalysisEnabled: state.coach.basic[coachId].isPaid,
    reportSettings: state.coach.reportSettings[coachId],
    loadingProfileAnalysis:
      state.ui.coach.updatingCoachPaidStatus[coachId] || false,
    loadingFullReportPaymentStatus:
      state.ui.coach.loadingFullReportPaymentStatus[coachId] || false,
    loadingExecutiveSummaryReportPaymentStatus:
      state.ui.coach.loadingExecutiveSummaryReportPaymentStatus[coachId] ||
      false,
    loadingActionPlanReportPaymentStatus:
      state.ui.coach.loadingActionPlanReportPaymentStatus[coachId] || false,
  };
};

export default connect(mapStateToProps)(ReportDialog);
