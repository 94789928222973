import GaugeComponent from "react-gauge-component";

const RadialGauge = ({ score }) => {
  return (
    <GaugeComponent
      type="radial"
      className="gauge-component-class"
      style={{ width: "80px", height: "80px" }}
      value={score}
      pointer={{
        type: "needle",
        color: "#0e0e0e",
        width: 10
      }}
      labels={{
        valueLabel: {
          hide: true,
        },
        tickLabels: {
          hideMinMax: true,
        },
      }}
      arc={{
        width: 0.2,
        padding: 0,
        cornerRadius: 0,
        subArcs: [
          {
            limit: 10,
            color: '#f3f3f3',
          },
          {
            limit: 29,
            color: '#002839',
          },
          {
            limit: 49,
            color: '#FFB71B',
          },
          {
            limit: '69',
            color: '#0070B9',
          },
          {
            limit: 90,
            color: '#8EC158',
          },
          {
            limit: 100,
            color: '#f3f3f3',
          },
        ],
      }}
    />
  );
};

export default RadialGauge;
