import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import SearchIcon from "@material-ui/icons/SearchOutlined";
import React from "react";
import { useConfirm } from "material-ui-confirm";
import {GlobalCss} from "../../../config/GlobalCss";

const History = ({ query, onSelect, id, index, onDelete }) => {
  const confirm = useConfirm();
  const handleClick = () => {
    onSelect(index);
  };

  let handleDelete = () => {
    confirm({
      title: `Delete History - ${query.full_name}?`,
      description: "You are about to delete the history. Are you sure?",
      confirmationText: "Yes",
    })
      .then(() => {
        onDelete(id);
      })
      .catch(() => {});
  };

  return (
    <>
      <GlobalCss />
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <SearchIcon style={{ color: 'rgb(10, 45, 96, 0.6)' }}/>
        </ListItemIcon>
        <ListItemText primary={query.full_name} />
        <ListItemSecondaryAction>
          <IconButton onClick={handleDelete}>
            <DeleteIcon style={{ color: 'rgb(10, 45, 96, 0.6)' }}/>
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </>
  );
};

export default History;
