import React from 'react';
import {Route, Redirect } from 'react-router-dom';

// assume private routes should always redirect to / 
let PublicRoute = ({
    path,
    children,
    redirect="/",
    isAuthenticated=false,
    ...rest
}) => {
    if (!isAuthenticated) {
        return <Route path={path} {...rest} component={children} />
    } else {
        return <Redirect to={redirect} />
    }
};

export default PublicRoute;