import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AtIcon from "@material-ui/icons/AlternateEmail";
import Alert from "@material-ui/lab/Alert";
import { Field } from "formik";
import { Select, TextField } from "formik-material-ui";
import React from "react";
import { connect } from "react-redux";

let useStyles = makeStyles((theme) => ({
  heading: {
    // marginTop: theme.spacing(3),
    // marginBottom: theme.spacing(2),
    maxWidth: 480,
    fontWeight: 500,
    lineHeight: "60px",
    color : 'blue'
  },
  form: {
    paddingTop: theme.spacing(1),
    maxWidth: '100%',
    marginRight: '1rem',
  },
}));

let CreatePlayerSocialMedia = (props) => {
  let classes = useStyles();
  let { errors, touched, teams, values, setFieldError, ...rest } = props;

  return (
    <React.Fragment>
      <Typography variant="h4" className={classes.heading}>
        Let's input Social Media
      </Typography>

      <div className={classes.form}>
        <Alert severity="info" style={{ marginBottom: 16 }}>
          Please enter at least Twitter/Instagram account in order to continue
        </Alert>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Field
              name="whatsApp"
              label="Phone"
              placeholder="Phone"
              component={TextField}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">Twitter</Typography>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item md={12} xs={12}>
              <Field
                name="twitter"
                label="Twitter handle"
                placeholder="Handle"
                component={TextField}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AtIcon style={{ color: "#b1b1b1" }} />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            {/* <Grid item md={6} xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
                error={
                  touched["twitterSourceLn"] && !!errors["twitterSourceLn"]
                }
              >
                <InputLabel htmlFor="twitterSourceLn">
                  Source Language
                </InputLabel>
                <Field
                  component={Select}
                  name="twitterSourceLn"
                  label="Source Language"
                  placeholder="Source Language"
                  labelId="twitterSourceLn"
                  inputProps={{
                    id: "twitterSourceLn",
                  }}
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="es">Spanish</MenuItem>
                  <MenuItem value="fr">French</MenuItem>
                </Field>
                {touched["twitterSourceLn"] && !!errors["twitterSourceLn"] ? (
                  <FormHelperText>{errors["twitterSourceLn"]}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid> */}
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">Instagram</Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item md={12} xs={12}>
                <Field
                  name="instagram"
                  label="Instagram handle"
                  placeholder="Handle"
                  component={TextField}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AtIcon style={{ color: "#b1b1b1" }} />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Grid>
              {/* <Grid item md={6} xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
                error={
                  touched["instagramSourceLn"] && !!errors["instagramSourceLn"]
                }
              >
                <InputLabel htmlFor="instagramSourceLn">
                  Source Language
                </InputLabel>
                <Field
                  component={Select}
                  name="instagramSourceLn"
                  label="Source Language"
                  placeholder="Source Language"
                  labelId="instagramSourceLn"
                  inputProps={{
                    id: "instagramSourceLn",
                  }}
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="es">Spanish</MenuItem>
                  <MenuItem value="fr">French</MenuItem>
                </Field>
                {touched["instagramSourceLn"] &&
                !!errors["instagramSourceLn"] ? (
                  <FormHelperText>{errors["instagramSourceLn"]}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid> */}
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">Linkedin</Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item md={12} xs={12}>
                <Field
                  name="linkedin"
                  label="Linkedin handle"
                  placeholder="Handle"
                  component={TextField}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AtIcon style={{ color: "#b1b1b1" }} />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Grid>
              {/* <Grid item md={6} xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
                error={
                  touched["linkedinSourceLn"] && !!errors["linkedinSourceLn"]
                }
              >
                <InputLabel htmlFor="linkedinSourceLn">
                  Source Language
                </InputLabel>
                <Field
                  component={Select}
                  name="linkedinSourceLn"
                  label="Source Language"
                  placeholder="Source Language"
                  labelId="linkedinSourceLn"
                  inputProps={{
                    id: "linkedinSourceLn",
                  }}
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="es">Spanish</MenuItem>
                  <MenuItem value="fr">French</MenuItem>
                </Field>
                {touched["linkedinSourceLn"] && !!errors["linkedinSourceLn"] ? (
                  <FormHelperText>{errors["linkedinSourceLn"]}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid> */}
            </Grid>

            <Grid item>
              <Typography variant="subtitle2">YouTube</Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item md={12} xs={12}>
                <Field
                  name="youtube"
                  label="Youtube handle"
                  placeholder="Channel"
                  component={TextField}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AtIcon style={{ color: "#b1b1b1" }} />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Grid>
              {/* <Grid item md={6} xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
                error={
                  touched["youtubeSourceLn"] && !!errors["youtubeSourceLn"]
                }
              >
                <InputLabel htmlFor="youtubeSourceLn">
                  Source Language
                </InputLabel>
                <Field
                  component={Select}
                  name="youtubeSourceLn"
                  label="Source Language"
                  placeholder="Source Language"
                  labelId="youtubeSourceLn"
                  inputProps={{
                    id: "youtubeSourceLn",
                  }}
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="es">Spanish</MenuItem>
                  <MenuItem value="fr">French</MenuItem>
                </Field>
                {touched["youtubeSourceLn"] && !!errors["youtubeSourceLn"] ? (
                  <FormHelperText>{errors["youtubeSourceLn"]}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid> */}
            </Grid>

            <Grid item>
              <Typography variant="subtitle2">Print News</Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item md={12} xs={12}>
                <Field
                  name="printNews"
                  label="printNews handle"
                  placeholder="Enter Print News"
                  component={TextField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              {/* <Grid item md={6} xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
                error={
                  touched["printNewsSourceLn"] && !!errors["printNewsSourceLn"]
                }
              >
                <InputLabel htmlFor="printNewsSourceLn">
                  Source Language
                </InputLabel>
                <Field
                  component={Select}
                  name="printNewsSourceLn"
                  label="Source Language"
                  placeholder="Source Language"
                  labelId="printNewsSourceLn"
                  inputProps={{
                    id: "printNewsSourceLn",
                  }}
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="es">Spanish</MenuItem>
                  <MenuItem value="fr">French</MenuItem>
                </Field>
                {touched["printNewsSourceLn"] && !!errors["printNewsSourceLn"] ? (
                  <FormHelperText>{errors["printNewsSourceLn"]}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid> */}
            </Grid>

          </Grid>
      </div>
    </React.Fragment>
  );
};

let mapStateToProps = (state) => {
  return {
    teams: state.ui.player.teams,
  };
};

export default connect(mapStateToProps)(CreatePlayerSocialMedia);
