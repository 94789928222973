import {connect} from "react-redux";
import {Box, FormControl, FormLabel, Grid} from "@material-ui/core";
import {Container, DeleteButtonModal, FormEditor, RunDashedLogo, Title} from "../../shared";
import {Field, Form, Formik} from "formik";
import {number, object, string} from "yup";
import {GlobalCss} from "../../../config/GlobalCss";
import Alert from "@material-ui/lab/Alert";
import {TextField} from "formik-material-ui";
import clsx from "clsx";
import Button from "../../shared/Button";
import React, {useContext, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory, useParams} from "react-router-dom";
import {ActionContext} from "../../../contexts";
import {snackbarActions} from "../../shared/Snackbar";
import CommentAttributes from "./CommentAttributes";
import CircularProgress from "@material-ui/core/CircularProgress";
import {EditorState, convertToRaw, convertFromRaw} from 'draft-js';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';

let useStyles = makeStyles((theme) => ({
    form: {
        paddingTop: theme.spacing(2),
        maxWidth: 800,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    content: {
        display: "flex",
        justifyContent: "end",
        margin: '2rem 0rem 0rem 0rem'
    },
    btnPrimary: {
        color: theme.palette.common.white,
        padding: '10px',
        backgroundColor: theme.colors.primaryNavy,
        width: '13rem',
        borderRadius: '7px',
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            color: theme.colors.primaryNavy,
            border: `1px solid ${theme.colors.primaryNavy}`,
        },
        textTransform: 'uppercase',
    },
    btnSecondary: {
        color: theme.palette.secondary.main,
        border: '1px solid',
        width: '13rem',
        padding: '10px',
        marginRight: '10px',
        borderRadius: '7px',
        '&:hover': {
            background: theme.palette.secondary.main,
            color: theme.palette.common.white,
        },
        textTransform: 'uppercase',
    },
}));

const UpdateAssessmentComment = (props) => {
    const { assessmentCommentActions } = useContext(ActionContext);
    const { assessmentComment, error, loading } = props;
    const classes = useStyles();
    const history = useHistory();
    const {commentId} = useParams();
    let [descriptionEditorState, setDescriptionEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        assessmentCommentActions.getAssessmentCommentById(commentId);
    }, []);

    useEffect(() => {
        if (assessmentComment && assessmentComment.description) {
            try {
                const markToDraft = markdownToDraft(assessmentComment.description);
                const descriptionContentState = convertFromRaw(markToDraft);
                setDescriptionEditorState(EditorState.createWithContent(descriptionContentState));
            } catch (error) {
                throw new Error(error);
            }
        }
    }, [assessmentComment]);

    const handleClose = () => {
        assessmentCommentActions.resetAssessmentCommentsId();
        history.push("/assessment-comments");
    };

    let handleErrorClose = () => {
        assessmentCommentActions.resetAssessmentCommentError();
    };

    useEffect(() => {
        if (error && error.length > 0) {
            setTimeout(() => {
                handleErrorClose();
            }, 3500);
        }
    }, [error]);

    const handleFormSubmit = (values) => {
        const descriptionContentState = descriptionEditorState.getCurrentContent();
        const descriptionRawContent = convertToRaw(descriptionContentState);
        const markdown = draftToMarkdown(descriptionRawContent)

        let data = {
            title: values.title,
            main_attribute: values.main_attribute,
            age_start: values.age_start,
            age_end: values.age_end,
            sport: values.sport,
            description: markdown,
        };

        assessmentCommentActions.updateAssessmentComment(assessmentComment._id, data);

        snackbarActions.showSnackbar(
            'Assessment comment was successfully updated.'
        );

        history.push('/assessment-comments');
    };

    const handleDelete = (id) => {
        assessmentCommentActions.deleteAssessmentComment(id);

        snackbarActions.showSnackbar(
            'Assessment comment was successfully deleted.'
        );

        history.push('/assessment-comments');
    };

    return (
        <Container>
            <Grid container spacing={1} >
                <Grid item xs={8} style={{ height: '100%', minHeight: '600px', maxHeight: 'auto' }}>
                    <div style={{margin: '0rem 3rem'}}>
                        {
                            loading && !assessmentComment ? (
                                <Box style={{display: 'flex', justifyContent: 'center'}}>
                                    <CircularProgress size={72} thickness={3} color='primary' />
                                </Box>
                            ) : (
                                <Formik
                                    initialValues={{
                                        title: assessmentComment?.title || '',
                                        main_attribute: assessmentComment?.main_attribute || '',
                                        age_start: assessmentComment?.age_start || '',
                                        age_end: assessmentComment?.age_end || '',
                                        sport: assessmentComment?.sport || '',
                                        description: assessmentComment?.description || '',
                                    }}
                                    validator={object().shape({
                                        title: string()
                                            .trim()
                                            .matches(
                                                /^[a-zA-Z0-9]+(([a-zA-Z0-9 ])?[a-zA-Z0-9]*)*$/,
                                                "Enter a valid title, no special characters allowed."
                                            )
                                            .required("A title is required."),
                                        age_start: number().nullable().required('Select a start age'),
                                        age_end: number().nullable().required('Select a end age'),
                                    })}
                                    onSubmit={(values) => {
                                        handleFormSubmit(values)
                                    }}
                                >
                                    <Form autoComplete='off'>
                                        <GlobalCss />
                                        <div className={classes.form}>
                                            {
                                                assessmentComment.sport === 'all' ? (
                                                    <Title title='Update' text={`${assessmentComment.title} - ${assessmentComment.main_attribute}`}/>
                                                ) : (
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center'
                                                    }}>
                                                        <Title text='Update' title={`${assessmentComment.title} - ${assessmentComment.main_attribute}`}/>
                                                        <DeleteButtonModal
                                                            title={`${assessmentComment.title} - ${assessmentComment.main_attribute}`}
                                                            handleDelete={() => handleDelete(assessmentComment._id)}
                                                        />
                                                    </div>
                                                )
                                            }
                                            {error ? (
                                                <Grid item>
                                                    <Alert severity='error' onClose={handleErrorClose}>
                                                        {error}
                                                    </Alert>
                                                </Grid>
                                            ) : null}
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <Field
                                                        name="title"
                                                        label="Title"
                                                        component={TextField}
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        autoFocus
                                                        style={{marginTop: '1rem'}}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} style={{display: 'flex', justifyContent: 'space-between'}}>
                                                    <Field
                                                        name="main_attribute"
                                                        label="Main Attribute"
                                                        component={TextField}
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        autoFocus
                                                        disabled
                                                        style={{marginTop: '1rem', width: '49%'}}
                                                    />
                                                    <Field
                                                        name="sport"
                                                        label="Sport"
                                                        component={TextField}
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        autoFocus
                                                        disabled
                                                        style={{marginTop: '1rem', width: '49%'}}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}
                                                      style={{display: 'flex', justifyContent: 'space-between'}}>
                                                    <Field
                                                        name="age_start"
                                                        label="Age Group Starting"
                                                        component={TextField}
                                                        variant="outlined"
                                                        required
                                                        autoFocus
                                                        style={{width: '49%'}}
                                                    />
                                                    <Field
                                                        name="age_end"
                                                        label="Age Group Ending"
                                                        component={TextField}
                                                        variant="outlined"
                                                        required
                                                        autoFocus
                                                        style={{width: '49%'}}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl fullWidth>
                                                        <FormLabel style={{marginBottom: '5px'}}>Description</FormLabel>
                                                        <FormEditor editorState={descriptionEditorState} onEditorStateChange={setDescriptionEditorState} />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <div className={clsx(classes.content)}>
                                                <Button
                                                    className={classes.btnSecondary}
                                                    type="button"
                                                    variant="text"
                                                    onClick={handleClose}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    className={classes.btnPrimary}
                                                    type="submit"
                                                >
                                                    Update
                                                </Button>
                                            </div>
                                        </div>
                                    </Form>
                                </Formik>
                            )
                        }
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <RunDashedLogo />
                </Grid>
                <Grid item style={{ width: '100%', margin: '0rem 3rem' }}>
                    <CommentAttributes commentId={commentId}/>
                </Grid>
            </Grid>
        </Container>
    )
}

let mapStateToProps = (state) => {
    return {
        error: state.ui.assessmentComments.error,
        loading: state.ui.assessmentComments.selectedAssessmentCommentLoading,
        assessmentComment: state.assessmentComments.selectedAssessmentCommentData.assessmentComment,
        assessmentCommentId: state.assessmentComments.selectedAssessmentCommentIdData,
    };
};

export default connect(mapStateToProps)(UpdateAssessmentComment);