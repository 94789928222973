import apiHandler, { getAuthHeader } from "./apiHandler";

export const postConsentForms = async (files) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post("upload-consent-forms", files, {
      headers,
    });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    let forms = response.data.info["files_status"].reduce((obj, form) => {
      obj[form._id] = {
        id: form._id,
        name: form.name.split(".")[0],
      };

      return obj;
    }, {});

    return {
      forms,
      notification: response.data.notification,
    };
  } catch (err) {
    throw new Error(err);
  }
};
export const getPendingConsentForms = async () => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get("/pending-consent-forms", { headers });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    let forms = response.data.info["pending_consent_forms"].reduce(
      (obj, form) => {
        obj[form._id] = {
          id: form._id,
          name: form.name,
        };

        return obj;
      },
      {}
    );

    return forms;
  } catch (err) {
    throw new Error(err);
  }
};

export const getLinkedConsentForms = async () => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get("/linked-consent-forms", { headers });
    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    let forms = response.data.info["linked_consent_forms"].reduce(
      (obj, form) => {
        obj[form._id] = {
          id: form._id,
          name: form.name,
          playerId: form.candidate_id,
        };

        return obj;
      },
      {}
    );

    return forms;
  } catch (err) {
    throw new Error(err);
  }
};
export const getConsentForm = async (id) => {
  try {
    let headers = getAuthHeader();

    let response = await apiHandler.get(`/export/consent-form-b64/${id}`, {
      headers,
    });

    return response.data.info["pdf_base_64_encoded"];
  } catch (err) {
    throw new Error(err);
  }
};
export const postLinkConsentForm = async (data) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post("/consent-form/link", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }
  } catch (err) {
    throw new Error(err);
  }
};
export const postUnlinkConsentForm = async (data) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post("/consent-form/unlink", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }
    return {
      id: response.data.info._id,
      name: response.data.info.name,
    };
  } catch (err) {
    throw new Error(err);
  }
};
export const postUploadConsentForms = async (data) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post("/upload-consent-forms", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    return response.data.info["files_status"];
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteConsentForm = async (data) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.post("/consent-form/delete", data, {
      headers,
    });
    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }
  } catch (err) {
    throw new Error(err);
  }
};

const consent = {
  getPendingConsentForms,
  getLinkedConsentForms,
  postConsentForms,
  getConsentForm,
  postLinkConsentForm,
  postUnlinkConsentForm,
  postUploadConsentForms,
  deleteConsentForm,
};

export default consent;
