import * as types from './assessmentCommentTypes';
import { assessmentCommentsApi } from '../../../api';

import {
    showSuccessMessage,
    showErrorMessage,
} from "../../shared/Snackbar";

const getAssessmentCommentList = () => {
  return async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ALL_ASSESSMENT_COMMENTS_REQUEST,
        });

        let assessmentComments = await assessmentCommentsApi.getAllAssessmentComments();

        dispatch({
            type: types.GET_ALL_ASSESSMENT_COMMENTS_SUCCESS,
            payload: assessmentComments,
        });
    } catch (err) {
        dispatch(showErrorMessage(err.message));
        dispatch({
            type: types.GET_ALL_ASSESSMENT_COMMENTS_FAILED,
            payload: 'Failed to fetch all assessment comments',
        });
    }
  };
};

const getAssessmentCommentId = (id) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.GET_ASSESSMENT_COMMENT_ID_REQUEST,
            });

            dispatch({
                type: types.GET_ASSESSMENT_COMMENT_ID_SUCCESS,
                payload: id,
            });
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.GET_ASSESSMENT_COMMENT_ID_FAILED,
                payload: 'Failed to get assessment comment id',
            });
        }
    }
};

const resetAssessmentCommentsId = () => {
    return async (dispatch) => {
      dispatch({
          type: types.RESET_ASSESSMENT_COMMENT_ID,
          payload: ''
      });
    };
};

const getAssessmentCommentById = (id) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.GET_ASSESSMENT_COMMENT_BY_ID_REQUEST,
                payload: {
                    assessmentCommentId: id,
                }
            });

            const assessmentComment = await assessmentCommentsApi.getAssessmentCommentById(id);

            dispatch({
                type: types.GET_ASSESSMENT_COMMENT_BY_ID_SUCCESS,
                payload: {
                    assessmentCommentId: id,
                    assessmentComment,
                }
            });
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.GET_ASSESSMENT_COMMENT_BY_ID_FAILED,
                payload: {
                    error: 'Failed to fetch assessment comment',
                }
            });
        }
    };
};

const resetAssessmentCommentError = () => {
    return {
        type: types.RESET_ASSESSMENT_COMMENT_CREATION_ERROR,
    };
};

const createAssessmentComment = (data, successCb = (f) => f) => {
    return async (dispatch) => {
      try {
          dispatch({
              type: types.POST_ASSESSMENT_COMMENT_REQUEST,
          });

          await assessmentCommentsApi.postAssessmentComment(data);
          dispatch({
              type: types.POST_ASSESSMENT_COMMENT_SUCCESS,
              payload: data,
          });

          dispatch(showSuccessMessage('Assessment comment successfully created'));
          successCb();
      } catch (err) {
          dispatch(showErrorMessage(err.message));
          dispatch({
             type: types.POST_ASSESSMENT_COMMENT_FAILED,
             payload: {
               error: 'Failed to create new assessment comment',
             },
          });
      }
    };
};

const updateAssessmentComment = (id, data, successCb = (f) => f) => {
    return async (dispatch) => {
       try {
          dispatch({
             type: types.UPDATE_ASSESSMENT_COMMENT_BY_ID_REQUEST,
              payload: {
                assessmentCommentId: id,
              },
          });

          await assessmentCommentsApi.updateAssessmentComment(id, data);
          dispatch({
              type: types.UPDATE_ASSESSMENT_COMMENT_BY_ID_SUCCESS,
              payload: {
                  data,
                  assessmentCommentId: id,
              },
          });

          dispatch(showSuccessMessage('Assessment comment successfully updated'));
          dispatch(getAssessmentCommentList());
          successCb();
       } catch (err) {
           dispatch(showErrorMessage(err.message));
           dispatch({
               type: types.UPDATE_ASSESSMENT_COMMENT_BY_ID_FAILED,
               payload: {
                   error: 'Failed to update assessment comment',
               },
           });
       }
    };
};

const deleteAssessmentComment = (id, successCb = (f) => f) => {
    return async (dispatch) => {
      try{
          dispatch({
              type: types.DELETE_ASSESSMENT_COMMENT_BY_ID_REQUEST,
              payload: id,
          });

          await assessmentCommentsApi.deleteAssessmentComment(id);

          dispatch({
              type: types.DELETE_ASSESSMENT_COMMENT_BY_ID_SUCCESS,
              payload: id,
          });

          dispatch(showSuccessMessage('Assessment comment successfully deleted'));
          successCb();
      } catch (err) {
          dispatch(showErrorMessage(err.message));
          dispatch({
             type: types.DELETE_ASSESSMENT_COMMENT_BY_ID_FAILED,
             payload: 'Assessment comment did not delete',
          });
      }
    };
};

const getAllAssessmentCommentAttributesList = (id) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.GET_ALL_ASSESSMENT_COMMENT_ATTRIBUTES_REQUEST,
                commentId: id,
            });

            let attributes = await assessmentCommentsApi.getAssessmentCommentAttributes(id);

            dispatch({
                type: types.GET_ALL_ASSESSMENT_COMMENT_ATTRIBUTES_SUCCESS,
                payload: attributes,
            });
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.GET_ALL_ASSESSMENT_COMMENT_ATTRIBUTES_FAILED,
                payload: 'Failed to get all comment attributes',
            });
        }
    };
};

const createCommentAttribute = (id, data, successCb = (f) => f) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.POST_ASSESSMENT_COMMENT_ATTRIBUTES_REQUEST,
            });

            await assessmentCommentsApi.createCommentAttribute(id, data);
            dispatch({
                type: types.POST_ASSESSMENT_COMMENT_ATTRIBUTES_SUCCESS,
                payload: {
                    assessmentCommentId: id,
                    data,
                },
            });

            dispatch(showSuccessMessage('Comment attribute successfully created'));
            successCb();
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.POST_ASSESSMENT_COMMENT_ATTRIBUTES_FAILED,
                payload: 'Failed to create comment attribute',
            });
        }
    };
};

const resetCommentAttributeError = () => {
    return {
        type: types.RESET_COMMENT_ATTRIBUTE_CREATION_ERROR,
    };
};

const getCommentAttributeById = (id) => {
    return async (dispatch) => {
        try {
            dispatch({
               type: types.GET_COMMENT_ATTRIBUTE_BY_ID_REQUEST,
            });

            let attribute = await assessmentCommentsApi.getAttributeById(id)
            dispatch({
                type: types.GET_COMMENT_ATTRIBUTE_BY_ID_SUCCESS,
                payload: attribute,
            });
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.GET_COMMENT_ATTRIBUTE_BY_ID_FAILED,
                payload: 'Failed to get comment attribute',
            });
        }
    }
};

const updateAttribute = (id, data, successCb = (f) => f) => {
    return async (dispatch) => {
       try {
           dispatch({
               type: types.UPDATE_ASSESSMENT_COMMENT_ATTRIBUTE_BY_ID_REQUEST,
               payload: {
                   commentAttributeId: id,
               },
           });

           await assessmentCommentsApi.updateCommentAttribute(id, data);
           dispatch({
               type: types.UPDATE_ASSESSMENT_COMMENT_ATTRIBUTE_BY_ID_SUCCESS,
               payload: {
                   commentAttributeId: id,
                   data,
               },
           });

           dispatch(showSuccessMessage('Attribute successfully updated'));
           successCb();
       } catch (err) {
           dispatch(showErrorMessage(err.message));
           dispatch({
               type: types.UPDATE_ASSESSMENT_COMMENT_ATTRIBUTE_BY_ID_FAILED,
               payload: 'Failed to update comment attribute'
           });
       }
    }
};

const deleteCommentAttribute = (id, successCb = (f) => f) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.DELETE_ASSESSMENT_COMMENT_ATTRIBUTES_BY_ID_REQUEST,
                payload: id,
            });

            await assessmentCommentsApi.deleteCommentAttribute(id);
            dispatch({
                type: types.DELETE_ASSESSMENT_COMMENT_ATTRIBUTES_BY_ID_SUCCESS,
                payload: id,
            });

            dispatch(showSuccessMessage('Successfully deleted comment attribute'));
            successCb();
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.DELETE_ASSESSMENT_COMMENT_ATTRIBUTES_BY_ID_FAILED,
                payload: 'Could not delete comment attribute',
            });
        }
    };
};

const assessmentCommentActions = {
    getAssessmentCommentList,
    getAssessmentCommentId,
    resetAssessmentCommentsId,
    getAssessmentCommentById,
    resetAssessmentCommentError,
    createAssessmentComment,
    updateAssessmentComment,
    deleteAssessmentComment,
    getAllAssessmentCommentAttributesList,
    createCommentAttribute,
    resetCommentAttributeError,
    getCommentAttributeById,
    updateAttribute,
    deleteCommentAttribute,
};

export default assessmentCommentActions;