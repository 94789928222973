import React, { useContext } from "react";
import ActionDialog from "./ActionDialog";
import { ActionContext } from "../../../../contexts";
import { connect } from "react-redux";
import { EditorArticle } from "../../../shared/Editor";
import { EditorState, convertFromRaw } from "draft-js";

let Action = (props) => {
  let { playerId, categoryId, isEditable, actionPlan } = props;
  let { dialogActions,playerActions } = useContext(ActionContext);

  let editorState = actionPlan.blocks
    ? EditorState.createWithContent(convertFromRaw(actionPlan))
    : EditorState.createEmpty();
 
  let handleEditClick = () => {
    dialogActions.openDialog(
      <ActionDialog
        playerId={playerId}
        categoryId={categoryId}
        initialEditorState={editorState}
      />
    );
  };
  
  return (
    <EditorArticle
      onEdit={handleEditClick}
      isEditable={isEditable}
      editorState={editorState}
      placeholder="Psychologist comments pending"
    />
  );
};

let mapStateToProps = (state, ownProps) => {
  let { playerId, categoryId } = ownProps;
  return {
    actionPlan: state.player.actionPlan[`${playerId}.${categoryId}`],
  };
};
export default connect(mapStateToProps)(Action);
