import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/ClearOutlined";
import { ActionContext } from "contexts";
import React, { useState, useContext } from "react";
import { connect } from "react-redux";
import Button from "../../../shared/Button";

let useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(),
  },
  list: {
    maxHeight: 250,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: 7,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.background.default,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  submit: {
    display: "flex",
    flexDirection: "row-reverse",
    paddingTop: theme.spacing(3),
  },
}));

const RiskDialog = (props) => {
  let classes = useStyles();
  let { playerActions } = useContext(ActionContext);
  let { closeDialog, score, categoryId, playerId, loading } = props;
  let [riskScore, setRiskScore] = useState(score);

  let handleCloseDialog = () => {
    closeDialog();
  };

  let handleSelect = ({ target: { value } }) => {
    setRiskScore(value);
  };

  let handleUpdate = () => {
    const data = {
      candidate_id: playerId,
      category: categoryId,
      score: riskScore,
    };
    playerActions.updateRiskScore(data, closeDialog);
  };

  return (
    <div>
      <div
        style={{
          padding: "16px 24px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Edit Risk Score - {categoryId}</Typography>
        <IconButton onClick={handleCloseDialog}>
          <ClearIcon />
        </IconButton>
      </div>
      <DialogContent style={{ paddingBottom: 16 }}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="demo-simple-select-outlined-label">
            Risk Score
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={riskScore}
            onChange={handleSelect}
            label="Risk Score"
          >
            <MenuItem value={0}>0</MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={10}>10</MenuItem>
          </Select>
          <FormHelperText>Select Risk Score</FormHelperText>
        </FormControl>

        <div className={classes.submit}>
          <Button
            type="submit"
            color="primary"
            size="large"
            loading={loading}
            onClick={handleUpdate}
          >
            Update
          </Button>
        </div>
      </DialogContent>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { playerId, categoryId } = ownProps;
  return {
    loading: state.ui.player.updatingRiskScore[`${playerId}.${categoryId}`],
  };
};
export default connect(mapStateToProps)(RiskDialog);
