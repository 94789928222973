import React from "react";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import PositiveIcon from "@material-ui/icons/SentimentVerySatisfied";
import NegativeIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import NeutralIcon from "@material-ui/icons/SentimentSatisfied";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    positive: {
        color: theme.palette.text.secondary,
        fontSize: 14,
        fontFamily: theme.fontFamily.robotoCondensed,
    },
    positiveIcon: {
        color: theme.palette.text.secondary,
    },
    neutral: {
        color: theme.palette.primary.main,
        fontSize: 14,
        fontFamily: theme.fontFamily.robotoCondensed,
    },
    neutralIcon: {
        color: theme.palette.primary.main,
    },
    negative: {
        color: theme.palette.error.main,
        fontSize: 14,
        fontFamily: theme.fontFamily.robotoCondensed,
    },
    negativeIcon: {
        color: theme.palette.error.main,
    },
}));

const FeedBottomNavigation = (props) => {
  let { tab, onChange, stats } = props;
  const classes = useStyles();

  return (
    <BottomNavigation value={tab} onChange={onChange} showLabels>
      <BottomNavigationAction
        label={
          <Typography variant="subtitle1" className={classes.positive}>
            {stats.positivePercentage === "nodata"
              ? "Positives"
              : `Positives ${stats.positivePercentage}%`}
          </Typography>
        }
        icon={<PositiveIcon className={classes.positiveIcon}/>}
      />

      <BottomNavigationAction
        label={
          <Typography variant="subtitle1" className={classes.neutral}>
            {stats.neutralPercentage === "nodata"
              ? "Neutrals"
              : `Neutrals ${stats.neutralPercentage}%`}
          </Typography>
        }
        icon={<NeutralIcon className={classes.neutralIcon}/>}
      />
      <BottomNavigationAction
        label={
          <Typography variant="subtitle1" className={classes.negative}>
            {stats.negativePercentage === "nodata"
              ? "Negatives"
              : `Negatives ${stats.negativePercentage}%`}
          </Typography>
        }
        icon={<NegativeIcon className={classes.negativeIcon}/>}
      />
    </BottomNavigation>
  );
};

export default FeedBottomNavigation;
