import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, {useContext, useEffect} from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ActionContext } from "../../../contexts";
import Popover from "../../shared/Popover";
import SettingsIconButton from "./SettingsIconButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: 350,
  },
  heading: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    padding: theme.spacing(5, 3, 3, 3),
  },
  accountButton: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    marginBottom: theme.spacing(1),
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 80,
  },
  avatar_appbar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  button: {
    background: theme.colors.primaryNavy,
    color: theme.colors.white,
  },
  signOutBtn: {
    boxShadow: `3px 3px 10px 3px ${theme.colors.veryLightGrey}`,
    border: 'none',
    color: theme.colors.slate,
  },
  textName: {
    color: theme.colors.slate,
    fontWeight: 500,
    fontFamily: theme.fontFamily.prometo,
  },
  text: {
    color: theme.colors.slate,
    fontFamily: theme.fontFamily.robotoCondensed,
  }
}));

const AccountPopover = () => {
  let classes = useStyles();
  let history = useHistory();
  let { accountActions } = useContext(ActionContext);

  useEffect(() => {
    accountActions.getUserProfile()
  }, [])

  let handleLogout = () => {
    history.push("/signin");
    localStorage.removeItem("@accessToken");
    localStorage.removeItem("@accountType");
    localStorage.removeItem("@refreshToken");
    accountActions.appSignOut();
  };

  let handleRedirectToAccountSettings = () => {
    history.push("/account/settings");
  };

  let accountState = useSelector((state) => state.account.accountData);

  let { full_name, email, team } = accountState;

  return (
    <Popover
      component={SettingsIconButton}
      ComponentProps={{ full_name, email }}
    >
      <div className={classes.root}>
        <div className={classes.heading}>
          <Typography variant="body1" className={classes.textName}>
            {full_name}
          </Typography>
          <Typography variant="caption" className={classes.text}>{email}</Typography>
          <Typography variant="caption" className={classes.text}>
            {team}
          </Typography>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleRedirectToAccountSettings}
          >
            Account Settings
          </Button>
        </div>
        <Divider />
        <div className={classes.footer}>
          <Button variant="outlined" style={{
            boxShadow: '3px 3px 10px 3px #cdcdcd',
            border: 'none',
          }} onClick={handleLogout}>
            Sign out
          </Button>
        </div>
      </div>
    </Popover>
  );
};

export default AccountPopover;
