import { Button, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { convertFromRaw, EditorState } from "draft-js";
import React, { useContext } from "react";
import { connect } from "react-redux";
import { ActionContext } from "../../../../contexts";
import { Article } from "../../../shared/Article";
import { EditorArticle } from "../../../shared/Editor";
import { accountTypes } from "../../../../store/constants";
import LinkList from "./LinkList";
import LinksDialog from "./LinksDialog";
import NotesDialog from "./NotesDialog";

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  container: {
    flexGrow: 1,
    display: "flex",
    flexDirection: 'column',
    margin: "0px",
    backgroundColor: theme.palette.common.white,
    boxShadow: "3px 3px 10px 3px #cdcdcd",
    borderRadius: 8,
    padding: '2rem 1rem'
  },
  heading: {
    justifyContent: "space-between",
  },
  btnPrimary: {
    color: theme.palette.common.white,
    padding: '10px',
    backgroundColor: theme.colors.primaryNavy,
    width: '8rem',
    borderRadius: '7px',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.colors.primaryNavy,
      border: `1px solid ${theme.colors.primaryNavy}`,
    },
    textTransform: 'uppercase',
  },
}));

let Notes = (props) => {
  let classes = useStyles();
  let { dialogActions } = useContext(ActionContext);
  let { coachId, accountType, notes } = props;

  let editorState = notes.blocks
    ? EditorState.createWithContent(convertFromRaw(notes))
    : EditorState.createEmpty();

  let handleEditClick = () => {
    dialogActions.openDialog(
      <NotesDialog coachId={coachId} initialEditorState={editorState} />
    );
  };

  let allowedAccountTypes = [
    accountTypes.superAdmin,
    accountTypes.mmPsychologist,
    accountTypes.mmDdiAdmin,
  ];

  let isEditable = allowedAccountTypes.includes(accountType);

  let handleLink = () => {
    dialogActions.openDialog(<LinksDialog coachId={coachId} />, {
      maxWidth: "sm",
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <EditorArticle
            placeholder="Notes not added yet"
            editorState={editorState}
            isEditable={isEditable}
            onEdit={handleEditClick}
        />
        <Article
            title="Links"
            button={
              <Button
                  variant="contained"
                  className={classes.btnPrimary}
                  startIcon={<AddIcon/>}
                  onClick={handleLink}
                  disableElevation
              >
                Add Link
              </Button>
            }
        >
          <LinkList coachId={coachId}/>
        </Article>
      </div>
    </div>
  );
};

let mapStateToProps = (state, ownProps) => {
  let { coachId } = ownProps;

  return {
    notes: state.coach.notes[coachId],
    accountType: state.account.accountType,
  };
};

export default connect(mapStateToProps)(Notes);
