import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "react-feather/dist/icons/x";

let CloseAction = ({ onClick, ...rest }) => {
  return (
    <IconButton key="Close" aria-label="close" onClick={onClick}>
      <CloseIcon color="#fff" />
    </IconButton>
  );
};

export default CloseAction;
