import { dashboardApi } from "../../../api";
import {showErrorMessage} from "../../shared/Snackbar";
import * as types from "./dashboardTypes";

const getStats = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_GET_DASHBOARD_STATS,
      });

      const stats = await dashboardApi.getDashboardStats();
      dispatch({
        type: types.GET_DASHBOARD_STATS_SUCCESS,
        payload: {
          ...stats,
        },
      });
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.GET_DASHBOARD_STATS_FAILED,
      });
    }
  };
};

const getAllLitePlayerInfo = (statusName) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.GET_LITE_PLAYERS_INFO_REQUEST
      });

      let response = await dashboardApi.getLiteCandidateDetails(statusName);
      dispatch({
        type: types.GET_LITE_PLAYERS_INFO_SUCCESS,
        payload: response
      });
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.GET_LITE_PLAYERS_INFO_FAILED,
      });
    }
  };
};

const getTodaysUpdate = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.GET_TODAYS_UPDATE_REQUEST,
      });

      let response = await dashboardApi.todaysLatestUpdate();
      dispatch({
        type: types.GET_TODAYS_UPDATE_SUCCESS,
        payload: response,
      });
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.GET_TODAYS_UPDATE_FAILED,
      });
    }
  };
};



const selectCard = (cardId) => {
  return {
    type: types.SELECT_DASHBOARD_CARD,
    payload: cardId,
  };
};

const deselectCard = () => {
  return {
    type: types.DESELECT_DASHBOARD_CARD,
    payload: "",
  };
};

const selectDashboardFilter = (key, value) => {
  return {
    type: types.SELECT_DASHBOARD_FILTER,
    payload: {
      key,
      value,
    },
  };
};

const resetDashboardFilter = () => {
  return {
    type: types.RESET_DASHBOARD_FILTER,
  };
};

const selectAccountFilter = (key, value) => {
  return {
    type: types.SELECT_ACCOUNT_FILTER,
    payload: {
      key,
      value,
    },
  };
};

const resetAccountFilter = () => {
  return {
    type: types.RESET_ACCOUNT_FILTER,
  };
};

const selectDummyData = () => {
  return {
    type: types.DUMMY_STATIC_PLAYERS_LIST,
  };
};

const getAccountsList = (email) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.GET_ACCOUNT_NAMES_INFO_REQUEST,
      });

      let response = await dashboardApi.getAccountsList(email);
      dispatch({
        type: types.GET_ACCOUNT_NAMES_INFO_SUCCESS,
        payload: response,
      });
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.GET_ACCOUNT_NAMES_INFO_FAILED,
      });
    }
  };
};

const activateAccount = (email, successCb = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.GET_ACCOUNT_ACTIVATE_REQUEST,
        payload: {
          email: email,
        },
      });

      await dashboardApi.activateAccount(email);
      dispatch({
        type: types.GET_ACCOUNT_ACTIVATE_SUCCESS,
      });
      successCb();
    }
    catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.GET_ACCOUNT_ACTIVATE_FAILED,
      });
    }
  };
};

const deActivateAccount = (email, successCb = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.GET_ACCOUNT_DEACTIVATE_REQUEST,
      });

      await dashboardApi.deActivateAccount(email);
      dispatch({
        type: types.GET_ACCOUNT_DEACTIVATE_SUCCESS,
      });
      successCb();
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.GET_ACCOUNT_DEACTIVATE_FAILED,
      });
    }
  };
};

const dashboardActions = {
  getStats,
  getAllLitePlayerInfo,
  selectCard,
  deselectCard,
  selectDashboardFilter,
  resetDashboardFilter,
  selectDummyData,
  getTodaysUpdate,
  getAccountsList,
  deActivateAccount,
  activateAccount,
  selectAccountFilter,
  resetAccountFilter,
};

export default dashboardActions;
