import React from "react";
import MuiCircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";
import theme from "../../../config/theme";

let CircularProgress = ({
  size = theme.fontSize.md,
  fadeIn = "700ms",
  color = theme.colors.primaryBlue,
  thickness = 4.5,
  ...rest
}) => {
  return (
    <Fade in={true} style={{ transitionDelay: fadeIn }}>
      <MuiCircularProgress
        color={color}
        size={size}
        thickness={thickness}
        {...rest}
      />
    </Fade>
  );
};

export default CircularProgress;
