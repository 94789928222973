import { combineReducers } from "redux";
import * as types from "./consentTypes";

const consentFormsById = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_PENDING_CONSENT_FORMS_SUCCESS: {
      return {
        ...payload.forms,
      };
    }

    case types.UNLINK_CONSENT_FORM_SUCCESS: {
      return {
        ...state,
        [payload.id]: {
          id: payload.id,
          name: payload.name,
        },
      };
    }

    case types.UPLOAD_CONSENT_FORMS_SUCCESS: {
      return {
        ...state,
        ...payload.forms,
      };
    }

    default:
      return state;
  }
};

const allConsentFormIds = (state = [], action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_PENDING_CONSENT_FORMS_SUCCESS: {
      return [...Object.keys(payload.forms)];
    }

    case types.LINK_CONSENT_FORM_SUCCESS: {
      return state.filter((id) => id !== payload.formId);
    }

    case types.UNLINK_CONSENT_FORM_SUCCESS: {
      return [...state, payload.id];
    }

    case types.UPLOAD_CONSENT_FORMS_SUCCESS: {
      return [...state, ...Object.keys(payload.forms)];
    }

    case types.DELETE_CONSENT_FORM_SUCCESS: {
      return state.filter((id) => id !== payload.consentFormId);
    }

    default:
      return state;
  }
};

const consentForms = combineReducers({
  byId: consentFormsById,
  allIds: allConsentFormIds,
});

const linkedConsentFormsById = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_LINKED_CONSENT_FORMS_SUCCESS: {
      return {
        ...payload.forms,
      };
    }

    default:
      return state;
  }
};

const allLinkedConsentFormsIds = (state = [], action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_LINKED_CONSENT_FORMS_SUCCESS: {
      return [...Object.keys(payload.forms)];
    }

    case types.UNLINK_CONSENT_FORM_SUCCESS: {
      return state.filter((id) => id !== payload.consentFormId);
    }

    default:
      return state;
  }
};
const linkedConsentForms = combineReducers({
  byId: linkedConsentFormsById,
  allIds: allLinkedConsentFormsIds,
});

const pdfs = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_CONSENT_FORM_SUCCESS: {
      return {
        ...state,
        [payload.formId]: payload.form,
      };
    }
    default:
      return state;
  }
};
export default combineReducers({
  consentForms,
  linkedConsentForms,
  pdfs,
});
