import {makeStyles, Typography} from "@material-ui/core";

let useStyles = makeStyles((theme) => ({
    title: {
        fontSize: theme.fontSize.md,
        fontFamily: theme.fontFamily.prometo,
        fontWeight: 700,
        color: theme.palette.text.primary,
        textTransform: 'uppercase',
        marginRight: theme.spacing(1),
    },
    text: {
        fontSize: theme.fontSize.md,
        fontFamily: theme.fontFamily.prometo,
        fontWeight: 700,
        color: theme.palette.text.secondary,
        textTransform: 'uppercase',
        marginRight: theme.spacing(1),
    },
}));

const Title = ({ text = '', title }) => {
    const classes = useStyles();

    return (
        <div style={{ display: "flex" }} >
            <Typography className={classes.title}>
                {title}
            </Typography>
            <Typography className={classes.text} >
                {text}
            </Typography>
        </div>
    )
};

export default Title;