import React, { useContext, useEffect  } from "react";
import Filter from "./Filter";
import { statusTypes, riskTypes } from "../../../store/constants";
import { connect } from "react-redux";
import { ActionContext } from "../../../contexts";
import { makeStyles } from "@material-ui/core/styles";

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(6),
    [theme.breakpoints.only("sm")]: {
      marginLeft: 175,
    },
  },
  menu: {
    minWidth: 100,
    marginLeft: theme.spacing(3),
  },
}));

const Filters = (props) => {
  let classes = useStyles();
  let context = useContext(ActionContext);
  let { statusFilter, riskFilter, teamFilter, teams, loadingTeams, conferenceFilter, loadingConference, loadingSport, sportFilter, sportData, conferenceData } = props;



  // remove BCA/DDI team
  let teamTypes = { default: "team", ...teams };
  let { playerActions , conferenceActions , sportsActions } = context;

  useEffect(() => {
    conferenceActions.getConferenceList();
    sportsActions.getSportsList();
  }, []);


  let confData = conferenceData && conferenceData.map(v => v.name)
  // sam.push(v.name)
  // )
  let conferenceTypes = { default: "conference", ...confData };
  let sportD = sportData && sportData.map(v => v.name)
  let sportTypes = { default: "sport", ...sportD };

  let handleStatusfilter = (filter) => {
    playerActions.filterByStatus(filter);
  };

  let handleRiskFilter = (filter) => {
    playerActions.filterByRisk(filter);
  };

  let handleTeamFilter = (filter) => {
    playerActions.filterByTeam(filter);
  };

  let handleConferenceFilter = (filter) => {
    playerActions.filterByConference(filter);
  };

  let handleSportFilter = (filter) => {
    playerActions.filterBySport(filter);
  };

  return (
    <div className={classes.root}>
      <div className={classes.menu}>
        <Filter
          filterTypes={conferenceTypes}
          filter={conferenceFilter}
          loading={loadingConference}
          onFilterChange={handleConferenceFilter}
        />
      </div>
      <div className={classes.menu}>
        <Filter
          filterTypes={sportTypes}
          filter={sportFilter}
          loading={loadingSport}
          onFilterChange={handleSportFilter}
        />
      </div>
      <div className={classes.menu}>
        <Filter
          filterTypes={teamTypes}
          filter={teamFilter}
          loading={loadingTeams}
          onFilterChange={handleTeamFilter}
        />
      </div>
      <div className={classes.menu}>
        <Filter
          filterTypes={statusTypes}
          filter={statusFilter}
          onFilterChange={handleStatusfilter}
        />
      </div>

      <div className={classes.menu}>
        <Filter
          filterTypes={riskTypes}
          filter={riskFilter}
          onFilterChange={handleRiskFilter}
        />
      </div>

    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    statusFilter: state.ui.player.statusFilter,
    riskFilter: state.ui.player.riskFilter,
    consentFilter: state.ui.player.consentFilter,
    teamFilter: state.ui.player.teamFilter,
    sportFilter: state.ui.player.sportFilter,
    conferenceFilter: state.ui.player.conferenceFilter,
    teams: state.ui.player.candidateTeams,
    loadingTeams: state.ui.player.loadingTeams,
    sportData: state.sports.sportsListData,
    loadingSport: state.ui.sports.sportsListLoading,
    conferenceData: state.conference.allConferenceList.allData,
    loadingConference: state.ui.dashboard.loadingStats,
  };
};
export default connect(mapStateToProps)(Filters);
