import React, { useContext } from "react";
import Button from "../../../shared/Button";
import SaveIcon from "@material-ui/icons/Save";
import fileDownload from "js-file-download";
import { accountTypes } from "../../../../store/constants";
import { ActionContext } from "../../../../contexts";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  btnPrimary: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    fontFamily: theme.fontFamily.robotoCondensed,
    color: theme.colors.primaryNavy,
    fontWeight: 700,
    cursor: 'pointer',
    textTransform: 'uppercase',
  },
}));

const FeedExport = (props) => {
  let {
    playerId,
    source,
    accountType,
    isDisabled,
    loading,
    playerName, team
  } = props;
  let { playerActions } = useContext(ActionContext);
  const classes = useStyles();

  let allowedTypes = [
    accountTypes.superAdmin,
    accountTypes.mmDdiAdmin,
    accountTypes.mmPsychologist,
    accountTypes.mmChiefPsychologist
  ];

  let hasAccessToDownload = allowedTypes.includes(accountType);

  let handleFeedExport = () => {
    playerActions.exportFeed(playerId, playerName, source, (data, name) => {
      return () => {
        fileDownload(data, name);
      };
    });
  };

  return (
    <div>
      {hasAccessToDownload && (
        <div style={{ alignSelf: "flex-start", marginLeft: 8 }}>
          {team === 'Star Wars' ? '' :
            <Button
              className={classes.btnPrimary}
              variant="text"
              disabled={isDisabled}
              disableElevation
              startIcon={<SaveIcon />}
              onClick={handleFeedExport}
              loading={loading}
              style={{ minWidth: 98 }}
            >
              Export Feed
            </Button>}
        </div>
      )}
    </div>
  );
};

export default FeedExport;
