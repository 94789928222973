import React, { useContext, useEffect } from "react";
import Summery from "./Summery";
import withPaymentLock from "../withPaymentLock";
import { accountTypes } from "../../../../store/constants";
import { connect } from "react-redux";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { ActionContext } from "../../../../contexts";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: 'column',
  },
  container: {
    flexGrow: 1,
    display: "flex",
    margin: "0px",
    backgroundColor: theme.palette.common.white,
    boxShadow: "3px 3px 10px 3px #cdcdcd",
    borderRadius: 8,
    padding: '2rem 1rem',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'right',
    marginBottom: '1rem',
    width: '100%',
    marginRight: -20,
  },
  btnPrimary: {
    color: theme.palette.common.white,
    padding: '10px',
    backgroundColor: theme.colors.primaryNavy,
    width: '6rem',
    marginRight: '10px',
    borderRadius: '7px',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.colors.primaryNavy,
      border: `1px solid ${theme.colors.primaryNavy}`,
    },
    textTransform: 'uppercase',
  },
}));

let PsychSummary = (props) => {
  let classes = useStyles();
  let { playerActions, snackbarActions } = useContext(ActionContext);
  let categories = {
    significantBackgroundInformation: "Significant Background Information",
    symptomsAndInformationCaptured: "Symptoms and Information Captured",
    custom: "Custom"
  };

  let { playerId, readOnly = false, accountType, summery } = props;

  let hasEditPrivileges =
    accountType === accountTypes.mmPsychologist ||
    accountType === accountTypes.mmChiefPsychologist ||
    accountType === accountTypes.superAdmin;

  let isEditable = !readOnly && hasEditPrivileges;

  const [state, setState] = React.useState({
    openCard: false,
    customCards: []
  })

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join('') +
      +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join('')
    );
  }

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      customCards: summery && summery[`${playerId}.${categories.custom}`]
    }))
  }, [summery]);

  const date = formatDate(new Date());

  function handleNewCard() {
    let data = {
      candidate_id: playerId,
      category: "Custom",
      content: {
        "blocks": [
          {
            "key": "clrlg",
            "text": "Untitled",
            "type": "header-three",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "8mp07",
            "text": "Psychologist comments pending...",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
            "data": {}
          }
        ],
        "entityMap": {}
      },
      _id: playerId + '-' + date
    };

    let success = () => {
      return snackbarActions.showSnackbar("New Note Added");
    };

    playerActions.addExecutiveSummeryContent(data, success, state.customCards);

    setState(prevState => ({
      ...prevState,
      customCards: [...state.customCards, data]
    }))
  }

  return (
      <div className={classes.root}>
        <div className={classes.btnContainer}>
          {
            (accountType === accountTypes.superAdmin || accountType === accountTypes.mmPsychologist || accountType === accountTypes.mmChiefPsychologist) ?
                <Button className={classes.btnPrimary} onClick={handleNewCard}>Add +</Button>
                :
                ""
          }
        </div>
        <div className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Summery
                  isEditable={isEditable}
                  playerId={playerId}
                  categoryId={categories.significantBackgroundInformation}
                  content={summery && summery[`${playerId}.${categories.significantBackgroundInformation}`]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Summery
                  isEditable={isEditable}
                  playerId={playerId}
                  categoryId={categories.symptomsAndInformationCaptured}
                  content={summery && summery[`${playerId}.${categories.symptomsAndInformationCaptured}`]}
              />
            </Grid>
            {
              state.customCards && state.customCards.length > 0 ?
                  state.customCards?.map(list =>
                      <Grid item xs={12} sm={6}>
                        <Summery
                            isEditable={isEditable}
                            playerId={playerId}
                            categoryId={categories.custom}
                            content={list && list.content}
                            open={true}
                            id={list._id}
                            currentData={state.customCards}
                            customData={list}
                        />
                      </Grid>
                  )
                  :
                  ""
            }
          </Grid>
        </div>
      </div>
  );
};

let mapStateToProps = (state) => {
  return {
    accountType: state.account.accountType,
    summery: state.player.executiveSummery,
  };
};

export default connect(mapStateToProps)(withPaymentLock(PsychSummary));
