import { Grid, makeStyles, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Formik } from "formik";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { object, string } from "yup";
import { ActionContext } from "../../../contexts";
import { errorProps } from "../../../utils";
import Button from "../../shared/Button";
import TextField from "../../shared/TextField";

let forgotPasswordSchema = object().shape({
  email: string().email().required("Enter email address"),
});

let useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    borderTop: `5px solid ${theme.palette.primary.main}`,
    margin: 'auto',
    padding: theme.spacing(2),
  },
  heading: {
    display: "flex",
    flexDirection: "column",
  },
  alert: {
    marginBottom: theme.spacing(3),
  },
  title: {
    fontFamily: theme.fontFamily.prometo,
    fontWeight: 800,
    fontSize: theme.fontSize.lg,
    color: theme.colors.primaryNavy,
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(2)
  },
  inputs: {
    paddingBottom: theme.spacing(2),
  },
  caption: {
    fontFamily: theme.fontFamily.robotoCondensed,
    color: theme.palette.text.disabled,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
  },
  submitBtn: {
    backgroundColor: theme.colors.primaryGreen,
    width: '150px',
    fontFamily: theme.fontFamily.prometo,
    textTransform: 'uppercase',
  },
  signinBtn: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '1rem',
    fontSize: '13px',
    fontFamily: theme.fontFamily.robotoCondensed,
    color: theme.palette.text.primary,
    fontWeight: 700,
    cursor: 'pointer',
    textTransform: 'uppercase',
  },
}));

let ForgotPassword = () => {
  let classes = useStyles();
  let history = useHistory();
  let actions = useContext(ActionContext);
  let forgotState = useSelector((state) => state.ui.account.forgot);

  let { error } = forgotState;

  let handleRedirectToSignin = () => {
    history.push("/signin");
  };

  let handleFormSubmit = (values) => {
    let data = {
      email: values.email,
    };

    let { accountActions } = actions;

    accountActions.forgotPassword(data, handleRedirectToSignin);
  };

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <Typography variant="h5" className={classes.title}>
          Reset Password
        </Typography>
        {error ? (
          <div className={classes.alert}>
            <Alert severity="error">{error}</Alert>
          </div>
        ) : null}
      </div>
      <div className={classes.main}>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={forgotPasswordSchema}
          onSubmit={handleFormSubmit}
        >
          {(formik) => {
            let {
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              touched,
            } = formik;
            return (
              <form onSubmit={handleSubmit} noValidate>
                <div className={classes.inputs}>
                  <Grid container spacing={3}>
                    <Grid item sm={12}>
                      <TextField
                        name="email"
                        label="Email Address"
                        placeholder="Email Address"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        required
                        autoFocus
                        {...errorProps(errors, touched, "email")}
                      />
                      <Typography className={classes.caption}>An email will be sent with a temporary password</Typography>
                    </Grid>
                  </Grid>
                </div>

                <div className={classes.buttons}>
                  <Button variant="text" onClick={handleRedirectToSignin} className={classes.signinBtn}>
                    Sign in
                  </Button>
                  <Button type="submit" color="primary" disableElevation className={classes.submitBtn}>
                    Submit
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default ForgotPassword;
