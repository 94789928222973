import MuiAvatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import CardActionArea from "@material-ui/core/CardActionArea";
import React from "react";
import { useConfirm } from "material-ui-confirm";
import { facebook, linkedin, twitter, instagram } from "assets";

const Avatar = ({ network }) => {
  const getAvatar = () => {
    switch (network) {
      case "facebook":
        return facebook;
      case "instagram":
        return instagram;
      case "linkedin":
        return linkedin;
      case "twitter":
        return twitter;
      default:
        return null;
    }
  };
  const avatar = getAvatar(network);

  if (!avatar) {
    return <MuiAvatar>{network}</MuiAvatar>;
  }

  return <MuiAvatar src={avatar} alt={network} />;
};

const Result = ({ result, onDelete, withDelete = true }) => {
  const confirm = useConfirm();

  let handleDelete = () => {
    confirm({
      title: `Delete result - ${result.name}?`,
      description: "You are about to delete the result. Are you sure?",
      confirmationText: "Yes",
    })
      .then(() => {
        onDelete(result._id);
      })
      .catch(() => {});
  };

  const handleClick = () => {
    window.open(result.url, "_blank");
  };

  return (
    <Card variant="outlined" style={{width: "100%" }}>
      <CardHeader
        avatar={<Avatar network={result.network} />}
        action={
          withDelete ? (
            <IconButton aria-label="delete" onClick={handleDelete}>
              <DeleteIcon />
            </IconButton>
          ) : null
        }
        title={result.name}
        subheader={result.id}
      />
      <CardActionArea onClick={handleClick}>
        <CardContent>
          {result.description || "Description not available - View Account"}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default Result;
