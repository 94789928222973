import {Title} from "../../shared";
import {connect} from "react-redux";
import {Box, Grid, makeStyles} from "@material-ui/core";
import React, {useContext, useEffect} from "react";
import {ActionContext} from "../../../contexts";
import {Field, Form, Formik} from "formik";
import {string} from "yup";
import {GlobalCss} from "../../../config/GlobalCss";
import Alert from "@material-ui/lab/Alert";
import {TextField} from "formik-material-ui";
import clsx from "clsx";
import Button from "../../shared/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

let useStyles = makeStyles((theme) => ({
    form: {
        paddingTop: theme.spacing(2),
        maxWidth: 800,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        minHeight: '95vh',
    },
    content: {
        display: "flex",
        justifyContent: "end",
        margin: '1rem 0rem'
    },
    btnPrimary: {
        color: theme.palette.common.white,
        padding: '10px',
        backgroundColor: theme.colors.primaryNavy,
        width: '13rem',
        borderRadius: '7px',
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            color: theme.colors.primaryNavy,
            border: `1px solid ${theme.colors.primaryNavy}`,
        },
        textTransform: 'uppercase',
    },
    btnSecondary: {
        color: theme.palette.secondary.main,
        border: '1px solid',
        width: '13rem',
        padding: '10px',
        marginRight: '10px',
        borderRadius: '7px',
        '&:hover': {
            background: theme.palette.secondary.main,
            color: theme.palette.common.white,
        },
        textTransform: 'uppercase',
    },
}));

const UnitForm = (props) => {
    const classes = useStyles();
    const {sportsActions} = useContext(ActionContext);
    const {allUnits, error, formType, loading, sportId, setOpen, unit, unitId} = props;
    const type = formType === 'create';

    useEffect(() => {
        sportsActions.getUnitById(unitId)
    }, []);

    const handleSubmit = (values) => {
        let data = {
            unit: values.unit,
        };

        let unitFound = allUnits.find(unit => unit === values.unit);

        if (type) {
            if (!unitFound) {
                sportsActions.createUnit(data, sportId);
            }
        } else {
            sportsActions.updateUnit(unitId, data, sportId);
        }

        setOpen(false);
    };


    const handleErrorClose = () => {
        sportsActions.resetUnitCreationError();
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid container spacing={1} style={{height: 220}}>
            <Grid item xs={12}>
                <div style={{margin: '0rem 3rem'}}>
                    {
                        loading && loading ? (
                            <Box style={{display: 'flex', justifyContent: 'center'}}>
                                <CircularProgress size={72} thickness={3} color='primary' />
                            </Box>
                        ) : (
                            <Formik
                                initialValues={{
                                    unit: '' || unit.unit,
                                }}
                                validator={{
                                    unit: string()
                                        .trim()
                                        .matches(
                                            /^[a-zA-Z0-9]+(([a-zA-Z0-9 ])?[a-zA-Z0-9]*)*$/,
                                            "Enter a valid sport name, no special characters allowed."
                                        )
                                        .required('A name is required.'),
                                }}
                                onSubmit={(values) => {
                                    handleSubmit(values)
                                }}
                            >
                                <Form autoComplete='off'>
                                    <GlobalCss/>
                                    <div className={classes.form}>
                                        {type ? <Title title='Create Unit'/> : <Title title='Update' text={`${unit.unit}`}/>}
                                        {error ? (
                                            <Grid item>
                                                <Alert severity='error' onClose={handleErrorClose}>
                                                    {error}
                                                </Alert>
                                            </Grid>
                                        ) : null}
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Field
                                                    name="unit"
                                                    label="Unit Name "
                                                    placeholder="Unit Name "
                                                    component={TextField}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    autoFocus
                                                    style={{marginTop: '1rem'}}
                                                />
                                            </Grid>
                                        </Grid>
                                        <div className={clsx(classes.content)}>
                                            <Button
                                                className={classes.btnSecondary}
                                                type="button"
                                                variant="text"
                                                onClick={handleClose}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                className={classes.btnPrimary}
                                                type="submit"
                                            >
                                                {type ? 'Create' : 'Update'}
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </Formik>
                        )
                    }
                </div>
            </Grid>
        </Grid>
    )
};

let mapStateToProps = (state) => {
    return {
        error: state.ui.sports.error,
        loading: state.ui.sports.loadingAllSportsUnits,
        unitId: state.ui.sports.selectedSportUnitId,
        allUnits: state.sports.sportUnits,
        unit: state.sports.unitSport,
        formType: state.ui.sports.selectedFormState,
        sportId: state.ui.sports.selectedSportId,
    };
};

export default connect(mapStateToProps)(UnitForm);