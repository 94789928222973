import React, { useContext } from 'react'
import { DialogTitle, Typography, TextField, Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ActionContext } from "../../../../contexts";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    flex: 1,
    fontSize: theme.fontSize.md,
    fontFamily: theme.fontFamily.prometo,
    fontWeight: 700,
    color: theme.colors.primaryNavy,
    textTransform: 'uppercase'
  },
  closeIcon: {
    color: theme.colors.primaryNavy,
  },
  text: {
    color: theme.palette.text.secondary,
    fontFamily: theme.fontFamily.robotoCondensed,
    fontSize: '1.1rem',
  },
  warning: {
    color: theme.palette.error.main,
    fontFamily: theme.fontFamily.robotoCondensed,
    fontSize: '0.9rem',
  },
  btnPrimary: {
    color: theme.palette.common.white,
    padding: '10px',
    backgroundColor: theme.colors.primaryNavy,
    width: '8rem',
    borderRadius: '7px',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.colors.primaryNavy,
      border: `1px solid ${theme.colors.primaryNavy}`,
    },
    textTransform: 'uppercase',
  },
  btnSecondary: {
    color: theme.palette.secondary.main,
    border: '1px solid',
    width: '8rem',
    padding: '10px',
    marginRight: '10px',
    borderRadius: '7px',
    '&:hover': {
      background: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    textTransform: 'uppercase',
  },
}));

const ReProcessDialog = (props) => {
  const classes = useStyles();
  const { playerInfo, type, submittingBrainReceptivity } = props;
  const { playerActions } = useContext(ActionContext);
  const [state, setState] = React.useState({
    name: '',
  });

  const { closeDialog } = props;
  const handleCloseDialog = () => {
    closeDialog();
  };

  function handleFormDataChange(key, value) {
    setState(prevState => ({
      ...prevState,
      [key]: value
    }));
  }

  const handleConfirmPlayer = (data) => {
    const handleSuccess=()=>{
      handleCloseDialog();
    };

    playerActions.getReceptivitiBig5Score(data && data.id, type,handleSuccess);
  };

  return (
    <React.Fragment>
      <div style={{ display: 'flex', alignItems: 'center'}}>
        <DialogTitle id="simple-dialog-title" className={classes.title}>
          Re-process
        </DialogTitle>
        <div style={{ margin: '8px 16px 8px 8px' }}>
          <IconButton onClick={handleCloseDialog}>
            <CloseIcon className={classes.closeIcon}/>
          </IconButton>
        </div>
      </div>
      <div style={{ margin: '30px' }}>
        <Typography className={classes.text}>
          Are you sure you want to re-process the scores through Receptiviti with all existing language for <span style={{ fontWeight: 'bold' }}>{playerInfo.name}</span>?
        </Typography>
        <div style={{ marginTop: '8px' }}>
          <Typography className={classes.warning}>Type player name to confirm:</Typography>
          <TextField
            style={{ width: '100%', marginTop: '8px' }}
            variant="outlined"
            size="small"
            value={state.name}
            onChange={(ev) => handleFormDataChange('name', ev.target.value)}
          />
        </div>
        <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleCloseDialog} className={classes.btnSecondary}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" className={classes.btnPrimary} disabled={!state.name || state.name !== playerInfo.name}
            onClick={() => handleConfirmPlayer(playerInfo)}>
            {submittingBrainReceptivity ? <CircularProgress size={24} color="inherit" /> : 'Confirm'}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    submittingBrainReceptivity: state.ui.player.submittingBrainReceptivity,
  };
};

export default connect(mapStateToProps)(ReProcessDialog);
