export const REQUEST_GET_DASHBOARD_STATS = "REQUEST_GET_DASHBOARD_STATS";
export const GET_DASHBOARD_STATS_SUCCESS = "GET_DASHBOARD_STATS_SUCCESS";
export const GET_DASHBOARD_STATS_FAILED = "GET_DASHBOARD_STATS_FAILED";

export const GET_LITE_PLAYERS_INFO_REQUEST = "GET_LITE_PLAYERS_INFO_REQUEST";
export const GET_LITE_PLAYERS_INFO_SUCCESS = "GET_LITE_PLAYERS_INFO_SUCCESS";
export const GET_LITE_PLAYERS_INFO_FAILED = "GET_LITE_PLAYERS_INFO_FAILED";

export const SELECT_DASHBOARD_CARD = "SELECT_DASHBOARD_CARD";
export const DESELECT_DASHBOARD_CARD = "DESELECT_DASHBOARD_CARD";

export const SELECT_DASHBOARD_FILTER = "SELECT_DASHBOARD_FILTER";
export const RESET_DASHBOARD_FILTER = "RESET_DASHBOARD_FILTER";

export const DUMMY_STATIC_PLAYERS_LIST = "DUMMY_STATIC_PLAYERS_LIST";
export const DUMMY_DASHBOARD_LIST = "DUMMY_DASHBOARD_LIST";

export const GET_TODAYS_UPDATE_REQUEST = "GET_TODAYS_UPDATE_REQUEST";
export const GET_TODAYS_UPDATE_SUCCESS = "GET_TODAYS_UPDATE_SUCCESS";
export const GET_TODAYS_UPDATE_FAILED = "GET_TODAYS_UPDATE_FAILED";

export const GET_ACCOUNT_NAMES_INFO_REQUEST = "GET_ACCOUNT_NAMES_INFO_REQUEST";
export const GET_ACCOUNT_NAMES_INFO_SUCCESS = "GET_ACCOUNT_NAMES_INFO_SUCCESS";
export const GET_ACCOUNT_NAMES_INFO_FAILED = "GET_ACCOUNT_NAMES_INFO_FAILED";

export const GET_ACCOUNT_ACTIVATE_REQUEST = "GET_ACCOUNT_ACTIVATE_REQUEST";
export const GET_ACCOUNT_ACTIVATE_SUCCESS = "GET_ACCOUNT_ACTIVATE_SUCCESS";
export const GET_ACCOUNT_ACTIVATE_FAILED = "GET_ACCOUNT_ACTIVATE_FAILED";

export const GET_ACCOUNT_DEACTIVATE_REQUEST = "GET_ACCOUNT_DEACTIVATE_REQUEST";
export const GET_ACCOUNT_DEACTIVATE_SUCCESS = "GET_ACCOUNT_DEACTIVATE_SUCCESS";
export const GET_ACCOUNT_DEACTIVATE_FAILED = "GET_ACCOUNT_DEACTIVATE_FAILED";

export const SELECT_ACCOUNT_FILTER = "SELECT_ACCOUNT_FILTER";
export const RESET_ACCOUNT_FILTER = "RESET_ACCOUNT_FILTER";
