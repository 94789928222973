import * as types from "./accountTypes";
import { combineReducers } from "redux";

let accessToken = localStorage.getItem("@accessToken");
let accountType = localStorage.getItem("@accountType");

// had to use these small reducers when converting to combine reducer
let isAuthenticatedReducer = (state = Boolean(accessToken), action) => {
  switch (action.type) {
    case types.SIGNIN_SUCCESS: {
      return true;
    }

    default:
      return state;
  }
};

let loggedInAccountReducer = (state = {}, action) => {
  const { payload } = action;
  if (action.type === types.GET_PROFILE_SUCCESS) return payload;
  return state;
};

let accountTypeReducer = (state = accountType || null, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.SIGNIN_SUCCESS: {
      const { user } = payload;
      return user.account_type;
    }

    case types.GET_PROFILE_SUCCESS: {
      return payload?.account_type 
    }

    default:
      return state;
  }
};

let notificationsById = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_NOTIFICATIONS_SUCCESS: {
      return {
        ...payload.notifications,
      };
    }

    case types.MARK_NOTIFICATION_BY_ID_AS_READ_SUCCESS: {
      const { notificationId } = payload;
      return {
        ...state,
        [notificationId]: {
          ...state[notificationId],
          status: "Read",
        },
      };
    }

    default:
      return state;
  }
};

let allNotifications = (state = [], action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_NOTIFICATIONS_SUCCESS: {
      return [...Object.keys(payload.notifications)];
    }

    default:
      return state;
  }
};

let unreadIds = (state = [], action) => {
  let { payload } = action;
  switch (action.type) {
    case types.GET_NOTIFICATIONS_SUCCESS: {
      return [...payload.unread];
    }

    case types.MARK_NOTIFICATION_BY_ID_AS_READ_SUCCESS: {
      return state.filter((id) => id !== payload.notificationId);
    }

    default:
      return state;
  }
};

let notificationsReducer = combineReducers({
  byId: notificationsById,
  allIds: allNotifications,
  unreadIds,
});

const selectedAccountData = (state = {}, action) => {
  const { payload } = action;
  if (action.type === types.GET_ACCOUNT_BY_EMAIL_SUCCESS) return payload;
  return state;
};

let accountReducer = combineReducers({
  isAuthenticated: isAuthenticatedReducer,
  accountData: loggedInAccountReducer,
  accountType: accountTypeReducer,
  notifications: notificationsReducer,
  selectedAccountData,
});

export default accountReducer;
