import React, { useState, useContext } from "react";
import List from "@material-ui/core/List";
import RiskCommentItem from "./RiskCommentItem";
import TextField from "@material-ui/core/TextField";
import Button from "../../../shared/Button";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/ClearOutlined";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import AddCommentIcon from "@material-ui/icons/AddComment";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { DialogContentText } from "@material-ui/core";
import { ActionContext } from "../../../../contexts";

let useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(),
  },
  list: {
    maxHeight: 250,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: 7,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.background.default,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[200],
    },
  },
}));

const RiskComments = (props) => {
  let {
    category,
    playerId,
    categoryId,
    comments,
    closeDialog,
    updatingRiskComment,
  } = props;

  let classes = useStyles();
  let { playerActions } = useContext(ActionContext);

  let [textValue, setTextValue] = useState("");

  let handleTextChange = (e) => {
    setTextValue(e.target.value);
  };

  let resetCommentField = () => setTextValue("");

  let handleAddComment = () => {
    let data = {
      candidate_id: playerId,
      content: textValue,
      category: categoryId,
    };

    playerActions.addRiskComment(data, resetCommentField);
  };

  let handleCloseDialog = () => {
    closeDialog();
  };

  let handleDeleteComment = (id) => {
    let data = {
      candidate_id: playerId,
      content_id: id,
      category: categoryId,
    };

    playerActions.deleteRiskComment(data);
  };

  let isDisabled = textValue.length < 2;

  return (
    <div>
      <div
        style={{
          padding: "16px 24px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">{category.id}</Typography>
        <IconButton onClick={handleCloseDialog}>
          <ClearIcon />
        </IconButton>
      </div>
      <DialogContent style={{ paddingBottom: 16 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 16,
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            multiline
            rows={3}
            value={textValue}
            onChange={handleTextChange}
            label="Comment"
            placeholder="Enter your comment here..."
          />

          <div style={{ alignSelf: "flex-end", marginTop: 8 }}>
            <Button
              variant="contained"
              disableElevation
              color="primary"
              onClick={handleAddComment}
              disabled={isDisabled}
              loading={updatingRiskComment}
              startIcon={<AddCommentIcon />}
            >
              Add Comment
            </Button>
          </div>
        </div>
        <DialogContentText>Comments</DialogContentText>
        {comments.length > 0 ? (
          <List className={classes.list}>
            {comments.map((commentId) => {
              return (
                <RiskCommentItem
                  id={commentId}
                  key={commentId}
                  onDelete={handleDeleteComment}
                  editable
                />
              );
            })}
          </List>
        ) : (
          <Typography variant="caption">No comments added</Typography>
        )}
      </DialogContent>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  let { categoryId, playerId } = ownProps;

  return {
    category: state.player.risk.categories[playerId][categoryId],
    comments: state.player.risk.categoryComments[`${playerId}.${categoryId}`],
    updatingRiskComment:
      state.ui.player.updatingRiskComments[`${playerId}.${categoryId}`] ||
      false,
  };
};
export default connect(mapStateToProps)(RiskComments);
