import * as types from "./snackbarTypes";

const initialState = {
  isOpen: false,
  message: "",
  props: {},
};

const reducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.SHOW_SNACKBAR: {
      return {
        isOpen: true,
        message: payload.message,
        props: payload.props,
      };
    }

    case types.CLOSE_SNACKBAR: {
      return {
        isOpen: false,
        message: "",
        props: {},
      };
    }

    default:
      return state;
  }
};

export default reducer;
