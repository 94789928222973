import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React, { useState } from "react";
import { connect } from "react-redux";
import { capitalize } from "../../../../utils";
import { IconButton } from "../../../shared/Button";
import ApprovePanel from "./ApprovePanel";
import MarkAsReviewCompleteToggleButton from "./MarkAsReviewCompleteToggleButton";
import Message from "./Message";
import Nav from "./Nav";
import Payments from "./Payments";
import Reports from "./Reports";

let useStyles = makeStyles((theme) => ({
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderTopLeftRadius: 8,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    paddingTop: theme.spacing(2),
  },
  nav: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 101,
    borderLeft: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
  },
  avatar: {
    width: 40,
    height: 40,
    marginRight: 8,
  },
  name: {
    width: 95,
    fontWeight: theme.typography.fontWeightBold,
  },
  team: {
    width: 95,
    fontSize: 10,
  },
  page: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    overflowY: "scroll",
    scrollbarWidth: 7,
    scrollbarColor: theme.palette.grey[200],
    "&::-webkit-scrollbar": {
      width: 8,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.background.default,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[300],
      height: 50,
    },
    padding: theme.spacing(2),
  },
  checkbox: {
    color: "red",
  },
}));

const PlayerDetails = (props) => {
  let classes = useStyles();
  let { playerId, pages, basicInfo, accountType, onClose, message } = props;
  let [selectedIndex, setSelectedIndex] = useState(1);
  let [showMessage, setShowMessage] = useState(message.show);

  let page = pages[selectedIndex];

  if (!page) {
    return null;
  }

  let Page = page.component;
  let hasAnalyzed = true;
  let status = basicInfo.status;

  let showErrorMessage = true;

  let handleSelect = (e, index) => {
    setSelectedIndex(index);
  };

  let handleMessage = () => {
    setShowMessage(false);
  };

  return (
    <React.Fragment>
      <div className={classes.content}>
        <AppBar
          position="relative"
          color="inherit"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar>
            <Hidden lgUp>
              <IconButton icon={<ArrowBackIcon />} onClick={onClose} />
            </Hidden>
            <Avatar
              src={basicInfo.image}
              alt="avatar"
              className={classes.avatar}
            />
            <Typography variant="h6">{capitalize(basicInfo.name)}</Typography>
            <div style={{ flex: 1 }} />

            <div
              style={{
                display: "flex",
              }}
            >
              {/* <MarkAsPaidToggleButton
                playerId={playerId}
                status={basicInfo.status}
              /> */}
              <MarkAsReviewCompleteToggleButton
                playerId={playerId}
                consent={basicInfo.consent}
                status={basicInfo.status}
              />
            </div>
            <Payments
              playerId={playerId}
              status={status}
              accountType={accountType}
            />

            <Reports
              playerId={playerId}
              status={status}
              accountType={accountType}
            />
          </Toolbar>
        </AppBar>
        <div className={classes.page}>
          {showErrorMessage && (
            <div style={{ width: "100%" }}>
              <Message
                show={showMessage}
                title={message.title}
                content={message.content}
                buttonLabel={message.buttonLabel}
                severity={message.severity}
                onClick={handleMessage}
              />
            </div>
          )}

          <Typography variant="h5" align="center" className={classes.title}>
            {page.title}
          </Typography>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            align="center"
            style={{ marginBottom: 16 }}
          >
            {page.description}
          </Typography>
          <Page playerId={playerId} />
        </div>
        <ApprovePanel playerId={playerId} />
      </div>
      <div className={classes.nav}>
        <Nav
          selectedIndex={selectedIndex}
          onSelect={handleSelect}
          hasAnalyzed={hasAnalyzed}
          status={basicInfo.status}
        />
      </div>
    </React.Fragment>
  );
};

let mapStateToProps = (state, ownProps) => {
  let { playerId } = ownProps;

  return {
    basicInfo: state.player.basic[playerId],
    accountType: state.account.accountType,
    loadingMarkAsReviewCompleted:
      state.ui.player.loadingMarkAsReviewCompleted[playerId],
    exportingPlayerReport: state.ui.player.exportingPlayerReport[playerId],
    message: state.player.message[playerId],
  };
};
export default connect(mapStateToProps)(PlayerDetails);
