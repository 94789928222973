import Sign from '../../../../assets/mental-metrix-infinity-sign.svg'

const MentalMetrixInfinitySign = () => {
    return (
        <img
            src={Sign}
            alt="Sign"
            style={{
                width: 65,
                height: 50,
                paddingTop: 10
            }}
        />
    )
}

export default MentalMetrixInfinitySign