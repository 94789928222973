import createMuiTheme from "@material-ui/core/styles/createTheme";

let theme = createMuiTheme({
    // breakpoints: {
    //   keys: ["xs", "sm", "md", "lg", "xl"],
    //   values: {
    //     xs: 0,
    //     sm: 768,
    //     md: 1282,
    //     lg: 1440,
    //     xl: 1920,
    //   },
    // },
  fontFamily: {
    prometo: 'Prometo, sans-serif',
    robotoCondensed: 'roboto-condensed, sans-serif',
    textPlan: 'roboto, sans-serif'
  },
  fontSize: {
    md: 20,
    lg: 24
  },
  colors: {
    blue30Percent: '#9BD0F3',
    lightblue: '#DAEFFD',
    primaryBlue: '#058AE0',
    blue: '#0070B9',
    darkBlue: '#0A2D60',
    sherpaBlue: '#335C6D',
    lightOrange: '#fdd06d',
    primaryOrange: '#FFB71B',
    darkOrange: '#bd8b17',
    lightGreen: '#a9e66a',
    primaryGreen: '#8EC158',
    darkGreen: '#5d8532',
    lightNavy: "#014f73",
    primaryNavy: "#002839",
    darkNavy: "#001f2b",
    slate: '#212121',
    white: '#FFFFFF',
    ghostWhite: '#EAEAEA',
    lightGrey: '#D8D8D8',
    veryLightGrey: '#f3f3f3',
    black: '#000000',
    darkBlue60Percent: 'rgb(10, 45, 96, 0.6)'
  },
  palette: {
    primary: {
      light: '#37A1E6',
      main: '#058AE0',
      dark: '#0070B9',
    },
    secondary: {
      light: '#A2C27F',
      main: '#8EC158',
      dark: '#4F921E',
    },
    error: {
      light: '#FF5A65',
      main: '#DC2B2B',
      dark: '#BE0707',
    },
    warning: {
      light: '#FFCF52',
      main: '#FFB71B',
      dark: '#E5AB2F',
    },
    info: {
      light: '#37A1E6',
      main: '#058AE0',
      dark: '#0070B9',
    },
    success: {
      light: '#A2C27F',
      main: '#8EC158',
      dark: '#4F921E',
    },
    text: {
      primary: '#002839',
      secondary: '#8EC158',
      disabled: 'rgb(10, 45, 96, 0.6)'
    },
    background: {
      primary: '#002839',
    }
  },
});

export default theme;
