import React, {useContext, useEffect, useState} from "react";
import {
  FormHelperText,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Field } from "formik";
import {connect, useSelector} from "react-redux";
import { ActionContext } from "../../../contexts";
import { Autocomplete } from "formik-material-ui-lab";
import MuiTextField from "@material-ui/core/TextField";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

let useStyles = makeStyles((theme) => ({
    heading: {
        maxWidth: 480,
        fontWeight: 500,
        lineHeight: "60px",
        color: theme.palette.text.primary,
        fontFamily: theme.fontFamily.prometo,
    },
    form: {
        paddingTop: theme.spacing(1),
        maxWidth: '100%',
    },
}));

let CreatePlayerBackground = (props) => {
  let classes = useStyles();
  const {
    errors,
    touched,
    countriesData,
    stateData,
    values,
    sportPositions,
    allConferenceByType,
    sportByConference,
    units
  } = props;
  let actions = useContext(ActionContext);
  let { sportsActions, conferenceActions } = actions;
  const [finalPosition, setFinalPosition] = useState([]);
  const accountState = useSelector((state) => state.account.accountData);
  const current_team = accountState.team !== "MM-DDi";

  useEffect(() => {
      if (values.conferenceType.length > 0) {
          conferenceActions.getConferenceByType(values.conferenceType)
      }
  }, [])

  useEffect(() => {
      if (values.conference.length > 0) {
          let conference = allConferenceByType && allConferenceByType.find(conference => conference.name === values.conference);
          if (conference) {
              conferenceActions.getSportsByConferenceId(conference.id);
          }
      } else {
          conferenceActions.resetSportsByConferenceId();
      }
  }, [allConferenceByType, conferenceActions, values.conference])

  useEffect(() => {
      if (values.sport.length > 0) {
          sportsActions.getSportUnitDetails(sportByConference._id);
          sportsActions.getSportPositionDetails(sportByConference._id);
      }
  }, [sportByConference._id, sportsActions, values.sport.length])

  let finalSport = {
      name: sportByConference.name,
  };

  let finalUnits = units.map(u => u.unit_name)

  const handleCountryName = (value) => {
    values.homeCountry = value;
    conferenceActions.getStatesByCountryName(value);
  };

  const conferenceTypes = {
    Professional: "Professional",
    Collegiate: "Collegiate",
  };

    let conferenceByType =
        allConferenceByType &&
        allConferenceByType.reduce((obj, item) => {
            obj[item.name] = item.name
            return obj;
        }, {});

    const handleConferenceType = (value) => {
    values.conferenceType = value;
    conferenceActions.getConferenceByType(value);
  };

  const handleConference = (value) => {
    values.conference = value;
    values.sport = "";
    values.unit = "";
    values.positions = "";
    if (value !== null) {
      let conference = allConferenceByType && allConferenceByType.find(conference => conference.name === value);
      conferenceActions.getSportsByConferenceId(conference.id);
    } else {
      conferenceActions.resetSportsByConferenceId();
    }
  };

    const handleSportChange = (value) => {
        values.sport = value;
        values.unit = "";
        values.positions = "";
        if (value !== null) {
            sportsActions.getSportPositionDetails(sportByConference._id);
        } else {
            sportsActions.resetUnitsApi();
        }
    };

    const handleUnitChange = (value) => {
        values.unit = value;
        values.positions = "";
        if (value !== null) {
            sportsActions.getSportPositionDetails(sportByConference._id);
            if (sportPositions) {
                setFinalPosition(sportPositions
                    .filter(unit => unit.unit_name === value)
                    .map(position => position.position));
            }
        } else {
            sportsActions.resetSportsApi();
        }
    };

    const handlePositionChange = (value) => {
        values.positions = value;
    }

  const relationshipType = {
      Single: "Single",
      Married: "Married",
      Unknown: "Unknown"
  }

  const handleRelationshipStatus = (value) => {
      values.relationship = value;
  }

  const genderType = {
    "Men's Sport":  "Men's Sports",
    "Women's Sport": "Women's Sports"
  };

  const handleSelectedGender = (value) => {
    values.gender = value;
  };

  const raceTypes = {
    "American Indian/Alaska Native": "American Indian/Alaska Native",
    "Asian": "Asian",
    "Black/African American": "Black/African American",
    "Hispanic/Latino": "Hispanic/Latino",
    "Native Hawaiian/Pacific Islander": "Native Hawaiian/Pacific Islander",
    "White": "White",
    "Unknown/Prefer Not To Identify": "Unknown/Prefer Not To Identify"
  };

  const handleSelectedRaceEthnicity = (value) => {
    values.race = value;
  };

  const athleteType = {
    "Roster": "Roster",
    "Recruit": "Recruit"
  };

  const handleAthleteType = (value) => {
    values.athleteType = value;
  };

  return (
    <React.Fragment>
      <Typography variant="h5" className={classes.heading}>
        Let's get some background info
      </Typography>
      <div className={classes.form}>
        <Grid container spacing={3}>
          <Grid item lg={6} sm={12}>
            <Field
              name="homeCountry"
              required
              component={Autocomplete}
              options={Object.keys(countriesData)}
              renderInput={(props) => {
                return (
                  <MuiTextField
                    variant="outlined"
                    label="Home Country"
                    placeholder="Home Country"
                    error={touched["homeCountry"] && !!errors["homeCountry"]}
                    helperText={
                      <FormHelperText style={{ color: "red" }}>
                        {errors["homeCountry"]}
                      </FormHelperText>
                    }
                    required
                    {...props}
                  />
                );
              }}
              onChange={(e, value) => {
                handleCountryName(value);
              }}
              renderOption={(value, { inputValue }) => {
                const matches = match(value, inputValue);
                const parts = parse(value, matches);

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
            />
          </Grid>

          <Grid item lg={6} sm={12}>
            <Field
              name="homeState"
              component={Autocomplete}
              options={Object.keys(stateData) || {}}
              renderInput={(props) => {
                return (
                  <MuiTextField
                    variant="outlined"
                    label="Home State"
                    placeholder="Home State"
                    error={touched["homeState"] && !!errors["homeState"]}
                    helperText={errors["homeState"]}
                    {...props}
                  />
                );
              }}
              renderOption={(value, { inputValue }) => {
                const matches = match(value, inputValue);
                const parts = parse(value, matches);

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
            />
          </Grid>

          <Grid item sm={12}>
            <Field
              name="hometown"
              label="Hometown"
              placeholder="Hometown"
              component={TextField}
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item lg={6} sm={12}>
            <Field
                name="gender"
                required
                component={Autocomplete}
                options={Object.keys(genderType)}
                renderInput={(props) => {
                  return (
                      <MuiTextField
                          variant="outlined"
                          label="Gender"
                          placeholder="Gender"
                          error={
                              touched["gender"] && !!errors["gender"]
                          }
                          helperText={
                            <FormHelperText style={{ color: "red" }}>
                              {errors["gender"]}
                            </FormHelperText>
                          }
                          required
                          {...props}
                      />
                  );
                }}
                onChange={(e, value) => {
                  handleSelectedGender(value);
                }}
                renderOption={(value, { inputValue }) => {
                  const matches = match(value, inputValue);
                  const parts = parse(value, matches);

                  return (
                      <div>
                        {parts.map((part, index) => (
                            <span
                                key={index}
                                style={{ fontWeight: part.highlight ? 700 : 400 }}
                            >
                        {part.text}
                      </span>
                        ))}
                      </div>
                  );
                }}
            />
          </Grid>

          <Grid item lg={6} sm={12}>
            <Field
                name="race"
                required
                component={Autocomplete}
                options={Object.keys(raceTypes)}
                renderInput={(props) => {
                  return (
                      <MuiTextField
                          variant="outlined"
                          label="Race/Ethnicity"
                          placeholder="Race/Ethnicity"
                          error={
                              touched["race"] && !!errors["race"]
                          }
                          helperText={
                            <FormHelperText style={{ color: "red" }}>
                              {errors["race"]}
                            </FormHelperText>
                          }
                          required
                          {...props}
                      />
                  );
                }}
                onChange={(e, value) => {
                  handleSelectedRaceEthnicity(value);
                }}
                renderOption={(value, { inputValue }) => {
                  const matches = match(value, inputValue);
                  const parts = parse(value, matches);

                  return (
                      <div>
                        {parts.map((part, index) => (
                            <span
                                key={index}
                                style={{ fontWeight: part.highlight ? 700 : 400 }}
                            >
                        {part.text}
                      </span>
                        ))}
                      </div>
                  );
                }}
            />
          </Grid>

            <Grid item sm={12}>
                <Field
                    name="relationship"
                    component={Autocomplete}
                    options={Object.keys(relationshipType)}
                    renderInput={(props) => {
                        return (
                            <MuiTextField
                                variant="outlined"
                                label="Relationship Status"
                                placeholder="Relationship Status"
                                error={
                                    touched["relationship"] && !!errors["relationship"]
                                }
                                helperText={
                                    <FormHelperText style={{ color: "red" }}>
                                        {errors["relationship"]}
                                    </FormHelperText>
                                }
                                required
                                {...props}
                            />
                        );
                    }}
                    onChange={(e, value) => {
                        handleRelationshipStatus(value);
                    }}
                    renderOption={(value, { inputValue }) => {
                        const matches = match(value, inputValue);
                        const parts = parse(value, matches);

                        return (
                            <div>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                                    >
                        {part.text}
                      </span>
                                ))}
                            </div>
                        );
                    }}
                />
            </Grid>

          <Grid item lg={6} sm={12}>
            <Field
              name="conferenceType"
              required
              component={Autocomplete}
              options={Object.keys(conferenceTypes)}
              disabled={values.conferenceType.length > 0 && current_team}
              renderInput={(props) => {
                return (
                  <MuiTextField
                    variant="outlined"
                    label="League"
                    placeholder="League"
                    error={
                      touched["conferenceType"] && !!errors["conferenceType"]
                    }
                    helperText={
                      <FormHelperText style={{ color: "red" }}>
                        {errors["conferenceType"]}
                      </FormHelperText>
                    }
                    required
                    {...props}
                  />
                );
              }}
              onChange={(e, value) => {
                handleConferenceType(value);
              }}
              renderOption={(value, { inputValue }) => {
                const matches = match(value, inputValue);
                const parts = parse(value, matches);

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
            />
          </Grid>

            <Grid item lg={6} sm={12}>
                <Field
                    name="conference"
                    required
                    component={Autocomplete}
                    options={Object.keys(conferenceByType)}
                    disabled={values.conferenceType.length > 0 && current_team}
                    renderInput={(props) => {
                        return (
                            <MuiTextField
                                variant="outlined"
                                label="Conference"
                                placeholder="Conference"
                                error={touched["conference"] && !!errors["conference"]}
                                helperText={
                                    <FormHelperText style={{ color: "red" }}>
                                        {errors["conference"]}
                                    </FormHelperText>
                                }
                                required
                                {...props}
                            />
                        );
                    }}
                    onChange={(e, value) => {
                        handleConference(value);
                    }}
                    renderOption={(value, { inputValue }) => {
                        const matches = match(value, inputValue);
                        const parts = parse(value, matches);

                        return (
                            <div>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                                    >
                        {part.text}
                      </span>
                                ))}
                            </div>
                        );
                    }}
                />
            </Grid>

          <Grid item lg={6} sm={12}>
            <Field
              name="sport"
              required
              component={Autocomplete}
              options={Object.values(finalSport)}
              disabled={values.conferenceType.length > 0 && current_team}
              renderInput={(props) => {
                return (
                  <MuiTextField
                    variant="outlined"
                    label="Sport"
                    placeholder="Sport"
                    error={touched["sport"] && !!errors["sport"]}
                    helperText={
                      <FormHelperText style={{ color: "red" }}>
                        {errors["sport"]}
                      </FormHelperText>
                    }
                    required
                    {...props}
                  />
                );
              }}
              onChange={(e, value) => {
                handleSportChange(value);
              }}
              renderOption={(value, { inputValue }) => {
                const matches = match(value, inputValue);
                const parts = parse(value, matches);

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
            />
          </Grid>

          <Grid item lg={6} sm={12}>
            <Field
              name="unit"
              required
              component={Autocomplete}
              options={finalUnits}
              renderInput={(props) => {
                return (
                  <MuiTextField
                    variant="outlined"
                    label="Unit"
                    placeholder="Unit"
                    error={touched["unit"] && !!errors["unit"]}
                    helperText={
                      <FormHelperText style={{ color: "red" }}>
                        {errors["unit"]}
                      </FormHelperText>
                    }
                    required
                    {...props}
                  />
                );
              }}
              onChange={(e, value) => {
                handleUnitChange(value);
              }}
              renderOption={(value, { inputValue }) => {
                const matches = match(value, inputValue);
                const parts = parse(value, matches);

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
            />
          </Grid>

            <Grid item lg={6} sm={12}>
                <Field
                    name="position"
                    required
                    component={Autocomplete}
                    options={finalPosition}
                    renderInput={(props) => {
                        return (
                            <MuiTextField
                                variant="outlined"
                                label="Position"
                                placeholder="Position"
                                error={touched["position"] && !!errors["position"]}
                                helperText={
                                    <FormHelperText style={{ color: "red" }}>
                                        {errors["position"]}
                                    </FormHelperText>
                                }
                                required
                                {...props}
                            />
                        );
                    }}
                    onChange={(e, value) => {
                        handlePositionChange(value);
                    }}
                    renderOption={(value, { inputValue }) => {
                        const matches = match(value, inputValue);
                        const parts = parse(value, matches);

                        return (
                            <div>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                                    >
                        {part.text}
                      </span>
                                ))}
                            </div>
                        );
                    }}
                />
            </Grid>

          <Grid item lg={6} sm={12}>
            <Field
                name="athleteType"
                required
                component={Autocomplete}
                options={Object.keys(athleteType)}
                renderInput={(props) => {
                  return (
                      <MuiTextField
                          variant="outlined"
                          label="Athlete Type"
                          placeholder="Athlete Type"
                          error={
                              touched["athleteType"] && !!errors["athleteType"]
                          }
                          helperText={
                            <FormHelperText style={{ color: "red" }}>
                              {errors["athleteType"]}
                            </FormHelperText>
                          }
                          required
                          {...props}
                      />
                  );
                }}
                onChange={(e, value) => {
                  handleAthleteType(value);
                }}
                renderOption={(value, { inputValue }) => {
                  const matches = match(value, inputValue);
                  const parts = parse(value, matches);

                  return (
                      <div>
                        {parts.map((part, index) => (
                            <span
                                key={index}
                                style={{ fontWeight: part.highlight ? 700 : 400 }}
                            >
                        {part.text}
                      </span>
                        ))}
                      </div>
                  );
                }}
            />
          </Grid>

        </Grid>
      </div>
    </React.Fragment>
  );
};

let mapStateToProps = (state) => {
  return {
    countriesData: state.conference.allCountries,
    stateData: state.conference.allStateByCountry,
    sportPositions: state.sports.sportPositions,
    allConferenceByType: state.conference.allConferenceByType,
    sportByConference: state.conference.sportByConference,
    units: state.sports.unitsBySportId,
  };
};
export default connect(mapStateToProps)(CreatePlayerBackground);
