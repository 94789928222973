import React from "react";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Button from "@material-ui/core/Button";

const Message = (props) => {
  const { title, severity, content, show, buttonLabel, onClick } = props;

  const handleClick = () => {
    onClick();
  };

  if (!show) {
    return null;
  }

  return (
    <Alert
      severity={severity}
      style={{
        width: "100%",
        maxWidth: 700,
        marginLeft: "auto",
        marginRight: "auto",
      }}
      action={
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={handleClick}
          disableElevation
        >
          {buttonLabel}
        </Button>
      }
    >
      <AlertTitle>{title}</AlertTitle>
      {content}
    </Alert>
  );
};

export default Message;
