import React, {useContext, useEffect, useState} from "react";
import { connect } from "react-redux";
import { ActionContext } from "../../../contexts";
import ConferenceList from "./ConferenceList";
import TeamDetails from "./ConferenceList/TeamDetails";
import {Container, Modal} from "../../shared";
import ConferenceForm from "./ConferenceList/ConferenceForm";

function ConferenceMain(props) {
  let { selectedConferencePageId } = props;
  const [open, setOpen] = useState(false);
  let { conferenceActions } = useContext(ActionContext);

  useEffect(() => {
    conferenceActions.getConferenceList();
  }, []);

  const handleModalClick = () => {
    setOpen(prevState => !prevState);
  };

  return (
      <Container>
        <Modal open={open} onClose={handleModalClick}>
          <ConferenceForm setOpen={setOpen}/>
        </Modal>
        {
          selectedConferencePageId === "list" ?
              <ConferenceList setOpen={setOpen}/>
              :
              <TeamDetails setOpen={setOpen}/>
        }
      </Container>
  )
}

let mapStateToProps = (state) => {
  return {
    selectedConferencePageId: state.ui.conference.selectedConferencePageId,
  };
};

export default connect(mapStateToProps)(ConferenceMain);