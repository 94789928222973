import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { alpha, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import React, { useEffect, useState } from "react";
import Button from "../../../shared/Button";

let useStyles = makeStyles((theme) => ({
  root: {
    height: 56,
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: alpha(theme.palette.primary.light, 0.2),
    display: "flex",
    alignItems: "center",
    padding: "0px 24px",
    justifyContent: "space-between",
    marginLeft: -1,
    marginRight: -1,
  },
  text: {
    flex: 1,
  },
  checkbox: {
    color: theme.palette.primary.main,
  },
}));

const Approve = (props) => {
  let classes = useStyles();
  let {
    showDuplicateActions,
    onApprove,
    onReject,
    approvingCandidateProfile,
    rejectingCandidateProfile,
  } = props;

  let [copyArchive, setCopyArchive] = useState(false);
  let [copyAnalysis, setCopyAnalysis] = useState(false);
  let [copyReviews, setCopyReviews] = useState(false);

  let handleCopyArchive = (e) => setCopyArchive(e.target.checked);
  let handleCopyReviews = (e) => setCopyReviews(e.target.checked);
  let handleCopyAnalysis = (e) => setCopyAnalysis(e.target.checked);

  let handleApprove = () => {
    onApprove(copyArchive, copyReviews, copyAnalysis);
  };

  let handleReject = () => {
    onReject();
  };

  useEffect(() => {
    setCopyAnalysis(copyArchive && copyAnalysis);
    setCopyReviews(copyArchive && copyReviews);
  }, [copyArchive]);

  return (
    <div className={classes.root}>
      <div style={{ flex: 1 }}>
        {showDuplicateActions ? (
          <FormGroup row>
            <Tooltip title="Copy archives from original record">
              <FormControlLabel
                control={
                  <Checkbox
                    name="copyArchives"
                    color="primary"
                    className={classes.checkbox}
                    checked={copyArchive}
                    onChange={handleCopyArchive}
                    disabled={
                      approvingCandidateProfile || rejectingCandidateProfile
                    }
                  />
                }
                label="Copy Archives"
              />
            </Tooltip>

            <Tooltip title="Copy analysis from original record">
              <FormControlLabel
                control={
                  <Checkbox
                    name="copyAnalysis"
                    color="primary"
                    className={classes.checkbox}
                    checked={copyAnalysis}
                    onChange={handleCopyAnalysis}
                    disabled={
                      approvingCandidateProfile ||
                      rejectingCandidateProfile ||
                      Boolean(!copyArchive)
                    }
                  />
                }
                label="Copy Analysis"
              />
            </Tooltip>

            <Tooltip title="Copy reviews from original record">
              <FormControlLabel
                control={
                  <Checkbox
                    name="copyReviews"
                    color="primary"
                    className={classes.checkbox}
                    checked={copyReviews}
                    onChange={handleCopyReviews}
                    disabled={
                      approvingCandidateProfile ||
                      rejectingCandidateProfile ||
                      Boolean(!copyArchive)
                    }
                  />
                }
                label="Copy Reviews"
              />
            </Tooltip>
          </FormGroup>
        ) : null}
      </div>

      <div>
        <Button
          disableElevation
          color="primary"
          size="small"
          onClick={handleApprove}
          loading={approvingCandidateProfile}
          disabled={rejectingCandidateProfile}
        >
          Approve
        </Button>
      </div>
      <div style={{ width: 24 }} />
      <div>
        <Button
          disableElevation
          color="default"
          size="small"
          onClick={handleReject}
          loading={rejectingCandidateProfile}
          disabled={approvingCandidateProfile}
        >
          Reject
        </Button>
      </div>
    </div>
  );
};

export default Approve;
