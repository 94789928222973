import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ReportDialog from "./ReportDialog";
import { ActionContext } from "../../../../contexts";

const Reports = (props) => {
  const { playerId, status } = props;
  const { dialogActions } = useContext(ActionContext);

  const handleClick = () => {
    dialogActions.openDialog(
      <ReportDialog playerId={playerId} status={status} />,
      {
        maxWidth: "xs",
      }
    );
  };

  return (
    <div style={{ marginLeft: 8 }}>
      <Button
        variant="contained"
        color="default"
        startIcon={<AssessmentIcon />}
        onClick={handleClick}
        disableElevation
      >
        Reports
      </Button>
    </div>
  );
};

export default Reports;
