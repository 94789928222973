import React, { useContext, useState, useEffect } from "react";
import { connect } from "react-redux";
import { object, string } from "yup";
import { ActionContext } from "../../../contexts";
import { Step, Stepper } from "../../shared/Stepper";
import BackgroundStep from "./BackGroundStepper";
import BasicInfoStep from "./BasicInfoStep";
import NotesStep from "./NotesStep";
import SocialMediaStep from "./SocialMediaStep";
import runDashedLogo from "../../../assets/RunDashedIcon.svg";
import { Box, Grid, makeStyles } from "@material-ui/core";
import MuiStepper from "@material-ui/core/Stepper";
import MuiStep from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { GlobalCss } from "../../../config/GlobalCss";

let useStyles = makeStyles((theme) => ({
    stepperContainer: {
        background: 'none',
        padding: '0px',
        marginLeft: '10px'
    },
    stepperIcon: {
        color: theme.palette.text.disabled,
    },
    stepperIconActive: {
        color: theme.palette.text.primary,
    },
    stepper: {
        paddingTop: theme.spacing(5),
    },
}));

const CreatePlayerStepper = (props) => {
  let { accountState, submittingPlayer, teams, updateFilter } = props;
  let classes = useStyles();
  let [step, setStep] = useState(0);

  let defaultTeam =
    Object.keys(teams).length > 1 ? null : Object.keys(teams)[0];
  let { snackbarActions, playerActions, conferenceActions, sportsActions } =
    useContext(ActionContext);

  let sport = accountState.team !== "MM-DDI" ? accountState.sport : "";
  let conference = accountState.team !== "MM-DDI" ? accountState.conference : "";
  let conference_type = accountState.team !== "MM-DDI" ? accountState.conference_type : ""

  let getValue = (value) => (value && value.length > 0 ? value : "N/A");

  let handleFormSubmit = (values) => {
    let data = {
      first_name: values.firstName,
      last_name: values.lastName,
      full_name: values.firstName + " " + values.lastName,
      team: values.team,
      email: values.email,
      home_town: getValue(values.hometown),
      school: getValue(values.school),
      dob: values.birthday.format("L"),
      race: getValue(values.race),
      relationship_status: getValue(values.relationship),
      phone: getValue(values.whatsapp),
      twitter: getValue(values.twitter),
      twitter_source_ln: values.twitterSourceLn,
      instagram: getValue(values.instagram),
      instagram_source_ln: values.instagramSourceLn,
      linkedin: getValue(values.linkedin),
      linkedin_source_ln: values.linkedinSourceLn,
      youtube: getValue(values.youtube),
      youtube_source_ln: values.youtubeSourceLn,
      print_news: getValue(values.printNews),
      print_news_source_ln: values.printNewsSourceLn,
      notes: values.notes,
      sport: values.sport,
      home_country: values.homeCountry,
      home_state: values.homeState,
      gender: values.gender,
      athlete_type: values.athleteType,
      position: values.positions,
    };

    let success = () => {
      onSuccessApi();
      playerActions.individualAthleteFormSubmitted();
      snackbarActions.showSnackbar("Player added successfully");
    };
    playerActions.createPlayer(data, success);
  };

  const onSuccessApi = () => {
    let model = {
      conference: updateFilter.conference ? [updateFilter.conference] : [],
      sport: updateFilter.sport ? [updateFilter.sport] : [],
      unit: updateFilter.unit ? [updateFilter.unit] : [],
      position: updateFilter.position ? [updateFilter.position] : [],
      athleteType:
        updateFilter.athleteType === "All" ? [] : [updateFilter.athleteType],
      conferenceType:
        updateFilter.conferenceType === "All"
          ? []
          : [updateFilter.conferenceType],
      team: updateFilter.team ? [updateFilter.team] : [],
    };

    playerActions.getAllFiltersCandidates(model);
  };

  useEffect(() => {
    playerActions.getTeamList();
    conferenceActions.getCountries();
    sportsActions.getSportsList();
  }, []);

  const steps = [1, 2, 3, 4];

  const validateMonth = (value) => {
    const selectedDate = new Date(value);
    const selectedMonth = selectedDate.getMonth() + 1;
    return selectedMonth >= 1 && selectedMonth <= 12;
  };

  return (
    <>
      <Box sx={{ maxWidth: 750 }}>
        <GlobalCss />
        <MuiStepper
          activeStep={step}
          orientation="horizontal"
          className={classes.stepperContainer}
        >
          { steps.map((step) => (
            <MuiStep key={step.label}>
              <StepLabel
                  StepIconProps={{
                      classes: {
                          root: classes.stepperIcon,
                          active: classes.stepperIconActive,
                          completed: classes.stepperIconActive,
                      },
                  }}
              >
              </StepLabel>
            </MuiStep>
          ))}
        </MuiStepper>
      </Box>
      <Grid style={{ display: "flex", margin: "0rem 2rem" }}>
        <Grid item xs={7}>
          <Stepper
            className={classes.stepper}
            step={step}
            setStep={setStep}
            initialValues={{
              notes: "",
              firstName: "",
              lastName: "",
              team: defaultTeam,
              email: "",
              birthday: null,
              hometown: "",
              school: "",
              race: "",
              relationship: "",
              whatsApp: "",
              twitter: "",
              twitterSourceLn: "en",
              instagram: "",
              instagramSourceLn: "en",
              linkedin: "",
              linkedinSourceLn: "en",
              youtube: "",
              youtubeSourceLn: "en",
              printNews: "",
              printNewsSourceLn: "en",
              sport: sport,
              conferenceType: conference_type,
              unit: "",
              homeCountry: "",
              homeState: "",
              gender: "",
              conference: conference,
              athleteType: "",
              positions: "",
            }}
            onSubmit={handleFormSubmit}
            loading={submittingPlayer}
          >
            <Step
              validationSchema={object().shape({
                firstName: string()
                  .trim()
                  .matches(
                    /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
                    "Enter valid First name"
                  )
                  .required("Enter athlete first name"),
                lastName: string()
                  .trim()
                  .matches(
                    /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
                    "Enter valid Last name"
                  )
                  .required("Enter athlete last name"),
                team: string().nullable().required("Select team"),
                email: string().email("Enter a valid email"),
                birthday: string()
                  .nullable()
                  .required("Select birthday")
                  .test("valid-month", "Invalid month", (value) =>
                    validateMonth(new Date(value))
                  ),
              })}
              render={(props) => <BasicInfoStep {...props} />}
            />

            <Step
              validationSchema={object().shape({
                homeCountry: string()
                  .nullable()
                  .required("Select Home Country"),
                homeState: string().nullable().required("Select Home state"),
                race: string().nullable().required("Select Race/Ethnicity"),
                gender: string().nullable().required("Select Gender"),
                conference: string().nullable().required("Select Conference"),
                sport: string().nullable().required("Select Sport"),
                unit: string().nullable().required("Select Unit"),
                conferenceType: string().nullable().required("Select League"),
                positions: string().nullable().required("Select Positions"),
                athleteType: string()
                  .nullable()
                  .required("Select Athlete Type"),
              })}
              render={(props) => <BackgroundStep {...props} />}
            />

            <Step
              validationSchema={object().shape(
                {
                  twitter: string().when("instagram", {
                    is: (b) => !b,
                    then: string().required("Enter at least one"),
                  }),
                  instagram: string().when("twitter", {
                    is: (a) => !a,
                    then: string().required("Enter at least one"),
                  }),
                },
                ["twitter", "instagram"]
              )}
              render={(props) => <SocialMediaStep {...props} />}
            />
            <Step render={(props) => <NotesStep {...props} />} />
          </Stepper>
        </Grid>
        <Grid item xs={5} style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img style={{ width: "70%" }} src={runDashedLogo} alt="logo" />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

let mapStateToProps = (state) => {
  return {
    submittingPlayer: state.ui.player.submittingPlayer,
    teams: state.ui.player.candidateTeams,
    updateFilter: state.player.updateFilter,
    accountState: state.account.accountData,
  };
};

export default connect(mapStateToProps)(CreatePlayerStepper);
