import React from "react";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/RateReviewOutlined";
import Editor from "./Editor";
import { Article } from "../Article";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import {accountTypes} from "../../../store/constants";

let useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    padding: theme.spacing(2),
    borderRadius: 8,
  },
  editorContainer: {
    width: "100%",
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
  },
  editControl: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  closeIcon: {
    color: theme.colors.primaryNavy,
    fontSize: 'inherit',
  },
}));

let EditorArticle = (props) => {
  let classes = useStyles();

  let {
    notes,
    playerId,
    isEditable = false,
    isDelete, id,
    onEdit = (f) => f,
    onDelete = (f) => f,
    currentData = [],
    accountType,
    ...rest
  } = props;

  let handleEditClick = () => {
    onEdit();
  };
  let handleDelete = (id) => {
    return () => onDelete(id);
  };
 
  return (
    <Article>
      <div className={classes.container}>
        <div className={classes.editorContainer}>
          <Editor
            classes={{ editor: classes.editor }}
            readOnly={true}
            toolbarHidden
            {...rest}
          />
        </div>
        <div className={classes.iconContainer}>
          {isEditable ? (
              <div className={classes.editControl}>
                <IconButton onClick={handleEditClick} edge="end">
                  <EditIcon className={classes.closeIcon}/>
                </IconButton>
              </div>
          ) : null}
          {
            (accountType === accountTypes.superAdmin || accountType === accountTypes.mmPsychologist || accountType === accountTypes.mmChiefPsychologist) ?
                (isDelete ? (
                    <div className={classes.editControl}>
                      <IconButton edge="end" onClick={handleDelete(id)}>
                        <DeleteIcon className={classes.closeIcon}/>
                      </IconButton>
                    </div>
                ) : null) : ""}
        </div>
      </div>
    </Article>
  );
};

export default EditorArticle;
