import React, { useState } from "react";
import ExpansionArticle from "../ExpansionArticle";
import ChartAndDetails from "../ChartAndDetails";
import { accountTypes, chartColors } from "../../../../store/constants";
import { connect } from "react-redux";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { RadialGauge } from "modules/shared/Charts";
import { round } from "utils";
import {ChildTab, MainTab} from "../../../shared/Tabs";

let useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
    },
    container: {
        flexGrow: 1,
        display: "flex",
        margin: "0px",
        backgroundColor: theme.palette.common.white,
        boxShadow: "3px 3px 10px 3px #cdcdcd",
        borderRadius: 8,
    },
    sideBarTitle: {
        fontFamily: theme.fontFamily.robotoCondensed,
        fontSize: '1rem',
        fontWeight: 700,
        color: theme.palette.text.primary,
        textTransform: 'capitalize',
    },
    selectedSideBarTitle: {
        fontFamily: theme.fontFamily.robotoCondensed,
        fontSize: '1rem',
        fontWeight: 700,
        textTransform: 'capitalize',
        color: theme.palette.primary.main,
        backgroundColor: theme.colors.veryLightGrey,
    },
    tabPanel: {
        backgroundColor: theme.palette.common.white,
        width: '85%',
        padding: '2rem 1rem',
        borderRadius: "0px 8px 8px 0px",
    },
    content: {
        backgroundColor: theme.colors.veryLightGrey,
        width: '90%',
        alignSelf: 'center',
        marginBottom: '1rem',
        borderRadius: 5,
    },
    gauges: {
        marginBottom: 48,
        width: "100%",
        display: "flex",
        justifyContent: "space-evenly",
    },
    gaugeContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: 150,
    },
    subTraits: {
        fontFamily: theme.fontFamily.robotoCondensed,
        fontSize: 16,
    },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

let roundTo1PrecisionPoint = (value) => {
  return round(value, 0);
};

const Attributes = (props) => {
  let classes = useStyles();
  let { customTraits, accountType } = props;
  const [selectedPage, setSelectedPage] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedPage(newValue);
  };

  let handleColors = (score) => {
    if (0 <= score && score < 25) {
      return chartColors.green;
    } else if (25 <= score && score < 50) {
      return chartColors.yellow;
    } else if (50 <= score && score < 75) {
      return chartColors.orange;
    } else if (75 <= score && score < 100) {
      return chartColors.red;
    }
  };

  return (
      <Box className={classes.container}>
          <MainTab
              orientation="vertical"
              variant="scrollable"
              value={selectedPage}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              style={{ width: 250 }}
          >
              <ChildTab
                  className={selectedPage === 0 ? classes.selectedSideBarTitle : classes.sideBarTitle}
                  label="Character"
                  {...a11yProps(0)}
              />
              <ChildTab
                  className={selectedPage === 1 ? classes.selectedSideBarTitle : classes.sideBarTitle}
                  label="Relatability"
                  {...a11yProps(1)}
              />
              <ChildTab
                  className={selectedPage === 2 ? classes.selectedSideBarTitle : classes.sideBarTitle}
                  label="Composure"
                  {...a11yProps(2)}
              />
              <ChildTab
                  className={selectedPage === 3 ? classes.selectedSideBarTitle : classes.sideBarTitle}
                  label="Leadership"
                  {...a11yProps(3)}
              />
          </MainTab>
          <TabPanel
              value={selectedPage}
              index={0}
              className={classes.tabPanel}
          >
              <div>
                  <ExpansionArticle
                      title={customTraits["character"]["label"]}
                      details={customTraits["character"]["description"]}
                  >
                      <div className={classes.content}>
                          <ChartAndDetails
                              diagnosis={customTraits["character"]["text"]}
                              score={customTraits["character"]["score"]}
                              handleColors={handleColors}
                          />
                          <div className={classes.gauges}>
                              {Object.values(customTraits["character"].sub).map(
                                  (subTrait, index) => {
                                      return (
                                          <div key={index} className={classes.gaugeContainer}>
                                              <RadialGauge score={roundTo1PrecisionPoint(subTrait["score"])}/>
                                              {accountType === accountTypes.superAdmin ||
                                              accountType === accountTypes.mmPsychologist ||
                                              accountType === accountTypes.mmChiefPsychologist ? (
                                                  <Typography className={classes.subTraits}>
                                                      {roundTo1PrecisionPoint(subTrait["score"])}%
                                                  </Typography>
                                              ) : (
                                                  ""
                                              )}
                                              <Typography className={classes.subTraits}>
                                                  {`+ ${subTrait["left_label"]}`}
                                              </Typography>
                                          </div>
                                      );
                                  }
                              )}
                          </div>
                      </div>
                  </ExpansionArticle>
              </div>
          </TabPanel>
          <TabPanel
              value={selectedPage}
              index={2}
              className={classes.tabPanel}
          >
              <div>
                  <ExpansionArticle
                      title="Composure"
                      details={customTraits["composure"]["description"]}
                  >
                      <div className={classes.content}>
                          <ChartAndDetails
                              diagnosis={customTraits["composure"]["text"]}
                              score={customTraits["composure"]["score"]}
                              handleColors={handleColors}
                          />
                          <div className={classes.gauges}>
                              {Object.values(customTraits["composure"].sub).map(
                                  (subTrait, index) => {
                                      return (
                                          <div key={index} className={classes.gaugeContainer}>
                                              <RadialGauge score={roundTo1PrecisionPoint(subTrait["score"])}/>
                                              {accountType === accountTypes.superAdmin ||
                                              accountType === accountTypes.mmPsychologist ||
                                              accountType === accountTypes.mmChiefPsychologist ? (
                                                  <Typography className={classes.subTraits}>
                                                      {roundTo1PrecisionPoint(subTrait["score"])}%
                                                  </Typography>
                                              ) : (
                                                  ""
                                              )}
                                              <Typography className={classes.subTraits}>
                                                  {`+ ${subTrait["left_label"]}`}
                                              </Typography>
                                          </div>
                                      );
                                  }
                              )}
                          </div>
                      </div>
                  </ExpansionArticle>
              </div>
          </TabPanel>
          <TabPanel
              value={selectedPage}
              index={3}
              className={classes.tabPanel}
          >
              <div>
                  <ExpansionArticle
                      title={customTraits["leadership"]["label"]}
                      details={customTraits["leadership"]["description"]}
                  >
                      <div className={classes.content}>
                          <ChartAndDetails
                              diagnosis={customTraits["leadership"]["text"]}
                              details="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
                              score={customTraits["leadership"]["score"]}
                              handleColors={handleColors}
                          />
                          <div className={classes.gauges}>
                              {Object.values(customTraits["leadership"].sub).map(
                                  (subTrait, index) => {
                                      return (
                                          <div key={index} className={classes.gaugeContainer}>
                                              <RadialGauge score={roundTo1PrecisionPoint(subTrait["score"])}/>
                                              {accountType === accountTypes.superAdmin ||
                                              accountType === accountTypes.mmPsychologist ||
                                              accountType === accountTypes.mmChiefPsychologist ? (
                                                  <Typography className={classes.subTraits}>
                                                      {roundTo1PrecisionPoint(subTrait["score"])}%
                                                  </Typography>
                                              ) : (
                                                  ""
                                              )}
                                              <Typography className={classes.subTraits}>
                                                  {`+ ${subTrait["left_label"]}`}
                                              </Typography>
                                          </div>
                                      );
                                  }
                              )}
                          </div>
                      </div>
                  </ExpansionArticle>
              </div>
          </TabPanel>
          <TabPanel
              value={selectedPage}
              index={1}
              className={classes.tabPanel}
          >
              <div>
                  <ExpansionArticle
                      title={customTraits["relatability"]["label"]}
                      details={customTraits["relatability"]["description"]}
                  >
                      <div className={classes.content}>
                          <ChartAndDetails
                              diagnosis={customTraits["relatability"]["text"]}
                              score={customTraits["relatability"]["score"]}
                              handleColors={handleColors}
                          />
                          <div className={classes.gauges}>
                              {Object.values(customTraits["relatability"].sub).map(
                                  (subTrait, index) => {
                                      return (
                                          <div key={index} className={classes.gaugeContainer}>
                                              <RadialGauge score={roundTo1PrecisionPoint(subTrait["score"])}/>
                                              {accountType === accountTypes.superAdmin ||
                                              accountType === accountTypes.mmPsychologist ||
                                              accountType === accountTypes.mmChiefPsychologist ? (
                                                  <Typography className={classes.subTraits}>
                                                      {roundTo1PrecisionPoint(subTrait["score"])}%
                                                  </Typography>
                                              ) : (
                                                  ""
                                              )}
                                              <Typography className={classes.subTraits}>
                                                  {`+ ${subTrait["left_label"]}`}
                                              </Typography>
                                          </div>
                                      );
                                  }
                              )}
                          </div>
                      </div>
                  </ExpansionArticle>
              </div>
          </TabPanel>
      </Box>
  );
};

let mapStateToProps = (state, ownProps) => {
    let {coachId} = ownProps;

    return {
        customTraits: state.coach.customTraits[coachId],
        team: state.account.team,
        accountType: state.account.accountType,
    };
};

export default connect(mapStateToProps)(Attributes);
