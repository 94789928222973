import FormControl from "@material-ui/core/FormControl";
import {FormHelperText, Grid, InputAdornment, InputLabel, TextField,  makeStyles, MenuItem, Typography} from "@material-ui/core";
import AtIcon from "@material-ui/icons/AlternateEmail";
import { Field, Form, Formik } from "formik";
import { Select } from "formik-material-ui";
import React from "react";
import { object, string } from "yup";
import Button from "../../shared/Button";
import {GlobalCss} from "../../../config/GlobalCss";

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 500,
        fontSize: theme.fontSize.md,
        fontFamily: theme.fontFamily.prometo,
        color: theme.colors.primaryNavy,
        textTransform: 'uppercase',
    },
    inputInput: {
        paddingLeft: 10,
        borderRadius: 5,
        color: theme.palette.text.disabled,
        backgroundColor: theme.colors.veryLightGrey,
        fontFamily: theme.fontFamily.robotoCondensed,
        fontWeight: 500,
        border: 'none',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    },
    btnPrimary: {
        color: theme.palette.common.white,
        padding: '10px',
        backgroundColor: theme.colors.primaryNavy,
        width: '10rem',
        borderRadius: '7px',
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            color: theme.colors.primaryNavy,
            border: `1px solid ${theme.colors.primaryNavy}`,
        },
        textTransform: 'uppercase',
    },
    btnSecondary: {
        color: theme.palette.secondary.main,
        border: '1px solid',
        width: '10rem',
        padding: '10px',
        marginRight: '10px',
        borderRadius: '7px',
        backgroundColor: theme.palette.common.white,
        '&:hover': {
            background: theme.palette.secondary.main,
            color: theme.palette.common.white,
        },
        textTransform: 'uppercase',
    },
}))

const Query = ({ onSubmit, searching }) => {
    const classes = useStyles();

  return (
    <Formik
      initialValues={{
        name: "",
        city: "",
        country: "",
        sport: "",
        twitter: "",
        instagram: "",
        keywords: "",
      }}
      validationSchema={object().shape({
        name: string().required("Enter Athlete Name"),
        city: string(),
        country: string(),
        sport: string(),
        twitter: string(),
        instagram: string(),
        keywords: string(),
      })}
      onSubmit={onSubmit}
    >
      {(formik) => {
        let { errors, touched } = formik;

        return (
          <Form>
            <Grid container direction="column" spacing={2}>
                <GlobalCss />
              <Grid item>
                <Typography className={classes.title} style={{ paddingTop: 15 }}>Basic Info</Typography>
              </Grid>
              <Grid item>
                <Field
                  name="name"
                  label="Athlete Name"
                  placeholder="Athlete Name"
                  component={TextField}
                  disabled={searching}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item>
                <Field
                  name="city"
                  label="City"
                  placeholder="City"
                  component={TextField}
                  disabled={searching}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item>
                <Field
                  name="country"
                  label="Country"
                  placeholder="Country"
                  component={TextField}
                  disabled={searching}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={touched["sport"] && !!errors["sport"]}
                >
                  <InputLabel htmlFor="sport">Sport</InputLabel>
                  <Field
                    component={Select}
                    name="sport"
                    label="Sport"
                    placeholder="Sport"
                    labelId="sport"
                    disabled={searching}
                    inputProps={{
                      id: "sport",
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="en">Basketball</MenuItem>
                    <MenuItem value="es">Football</MenuItem>
                    <MenuItem value="fr">Hockey</MenuItem>
                  </Field>
                  {touched["sport"] && !!errors["sport"] ? (
                    <FormHelperText>{errors["sport"]}</FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>

              <Grid item>
                <Typography className={classes.title}>Known Aliases</Typography>
              </Grid>
              <Grid item>
                <Field
                  name="twitter"
                  label="Twitter handle"
                  placeholder="Twitter handle"
                  component={TextField}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AtIcon style={{ color: 'rgb(10, 45, 96, 0.6)' }} />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Field
                  name="instagram"
                  label="Instagram handle"
                  placeholder="Instagram handle"
                  component={TextField}
                  variant="outlined"
                  disabled={searching}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AtIcon style={{ color: 'rgb(10, 45, 96, 0.6)' }} />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Typography className={classes.title}>Additional Info</Typography>
              </Grid>
              <Grid item>
                <Field
                  name="keywords"
                  label="Keywords"
                  placeholder="Keywords"
                  component={TextField}
                  disabled={searching}
                  variant="outlined"
                  fullWidth
                />
                <FormHelperText style={{ color: 'rgb(10, 45, 96, 0.6)'}}>
                  Enter comma seperated values. Eg: Nashville, Captain
                </FormHelperText>
              </Grid>
              <Grid item container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    type="button"
                    className={classes.btnSecondary}
                    onClick={() => window.location.reload()}
                  >
                    Cancel Search
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    className={classes.btnPrimary}
                    loading={searching}
                    color='white'
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Query;
