import React, { useContext, useEffect } from "react";
import Button from "../../../shared/Button";
import Alert from "@material-ui/lab/Alert";
import { ActionContext } from "../../../../contexts";
import { object, string } from "yup";
import { connect } from "react-redux";
import { Field, Formik, Form } from "formik";
import { TextField, Select } from "formik-material-ui";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import {GlobalCss} from "../../../../config/GlobalCss";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import {Title} from "../../../shared";

let useStyles = makeStyles((theme) => ({
  form: {
    paddingTop: theme.spacing(2),
    maxWidth: 800,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  content: {
    display: "flex",
    justifyContent: "end",
    margin: '1rem 0rem'
  },
  btnPrimary: {
    color: theme.palette.common.white,
    padding: '10px',
    backgroundColor: theme.colors.primaryNavy,
    width: '13rem',
    borderRadius: '7px',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.colors.primaryNavy,
      border: `1px solid ${theme.colors.primaryNavy}`,
    },
    textTransform: 'uppercase',
  },
  btnSecondary: {
    color: theme.palette.secondary.main,
    border: '1px solid',
    width: '13rem',
    padding: '10px',
    marginRight: '10px',
    borderRadius: '7px',
    '&:hover': {
      background: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    textTransform: 'uppercase',
  },
}));

const ConferenceForm = (props) => {
  let classes = useStyles();
  let { formType, loadingSportList, conferencesData, error, selectedConference, setOpen, sportsData, loadingConference } = props;
  let { conferenceActions, sportsActions } = useContext(ActionContext);
  const type = formType === 'create';
  const allNames = conferencesData && conferencesData.map(v => v.name)

  let proCodeOptions = [
    {
      "key": "Professional",
      "value": "Professional"
    },
    {
      "key": "Collegiate",
      "value": "Collegiate"
    }
  ];

  let handleFormSubmit = (values) => {
    let data = {
      name: values.name,
      procode: values.procode,
      sport_id: values.sportId,
    };

    type ? conferenceActions.createConference(data) : conferenceActions.updateConference(selectedConference._id, data);
    setOpen(false)
  };

  let handleErrorClose = () => {
    conferenceActions.resetConferenceCreationError();
  };

  let uniqueNameValidation = (values) =>  {
    let error
    if (allNames.includes(values.name)) {
      error = 'Enter new name';
    }
    return error;
  };

  useEffect(() => {
    conferenceActions.resetConferenceCreationError();
    sportsActions.getSportsList();
  }, []);

  useEffect(() => {
    if (error && error.length > 0) {
      setTimeout(() => {
        handleErrorClose();
      }, 3500);
    }
  }, [error]);

  const renderError = (error) => {
    if (typeof error === 'string') {
      return error;
    } else if (Array.isArray(error)) {
      return error.join(', ');
    } else if (typeof error === 'object' && error !== null) {
      return JSON.stringify(error);
    }
    return '';
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
      <Grid container spacing={1} >
        <Grid item xs={12}>
          <div style={{margin: '0rem 3rem'}}>
            {
              loadingSportList && loadingConference ? (
                  <Box style={{display: 'flex', justifyContent: 'center'}}>
                    <CircularProgress size={72} thickness={3} color='primary' />
                  </Box>
              ) : (
                  <Formik
                      initialValues={{
                        name: selectedConference.name || '',
                        procode: selectedConference.procode || '',
                        sportId: selectedConference.sport_id || '',
                      }}
                      validationSchema={object().shape({
                        name: string()
                            .trim()
                            .matches(
                                /^[a-zA-Z0-9]+(([a-zA-Z0-9 -])?[a-zA-Z0-9]*)*$/,
                                "Enter a valid conference name, no special characters allowed."
                            )
                            .required("Enter conference name"),
                        procode: string().required("Select Conference Type"),
                        sportId: string().nullable().required('Select a sport'),
                      })}
                      onSubmit={handleFormSubmit}
                  >
                    {(formik) => {
                      let { errors, touched } = formik;

                      return (
                          <Form autoComplete="off">
                            {
                              type ? (
                                  <Title title='Create Conference' />
                              )  : (
                                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Title title='Update' text={`${selectedConference.name}`} />
                                  </div>
                              )
                            }
                            <div className={classes.form}>
                              <Grid container spacing={3}>
                                {error ? (
                                    <Grid item>
                                      <Alert severity="error" onClose={handleErrorClose}>
                                        {error}
                                      </Alert>
                                    </Grid>
                                ) : null}
                                <Grid item xs={12}>
                                  <GlobalCss />
                                  <Field
                                      name="name"
                                      label="Enter Name"
                                      placeholder="Enter Name"
                                      component={TextField}
                                      variant="outlined"
                                      fullWidth
                                      autoFocus
                                      validate={uniqueNameValidation}
                                  />
                                  <FormControl variant="outlined" style={{width: '100%', marginTop: '1rem'}}>
                                    <InputLabel htmlFor="procodeLabel">
                                      Conference Type
                                    </InputLabel>
                                    <Field
                                        component={Select}
                                        name="procode"
                                        label="Conference Type"
                                        placeholder="Conference Type"
                                        labelId="procodeLabel"
                                        inputProps={{
                                          id: "procode",
                                        }}
                                    >
                                      <MenuItem
                                          key={1}
                                          value={selectedConference.procode}
                                      >
                                        {selectedConference.key}
                                      </MenuItem>
                                      {
                                        proCodeOptions.map((list, index) => {
                                            return (
                                                <MenuItem
                                                    key={index}
                                                    value={list.value}
                                                >
                                                  {list.key}
                                                </MenuItem>
                                            );
                                          })}
                                    </Field>
                                    {touched["procode"] && !!errors["procode"] ? (
                                        <FormHelperText>{renderError(errors["procode"])}</FormHelperText>
                                    ) : null}
                                  </FormControl>
                                  <FormControl variant="outlined" style={{width: '100%', marginTop: '1rem'}}>
                                    <InputLabel htmlFor='unitId-lable'>
                                      Sport Name
                                    </InputLabel>
                                    <Field
                                        component={Select}
                                        name='sportId'
                                        label="Sport Name"
                                        placeholder='Sport Name'
                                        inputProps={{
                                          id: "sportId"
                                        }}
                                        required
                                        fullWidth
                                        // disabled={!type}
                                    >
                                      <MenuItem
                                          key={11}
                                          value={selectedConference.sport_id}
                                      >
                                        {selectedConference.sport_name}
                                      </MenuItem>
                                      {sportsData &&
                                          sportsData.map((list) => (
                                              <MenuItem key={list._id} value={list._id}>
                                                {list.name}
                                              </MenuItem>
                                          ))}
                                    </Field>
                                    {touched["sportId"] && !!errors["sportId"] && (
                                        <FormHelperText>{renderError(errors["sportId"])}</FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                              </Grid>
                              <div className={clsx(classes.content)}>
                                <Button
                                    className={classes.btnSecondary}
                                    type="button"
                                    variant="text"
                                    onClick={handleClose}
                                >
                                  Cancel
                                </Button>
                                <Button
                                    className={classes.btnPrimary}
                                    type="submit"
                                >
                                  {type ? 'Create' : ' Update'}
                                </Button>
                              </div>
                            </div>
                          </Form>
                      );
                    }}
                  </Formik>
              )
            }
          </div>
        </Grid>
      </Grid>
  );
};

let mapStateToProps = (state) => {
  return {
    loadingSportList: state.ui.sports.sportsListLoading,
    error: state.ui.conference.createConferenceReducer.error,
    formType: state.ui.sports.selectedFormState,
    sportsData: state.sports.sportsListData,
    conferencesData: state.conference.allConferenceList,
    selectedConference: state.conference.selectedConference,
    loadingConference: state.ui.conference.loadingSelectedConference,
  };
};

export default connect(mapStateToProps)(ConferenceForm);
