import React, { useContext, useState } from "react";
import { ActionContext } from "../../../../contexts";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { EditorDialog } from "../../../shared/Editor";
import { convertToRaw } from "draft-js";

let useStyles = makeStyles((theme) => ({
  editor: {
    padding: theme.spacing(0, 1),
  },
}));

let SummeryDialog = (props) => {
  let classes = useStyles();
  let { closeDialog, initialEditorState, playerId, categoryId, openEdit, id , currentData} = props;
  let { playerActions } = useContext(ActionContext);

  let [editorState, setEditorState] = useState(initialEditorState);

  let savingContent = useSelector(
    (state) =>
      state.ui.player.addingExecutiveSummeryContent[`${playerId}.${categoryId}`]
  );

  let handleEditorStateChange = (state) => {
    setEditorState(state);
  };

  let handleSave = () => {
    let data = {
      candidate_id: playerId,
      category: openEdit ? "Custom" : categoryId,
      content: convertToRaw(editorState.getCurrentContent()),
      _id: openEdit ? id : ''
    };

    const remainingData = currentData && currentData.filter(v => v._id !== id)
    playerActions.addExecutiveSummeryContent(data, closeDialog, remainingData);
  };

  return (
    <EditorDialog
      title="Psych Summary"
      classes={{ editor: classes.editor }}
      onClose={closeDialog}
      editorState={editorState}
      onEditorStateChange={handleEditorStateChange}
      onSave={handleSave}
      loading={savingContent}
      placeholder="Begin typing..."
    />
  );
};

export default SummeryDialog;
