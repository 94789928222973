import { Box, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Tabs } from "../../../shared/Tabs";
import { chartColors } from "../../../../store/constants";
import ChartAndDetails from "../ChartAndDetails";
import ExpansionArticle from "../ExpansionArticle";
import LeftTabs from "@material-ui/core/Tabs";
import LeftTab from "@material-ui/core/Tab";
import { MainTab, ChildTab } from "../../../shared/Tabs";
import ComingSoonImg from "../../CommingSoonImg";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: '100%'
  },
  container: {
    flexGrow: 1,
    display: "flex",
    margin: "0px",
    backgroundColor: theme.palette.common.white,
    boxShadow: "3px 3px 10px 3px #cdcdcd",
    borderRadius: 8,
  },
  sideBarTitle: {
    fontFamily: theme.fontFamily.robotoCondensed,
    fontSize: '1rem',
    fontWeight: 700,
    color: theme.palette.text.primary,
    textTransform: 'capitalize',
  },
  selectedSideBarTitle: {
    fontFamily: theme.fontFamily.robotoCondensed,
    fontSize: '1rem',
    fontWeight: 700,
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
    backgroundColor: theme.colors.veryLightGrey,
    minWidth: 150,
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
  tabPanel: {
    backgroundColor: theme.palette.common.white,
    width: '85%',
    paddingBottom: '2rem',
  },
  overviewContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    margin: "0rem 2rem",
    width: "auto",
  },
  content: {
    backgroundColor: theme.colors.veryLightGrey,
    width: '90%',
    alignSelf: 'center',
    marginBottom: '1rem',
    borderRadius: 5,
  },
  risk: {
    display: "flex",
    justifyContent: "center",
    margin: "0rem 2rem",
    width: "auto",
  },
  brain: {
    fontFamily: theme.fontFamily.prometo,
    fontSize: 15,
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0rem 2rem",
    width: "auto",
    height: "20vh",
    color: theme.palette.primary.main,
  },
}));

let ExecutiveSummary = (props) => {
  let classes = useStyles();
  let [selectedIndex, setSelectedIndex] = useState(0);
  let {
    traits,
    basicInfo,
    behaviourRisk,
  } = props;

  const allTraits = [
    "openness",
    "conscientiousness",
    "extraversion",
    "agreeableness",
    "emotional_range",
  ];

  const subValue = [];

  allTraits.forEach((x) => {
    if (Object.keys(traits).includes(x)) {
      subValue.push(traits[x]?.value);
    }
  });

  let sum = 0;

  for (let i = 0; i < subValue.length; i += 1) {
    sum += subValue[i];
  }

  const customTraitLabels = [
    "agreeableness",
    "conscientiousness",
    "emotional range",
    "extraversion",
    "openness",
  ];

  let customTrait = Object.values(traits)[selectedIndex];

  const getDescription = (key) => {
    if (key === "Agreeableness") {
      return "Agreeableness refers to an individual's desire to get along with and please others. People high in agreeableness put other people's needs before their own, and have a preference for social harmony. Individuals low in agreeableness may exhibit more traits of competitiveness and may push for individual gain over team gain.";
    } else if (key === "Conscientiousness") {
      return "Conscientiousness involves self-control, a strong work ethic, and a desire for tidiness or organization. Highly conscientious individuals are planners and carry out their assigned responsibilities purposefully. They can be strong willed and determined. Low conscientious individuals might struggle to execute their assigned duties, have lower self-control, and my be disorganized. Conscientiousness is associated with academic and occupational achievement.";
    } else if (key === "Emotional Range") {
      return "Emotional range refers to how an individual experiences emotions when under stress or faced with adversity. Individuals low in emotional range tend to experience less negative emotions under stressful situations and have higher stress tolerance and capacity for problem solving. Those higher in emotional range tend to be more reactive, experience more negative feelings, and have more difficulty coping.";
    } else if (key === "Extraversion") {
      return "Extraversion examines the degree to which a person feels energized or uplifted when interacting with others. It reflects sociability, a tendency to be outwardly positive, friendly, and active, and seek out others. Extraverts are sociable and enjoy interacting with others, assertive, and like to keep busy. Introverts are not the opposite of extraverts, but lack the strong characteristics of extraverts. Introverts are not cold or unfriendly, but are more reserved and may value being alone to help recharge.";
    } else if (key === "Openness") {
      return "Openness refers to an individual's general willingness to engage in new experiences and learn new things. People high in Openness have an active imagination and are willing to entertain novel ideas. Those low in Openness tend to be more conventional and practical.";
    }
  };

  let handleTabChange = (e, index) => {
    setSelectedIndex(index);
  };

  let handleColors = (score) => {
    if (0 <= score && score < 30) {
      return chartColors.red;
    } else if (30 <= score && score < 70) {
      return chartColors.orange;
    } else if (70 <= score && score < 100) {
      return chartColors.green;
    }
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [selectedRiskPage, setSelectedRiskPage] = useState(0);

  const handleChangeRiskPage = (event, newValue) => {
    setSelectedRiskPage(newValue);
  };
  const communicationLabels = [
    "Your Athlete",
    "About Dominant Styles",
    "About Influential Styles",
    "About Steady Styles",
    "About Compliant Styles",
  ];

  const riskFactors = [
    "Risk Seeking",
    "Civil Discourse",
    "Threat",
    "Hate Speech",
    "Self-Indulgence",
  ];

  return (
      <div className={classes.root}>
        <Box className={classes.container}>
          <LeftTabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              TabIndicatorProps={{ className: classes.indicator }}
          >
            <LeftTab
                className={value === 0 ? classes.selectedSideBarTitle : classes.sideBarTitle}
                label="Personality"
                {...a11yProps(0)}
            />

            <LeftTab
                className={value === 1 ? classes.selectedSideBarTitle : classes.sideBarTitle}
                label="Behaviour"
                {...a11yProps(1)}
            />
            <LeftTab
                className={value === 2 ? classes.selectedSideBarTitle : classes.sideBarTitle}
                label="Communication"
                {...a11yProps(2)}
            />
          </LeftTabs>
          <TabPanel
              value={value}
              index={0}
              className={classes.tabPanel}
          >
            <div className={classes.overviewContainer}>
              <Tabs
                  labels={customTraitLabels}
                  value={selectedIndex}
                  onChange={handleTabChange}
              />
              <ExpansionArticle
                  title={customTrait.label}
                  details={getDescription(customTrait.label)}
              >
                <div className={classes.content}>
                  <ChartAndDetails
                      diagnosis={customTrait["text"]}
                      score={customTrait["score"]}
                      handleColors={handleColors}
                  />
                </div>
              </ExpansionArticle>
            </div>
          </TabPanel>
          <TabPanel
              value={value}
              index={1}
              className={classes.tabPanel}
          >
            {basicInfo && basicInfo.brain === "Receptiviti" ? (
                <>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <MainTab
                        value={selectedRiskPage}
                        onChange={handleChangeRiskPage}
                    >
                      {riskFactors &&
                          riskFactors.map((list, index) => (
                              <ChildTab
                                  key={index}
                                  label={list}
                              />
                          ))}
                    </MainTab>
                  </div>
                  <TabPanel value={selectedRiskPage} index={0}>
                    <div className={classes.risk}>
                      <ExpansionArticle
                          title="Risk Seeking"
                          details="The degree to which a person is focused on engaging in risky behaviors or activities."
                      >
                        <ChartAndDetails
                            score={behaviourRisk["risk_seeking"]["score"]}
                            handleColors={handleColors}
                            tabName="Risk"
                        />
                      </ExpansionArticle>
                    </div>
                  </TabPanel>
                  <TabPanel value={selectedRiskPage} index={1}>
                    <div className={classes.risk}>
                      <ExpansionArticle
                          title="Civil Discourse"
                          details="Provides the probability that a text sample contains any content considered undesirable in civil discourse. In short, this measures the individual's willingness to engage in conversations deemed 'controversial'. This may include, but is not limited to threats, hate speech, offensive language, obscenities, bullying, direct insults, insensitive content, descriptions of violence, or overt sexual content. It is important to note this measures the degree to which an individual is engaging in the conversation. It does not indicate the manner (e.g. – supportive vs. offensive) in which an individual engages in the conversation."
                      >
                        <ChartAndDetails
                            score={behaviourRisk["civil_discourse"]["score"]}
                            handleColors={handleColors}
                            tabName="Risk"
                        />
                      </ExpansionArticle>
                    </div>
                  </TabPanel>
                  <TabPanel value={selectedRiskPage} index={2}>
                    <div className={classes.risk}>
                      <ExpansionArticle
                          title="Threat"
                          details="Provides the probability that a text sample contains a direct threat of any kind. This threat may be directed to the reader or recipient, a third party who isn’t reading the message, or to a type of person in general."
                      >
                        <ChartAndDetails
                            score={behaviourRisk["threat"]["score"]}
                            handleColors={handleColors}
                            tabName="Risk"
                        />
                      </ExpansionArticle>
                    </div>
                  </TabPanel>{" "}
                  <TabPanel value={selectedRiskPage} index={3}>
                    <div className={classes.risk}>
                      <ExpansionArticle
                          title="Hate Speech"
                          details="Provides the probability that a text sample contains an instance of hatred, anger, or disgust regarding any demographic group. Demographic groups are commonly differentiated by race, ethnicity, gender, sexual orientation, or disability. Expressions of racism or sexism are considered hate speech, while insults directed at a person or people with no mention of their race, gender, or other demographic markers are not hate speech."
                      >
                        <ChartAndDetails
                            score={behaviourRisk["hate_speech"]["score"]}
                            handleColors={handleColors}
                            tabName="Risk"
                        />
                      </ExpansionArticle>
                    </div>
                  </TabPanel>{" "}
                  <TabPanel value={selectedRiskPage} index={4}>
                    <div className={classes.risk}>
                      <ExpansionArticle
                          title="Self-Indulgence"
                          details="To prioritize physical pleasures, including (but not limited to) food, alcohol, drugs, and sex."
                      >
                        <ChartAndDetails
                            score={behaviourRisk["self_indulgence"]["score"]}
                            handleColors={handleColors}
                            tabName="Risk"
                        />
                      </ExpansionArticle>
                    </div>
                  </TabPanel>
                </>
            ) : (
                <span className={classes.brain}>
                This report is outdated. To see enhanced features, please
                contact your Mental Metrix representative.
              </span>
            )}
          </TabPanel>
          <TabPanel
              value={value}
              index={2}
              className={classes.tabPanel}
          >
            <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  margin: "0rem 2rem",
                  width: "auto",
                }}
            >
              <Tabs
                  labels={communicationLabels}
                  value={selectedIndex}
                  onChange={handleTabChange}
              />
              <div style={{width: '100%'}}>
                <ComingSoonImg />
              </div>
            </div>
          </TabPanel>
        </Box>
      </div>
  );
};

let mapStateToProps = (state, ownProps) => {
  let {coachId} = ownProps;

  return {
    riskCategories: state.coach.risk["categories"][coachId],
    customTraits: state.coach.customTraits[coachId],
    stats: state.coach.stats[coachId],
    traits: state.coach.traits[coachId],
    basicInfo: state.coach.basic[coachId],
    team: state.account.team,
    behaviourRisk: state.coach.behaviourRisk[coachId],
  };
};

export default connect(mapStateToProps)(ExecutiveSummary);
