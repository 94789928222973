import React, { useContext, useEffect } from "react";
import StatCard from "./StatCard";
import { connect } from "react-redux";
import { ActionContext } from "../../../contexts";
import { Grid, TableContainer, makeStyles , Slide, InputBase } from "@material-ui/core";
import CircularProgress from "modules/shared/CircularProgress";
import PlayerDetails from "modules/athlet-management/components/PlayerDetails";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {accountTypes} from "../../../store/constants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="left"
      ref={ref}
      timeout={{ enter: 1000, exit: 1000 }}
      {...props}
    />
  );
});

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: '100vh',
    zIndex: theme.zIndex.appBar,
    backgroundColor: theme.colors.veryLightGrey
  },
  statusAlignCss: {
    border: `1px solid ${theme.colors.slate}`,
    padding: '0.2rem 0.6rem',
    fontSize: '12px',
    fontWeight: '500',
    borderRadius: '9px',
  },
  viewAllButton: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '1rem',
    fontSize: '13px',
    fontFamily: theme.fontFamily.robotoCondensed,
    color: theme.palette.text.primary,
    fontWeight: 700,
    cursor: 'pointer',
    textTransform: 'uppercase',
  },
  fullName: {
    '&:hover': {
      color: theme.palette.text.secondary,
      borderBottom: `1px solid ${theme.palette.text.secondary}`,
      cursor: 'pointer'
    },
  },
  rowDataAlignment: {
    display: 'block',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  centerAlignWidth: {
    marginBottom: '2rem',
    cursor: 'pointer'
  },
  flexAlignDataFlow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    padding: '1rem 0px',
  },
  flexAlignDataFlowTitle: {
    fontSize: theme.fontSize.md,
    fontFamily: theme.fontFamily.prometo,
    fontWeight: 700,
    color: theme.palette.text.primary,
    textTransform: 'uppercase'
  },
  rowAlignment: {
    border: `solid 1px ${theme.colors.ghostWhite}`,
    borderRadius: '9px',
    backgroundColor: theme.palette.common.white,
    marginBottom: '0.5rem',
    display: 'flex',
  },
  flexAlignData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  container: {
    padding: 16,
    width: '100%',
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  gridFlowAlign: {
    padding: '1.5rem',
    backgroundColor: theme.colors.veryLightGrey,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
  inputRoot: {
    color: 'inherit',
  },
  dataTableAlignment: {
    width: '100%',
    padding: '0.8rem 0.4rem',
    display: 'block',
    fontSize: '12px',
    justifyContent: 'start',
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontFamily: theme.fontFamily.robotoCondensed,
  },
  headerDataTableAlignment: {
    width: '100%',
    padding: '0.8rem 0.4rem',
    display: 'block',
    fontWeight: 700,
    justifyContent: 'start',
  },
  inputInput: {
    padding: '8px 15px',
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    paddingLeft: '10px',
    maxWidth: 330 ?? '100%',
    borderRadius: 5,
    color: theme.palette.text.disabled,
    backgroundColor: theme.colors.veryLightGrey,
    fontFamily: theme.fontFamily.robotoCondensed,
    marginRight: '1rem',
    fontWeight: 500,
    border: 'none',
  },
  SummaryFlex: {
    display: '-webkit-inline-box',
    cursor: 'pointer',
  },
  primaryColor: {
    color: theme.palette.text.primary,
  },
  tableContainer: {
    padding: ' 0px 1rem',
    backgroundColor: theme.palette.common.white,
    borderRadius: 8,
  },
  sportOptions: {
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.white}`,
    padding: '20px',
    fontSize: 15,
  },
}));

const Dashboard = (props) => {
  let classes = useStyles();
  let { accountType, stats, loadingStats, liteDetailsLoader, selectedId, liteDetails, sportsData, updateFilter } = props;

  let { playerActions, accountActions, dashboardActions, conferenceActions, sportsActions, dialogActions } = useContext(ActionContext);

  function handleSearchText(v) {
    dashboardActions.selectDashboardFilter("searchText", v);
  }

  function handleSportChange(event) {
    dashboardActions.selectDashboardFilter("searchSport", event.target.value);
  }

  function handleViewAllChange() {
    dashboardActions.selectDashboardFilter("searchText", undefined);
    dashboardActions.selectDashboardFilter("searchSport", undefined);
  }

  const handleRedirect = (playerId) => {
    playerActions.getPlayer(playerId);
    playerActions.selectPlayer(playerId);

    dialogActions.openDialog(<PlayerDetails playerId={playerId} fromTeam={false} />, {
      fullScreen: true,
      TransitionComponent: Transition,
    });
  }

  useEffect(() => {
    dashboardActions.getStats();
    playerActions.getTeamList();
    accountActions.getUserTypes();
    accountActions.getNotifications();
    conferenceActions.getConferenceList();
    conferenceActions.getCountries();
    sportsActions.getSportsList();
    dashboardActions.selectDummyData();
    return () => {
      dashboardActions.selectCard(null);
    };
  }, []);

  const playerFilter = selectedId === "ProReports" ? (liteDetails && liteDetails) : (liteDetails && liteDetails)
  const bcaPlayers = selectedId === "status" ? (liteDetails && liteDetails) : (liteDetails && liteDetails.filter(v => v.status === selectedId))


  const filteredSearchData = updateFilter?.searchText ? playerFilter?.filter(v => v.full_name.toLowerCase().includes(updateFilter.searchText.toLowerCase())) : playerFilter && playerFilter
  const filteredData = updateFilter?.searchSport ? filteredSearchData?.filter(v => v.sport?.toLowerCase().includes(updateFilter.searchSport.toLowerCase())) : filteredSearchData && filteredSearchData

  const filteredBCASearchData = updateFilter?.searchText ? bcaPlayers?.filter(v => v.full_name.toLowerCase().includes(updateFilter.searchText.toLowerCase())) : bcaPlayers && bcaPlayers
  const filteredBCAData = updateFilter?.searchSport ? filteredBCASearchData?.filter(v => v.sport?.toLowerCase().includes(updateFilter.searchSport.toLowerCase())) : filteredBCASearchData && filteredBCASearchData

  const [orderBy, setOrderBy] = React.useState("full_name");
  const [order, setOrder] = React.useState('asc');

  const handleRequestSort = (event, property, type) => {
    setOrder(type);
    setOrderBy(property);
  };


  function alphabetically(ascending) {
    return function (a, b) {

      // equal items sort equally
      if (a[orderBy] === b[orderBy]) {
        return 0;
      }
      // nulls sort after anything else
      else if (a[orderBy] === null || a[orderBy] === "") {
        return 1;
      }
      else if (b[orderBy] === null || b[orderBy] === "") {
        return -1;
      }
      // otherwise, if we're ascending, lowest sorts first
      else if (ascending) {
        return a[orderBy].toLowerCase() < b[orderBy].toLowerCase() ? -1 : 1;
      }
      // if descending, highest sorts first
      else {
        return a[orderBy].toLowerCase() < b[orderBy].toLowerCase() ? 1 : -1;
      }
    };
  }

  const upDown = order === "desc" ?? true

  const filteredAthletes = filteredBCAData && filteredBCAData.sort(alphabetically(upDown));
  const filteredAthletesData = filteredData && filteredData.sort(alphabetically(upDown));

  const getSorting = (propertyName) => {
    return (
      <>
        {
          orderBy === propertyName && order === 'asc' ?
            <span style={{ cursor: 'pointer' }} onClick={(ev) => handleRequestSort(ev, propertyName, 'desc')}><ArrowUpwardIcon style={{ fontSize: '1rem', color: orderBy === propertyName && order === 'asc' ? "#002839" : "" }} /></span>
            :
            <span style={{ cursor: 'pointer' }} onClick={(ev) => handleRequestSort(ev, propertyName, 'asc')}><ArrowDownwardIcon style={{ fontSize: '1rem', color: orderBy === propertyName && order === 'desc' ? "#002839" : "" }} /></span>
        }
      </>
    )
  }

  return (
    <div className={classes.root} >
      <div className={classes.container}>
        <div className={classes.gridFlowAlign}>
          {
            (accountType === accountTypes.superAdmin || accountType === accountTypes.mmDdiAdmin || accountType === accountTypes.mmPsychologist || accountType === accountTypes.mmChiefPsychologist) ?
              <Grid container spacing={1} className={classes.centerAlignWidth}>
                <Grid item lg={3} md={6} sm={12}>
                  <StatCard
                    title="Total Athletes"
                    stat={stats.all}
                    loading={loadingStats}
                    statusName="status"
                  />
                </Grid>
                <Grid item lg={3} md={6} sm={12} >
                  <StatCard
                    title="Completed"
                    stat={stats.completed}
                    loading={loadingStats}
                    statusName="Completed"
                  />
                </Grid>
                <Grid item lg={3} md={6} sm={12} >
                  <StatCard
                    title="Consent Pending"
                    stat={stats.consentPending}
                    loading={loadingStats}
                    statusName="Consent Pending"
                  />
                </Grid>
                <Grid item lg={3} md={6} sm={12}>
                  <StatCard
                    title="Psych Review Pending"
                    stat={stats.reviewPending}
                    loading={loadingStats}
                    statusName="Psych Review Pending"
                  />
                </Grid>
                <Grid item lg={3} md={6} sm={12}>
                  <StatCard
                    title="Psych Review"
                    stat={stats.psychReview}
                    loading={loadingStats}
                    statusName="Psych Review"
                  />
                </Grid>
                <Grid item lg={3} md={8} sm={12} >
                  <StatCard
                    title="Approval Pending"
                    stat={stats.approvalPending}
                    loading={loadingStats}
                    statusName="Approval Pending"
                  />
                </Grid>
                <Grid item lg={3} md={8} sm={12} >
                  <StatCard
                    title="Base Report Completed"
                    stat={stats.baseReportCompleted}
                    loading={loadingStats}
                    statusName="Base Report Completed"
                  />
                </Grid>
                <Grid item lg={3} md={8} sm={12} >
                  <StatCard
                    title="Processing"
                    stat={stats.processing}
                    loading={loadingStats}
                    statusName="Processing"
                  />
                </Grid>
                <Grid item lg={3} md={8} sm={12}>
                  <StatCard
                    title="Error"
                    stat={stats.error}
                    loading={loadingStats}
                    statusName="Error"
                  />
                </Grid>
                <Grid item lg={3} md={8} sm={12}  >
                  <StatCard
                    title="Rejected"
                    stat={stats.rejected}
                    loading={loadingStats}
                    statusName="Rejected"
                  />
                </Grid>
              </Grid>
              :
              (accountType === accountTypes.colAthleticDirector) ?
                <Grid container spacing={1} className={classes.centerAlignWidth}>
                  <Grid item lg={3} md={6} sm={12} >
                    <StatCard
                      title="Total Reports"
                      stat={stats.all}
                      loading={loadingStats}
                      statusName="status"
                    />
                  </Grid>
                  <Grid item lg={3} md={6} sm={12}>
                    <StatCard
                      title="Team Dashboard"
                      stat={stats.allTeams}
                      loading={loadingStats}
                      statusName="Team Dashboard"
                      sport={sportsData}
                    />
                  </Grid>
                </Grid>
                :
                <Grid container spacing={1} className={classes.centerAlignWidth}>
                  <Grid item lg={3} md={6} sm={12} >
                    <StatCard
                      title="Total Reports"
                      stat={stats.all}
                      loading={loadingStats}
                      statusName="status"
                    />
                  </Grid>
                  <Grid item lg={3} md={6} sm={12}>
                    <StatCard
                      title="Remaining Reports"
                      stat={stats.remainingReports}
                      loading={loadingStats}
                      statusName="RemainingReports"
                    />
                  </Grid>
                  <Grid item lg={3} md={6} sm={12} >
                    <StatCard
                      style={{ backgroundColor: selectedId === "ProReports" ? '#f00' : '#f00' }}
                      title="Pro Reports Requested"
                      stat={stats.proReports}
                      loading={loadingStats}
                      statusName="Pro Reports"
                    />
                  </Grid>
                </Grid>
          }
          <div className={classes.tableContainer}>
            {
              selectedId !== null ?
                <Grid>
                  {liteDetailsLoader ?
                    <div style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>
                      <CircularProgress fontSize={30}/>
                    </div>
                    :
                    <div>
                      <div className={classes.flexAlignDataFlow}>
                        <div className={classes.flexAlignData}>
                          {
                            (accountType === accountTypes.superAdmin || accountType === accountTypes.mmDdiAdmin || accountType === accountTypes.mmPsychologist) ?
                              <div>
                                <div className={classes.flexAlignDataFlowTitle}>{selectedId === "status" ? "Total Athletes" : selectedId}</div>
                              </div>
                              :
                              <div>
                                <div className={classes.flexAlignDataFlowTitle}>{selectedId === "status" ? "Total Reports" : selectedId}</div>
                              </div>
                          }
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div onClick={handleViewAllChange} className={classes.viewAllButton}>View All</div>
                          <InputBase
                            placeholder="Search Player Name"
                            value={updateFilter?.searchText !== undefined ? updateFilter?.searchText : ""}
                            name="searchText"
                            classes={{
                              root: classes.inputRoot,
                              input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={ev => handleSearchText(ev.target.value)}
                          />
                          {
                            (accountType === accountTypes.proGeneralManager || accountType === accountTypes.proHeadCoach || accountType === accountTypes.proPositionCoach || accountType === accountTypes.proScouts || accountType === accountTypes.proSecurity || accountType === accountTypes.proPsychologist) ?
                                ""
                                :
                                <select
                                    className={classes.inputInput}
                                    style={{ marginRight: 0}}
                                    value={updateFilter?.searchSport !== undefined ? updateFilter?.searchSport : ""}
                                    onChange={handleSportChange}
                                    name="sport"
                                >
                                  <option value="" disabled selected hidden >Sport</option>
                                  {
                                      sportsData && sportsData.map(list =>
                                          <option className={classes.sportOptions} value={list.name}
                                                  key={list.name}>{list.name}</option>
                                      )
                                  }
                                </select>
                          }
                        </div>
                      </div>

                      <TableContainer>
                        <div>
                          <div>
                            <div className={classes.flexAlignData}>
                              <span className={classes.headerDataTableAlignment}>
                                <div className={classes.FlexAlignFlow}>
                                  <div className="profile-pic">
                                    <div className={classes.SummaryFlex}>Name
                                      <div className={orderBy === "full_name" ? "" : "edit"}>
                                        <span className={classes.flexAlignData}>{getSorting("full_name")}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div></span>
                              <span className={classes.headerDataTableAlignment}>
                                <div className={classes.FlexAlignFlow}>
                                  <div className="profile-pic">
                                    <div className={classes.SummaryFlex}>Team
                                      <div className={orderBy === "team" ? "" : "edit"} >
                                        <span className={classes.flexAlignData}>{getSorting("team")}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div></span>
                              <span className={classes.headerDataTableAlignment}>
                                <div className={classes.FlexAlignFlow}>
                                  <div className="profile-pic">
                                    <div className={classes.SummaryFlex}>Sport
                                      <div className={orderBy === "sport" ? "" : "edit"} >
                                        <span className={classes.flexAlignData}>{getSorting("sport")}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div></span>
                              <span className={classes.headerDataTableAlignment}>
                                <div className={classes.FlexAlignFlow}>
                                  <div className="profile-pic">
                                    <div className={classes.SummaryFlex}>Status
                                      <div className={orderBy === "status" ? "" : "edit"} >
                                        <span className={classes.flexAlignData}>{getSorting("status")}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div></span>
                            </div>
                          </div>
                          {
                            (accountType === accountTypes.superAdmin || accountType === accountTypes.mmDdiAdmin || accountType === accountTypes.mmPsychologist) ?
                              <div className={classes.rowDataAlignment}>
                                {
                                  filteredAthletes && filteredAthletes.map((result) => (
                                    <div className={classes.rowAlignment}>
                                      <span className={classes.dataTableAlignment} onClick={() => handleRedirect(result.id)}><span className={classes.fullName}>{result.full_name}</span></span>
                                      <span className={classes.dataTableAlignment}>{result.team}</span>
                                      <span className={classes.dataTableAlignment}>{result.sport}</span>
                                      <span className={classes.dataTableAlignment}><span className={classes.statusAlignCss}
                                        style={{
                                          background: result.status === 'Completed' ? '#e0f5ea' : result.status === 'Approval Pending' ? '#cfe3f2' : result.status === 'Rejected' ? '#EBE2E9' : result.status === 'Processing' ? '#fbf3d9' : result.status === 'Psych Review Pending' ? '#f2f2f2' : result.status === 'Consent Pending' ? '#cccccc' : result.status === 'Error' ? '#fddfdf' : result.status === 'Base Report Completed' ? '#BDBDBE' : '',
                                          border: result.status === 'Completed' ? '1px solid #e0f5ea' : result.status === 'Approval Pending' ? '1px solid #cfe3f2' : result.status === 'Rejected' ? '1px solid #EBE2E9' : result.status === 'Processing' ? '#fbf3d9' : result.status === 'Psych Review Pending' ? '1px solid #f2f2f2' : result.status === 'Consent Pending' ? '1px solid #cccccc' : result.status === 'Error' ? '1px solid #fddfdf' : result.status === 'Base Report Completed' ? '1px solid #BDBDBE' : '',
                                          color: result.status === 'Completed' ? '#00ae4d' : result.status === 'Approval Pending' ? '#1075bf' : result.status === 'Rejected' ? '#BC5353' : result.status === 'Processing' ? '#e7b618' : result.status === 'Psych Review Pending' ? '#666666' : result.status === 'Consent Pending' ? '#000000' : result.status === 'Error' ? '#f33a3a' : result.status === 'Base Report Completed' ? '#1A73E8' : ''
                                        }}>{result.status}</span></span>

                                    </div>
                                  ))
                                }
                              </div>
                              :
                              <div className={classes.rowDataAlignment}>
                                {
                                  filteredAthletesData && filteredAthletesData.map((result) => (
                                    <div className={classes.rowAlignment}>
                                      <span className={classes.dataTableAlignment} onClick={() => handleRedirect(result.id)}><span className={classes.fullName}>{result.full_name}</span></span>
                                      <span className={classes.dataTableAlignment}>{result.team}</span>
                                      <span className={classes.dataTableAlignment}>{result.sport}</span>
                                      <span className={classes.dataTableAlignment}><span className={classes.statusAlignCss}
                                        style={{
                                          background: result.status === 'Completed' ? '#e0f5ea' : result.status === 'Approval Pending' ? '#cfe3f2' : result.status === 'Rejected' ? '#EBE2E9' : result.status === 'Processing' ? '#fbf3d9' : result.status === 'Psych Review Pending' ? '#f2f2f2' : result.status === 'Consent Pending' ? '#cccccc' : result.status === 'Error' ? '#fddfdf' : result.status === 'Base Report Completed' ? '#BDBDBE' : '',
                                          border: result.status === 'Completed' ? '1px solid #e0f5ea' : result.status === 'Approval Pending' ? '1px solid #cfe3f2' : result.status === 'Rejected' ? '1px solid #EBE2E9' : result.status === 'Processing' ? '#fbf3d9' : result.status === 'Psych Review Pending' ? '1px solid #f2f2f2' : result.status === 'Consent Pending' ? '1px solid #cccccc' : result.status === 'Error' ? '1px solid #fddfdf' : result.status === 'Base Report Completed' ? '#BDBDBE' : '',
                                          color: result.status === 'Completed' ? '#00ae4d' : result.status === 'Approval Pending' ? '#1075bf' : result.status === 'Rejected' ? '#BC5353' : result.status === 'Processing' ? '#e7b618' : result.status === 'Psych Review Pending' ? '#666666' : result.status === 'Consent Pending' ? '#000000' : result.status === 'Error' ? '#f33a3a' : result.status === 'Base Report Completed' ? '#1A73E8' : ''
                                        }}>{result.status}</span></span>

                                    </div>
                                  ))
                                }
                              </div>
                          }
                        </div>
                        {
                          (accountType === accountTypes.superAdmin || accountType === accountTypes.mmDdiAdmin || accountType === accountTypes.mmPsychologist) ?
                            (
                              filteredAthletes && filteredAthletes.length === 0 ?
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', color: '#e61e21' }}>
                                  {"No players found"}
                                </div>
                                : ""
                            )
                            :
                            (
                              filteredAthletesData && filteredAthletesData.length === 0 ?
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', color: '#e61e21' }}>
                                  {"No players found"}
                                </div>
                                : ""
                            )
                        }
                      </TableContainer>
                    </div>
                  }
                </Grid>
                :
                ""
            }
          </div>
        </div>
      </div >
    </div >
  );
};

let mapStateToProps = (state) => {
  return {
    accountType: state.account.accountType,
    isDrawerOpen: state.ui.app.isDrawerOpen,
    stats: state.dashboard.stats,
    loadingStats: state.ui.dashboard.loadingStats,
    liteDetailsLoader: state.ui.dashboard.liteDetailsLoader,
    selectedId: state.ui.dashboard.selectedCardId,
    liteDetails: state.dashboard.liteCandidateReducer,
    sportsData: state.sports.sportsListData,
    updateFilter: state.dashboard.updateDashboardFilter,
    staticPlayData: state.dashboard.staticPlayData,

  };
};

export default connect(mapStateToProps)(Dashboard);
