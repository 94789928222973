import React from "react";
import MuiTextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { Field } from "formik";
import { KeyboardDatePicker } from "formik-material-ui-pickers";
import { Autocomplete } from "formik-material-ui-lab";
import { TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/core/styles";

let useStyles = makeStyles((theme) => ({
  heading: {
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    maxWidth: 480,
    fontWeight: 500,
    lineHeight: "60px",
  },
  form: {
    paddingTop: theme.spacing(1),
    maxWidth: 600,
  },
}));

let CreatePlayerBasicInfo = (props) => {
  let { errors, touched, teams } = props;
  let classes = useStyles();

  return (
    <React.Fragment>
      <Typography variant="h4" className={classes.heading}>
        Let's start with basics of the athlete
      </Typography>
      <div className={classes.form}>
        <Grid container spacing={3}>
          <Grid item lg={6} sm={12}>
            <Field
              name="firstName"
              label="First Name"
              placeholder="First Name"
              component={TextField}
              variant="outlined"
              required
              fullWidth
              autoFocus
            />
          </Grid>

          <Grid item lg={6} sm={12}>
            <Field
              name="lastName"
              label="Last Name"
              placeholder="Last Name"
              component={TextField}
              variant="outlined"
              required
              fullWidth
              autoFocus
            />
          </Grid>

          <Grid item sm={12}>
            <Field
              name="email"
              label="Email"
              placeholder="Email"
              component={TextField}
              variant="outlined"
              required
              fullWidth
            />
          </Grid>

          <Grid item lg={6} sm={12}>
            <Field
              name="team"
              component={Autocomplete}
              options={Object.keys(teams)}
              renderInput={(props) => {
                return (
                  <MuiTextField
                    variant="outlined"
                    label="Select Team"
                    placeholder="Team"
                    error={touched["team"] && !!errors["team"]}
                    helperText={errors["team"]}
                    required
                    {...props}
                  />
                );
              }}
              renderOption={(value, { inputValue }) => {
                const matches = match(value, inputValue);
                const parts = parse(value, matches);

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
            />
          </Grid>

          <Grid item lg={6} sm={12}>
            <Field
              label="Birthday"
              placeholder="MM / DD / YYYY"
              name="birthday"
              component={KeyboardDatePicker}
              views={["year", "month", "date"]}
              inputVariant="outlined"
              InputAdornmentProps={{ position: "end" }}
              openTo="year"
              format="MM/DD/yyyy"
              orientation="portrait"
              variant="inline"
              invalidDateMessage="Enter a valid date"
              required
              disableFuture
              fullWidth
              autoOk
            />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

let mapStateToProps = (state) => {
  return {
    teams: state.ui.player.candidateTeams,
  };
};

export default connect(mapStateToProps)(CreatePlayerBasicInfo);
