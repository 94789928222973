import React, { useContext } from "react";
import List from "@material-ui/core/List";
import LinkItem from "./LinkItem";
import { ActionContext } from "../../../../contexts";
import { connect } from "react-redux";

const LinkList = (props) => {
  const {  coachId, links  } = props;
  const { coachActions } = useContext(ActionContext);

  const handleLinkClick = (url) => {
    window.open(url, "_blank");
  };

  const handleLinkDelete = (link_id) => {
    const data = {
      coach_id: coachId,
      link_id,
    };
    coachActions.deleteCoachLink(data);
  };

  return (
    <List>
      {links.map((link, index) => {
        return (
          <LinkItem
            key={index}
            link={link}
            onClick={handleLinkClick}
            onDelete={handleLinkDelete}
            coachId={coachId}
          />
        );
      })}
    </List>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { coachId } = ownProps;

  return {
    links: state.coach.links[coachId] || [],
  };
};

export default connect(mapStateToProps)(LinkList);
