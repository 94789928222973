const Step = (props) => {
  let { children, formik, render = false, ...rest } = props;

  if (render) {
    return render({ ...formik, ...rest });
  }

  return <>{children}</>;
};

export default Step;
