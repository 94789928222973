import React from "react";
import MuiTabs from "@material-ui/core/Tabs";
import MuiTab from "@material-ui/core/Tab";
import getConfig from "../../config";
import { statusTypes, accountTypes } from "../../../../store/constants";
import { useSelector } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const Tab = withStyles((theme) => ({
  root: {
    textTransform: "capitalize",
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(12),
    paddingLeft: 5,
    paddingRight: 5,
    minWidth: 100,
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => {
  return <MuiTab {...props} />;
});

const Tabs = withStyles((theme) => ({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    width: 3,
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: theme.palette.secondary.main,
    },
  },
}))((props) => (
  <MuiTabs {...props} TabIndicatorProps={{ children: <span /> }} />
));

let useStyles = makeStyles(() => ({
  tab: {
    width: 100,
  },
}));

export default function Nav({ onSelect, selectedIndex, status }) {
  const classes = useStyles();
  const accountType = useSelector((state) => state.account.accountType);
  const { pages } = getConfig(accountType, status);

  const hasAccess = (accountType, status) => {
    if (status === statusTypes.rejected) {
      return false;
    } else if (
      accountType === accountTypes.mmDdiAdmin ||
      accountType === accountTypes.mmPsychologist ||
      accountType === accountTypes.superAdmin
    ) {
      return !(status === statusTypes.approvalPending ||
          status === statusTypes.processing);


    } else if (
      accountType === accountTypes.orgAdmin ||
      accountType === accountTypes.orgPsychologist ||
      accountType === accountTypes.orgPsychologist
    ) {
      return status === statusTypes.completed;

    }
  };

  const isDisabled = (alwaysAllow, accountType, status) => {
    return () => {
      if (alwaysAllow) {
        return false;
      }

      const access = hasAccess(accountType, status);

      return !access;
    };
  };

  return (
    <Tabs
      value={selectedIndex}
      onChange={onSelect}
      orientation="vertical"
      textColor="primary"
      variant="scrollable"
      scrollButtons="on"
    >
      {Object.values(pages).map((page, index) => {
        const Icon = page.navIcon;
        const label = page.navName;
        const alwaysAllow = page.alwaysAllow;

        return (
          <Tab
            disabled={isDisabled(alwaysAllow, accountType, status)()}
            key={index}
            label={label}
            icon={<Icon />}
            classes={{ root: classes.tab }}
          />
        );
      })}
    </Tabs>
  );
}
