import {connect} from "react-redux";
import {Container, FormEditor, RunDashedLogo, Title} from "../../shared";
import {Box, FormControl, FormHelperText, FormLabel, Grid, InputLabel, MenuItem} from "@material-ui/core";
import React, {useContext, useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import {number, object, string} from "yup";
import {GlobalCss} from "../../../config/GlobalCss";
import {makeStyles} from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import {ActionContext} from "../../../contexts";
import {Select, TextField} from "formik-material-ui";
import clsx from "clsx";
import Button from "../../shared/Button";
import {Link, useHistory, useParams} from "react-router-dom";
import {snackbarActions} from "../../shared/Snackbar";
import useStatusColor from "../../../utils/useStatusSelector";
import {EditorState, convertToRaw} from 'draft-js';
import { draftToMarkdown } from 'markdown-draft-js';

let useStyles = makeStyles((theme) => ({
    form: {
        paddingTop: theme.spacing(2),
        maxWidth: 800,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    content: {
        display: "flex",
        justifyContent: "end",
        margin: '2rem 0rem 0rem 0rem'
    },
    btnPrimary: {
        color: theme.palette.common.white,
        padding: '10px',
        backgroundColor: theme.colors.primaryNavy,
        width: '13rem',
        borderRadius: '7px',
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            color: theme.colors.primaryNavy,
            border: `1px solid ${theme.colors.primaryNavy}`,
        },
        textTransform: 'uppercase',
    },
    btnSecondary: {
        color: theme.palette.secondary.main,
        border: '1px solid',
        width: '13rem',
        padding: '10px',
        marginRight: '10px',
        borderRadius: '7px',
        '&:hover': {
            background: theme.palette.secondary.main,
            color: theme.palette.common.white,
        },
        textTransform: 'uppercase',
        textDecoration: 'none',
        textAlign: 'center',
        fontWeight: 600,
    },
}));

const CreateCommentAttribute = (props) => {
    const classes = useStyles();
    const {assessmentCommentActions} = useContext(ActionContext);
    const {error} = props;
    const {commentId} = useParams()
    const history = useHistory();
    const { getHexValue } = useStatusColor();
    let [descriptionEditorState, setDescriptionEditorState] = useState(EditorState.createEmpty());
    let [tipEditorState, setTipEditorState] = useState(EditorState.createEmpty());

    let handleErrorClose = () => {
        assessmentCommentActions.resetCommentAttributeError();
    };

    useEffect(() => {
        if (error && error.length > 0) {
            setTimeout(() => {
                handleErrorClose();
            }, 3500);
        }
    }, [error]);

    const colorList = [
        'Dark Blue',
        'Yellow',
        'Blue',
        'Light Blue',
        'Green'
    ];

    const renderError = (error) => {
        if (typeof error === 'string') {
            return error;
        } else if (Array.isArray(error)) {
            return error.join(', ');
        } else if (typeof error === 'object' && error !== null) {
            return JSON.stringify(error);
        }
        return '';
    };

    const handleFormSubmit = ( values ) => {
        const descriptionContentState = descriptionEditorState.getCurrentContent();
        const descriptionRawContent = convertToRaw(descriptionContentState);
        const descriptionMarkdown = draftToMarkdown(descriptionRawContent);

        const tipsContentState = tipEditorState.getCurrentContent();
        const tipsRawContent = convertToRaw(tipsContentState);
        const tipsMarkdown = draftToMarkdown(tipsRawContent);

        let data = {
            title: values.title,
            description: descriptionMarkdown,
            tips: tipsMarkdown,
            score_start: values.score_start,
            score_end: values.score_end,
            color: getHexValue(values.color),
        };

        assessmentCommentActions.createCommentAttribute(commentId, data);

        snackbarActions.showSnackbar(
            'Comment attribute was successfully created.'
        );

        history.push(`/assessment-comment/update/${commentId}`)
    };

    return (
        <Container>
            <Grid container spacing={1}>
                <Grid item xs={8} style={{ height: '100%', minHeight: '600px', maxHeight: 'auto' }}>
                    <div style={{margin: '0rem 3rem'}}>
                        <Formik
                            initialValues={{
                                title: '',
                                description: '',
                                tips: '',
                                score_start: 0,
                                score_end: 0,
                                color: '',
                            }}
                            validator={object().shape({
                                title: string()
                                    .trim()
                                    .matches(
                                        /^[a-zA-Z0-9\[\]]+(([',. \-\[\]][a-zA-Z0-9 \[\]])?[a-zA-Z0-9\[\]]*)*$/,
                                        "Enter a valid title"
                                    )
                                    .required('Enter a title'),
                                score_start: number().nullable().required('Select a starting score'),
                                score_end: number().nullable().required('Select a end score'),
                                color: string().nullable().required('Select a color'),
                            })}
                            onSubmit={(values) => {
                                handleFormSubmit(values)
                            }}
                        >
                            {(formik) => {
                                let {errors, touched} = formik;

                                return (
                                    <Form autoComplete='off'>
                                        <GlobalCss />
                                        <div className={classes.form}>
                                            <Title title="Create Score"/>
                                            {error ? (
                                                <Grid item>
                                                    <Alert severity='error' onClose={handleErrorClose}>
                                                        {error}
                                                    </Alert>
                                                </Grid>
                                            ) : null}
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <Field
                                                        name="title"
                                                        label="Title"
                                                        placeholder="Title"
                                                        component={TextField}
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        autoFocus
                                                        style={{marginTop: '1rem'}}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}
                                                      style={{display: 'flex', justifyContent: 'space-between'}}>
                                                    <Field
                                                        name="score_start"
                                                        label="Starting score"
                                                        placeholder="Starting score"
                                                        component={TextField}
                                                        type='number'
                                                        variant="outlined"
                                                        required
                                                        autoFocus
                                                        style={{width: '31%'}}
                                                    />
                                                    <Field
                                                        name="score_end"
                                                        label="End score"
                                                        placeholder="End score"
                                                        component={TextField}
                                                        type='number'
                                                        variant="outlined"
                                                        required
                                                        autoFocus
                                                        style={{width: '31%'}}
                                                    />
                                                    <FormControl variant="outlined" style={{display: 'flex', width: '34%'}}>
                                                        <InputLabel htmlFor="color-label">
                                                            Color *
                                                        </InputLabel>
                                                        <Field
                                                            component={Select}
                                                            name="color"
                                                            label="Color *"
                                                            placeholder="Color *"
                                                            inputProps={{
                                                                id: "color",
                                                            }}
                                                            required
                                                            renderValue={(selected) => (
                                                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ backgroundColor: getHexValue(selected), width: 10, height: 10, marginRight: 10 }} />
                                                                    {selected}
                                                                </Box>
                                                            )}
                                                        >
                                                            {colorList && colorList.map((list) => (
                                                                <MenuItem key={list} value={list}>
                                                                    <Box style={{ backgroundColor: getHexValue(list), width: 10, height: 10, marginRight: 10 }} />
                                                                    {list}
                                                                </MenuItem>
                                                            ))}
                                                        </Field>
                                                        {touched["sport"] && !!errors["sport"] && (
                                                            <FormHelperText>
                                                                {renderError(errors["sport"])}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl fullWidth>
                                                        <FormLabel style={{marginBottom: '5px'}}>Description</FormLabel>
                                                        <FormEditor editorState={descriptionEditorState} onEditorStateChange={setDescriptionEditorState} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl fullWidth>
                                                        <FormLabel style={{marginBottom: '5px'}}>Tips</FormLabel>
                                                        <FormEditor editorState={tipEditorState} onEditorStateChange={setTipEditorState} />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <div className={clsx(classes.content)}>
                                                <Link
                                                    to={`/assessment-comment/update/${commentId}`}
                                                    className={classes.btnSecondary}
                                                >
                                                    Cancel
                                                </Link>
                                                <Button
                                                    className={classes.btnPrimary}
                                                    type="submit"
                                                >
                                                    Create
                                                </Button>
                                            </div>
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <RunDashedLogo />
                </Grid>
            </Grid>
        </Container>
    )
};

let mapStateProps = (state) => {
    return {
        error: state.ui.assessmentComments.error,
    }
};

export default connect(mapStateProps)(CreateCommentAttribute);