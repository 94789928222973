import { blue } from "@material-ui/core/colors";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import React from "react";
import { Button, DialogActions } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    enabled: {
        color: theme.palette.success.main,
    },
    pending: {
        color: theme.palette.warning.main,
    },
    btnPrimary: {
        color: '#0070b9',
        border: '1px solid #0070b9',
        height: '30px',
        padding: '10px',
        marginRight: '10px',
        borderRadius: '20px',
        '&:hover': {
            background: '#0070b9',
            color: '#fff'
        },
    },
    btnSecondary: {
        color: theme.palette.secondary.main,
        border: '1px solid',
        height: '30px',
        padding: '10px',
        marginRight: '10px',
        borderRadius: '20px',
        '&:hover': {
            background: theme.palette.secondary.main,
            color: '#fff'
        }
    },
}));

const RemainingReportDialog = (props) => {
    const classes = useStyles();
    let history = useHistory();

    const {
        closeDialog,
    } = props;


    const handleCloseDialog = () => {
        closeDialog();
    };

    const handleAthlete = () => {
        history.push("/create-athlete");

        handleCloseDialog()
    }


    const handleCoach = () => {
        history.push("/create-coach");
        handleCloseDialog()
    }

    return (
        <React.Fragment>
            <div style={{ display: "flex" }}>
                <DialogTitle id="simple-dialog-title" style={{ flex: 1 }}>
                    Confirmation
                </DialogTitle>
                <div style={{ margin: "8px 16px 8px 8px" }}>
                    <IconButton onClick={handleCloseDialog}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>

            <div style={{ display: "flex", justifyContent: 'center', height: '7rem',
                color: '#0070b9',
                fontSize: '1.5rem',
                fontWeight: 700,
                 alignItems: 'center' }}>
                What do you want to create ?
            </div>

            <div>
                <DialogActions>
                    <Button className={classes.btnSecondary} onClick={handleAthlete}>Athlete</Button>
                    <Button className={classes.btnPrimary} onClick={handleCoach}>Coach</Button>
                </DialogActions>
            </div>
        </React.Fragment>
    );
};

export default RemainingReportDialog;
