import { combineReducers } from "redux";
import * as types from "./coachType";

const getCoachesLoading = (state = false, action) => {
  switch (action.type) {
    case types.REQUEST_GET_COACHES: {
      return true;
    }

    case types.SET_COACHES_SUCCESS:
    case types.GET_COACHES_FAILED: {
      return false;
    }

    default:
      return state;
  }
};

const searchKey = (state = "", action) => {
  switch (action.type) {
    case types.SEARCH_COACHES: {
      return action.payload.key;
    }

    case types.RESET_SEARCH: {
      return "";
    }

    default:
      return state;
  }
};

const selectedCoachId = (state = null, action) => {
  switch (action.type) {
    case types.SELECT_COACHES: {
      return action.payload.coachId;
    }

    case types.DESELECT_COACHES: {
      return null;
    }

    default:
      return state;
  }
};


const loadingCoachFilters = (state = false, action) => {
  switch (action.type) {
    case types.GET_FILTER_COACH_REQUEST: {
      return true
    }

    case types.GET_FILTER_COACH_SUCCESS:
    case types.GET_FILTER_COACH_FAILED: {
      return false
    }

    default:
      return state
  }

}

const loadingCoach = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_GET_COACH: {
      const { coachId } = payload;
      return {
        ...state,
        [coachId]: true,
      };
    }

    case types.GET_COACH_SUCCESS:
    case types.GET_COACH_FAILED: {
      const { coachId } = payload;
      return {
        ...state,
        [coachId]: false,
      };
    }

    default:
      return state;
  }
};

const loadingMarkAsReviewCompleted = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_REVIEW_COMPLETE_COACH: {
      return {
        ...state,
        [payload.coachId]: true,
      };
    }

    case types.REVIEW_COMPLETE_COACH_FAILED:
    case types.REVIEW_COMPLETE_COACH_SUCCESS: {
      return {
        ...state,
        [payload.coachId]: false,
      };
    }

    default:
      return state;
  }
};


const loadingMarkAsReviewIncompleted = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUSET_COACH_REVIEW_INCOMPLETE: {
      return {
        ...state,
        [payload.coachId]: true,
      };
    }

    case types.COACH_REVIEW_INCOMPLETE_SUCCESS:
    case types.COACH_REVIEW_INCOMPLETE_FAILED: {
      return {
        ...state,
        [payload.coachId]: false,
      };
    }

    default:
      return state;
  }
};


const exportingCoachReport = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_EXPORT_COACH_REPORT: {
      return {
        ...state,
        [payload.coachId]: true,
      };
    }

    case types.EXPORT_COACH_REPORT_SUCCESS:
    case types.EXPORT_COACH_REPORT_FAILED: {
      return {
        ...state,
        [payload.coachId]: false,
      };
    }

    default:
      return state;
  }
};

const approvingCoachProfile = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_APPROVE_COACH_PROFILE: {
      return {
        ...state,
        [payload.coachId]: true,
      };
    }

    case types.APPROVE_COACH_PROFILE_SUCCESS:
    case types.APPROVE_COACH_PROFILE_FAILED: {
      return {
        ...state,
        [payload.coachId]: false,
      };
    }

    default:
      return state;
  }
};

const rejectingCoachProfile = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_REJECT_COACH_PROFILE: {
      return {
        ...state,
        [payload.coachId]: true,
      };
    }

    case types.REJECT_COACH_PROFILE_FAILED:
    case types.REJECT_COACH_PROFILE_SUCCESS: {
      return {
        ...state,
        [payload.coachId]: false,
      };
    }

    default:
      return state;
  }
};

const updatingRiskComments = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_ADD_COACH_RISK_COMMENT: {
      const { coachId, categoryId } = payload;
      return {
        ...state,
        [`${coachId}.${categoryId}`]: true,
      };
    }

    case types.ADD_COACH_RISK_COMMENT_SUCCESS:
    case types.ADD_COACH_RISK_COMMENT_FAILED: {
      const { coachId, categoryId } = payload;
      return {
        ...state,
        [`${coachId}.${categoryId}`]: false,
      };
    }

    default:
      return state;
  }
};

const updatingRiskScore = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_UPDATE_COACH_RISK_SCORE: {
      const { coachId, categoryId } = payload;
      return {
        ...state,
        [`${coachId}.${categoryId}`]: true,
      };
    }

    case types.UPDATE_COACH_RISK_SCORE_SUCCESS:
    case types.UPDATE_COACH_RISK_SCORE_FAIL: {
      const { coachId, categoryId } = payload;
      return {
        ...state,
        [`${coachId}.${categoryId}`]: false,
      };
    }

    default:
      return state;
  }
};


const addingCoachLink = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_ADD_COACH_LINK: {
      return {
        [payload.coachId]: true,
      };
    }

    case types.ADD_COACH_LINK_SUCCESS:
    case types.ADD_COACH_LINK_FAIL: {
      return {
        [payload.coachId]: false,
      };
    }

    default:
      return state;
  }
};

const deletingCoachLink = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_DELETE_COACH_LINK: {
      return {
        [payload.coachId]: true,
      };
    }

    case types.DELETE_COACH_LINK_SUCCESS:
    case types.DELETE_COACH_LINK_FAIL: {
      return {
        [payload.coachId]: false,
      };
    }

    default:
      return state;
  }
};


const loadingTwitterFeed = (state = {}, action) => {
  const { payload } = action;

  if (
    action.type === types.REQUEST_GET_COACH_FEED &&
    payload.source === "twitter"
  ) {
    return {
      ...state,
      [payload.coachId]: true,
    };
  }

  if (
    (action.type === types.GET_COACH_FEED_SUCCESS ||
      action.type === types.GET_COACH_FEED_FAILED) &&
    payload.source === "twitter"
  ) {
    return {
      ...state,
      [payload.id]: false,
    };
  }

  return state;
};

const loadingInstaFeed = (state = {}, action) => {
  const { payload } = action;

  if (
    action.type === types.REQUEST_GET_COACH_FEED &&
    payload.source === "instagram"
  ) {
    return {
      ...state,
      [payload.coachId]: true,
    };
  }

  if (
    (action.type === types.GET_COACH_FEED_SUCCESS ||
      action.type === types.GET_COACH_FEED_FAILED) &&
    payload.source === "instagram"
  ) {
    return {
      ...state,
      [payload.id]: false,
    };
  }

  return state;
};

const loadingYoutubeFeed = (state = {}, action) => {
  const { payload } = action;

  if (
    action.type === types.REQUEST_GET_COACH_FEED &&
    payload.source === "youtube"
  ) {
    return {
      ...state,
      [payload.coachId]: true,
    };
  }

  if (
    (action.type === types.GET_COACH_FEED_SUCCESS ||
      action.type === types.GET_COACH_FEED_FAILED) &&
    payload.source === "youtube"
  ) {
    return {
      ...state,
      [payload.id]: false,
    };
  }

  return state;
};

const loadingNewspaperFeed = (state = {}, action) => {
  const { payload } = action;

  if (
    action.type === types.REQUEST_GET_COACH_FEED &&
    payload.source === "newspaper"
  ) {
    return {
      ...state,
      [payload.coachId]: true,
    };
  }

  if (
    (action.type === types.GET_COACH_FEED_SUCCESS ||
      action.type === types.GET_COACH_FEED_FAILED) &&
    payload.source === "newspaper"
  ) {
    return {
      ...state,
      [payload.id]: false,
    };
  }

  return state;
};

const loadingCoachFeed = combineReducers({
  twitter: loadingTwitterFeed,
  instagram: loadingInstaFeed,
  youtube: loadingYoutubeFeed,
  newspaper: loadingNewspaperFeed,
});


const exportingFeed = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_EXPORT_COACH_FEED: {
      return {
        ...state,
        [`${payload.coachId}.${payload.source}`]: true,
      };
    }

    case types.EXPORT_COACH_FEED_SUCCESS:
    case types.EXPORT_COACH_FEED_FAILED: {
      return {
        ...state,
        [`${payload.coachId}.${payload.source}`]: false,
      };
    }

    default:
      return state;
  }
};

const addingExecutiveSummeryContent = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_COACH_ADD_EXECUTIVE_SUMMERY_COMMENT: {
      const { coachId, categoryId } = payload;
      return {
        ...state,
        [`${coachId}.${categoryId}`]: true,
      };
    }

    case types.ADD_COACH_EXECUTIVE_SUMMERY_COMMENT_SUCCESS:
    case types.ADD_COACH_EXECUTIVE_SUMMERY_COMMENT_FAILED: {
      const { coachId, categoryId } = payload;
      return {
        ...state,
        [`${coachId}.${categoryId}`]: false,
      };
    }

    default:
      return state;
  }
};

const isCoachFormSubmit = (state = false, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.INDIVIDUAL_COACH_FORM_SUBMITTED: {
      return payload
    }

    case types.INDIVIDUAL_COACH_FORM_NOT_SUBMITTED: {
      return payload
    }

    default:
      return state
  }
}


const updatingCoachPaidStatus = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_TOGGLE_COACH_PAID_STATUS: {
      return {
        ...state,
        [payload.coachId]: true,
      };
    }

    case types.TOGGLE_COACH_PAID_STATUS_SUCCESS:
    case types.TOGGLE_COACH_PAID_STATUS_FAILED: {
      return {
        ...state,
        [payload.coachId]: false,
      };
    }

    default:
      return state;
  }
};

const loadingFullReportPaymentStatus = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_TOGGLE_COACH_FULL_REPORT_PAYMENT_STATUS: {
      return {
        ...state,
        [payload.coachId]: true,
      };
    }

    case types.TOGGLE_COACH_FULL_REPORT_PAYMENT_STATUS_SUCCESS:
    case types.TOGGLE_COACH_FULL_REPORT_PAYMENT_STATUS_FAILED: {
      return {
        ...state,
        [payload.coachId]: false,
      };
    }

    default:
      return state;
  }
};

const loadingExecutiveSummaryReportPaymentStatus = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_TOGGLE_COACH_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS: {
      return {
        ...state,
        [payload.coachId]: true,
      };
    }

    case types.TOGGLE_COACH_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS_SUCCESS:
    case types.TOGGLE_COACH_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS_FAILED: {
      return {
        ...state,
        [payload.coachId]: false,
      };
    }

    default:
      return state;
  }
};

const loadingActionPlanReportPaymentStatus = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_TOGGLE_COACH_ACTION_PLAN_REPORT_PAYMENT_STATUS: {
      return {
        ...state,
        [payload.coachId]: true,
      };
    }

    case types.TOGGLE_COACH_ACTION_PLAN_REPORT_PAYMENT_STATUS_SUCCESS:
    case types.TOGGLE_COACH_ACTION_PLAN_REPORT_PAYMENT_STATUS_FAILED: {
      return {
        ...state,
        [payload.coachId]: false,
      };
    }

    default:
      return state;
  }
};

const exportingFullReport = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_EXPORT_FULL_REPORT: {
      return {
        ...state,
        [payload.coachId]: true,
      };
    }

    case types.EXPORT_FULL_REPORT_SUCCESS:
    case types.EXPORT_FULL_REPORT_FAIL: {
      return {
        ...state,
        [payload.coachId]: false,
      };
    }

    default:
      return state;
  }
};

const exportingActionPlanReport = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_EXPORT_ACTION_PLAN_REPORT: {
      return {
        ...state,
        [payload.coachId]: true,
      };
    }

    case types.EXPORT_ACTION_PLAN_REPORT_SUCCESS:
    case types.EXPORT_ACTION_PLAN_REPORT_FAIL: {
      return {
        ...state,
        [payload.coachId]: false,
      };
    }

    default:
      return state;
  }
};

const exportingExecutiveSummaryReport = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_EXPORT_EXECUTIVE_SUMMARY_REPORT: {
      return {
        ...state,
        [payload.coachId]: true,
      };
    }

    case types.EXPORT_EXECUTIVE_SUMMARY_REPORT_SUCCESS:
    case types.EXPORT_EXECUTIVE_SUMMARY_REPORT_FAIL: {
      return {
        ...state,
        [payload.coachId]: false,
      };
    }

    default:
      return state;
  }
};

const exportingPsycologistReport = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_EXPORT_PSYCOLOGIST_REPORT: {
      return {
        ...state,
        [payload.coachId]: true,
      };
    }

    case types.EXPORT_PSYCOLOGIST_REPORT_SUCCESS:
    case types.EXPORT_PSYCOLOGIST_REPORT_FAIL: {
      return {
        ...state,
        [payload.coachId]: false,
      };
    }

    default:
      return state;
  }
};

const regeneratingFullReport = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_REGENERATE_FULL_REPORT: {
      return {
        ...state,
        [payload.coachId]: true,
      };
    }

    case types.REGENERATE_FULL_REPORT_SUCCESS:
    case types.REGENERATE_FULL_REPORT_FAIL: {
      return {
        ...state,
        [payload.coachId]: false,
      };
    }
    default:
      return state;
  }
};

const regeneratingActionPlanReport = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_REGENERATE_ACTION_PLAN_REPORT: {
      return {
        ...state,
        [payload.coachId]: true,
      };
    }

    case types.REGENERATE_ACTION_PLAN_REPORT_SUCCESS:
    case types.REGENERATE_ACTION_PLAN_REPORT_FAIL: {
      return {
        ...state,
        [payload.coachId]: false,
      };
    }
    default:
      return state;
  }
};

const regeneratingExecutiveSummaryReport = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_REGENERATE_EXECUTIVE_SUMMARY_REPORT: {
      return {
        ...state,
        [payload.coachId]: true,
      };
    }

    case types.REGENERATE_EXECUTIVE_SUMMARY_REPORT_SUCCESS:
    case types.REGENERATE_EXECUTIVE_SUMMARY_REPORT_FAIL: {
      return {
        ...state,
        [payload.coachId]: false,
      };
    }
    default:
      return state;
  }
};

const loadingSentimentAnalysisStatus = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_COACH_SENTIMENT_RESULTS: {
      return {
        ...state,
        [payload.coachId]: payload.value,
      };
    }

    case types.COACH_SENTIMENT_RESULTS_SUCCESS: {
      return {
        ...state,
        [payload.coachId]: payload.value,
      };
    }

    case types.COACH_SENTIMENT_RESULTS_FAILED: {
      return {
        ...state,
        [payload.coachId]: payload.value,
      };
    }

    default:
      return state;
  }
};

const loadingReqPlanReports = (state = false, action) => {
  switch (action.type) {
    case types.GET_REQUEST_PLAN_REPORTS: {
      return true
    }

    case types.GET_REQUEST_PLAN_REPORTS_SUCCESS:
    case types.GET_REQUEST_PLAN_REPORTS_FAILED: {
      return false
    }

    default:
      return state
  }

}
const alreadyRequestedPlusReport = (state = false, action) => {
  switch (action.type) {
    case types.SAVE_ALLREADY_REQUESTED_PLUS_REPORTS: {
      return action.payload.data
    }

    default:
      return state;
  }
}

const updatingNotes = (state = false, action) => {
  switch (action.type) {
    case types.REQUEST_UPDATE_COACH_NOTES: {
      return true;
    }

    case types.UPDATE_COACH_NOTES_SUCCESS:
    case types.UPDATE_COACH_NOTES_FAILED: {
      return false;
    }

    default:
      return state;
  }
};

export default combineReducers({
  updatingNotes,
  getCoachesLoading,
  searchKey,
  selectedCoachId,
  loadingCoachFilters,
  loadingCoach,
  loadingMarkAsReviewCompleted,
  loadingMarkAsReviewIncompleted,
  exportingCoachReport,
  approvingCoachProfile,
  rejectingCoachProfile,
  updatingRiskComments,
  updatingRiskScore,
  addingCoachLink,
  deletingCoachLink,
  loadingCoachFeed,
  exportingFeed,
  addingExecutiveSummeryContent,
  isCoachFormSubmit,
  updatingCoachPaidStatus,
  loadingFullReportPaymentStatus,
  loadingExecutiveSummaryReportPaymentStatus,
  loadingActionPlanReportPaymentStatus,
  exportingFullReport,
  exportingActionPlanReport,
  exportingExecutiveSummaryReport,
  regeneratingFullReport,
  regeneratingActionPlanReport,
  exportingPsycologistReport,
  regeneratingExecutiveSummaryReport,
  loadingSentimentAnalysisStatus,
  loadingReqPlanReports,
  alreadyRequestedPlusReport
});
