import {Container, DeleteButtonModal, RunDashedLogo, Title} from "../shared";
import {connect} from "react-redux";
import {ActionContext} from "../../contexts";
import React, {useContext, useEffect, useState} from "react";
import {
    Box,
    FormControl,
    FormControlLabel,
    FormGroup, FormHelperText,
    Grid, InputLabel,
    makeStyles, MenuItem,
    Modal,
    Switch,
    Typography
} from "@material-ui/core";
import {Field, Form, Formik} from "formik";
import {object, string} from "yup";
import {GlobalCss} from "../../config/GlobalCss";
import Alert from "@material-ui/lab/Alert";
import {Select, TextField} from "formik-material-ui";
import Button from "../shared/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useHistory} from "react-router-dom";
import {accountTypes} from '../../store/constants';

let useStyles = makeStyles((theme) => ({
    form: {
        maxWidth: 600,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'center',
        padding: '1rem 0px',
    },
    activate: {
      color: theme.palette.error.main,
    },
    deactivate: {
        color: theme.palette.primary.main,
    },
    label: {
        fontFamily: theme.fontFamily.robotoCondensed,
    },
    errorMsg: {
        color: theme.palette.error.main,
    },
    content: {
        display: "flex",
        justifyContent: "space-between",
        width: '97%',
        marginLeft: '0.6rem'
    },
    btnPrimary: {
        color: theme.palette.common.white,
        padding: '10px',
        backgroundColor: theme.colors.primaryNavy,
        width: '13rem',
        borderRadius: '7px',
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            color: theme.colors.primaryNavy,
            border: `1px solid ${theme.colors.primaryNavy}`,
        },
        textTransform: 'uppercase',
    },
    btnSecondary: {
        color: theme.palette.secondary.main,
        border: '1px solid',
        width: '13rem',
        padding: '10px',
        marginRight: '0.5rem',
        borderRadius: '7px',
        '&:hover': {
            background: theme.palette.secondary.main,
            color: theme.palette.common.white,
        },
        textTransform: 'uppercase',
    },
    btnReset: {
        padding: '10px',
        backgroundColor: theme.palette.common.white,
        color: theme.colors.primaryNavy,
        border: `1px solid ${theme.palette.text.primary}`,
        width: '13rem',
        borderRadius: '7px',
        marginRight: '0.5rem',
        '&:hover': {
            backgroundColor: theme.palette.text.primary,
            color: theme.palette.common.white,
        },
        textTransform: 'uppercase',
    },
    modal: {
        display: "flex",
        flexDirection: "column",
        width: 350,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 8,
        padding: theme.spacing(2, 4, 3),
        outline: 'none',
    },
    modalContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
    heading: {
        fontSize: theme.fontSize.md,
        fontWeight: 500,
        color: theme.palette.text.primary,
        fontFamily: theme.fontFamily.robotoCondensed,
    },
    title: {
        fontWeight: 800,
        color: theme.palette.error.main,
    },
    secondaryBtn: {
        display: "flex",
        alignItems: "center",
        marginRight: "1rem",
        fontSize: "13px",
        fontFamily: theme.fontFamily.robotoCondensed,
        color: theme.palette.text.primary,
        fontWeight: 700,
        cursor: "pointer",
        textTransform: "uppercase",
    },
    primaryBtn: {
        display: "flex",
        alignItems: "center",
        fontSize: "13px",
        fontFamily: theme.fontFamily.robotoCondensed,
        color: theme.palette.error.main,
        fontWeight: 700,
        cursor: "pointer",
        textTransform: "uppercase",
    },
}));

const UpdateAccount = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { accountActions, snackbarActions } = useContext(ActionContext);
    const {accountType, accountTypeNames, error, loading, teams, userAccount} = props;
    const [ status, setStatus ] = useState(userAccount.status);
    const [open, setOpen] = useState(false);

    let finalTeams = Object.keys(teams);

    useEffect(() => {
        accountActions.getUserTypes();
        setStatus(userAccount.status);
    }, [userAccount]);

    let handleFormSubmit = (values ) => {
        let data = {
            email: values.email,
            first_name: values.firstName,
            last_name: values.lastName,
            full_name: values.firstName + ' ' + values.lastName,
            status: status,
            team: values.team,
            account_type: values.accountType,
        };

        accountActions.updateUserAccount(userAccount._id, data);
        snackbarActions.showSnackbar('User has been successfully updated');

        history.push("/account-management");
    };

    const handleErrorClose = () => {
        accountActions.resetAccountUpdateError();
    };

    const handleDelete = (user_id) => {
        accountActions.deleteAccountByAdmin(user_id);
        history.push("/account-management");
    };

    const handleStatus = (status) => {
        if (status === 2) {
            setStatus(4)
        } else {
            setStatus(2)
        }
    };

    const handleClose = () => {
        history.push("/account-management");
    };

    const handleResetPassword = (email) => {
        accountActions.resetPasswordByAdmin(email);
        setOpen(prev => !prev);
    };

    const handleClick = () => {
      setOpen(prevState => !prevState);
    };

    const renderError = (error) => {
        if (typeof error === 'string') {
            return error;
        } else if (Array.isArray(error)) {
            return error.join(', ');
        } else if (typeof error === 'object' && error !== null) {
            return JSON.stringify(error);
        }
        return '';
    };

    return (
        <Container>
            <Grid container spacing={1}>
                <Grid item xs={8}>
                    <div style={{ margin: '0rem 3rem' }}>
                        {
                            loading ? (
                                <Box style={{display: 'flex', justifyContent: 'center'}}>
                                    <CircularProgress size={72} thickness={3} color='primary' />
                                </Box>
                            ) : (
                                <Formik
                                    initialValues={{
                                        firstName: userAccount?.first_name || "",
                                        lastName: userAccount?.last_name || "",
                                        email: userAccount?.email || "",
                                        team: userAccount?.team || "",
                                        accountType: userAccount?.account_type || "",
                                    }}
                                    validator={object().shape({
                                        firstName: string()
                                            .trim()
                                            .matches(
                                                /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
                                                "Enter valid First name"
                                            )
                                            .required("Enter player first ame"),
                                        lastName: string()
                                            .trim()
                                            .matches(
                                                /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
                                                "Enter valid Last name"
                                            )
                                            .required("Enter player last name"),
                                        email: string().email("Enter a valid email").required("Enter email"),
                                    })}
                                    onSubmit={handleFormSubmit}
                                >
                                    {
                                        (formik) => {
                                            let { errors, touched, values } = formik;
                                            return (
                                                <Form autoComplete="off">
                                                    <div className={classes.form}>
                                                        {
                                                            accountType !== accountTypes.superAdmin ? (
                                                                <Title title='Update' text={userAccount?.full_name}/>
                                                            ) : (
                                                                <div style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                    alignItems: 'center'
                                                                }}>
                                                                    <Title title='Update' text={userAccount?.full_name}/>
                                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                                        <FormGroup>
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Switch
                                                                                        checked={status === 2}
                                                                                        onChange={() => handleStatus(status)}
                                                                                        color='primary'
                                                                                        style={{ color: status === 2 ? '#058AE0' : '#DC2B2B' }}
                                                                                    />
                                                                                }
                                                                                label={status === 2 ? 'Deactivate Account' : 'Activate Account'}
                                                                            />
                                                                        </FormGroup>
                                                                        <DeleteButtonModal
                                                                            title={`${userAccount?.full_name}`}
                                                                            handleDelete={() => handleDelete(userAccount._id)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        <Grid container spacing={3} style={{ marginTop: '1rem' }}>
                                                            <GlobalCss />
                                                            {error ? (
                                                                <Grid item>
                                                                    <Alert severity="error" onClose={handleErrorClose}>
                                                                        {error}
                                                                    </Alert>
                                                                </Grid>
                                                            ) : null}
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between'
                                                                }}>
                                                                    <Field
                                                                        name="firstName"
                                                                        label="First Name"
                                                                        placeholder="First Name"
                                                                        component={TextField}
                                                                        variant="outlined"
                                                                        required
                                                                        fullWidth
                                                                        autoFocus
                                                                        style={{width: '49%'}}
                                                                    />
                                                                    <Field
                                                                        name="lastName"
                                                                        label="Last Name"
                                                                        placeholder="Last Name"
                                                                        component={TextField}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        required
                                                                        style={{width: '49%'}}
                                                                    />
                                                                </Grid>
                                                                <Grid item md={12} sm={12}>
                                                                    <Field
                                                                        name="email"
                                                                        label="Email"
                                                                        placeholder="Email"
                                                                        component={TextField}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        required
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between'
                                                                }}>
                                                                    <FormControl variant="outlined" style={{width: '49%'}}>
                                                                        <InputLabel htmlFor="mainAttribute-label">
                                                                            Team
                                                                        </InputLabel>
                                                                        <Field
                                                                            component={Select}
                                                                            name="team"
                                                                            label="Team *"
                                                                            inputProps={{
                                                                                id: "team",
                                                                            }}
                                                                            required
                                                                        >
                                                                            <MenuItem
                                                                                key={1}
                                                                                value={userAccount.account_type}
                                                                            >
                                                                                {userAccount.account_type}
                                                                            </MenuItem>
                                                                            {finalTeams &&
                                                                                finalTeams.map((list) => (
                                                                                    <MenuItem key={list} value={list}>
                                                                                        {list}
                                                                                    </MenuItem>
                                                                                ))}
                                                                        </Field>
                                                                        {touched["team"] && !!errors["team"] && (
                                                                            <FormHelperText>{renderError(errors["team"])}</FormHelperText>
                                                                        )}
                                                                    </FormControl>
                                                                    <FormControl
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        error={touched["accountType"] && !!errors["accountType"]}
                                                                        style={{width: '49%'}}
                                                                    >
                                                                        <InputLabel htmlFor="accountTypeLabel">
                                                                            Role *
                                                                        </InputLabel>
                                                                        <Field
                                                                            component={Select}
                                                                            name="accountType"
                                                                            label="Role *"
                                                                            labelId="accountTypeLabel"
                                                                            inputProps={{
                                                                                id: "accountType",
                                                                            }}
                                                                            required
                                                                        >
                                                                            {Object.keys(accountTypeNames).map((key, index) => {
                                                                                    return (
                                                                                        <MenuItem
                                                                                            key={index}
                                                                                            value={accountTypeNames[key]["code"]}
                                                                                        >
                                                                                            {accountTypeNames[key]["name"]}
                                                                                        </MenuItem>
                                                                                    );
                                                                                })}
                                                                        </Field>
                                                                        {touched["accountType"] && !!errors["accountType"] ? (
                                                                            <FormHelperText className={classes.errorMsg}>{renderError(errors["team"])}</FormHelperText>
                                                                        ) : null}
                                                                    </FormControl>
                                                                </Grid>
                                                                <div className={classes.content}>
                                                                    <Button
                                                                        className={classes.btnReset}
                                                                        type="button"
                                                                        variant="text"
                                                                        onClick={handleClick}
                                                                    >
                                                                        Reset Password
                                                                    </Button>
                                                                    <Modal open={open} onClose={handleClick}>
                                                                        <Box className={classes.modalContainer}>
                                                                            <Box className={classes.modal}>
                                                                                <Typography className={classes.heading}>
                                                                                    Are you sure you want to request a password reset for <span className={classes.title}>{userAccount.full_name}</span>
                                                                                </Typography>
                                                                                <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                                    <Box onClick={handleClick} className={classes.secondaryBtn}>No</Box>
                                                                                    <Box onClick={() => handleResetPassword(values.email)} className={classes.primaryBtn}>Yes</Box>
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    </Modal>
                                                                    <Button
                                                                        className={classes.btnSecondary}
                                                                        type="button"
                                                                        variant="text"
                                                                        onClick={handleClose}
                                                                    >
                                                                        Cancel
                                                                    </Button>
                                                                    <Button
                                                                        className={classes.btnPrimary}
                                                                        type="submit"
                                                                    >
                                                                        Update
                                                                    </Button>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </Form>
                                            )
                                        }
                                    }
                                </Formik>
                            )
                        }
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <RunDashedLogo/>
                </Grid>
            </Grid>
        </Container>
    )
};

const mapStateToProps = (state) => {
    return {
        loading: state.ui.account.selectedAccountLoading,
        userAccount: state.account.selectedAccountData,
        accountType: state.account.accountType,
        error: state.ui.account.error,
        teams: state.ui.player.organizationTeams,
        accountTypeNames: state.ui.account.userTypes,
    };
};

export default connect(mapStateToProps)(UpdateAccount);

