import {makeStyles} from "@material-ui/core";

let useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: 'center',
        zIndex: theme.zIndex.appBar,
        padding: '2rem',
        backgroundColor: theme.colors.veryLightGrey,
    },
    container: {
        padding: '1rem',
        flexGrow: 1,
        width: '100%',
        borderRadius: 8,
        backgroundColor: theme.palette.common.white,
        marginTop: '1rem',
    },
}));

const Container = ({ children }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                {children}
            </div>
        </div>
    )
};

export default Container;