import React from "react";
import Button from "@material-ui/core/Button";
import MuiMenu from "@material-ui/core/Menu";
import PropTypes from "prop-types";

const DefaultAnchorComponent = (props) => {
  return <Button {...props}>Open Menu</Button>;
};

let Menu = ({
  AnchorComponent = DefaultAnchorComponent,
  children,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    const hasChildren = React.Children.toArray(children).length > 0;
    // mount only if menu has children component
    if (hasChildren) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <AnchorComponent aria-haspopup="true" onClick={handleClick} />
      <MuiMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        {...rest}
      >
        {React.Children.toArray(children).map((Child) => {
          return React.cloneElement(Child, { handleClose });
        })}
      </MuiMenu>
    </div>
  );
};

Menu.propTyps = {
  children: PropTypes.node.isRequired,
};

export default Menu;
