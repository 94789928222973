import {connect} from "react-redux";
import React, {useContext, useEffect} from "react";
import {ActionContext} from "../../../contexts";
import {Title} from "../../shared";
import {Box, Grid, makeStyles, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";
import {Link, useLocation} from "react-router-dom";
import CircularProgress from "../../shared/CircularProgress";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import useStatusColor from "../../../utils/useStatusSelector";

let useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        paddingBottom: '1rem',
    },
    headerBtn: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '13px',
        fontFamily: theme.fontFamily.robotoCondensed,
        color: theme.palette.text.primary,
        fontWeight: 700,
        cursor: 'pointer',
        textTransform: 'uppercase',
        textDecoration: 'none',
    },
    noAttributes: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1rem',
        color: theme.palette.error.main,
        fontFamily: theme.fontFamily.prometo,
        fontWeight: 700,
        fontSize: '1.1rem',
    },
    rowAlignment: {
        border: 'solid 1px #f2f2f2',
        borderRadius: 8,
        backgroundColor: theme.palette.common.white,
        marginBottom: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        padding: '0.8rem 0.5rem',
        width: '100%',
    },
    tableHead: {
        borderBottom: 'solid 1px #f2f2f2',
        backgroundColor: theme.palette.common.white,
        marginBottom: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        padding: '0.8rem 0.5rem',
        width: '100%',
    },
    fullName: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        height: '1.2em',
        whiteSpace: 'nowrap',
        fontWeight: '700',
        fontFamily: theme.fontFamily.robotoCondensed,
        color: theme.palette.text.primary,
        textTransform: 'capitalize',
        textAlign: 'center',
    },
    dataTableAlignment: {
        fontWeight: '300',
        display: 'flex',
        color: theme.palette.text.primary,
        fontFamily: theme.fontFamily.robotoCondensed,
        fontSize: 14,
        justifyContent: 'center',
        textTransform: 'capitalize',
    },
    iconColor: {
        color: theme.palette.text.primary,
    },
}));

const CommentAttributes = (props) => {
    const {assessmentCommentActions} = useContext(ActionContext);
    const classes = useStyles();
    const { getColorName } = useStatusColor();
    const location = useLocation();
    const {commentAttributes, commentId, loading} = props;

    useEffect(() => {
        assessmentCommentActions.getAllAssessmentCommentAttributesList(commentId);
    }, []);

    return (
        <>
            <div className={classes.header}>
                <Title title='Attribute Scores' />
                <Link to={`${location.pathname}/create-attribute`} className={classes.headerBtn}>
                    Create Score
                </Link>
            </div>
            <TableContainer>
                <Grid container style={{ padding: '1rem 0px' }}>
                    {
                        loading && commentAttributes.length === 0 ?
                            <Box style={{display: 'flex', justifyContent: 'center'}}>
                                <CircularProgress size={72} thickness={3} color="primary"/>
                            </Box> :
                            (commentAttributes && commentAttributes.length === 0 ? (
                                <Typography className={classes.noAttributes}>
                                    No Comment Attributes Found
                                </Typography>
                            ) : (
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TableHead className={classes.tableHead}>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    className={classes.fullName}
                                                    style={{ textAlign: 'start' }}
                                                >
                                                    title
                                                </Grid>
                                                <Grid item xs={3} className={classes.fullName}>score start</Grid>
                                                <Grid item xs={2} className={classes.fullName}>score end</Grid>
                                                <Grid item xs={2} className={classes.fullName}>color</Grid>
                                                <Grid item xs={2} className={classes.fullName}>edit</Grid>
                                            </Grid>
                                        </TableHead>
                                        {
                                            commentAttributes && commentAttributes.map((item, index) => (
                                                <TableRow className={classes.rowAlignment} key={index}>
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        className={classes.fullName}
                                                        style={{ textAlign: 'start' }}
                                                    >
                                                        {item.title}
                                                    </Grid>
                                                    <Grid item xs={3} className={classes.dataTableAlignment}>
                                                        {item.score_start}
                                                    </Grid>
                                                    <Grid item xs={2} className={classes.dataTableAlignment}>
                                                        {item.score_end}
                                                    </Grid>
                                                    <Grid item xs={2} className={classes.dataTableAlignment}>
                                                        {getColorName(item.color)}
                                                    </Grid>
                                                    <Grid item xs={2} className={classes.dataTableAlignment}>
                                                        <Link to={`${location.pathname}/update-attribute/${item._id}`}>
                                                            <CreateOutlinedIcon className={classes.iconColor} />
                                                        </Link>
                                                    </Grid>
                                                </TableRow>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            ))
                    }
                </Grid>
            </TableContainer>
        </>
    )
};

let mapStateToProps = (state) => {
    return {
        commentAttributes: state.assessmentComments.getAllAssessmentCommentAttributesData,
        loading: state.ui.assessmentComments.getAssessmentCommentAttributesLoading,
    };
};

export default connect(mapStateToProps)(CommentAttributes);