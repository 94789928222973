import * as types from "./dialogTypes";
import { combineReducers } from "redux";

const addDialog = (state, action) => {
  const { payload } = action;
  const { id, component, props } = payload;

  return {
    ...state,
    [id]: {
      id,
      component,
      props,
    },
  };
};

const removeDialog = (state, action) => {
  const { payload } = action;
  const { id } = payload;

  delete state[id];

  return {
    ...state,
  };
};

const dialogsById = (state = {}, action) => {
  switch (action.type) {
    case types.OPEN_DIALOG: {
      return addDialog(state, action);
    }

    case types.CLOSE_DIALOG: {
      return removeDialog(state, action);
    }

    default:
      return state;
  }
};

const allDialogs = (state = [], action) => {
  const { payload } = action;

  switch (action.type) {
    case types.OPEN_DIALOG: {
      return [...state, payload.id];
    }

    case types.CLOSE_DIALOG: {
      return state.filter((id) => id !== payload.id);
    }

    default:
      return state;
  }
};

const dialogReducer = combineReducers({
  byId: dialogsById,
  allIds: allDialogs,
});

export default dialogReducer;
