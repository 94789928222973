export const REQUEST_GET_CONFERENCES = "REQUEST_GET_CONFERENCES";
export const SET_CONFERENCES_SUCCESS = "SET_CONFERENCES_SUCCESS";
export const GET_CONFERENCES_FAILED = "GET_CONFERENCES_FAILED";

export const SEARCH_CONFERENCE = "SEARCH_CONFERENCE";
export const RESET_SEARCH = "RESET_SEARCH";

export const SELECT_CONFERENCE = "SELECT_CONFERENCE";
export const DESELECT_CONFERENCE = "DESELECT_CONFERENCE";

export const GET_TEAMS_BY_CONFERENCE_ID = "GET_TEAMS_BY_CONFERENCE_ID";
export const GET_TEAMS_BY_CONFERENCE_ID_SUCCESS = "GET_TEAMS_BY_CONFERENCE_ID_SUCCESS";
export const GET_TEAMS_BY_CONFERENCE_ID_FAILED = "GET_TEAMS_BY_CONFERENCE_ID_FAILED";
export const RESET_TEAMS_BY_CONFERENCE_ID = "RESET_TEAMS_BY_CONFERENCE_ID";

export const GET_SPORTS_BY_CONFERENCE_ID = "GET_SPORTS_BY_CONFERENCE_ID";
export const GET_SPORTS_BY_CONFERENCE_ID_SUCCESS = "GET_SPORTS_BY_CONFERENCE_ID_SUCCESS";
export const GET_SPORTS_BY_CONFERENCE_ID_FAILED = "GET_SPORTS_BY_CONFERENCE_ID_FAILED";
export const RESET_SPORTS_BY_CONFERENCE_ID = "RESET_SPORTS_BY_CONFERENCE_ID";

export const CREATE_NEW_CONFERENCE_REQUEST = "CREATE_NEW_CONFERENCE_REQUEST";
export const CREATE_NEW_CONFERENCE_SUCCESS = "CREATE_NEW_CONFERENCE_SUCCESS";
export const CREATE_NEW_CONFERENCE_FAILED = "CREATE_NEW_CONFERENCE_FAILED";
export const RESET_CREATE_NEW_CONFERENCE_ERROR = "RESET_CREATE_NEW_CONFERENCE_ERROR";

export const DELETE_CONFERENCE_REQUEST = "DELETE_CONFERENCE_REQUEST";
export const DELETE_CONFERENCE_SUCCESS = "DELETE_CONFERENCE_SUCCESS";
export const DELETE_CONFERENCE_FAILED = "DELETE_CONFERENCE_FAILED";

export const GET_COUNTRIES_REQUEST = "GET_COUNTRIES_REQUEST";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILED = "GET_COUNTRIES_FAILED";

export const GET_COUNTRIES_STATES_REQUEST = "GET_COUNTRIES_STATES_REQUEST";
export const GET_COUNTRIES_STATES_SUCCESS = "GET_COUNTRIES_STATES_SUCCESS";
export const GET_COUNTRIES_STATES_FAILED = "GET_COUNTRIES_STATES_FAILED";

export const GET_CONFERENCE_BY_TYPE_REQUEST = "GET_CONFERENCE_BY_TYPE_REQUEST";
export const GET_CONFERENCE_BY_TYPE_SUCCESS = "GET_CONFERENCE_BY_TYPE_SUCCESS";
export const GET_CONFERENCE_BY_TYPE_FAILED = "GET_CONFERENCE_BY_TYPE_FAILED";

export const SELECTED_PAGE_TYPE = "SELECTED_PAGE_TYPE"
export const SELECTED_CONFERENCE_TYPE = "SELECTED_CONFERENCE_TYPE"

export const CREATE_NEW_TEAM = "CREATE_NEW_TEAM";
export const CREATE_NEW_TEAM_SUCCESS = "CREATE_NEW_TEAM_SUCCESS";
export const CREATE_NEW_TEAM_FAILED = "CREATE_NEW_TEAM_FAILED";
export const RESET_CREATE_NEW_TEAM = "RESET_CREATE_NEW_TEAM";

export const GET_CONFERENCE_BY_ID_REQUEST = 'GET_CONFERENCE_BY_ID_REQUEST';
export const GET_CONFERENCE_BY_ID_SUCCESS = 'GET_CONFERENCE_BY_ID_SUCCESS';
export const GET_CONFERENCE_BY_ID_FAILED = 'GET_CONFERENCE_BY_ID_FAILED';

export const UPDATE_CONFERENCE_BY_ID_REQUEST = 'UPDATE_CONFERENCE_BY_ID_REQUEST';
export const UPDATE_CONFERENCE_BY_ID_SUCCESS = 'UPDATE_CONFERENCE_BY_ID_SUCCESS';
export const UPDATE_CONFERENCE_BY_ID_FAILED = 'UPDATE_CONFERENCE_BY_ID_FAILED';