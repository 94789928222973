import apiHandler, {getAuthHeader} from "./apiHandler";

export const getDashboardStats = async () => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get("/dashboard/stats", { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.message);
      return Promise.reject(err);
    }

    const stats = response.data.info.stats;

    return {
      all: stats.all,
      completed: stats.completed,
      consentPending: stats.consent_pending,
      reviewPending: stats.review_pending,
      approvalPending: stats.approval_pending,
      error: stats.error,
      rejected: stats.rejected,
      allTeams: stats.allTeams,
      proReports: stats.proReports,
      psychReview: stats.psych_review,
      remainingReports: stats.remaining_reports,
      baseReportCompleted: stats.base_report_completed,
      processing: stats.processing

    };
  } catch (err) {
    throw new Error(err);
  }
};

export const getLiteCandidateDetails = async (statusName) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(`load_lite_candidates/${statusName}`, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.message);
      return Promise.reject(err);
    }

    return response.data.info.players;
  } catch (err) {
    throw new Error(err);
  }
};

export const todaysLatestUpdate = async () => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(`todays_status_update`, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.message);
      return Promise.reject(err);
    }

    return response.data.info.stats;
  } catch (err) {
    throw new Error(err);
  }
};

export const getAccountsList = async (team) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post(`/account-list`, team, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.message);
      return Promise.reject(err);
    }

    return response.data.info.accounts;
  } catch (err) {
    throw new Error(err);
  }
};

export const activateAccount = async (email) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post(`/activate-account`, email, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.message);
      return Promise.reject(err);
    }

    return response
  } catch (err) {
    throw new Error(err);
  }
};

export const deActivateAccount = async (email) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post("/deactivate-account", email, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.message);
      return Promise.reject(err);
    }

    return response.data;
  } catch (err) {
    throw new Error(err)
  }
};

const dashboard = {
  getDashboardStats,
  getLiteCandidateDetails,
  todaysLatestUpdate,
  getAccountsList,
  deActivateAccount,
  activateAccount
};

export default dashboard;
