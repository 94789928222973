import React from "react";
import Tabs from "./Tabs";
import Tab from "./Tab";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tabs: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontFamily: theme.fontFamily.prometo,
  },
}))

let TabsContainer = ({ labels = [], value, onChange, ...rest }) => {
  const classes = useStyles();

  return (
    <Tabs value={value} onChange={onChange} {...rest} className={classes.tabs}>
      {labels.map((label, index) => {
        return <Tab key={index} label={label} />;
      })}
    </Tabs>
  );
};

export default TabsContainer;
