import theme from "./theme";

let config = {
  appName: "Mental Metrix",
  appBarHeight: 64,
  drawer: {
    minWidth: '4rem',
    maxWidth: 290,
  },
  theme,
};

export default config;
