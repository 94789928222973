import React, { useContext } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Tooltip from "@material-ui/core/Tooltip";
import clsx from "clsx";
import YoutubeDialog from "./YoutubeDialog";
import ArticleDialog from "./ArticleDialog";
import { ActionContext } from "../../../../contexts";
import { formatDate } from "../../../../utils";
import { connect } from "react-redux";
import { makeStyles, alpha } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: alpha(theme.palette.primary.light, 0.2),
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.grey[500],
  },
  text: {
    display: "flex",
    width: 500,
    flexDirection: "column",
  },
  avatarTextRed: {
    color: "#ff1744",
  },
  avatarTextGreen: {
    color: "#00c853",
  },
}));

let FeedItem = (props) => {
  let classes = useStyles();
  const { dialogActions } = useContext(ActionContext);
  let { item, type, source, playerImage, style } = props;

  let handleItemClick = (url) => () => {
    window.open(url, "_blank");
  };

  let isNegatives = type === "negatives";
  let isPositives = type === "positives";

  const isButton = source === "youtube" || source === "newspaper";

  const handleClick = () => {
    if (source === "youtube") {
      dialogActions.openDialog(
        <YoutubeDialog
          url={item.url}
          dialogs={item.info.dialogs}
          title={item.text}
          playerImage={playerImage}
        />,
        {
          fullScreen: true,
        }
      );
    }

    if (source === "newspaper") {
      dialogActions.openDialog(
        <ArticleDialog paragraphs={item.info.paragraphs} title={item.text} />,
        { fullScreen: true }
      );
    }
  };

  return (
    <ListItem button={isButton} style={style}>
      <ListItemAvatar>
        <Avatar
          className={clsx(classes.avatar, {
            [classes.avatarTextRed]: isNegatives,
            [classes.avatarTextGreen]: isPositives,
          })}
        >
          {item.score}
        </Avatar>
      </ListItemAvatar>
      <div className={classes.text} onClick={handleClick}>
        <Tooltip
          title={item.text}
          enterTouchDelay={300}
          enterDelay={300}
          placement="bottom-end"
          arrow
        >
          <Typography variant="subtitle2" noWrap>
            {item.text}
          </Typography>
        </Tooltip>
        <Typography variant="caption" color="textSecondary">
          {formatDate(item.date)}
        </Typography>
      </div>
      <div style={{ display: "flex", flex: 1, flexDirection: "row-reverse" }}>
        <IconButton edge="end" onClick={handleItemClick(item.url)}>
          <OpenInNewIcon />
        </IconButton>
      </div>
    </ListItem>
  );
};

let mapStateToProps = (state, ownProps) => {
  let { data, index, playerId } = ownProps;
  let itemId = data[index];
  return {
    playerImage: state.player.basic[playerId]["image"],
    item: state.player.feed.dataById[itemId],
  };
};

export default connect(mapStateToProps)(FeedItem);
