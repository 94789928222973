import IFrame from "./IFrame";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ChatBubble from "./ChatBubble";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex",
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.colors.veryLightGrey,
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  appBar: {
    position: 'fixed',
    backgroundColor: theme.palette.common.white,
  },
  header: {
    fontFamily: theme.fontFamily.prometo,
    color: theme.palette.text.primary,
    flex: 1,
  },
  iconColor: {
    color: theme.palette.text.primary,
  },
  container: {
    flexGrow: 1,
    display: "flex",
    margin: "2rem",
    backgroundColor: theme.palette.common.white,
    boxShadow: "3px 3px 10px 3px #cdcdcd",
    borderRadius: 8,
    padding: "2rem 1rem",
    justifyContent: 'space-evenly'
  },
  video: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginRight: '1rem',
  },
  subtitle: {
    paddingLeft: 16,
    paddingTop: 16,
    marginTop: 0,
    color: theme.palette.text.primary,
    fontFamily: theme.fontFamily.prometo,
    fontSize: '1.1rem'
  },
  list: {
    height: "calc(100vh - 130px)",
    overflowY: "scroll",
    width: 500,
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
}));

const VideoDialog = (props) => {
  const classes = useStyles();
  const {
    closeDialog,
    dialogs = [],
    title = "",
    url,
    playerImage = null,
  } = props;

  return (
    <div>
      <AppBar
        elevation={0}
        className={classes.appBar}
      >
        <Toolbar>
          <Typography variant="h6" className={classes.header}>
            {title}
          </Typography>
          <IconButton onClick={closeDialog}>
            <CloseIcon className={classes.iconColor}/>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.video}>
            <IFrame url={url} width={'100%'} height={500}/>
          </div>
          <div>
            <Typography variant="subtitle2" className={classes.subtitle}>
              Conversation
            </Typography>
            <List className={classes.list}>
              {dialogs.map((dialog, index) => {
                return (
                    <ListItem key={index}>
                      <ChatBubble
                          text={dialog.text}
                          isSelf={dialog.is_self}
                          score={dialog.score}
                          coachImage={playerImage}
                      />
                    </ListItem>
                );
              })}
            </List>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoDialog;
