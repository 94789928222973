import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
import appConfig from "../../../config";
import { ActionContext } from "../../../contexts";
import PlayerDetails from "./PlayerDetails";
import PlayerList from "./PlayerList";
import SearchContainer from "./SearchContainer";

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "calc(100vh - 50px)",
    width: "100%",
    [theme.breakpoints.only("sm")]: {
      height: "calc(100vh - 100px)",
      marginTop: 50,
    },
    [theme.breakpoints.only("xs")]: {
      height: "calc(100vh - 100px)",
    },
  },
  list: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    boxSizing: "border-box",
    borderRight: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.between("lg", "xl")]: {
      maxWidth: 350,
    },
  },
  details: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    boxSizing: "border-box",
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
  },
  detailsText: {
    alignSelf: "center",
  },
  toolbar: {
    display: "flex",
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

let Players = (props) => {
  let classes = useStyles();
  let { selectedId, teams, isDrawerOpen } = props;
  let drawerWidth = isDrawerOpen
    ? appConfig.drawer.maxWidth
    : appConfig.drawer.minWidth;
  let selected = Boolean(selectedId);
  let actions = useContext(ActionContext);
  let { playerActions, conferenceActions, sportsActions } = actions;

  useEffect(() => {
    if (Object.keys(teams).length === 0) {
      playerActions.getTeamList();
    }
    playerActions.getPlayerList();
    conferenceActions.getConferenceList();
    sportsActions.getSportsList();

    return () => {
      playerActions.resetSearch();
    };
  }, []);

  return (
    <React.Fragment>
      <div className={classes.root} style={{ paddingLeft: drawerWidth }}>
        <div className={classes.list}>
          <Toolbar variant="dense" className={classes.toolbar} disableGutters>
            <SearchContainer />
          </Toolbar>
          <PlayerList />
        </div>
        <Hidden mdDown>
          <div className={classes.details}>
            {selected ? (
              <PlayerDetails playerId={selectedId} />
            ) : (
              <Typography
                variant="subtitle2"
                className={classes.detailsText}
                color="textSecondary"
              >
                Select an Athlete to view
              </Typography>
            )}
          </div>
        </Hidden>
      </div>
    </React.Fragment>
  );
};

let mapStateToProps = (state) => {
  return {
    selectedId: state.ui.player.selectedPlayerId,
    teams: state.ui.player.candidateTeams,
    isDrawerOpen: state.ui.app.isDrawerOpen,
  };
};
export default connect(mapStateToProps)(Players);
