import React from "react";
import MuiIconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "../CircularProgress";

let IconButton = (props) => {
  let {
    tooltip = null,
    loading = false,
    disabled = false,
    icon,
    onClick,
  } = props;

  let handleClick = () => {
    onClick();
  };

  let isDisabled = loading || disabled;

  let button = (
    <MuiIconButton onClick={handleClick} disabled={isDisabled}>
      {loading ? <CircularProgress color="primary" /> : icon}
    </MuiIconButton>
  );

  if (tooltip) {
    return <Tooltip title={tooltip}>{button}</Tooltip>;
  }

  return <React.Fragment>{button}</React.Fragment>;
};

export default IconButton;
