import React, { useContext, useState } from "react";
import Popover from "../../shared/Popover";
import AlertIconButton from "./AlertIconButton";
import NotificationList from "./NotificationList";
import Button from "../../shared/Button";
import { v4 as uuidv4 } from "uuid";
import { ActionContext } from "../../../contexts";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: 350,
    maxHeight: '400px',
    overflowY: 'auto',
    
  },
  list: {
    flex: 1,
    fontSize: '12px'
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(1),
    background: theme.colors.white,
    zIndex: 9
  },
}));

let NotificationsPopover = (props) => {
  let classes = useStyles();
  let { accountActions } = useContext(ActionContext);
  let { notifications, unreadCount } = props;

  let [key, setKey] = useState("notificationlist");
  let hasNotifications = notifications.length > 0;

  let handleClick = () => {
    let handleSuccess = () => setKey(uuidv4());
    accountActions.markNotificationsAsRead(handleSuccess);
  };

  return (
    <Popover
      component={AlertIconButton}
      ComponentProps={{ notificationCount: unreadCount }}
    >
      <div className={classes.root}>
        <NotificationList key={key} notifications={notifications} />

        {hasNotifications ? (
          <div className={classes.footer}>
            <Button variant="text" onClick={handleClick}>
              Mark all as read
            </Button>
          </div>
        ) : null}
      </div>
    </Popover>
  );
};

let mapStateToProps = (state) => {
  let unreadCount = state.account.notifications.unreadIds.length;
  return {
    notifications: state.account.notifications.allIds,
    unreadCount,
  };
};

export default connect(mapStateToProps)(NotificationsPopover);
