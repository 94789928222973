import * as types from "./accountTypes";
import { combineReducers } from "redux";

const loginInitialState = {
  loading: false,
  error: null,
};

const loginReducer = (state = loginInitialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_SIGNIN: {
      return {
        ...state,
        loading: true,
      };
    }

    case types.SIGNIN_SUCCESS: {
      return {
        ...state,
        error: null,
        loading: false,
      };
    }

    case types.SIGNIN_FAILED: {
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    }

    case types.RESET_LOGIN_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const forgotPasswordInitialState = {
  loading: false,
  error: null,
};

const forgotPasswordReducer = (state = forgotPasswordInitialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_RESET_PASSWORD: {
      return {
        ...state,
        loading: true,
      };
    }

    case types.RESET_PASSWORD_SUCCESS:
    case types.RESET_PASSWORD_FAILED: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }

    default:
      return state;
  }
};

const verifyAccountInitialState = {
  loading: false,
  email: null,
  error: null,
};

const verifyAccountReducer = (state = verifyAccountInitialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_VERIFY_ACCOUNT: {
      return {
        ...state,
        loading: true,
      };
    }

    case types.VERIFY_ACCOUNT_SUCCESS: {
      return {
        ...verifyAccountInitialState,
      };
    }

    case types.VERIFY_ACCOUNT_FAILED: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }

    case types.SET_VERIFY_EMAIL: {
      return {
        ...state,
        email: payload.email,
      };
    }

    case types.SET_VERIFY_ERROR: {
      return {
        ...state,
        error: payload.error,
      };
    }

    default:
      return state;
  }
};

const registerInitialState = {
  loading: false,
  error: null,
};

const registerReducer = (state = registerInitialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_SIGNUP: {
      return {
        ...state,
        loading: true,
      };
    }

    case types.SIGNUP_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case types.SIGNUP_FAILED: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }

    case types.RESET_ACCOUNT_CREATION_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const changePasswordInitialState = {
  loading: false,
  error: null,
};

const changePasswordReducer = (state = changePasswordInitialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_CHANGE_PASSWORD: {
      return {
        ...state,
        loading: true,
      };
    }

    case types.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...changePasswordInitialState,
      };
    }

    case types.CHANGE_PASSWORD_FAILED: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }

    default:
      return state;
  }
};

const loadingProfile = (state = false, action) => {
  switch (action.type) {
    case types.REQUEST_GET_PROFILE: {
      return true;
    }

    case types.GET_PROFILE_SUCCESS:
    case types.GET_PROFILE_FAILED: {
      return false;
    }

    default:
      return state;
  }
};

const profileReducer = combineReducers({
  loadingProfile,
});

const loadingUserTypes = (state = false, action) => {
  switch (action.type) {
    case types.REQUEST_GET_USER_TYPES: {
      return true;
    }

    case types.GET_USER_TYPES_SUCCESS:
    case types.GET_USER_TYPES_FAILED: {
      return false;
    }

    default:
      return state;
  }
};

const userTypes = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_USER_TYPES_SUCCESS: {
      return {
        ...payload.userTypes,
      };
    }

    default:
      return state;
  }
};

const alert = sessionStorage.getItem("@appAlert") || null;
const appAlert = (state = alert, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.SET_APP_ALERT: {
      return payload.message;
    }

    case types.RESET_APP_ALERT: {
      return null;
    }

    default:
      return state;
  }
};

const loadingNotifications = (state = false, action) => {
  switch (action.type) {
    case types.REQUEST_GET_NOTIFICATIONS: {
      return true;
    }

    case types.GET_NOTIFICATIONS_SUCCESS:
    case types.GET_NOTIFICATIONS_FAILED: {
      return false;
    }

    default:
      return state;
  }
};

const markingAsRead = (state = false, action) => {
  switch (action.type) {
    case types.REQUEST_MARK_NOTIFICATIONS_AS_READ: {
      return true;
    }

    case types.MARK_NOTIFICATIONS_AS_READ_SUCCESS:
    case types.MARK_NOTIFICATIONS_AS_READ_FAILED: {
      return false;
    }

    default:
      return state;
  }
};

const notificationsReducer = combineReducers({
  loadingNotifications,
  markingAsRead,
});

const selectedAccountLoading = (state = true, action) => {
  switch (action.type) {
    case types.GET_ACCOUNT_BY_EMAIL_REQUEST: {
      return true;
    }

    case types.GET_ACCOUNT_BY_EMAIL_SUCCESS:
    case types.GET_ACCOUNT_BY_EMAIL_FAILED: {
      return false;
    }

    default:
      return state;
  }
};

export default combineReducers({
  login: loginReducer,
  forgot: forgotPasswordReducer,
  verify: verifyAccountReducer,
  register: registerReducer,
  changePassword: changePasswordReducer,
  profile: profileReducer,
  notifications: notificationsReducer,
  appAlert,
  loadingUserTypes,
  userTypes,
  selectedAccountLoading,
});
