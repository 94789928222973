import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { Field } from "formik";
import { TextField, Select } from "formik-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Autocomplete } from "formik-material-ui-lab";
import MuiTextField from "@material-ui/core/TextField";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { ActionContext } from "../../../../contexts";

let useStyles = makeStyles((theme) => ({
  heading: {
    // marginTop: theme.spacing(3),
    //marginBottom: theme.spacing(3),
    maxWidth: 480,
    fontWeight: 500,
    lineHeight: "60px",
  },
  form: {
    paddingTop: theme.spacing(1),
    maxWidth: '100%',
    marginRight: '1rem',
  },
}));

let CreateCoachBackground = (props) => {
  let classes = useStyles();

  const { errors, touched, values, sportsData, countriesData, stateData, unitData, conferenceByType } = props;

  let actions = useContext(ActionContext);
  let { sportsActions, conferenceActions } = actions;

  const conferenceTypes = {
    "Professional": "Professional",
    "Collegiate": "Collegiate"
  }

  const handleCountryName = (value) => {
    values.homeCountry = value;
    conferenceActions.getStatesByCountryName(value);
  }

  const handleConferenceType = (value) => {
    values.conference_type = value;
    conferenceActions.getConferenceByType(value);
  }

  const handleSportChange = (value) => {
    values.sport = value;
    sportsActions.getSportUnitDetails(value);
  }

  let organizationSports = sportsData && sportsData.reduce((obj, item) => {
    obj[item.name] = item.name;
    return obj;
  }, {});

  let finalSports = JSON.parse(JSON.stringify(organizationSports));

  let organizationUnit = unitData && unitData.reduce((obj, item) => {
    obj[item.unit] = item.unit;
    return obj;
  }, {});

  let finalUnits = JSON.parse(JSON.stringify(organizationUnit));



  return (
    <React.Fragment>
      <Typography variant="h4" className={classes.heading}>
        Let's get some backgorund info
      </Typography>
      <div className={classes.form}>
        <Grid container spacing={3}>


          <Grid item lg={6} sm={12}>
            <Field
              name="homeCountry"
              required
              component={Autocomplete}
              options={Object.keys(countriesData)}
              renderInput={(props) => {
                return (
                  <MuiTextField
                    variant="outlined"
                    label="Home Country"
                    placeholder="Home Country"
                    error={touched["homeCountry"] && !!errors["homeCountry"]}
                    helperText={errors["homeCountry"]}
                    required
                    {...props}
                  />
                );
              }}
              onChange={(e, value) => {
                handleCountryName(value)
              }}

              renderOption={(value, { inputValue }) => {
                const matches = match(value, inputValue);
                const parts = parse(value, matches);

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
            />
          </Grid>

          <Grid item lg={6} sm={12}>
            <Field
              name="homeState"
              component={Autocomplete}
              options={Object.keys(stateData) || {}}
              renderInput={(props) => {
                return (
                  <MuiTextField
                    variant="outlined"
                    label="Home State"
                    placeholder="Home State"
                    error={touched["homeState"] && !!errors["homeState"]}
                    helperText={errors["homeState"]}
                    {...props}
                  />
                );
              }}
              renderOption={(value, { inputValue }) => {
                const matches = match(value, inputValue);
                const parts = parse(value, matches);

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
            />
          </Grid>

          <Grid item sm={12}>
            <Field
              name="hometown"
              label="Hometown"
              placeholder="Hometown"
              component={TextField}
              variant="outlined"
              fullWidth
            />
          </Grid>

          {/* <Grid item lg={7} sm={12}>
            <Field
              name="school"
              label="School"
              placeholder="School"
              component={TextField}
              variant="outlined"
              fullWidth
            />
          </Grid> */}

          {/* <Grid item lg={5} sm={12}>
            <Field
              name="race"
              label="Race"
              placeholder="Race"
              component={TextField}
              variant="outlined"
              fullWidth
            />
          </Grid> */}

          <Grid item lg={6} sm={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="race-label">
                Race/Ethnicity *
              </InputLabel>
              <Field
                component={Select}
                name="race"
                label="Race/Ethnicity *"
                placeholder="Race/Ethnicity"
                labelId="race-label"
                inputProps={{
                  id: "race",
                }}
              >
                <MenuItem value="Race">American Indian/Alaska Native</MenuItem>
                <MenuItem value="Asian">Asian</MenuItem>
                <MenuItem value="Black/African American">Black/African American</MenuItem>
                <MenuItem value="Hispanic/Latino">Hispanic/Latino</MenuItem>
                <MenuItem value="Native Hawaiian/Pacific Islander">Native Hawaiian/Pacific Islander</MenuItem>
                <MenuItem value="White">White</MenuItem>
              </Field>
            </FormControl>
          </Grid>

          {/* <Grid item sm={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="relationship-label">
                Relationship Status
              </InputLabel>
              <Field
                component={Select}
                name="relationship"
                label="Relationship Status"
                placeholder="Status"
                labelId="relationship-label"
                inputProps={{
                  id: "relationship",
                }}
              >
                <MenuItem value="Single">Single</MenuItem>
                <MenuItem value="Married">Married</MenuItem>
                <MenuItem value="Complicated">Complicated</MenuItem>
              </Field>
            </FormControl>
          </Grid> */}

          <Grid item lg={6} sm={12}>
            <Field
              name="conference_type"
              required
              component={Autocomplete}
              options={Object.keys(conferenceTypes)}
              renderInput={(props) => {
                return (
                  <MuiTextField
                    variant="outlined"
                    label="League"
                    placeholder="League"
                    error={touched["conference_type"] && !!errors["conference_type"]}
                    helperText={errors["conference_type"]}
                    required
                    {...props}
                  />
                );
              }}
              onChange={(e, value) => {
                handleConferenceType(value)
              }}
              renderOption={(value, { inputValue }) => {
                const matches = match(value, inputValue);
                const parts = parse(value, matches);

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
            />
          </Grid>

          <Grid item lg={6} sm={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="conference-label">
                Conference *
              </InputLabel>
              <Field
                component={Select}
                name="conference"
                label="Conference *"
                placeholder="Conference"
                labelId="conference-label"
                inputProps={{
                  id: "conference",
                }}
                required
              >
                {
                  conferenceByType && conferenceByType.map(list =>
                    <MenuItem value={list.name}>{list.name}</MenuItem>
                  )
                }
              </Field>
            </FormControl>
          </Grid>


          <Grid item lg={6} sm={12}>
            <Field
              name="sport"
              required
              component={Autocomplete}
              options={Object.keys(finalSports)}
              renderInput={(props) => {
                return (
                  <MuiTextField
                    variant="outlined"
                    label="Sport"
                    placeholder="Sport"
                    error={touched["sport"] && !!errors["sport"]}
                    helperText={errors["sport"]}
                    required
                    {...props}
                  />
                );
              }}
              onChange={(e, value) => {
                handleSportChange(value)
              }}
              renderOption={(value, { inputValue }) => {
                const matches = match(value, inputValue);
                const parts = parse(value, matches);

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
            />
          </Grid>

          <Grid item lg={6} sm={12}>
            <Field
              name="unit"
              required
              component={Autocomplete}
              options={Object.keys(finalUnits)}
              renderInput={(props) => {
                return (
                  <MuiTextField
                    variant="outlined"
                    label="Unit"
                    placeholder="Unit"
                    error={touched["unit"] && !!errors["unit"]}
                    helperText={errors["unit"]}
                    required
                    {...props}
                  />
                );
              }}
              renderOption={(value, { inputValue }) => {
                const matches = match(value, inputValue);
                const parts = parse(value, matches);

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
            />
          </Grid>

          <Grid item lg={6} sm={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="coach_type-label">
                Coach Type
              </InputLabel>
              <Field
                component={Select}
                name="coach_type"
                label="Coach Type *"
                placeholder="Type"
                required
                labelId="coach_type-label"
                inputProps={{
                  id: "coach_type",
                }}
              >
                <MenuItem value="Current Employee">Current Employee</MenuItem>
                <MenuItem value="Prospect Hire">Prospect Hire</MenuItem>
              </Field>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};
// export default CreateCoachBackground;
let mapStateToProps = (state) => {
  return {
    sportsData: state.sports.sportsListData,
    countriesData: state.conference.allCountries,
    stateData: state.conference.allStateByCountry,
    unitData: state.sports.unitSport,
    conferenceByType: state.conference.allConferenceByType,
  };
};
export default connect(mapStateToProps)(CreateCoachBackground);
