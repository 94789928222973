import React, { useContext, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import NotificationListItem from "../../app/NotificationsPopover/NotificationListItem";
import { connect } from "react-redux";
import { ActionContext } from "../../../contexts";
import { makeStyles } from "@material-ui/core/styles";

let useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 48px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
  },
  container: {
    paddingTop: theme.spacing(5),
  },
  heading: {
    padding: theme.spacing(2, 2, 1, 2),
  },
  list: {
    paddingBottom: theme.spacing(2),
    maxHeight: 400,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: 7,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.background.paper,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[200],
    },
  },
}));

let Settings = (props) => {
  let classes = useStyles();
  let { accountActions } = useContext(ActionContext);
  let { notifications } = props;

  useEffect(() => {
    accountActions.getNotifications();
  }, []);

  let showDivider = notifications.length > 1;

  if (notifications.length === 0) {
    return <div>loading...</div>;
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="sm" className={classes.container}>
        <Paper variant="outlined" style={{ marginTop: 40 }}>
          <div className={classes.heading}>
            <Typography variant="h6">Notifications</Typography>
          </div>
          <List className={classes.list}>
            {notifications.map((id) => (
              <NotificationListItem id={id} key={id} divider={showDivider} />
            ))}
          </List>
        </Paper>
      </Container>
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    notifications: state.account.notifications.allIds,
  };
};
export default connect(mapStateToProps)(Settings);
