import React, {useContext} from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import Badge from "@material-ui/core/Badge";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {ActionContext} from "../../../contexts";

let useStyles = makeStyles(() => ({
  root: {
    cursor: "pointer",
    fontSize: '12px'
  },
}));

let NotificationListItem = (props) => {
  let classes = useStyles();
  let history = useHistory();
  const { accountActions } = useContext(ActionContext);

  let { id, notification } = props;

  let isInvisible = notification.status !== "Unread";

  let handleNotificationClick = () => {
    accountActions.markNotificationByIdAsRead(id)
    history.push("/account/notifications");
  };

  return (
    <ListItem
      divider
      className={classes.root}
      onClick={handleNotificationClick}
    >
      <ListItemIcon>
        <Badge
          color="primary"
          badgeContent={" "}
          variant="dot"
          invisible={isInvisible}
        >
          <MailIcon />
        </Badge>
      </ListItemIcon>
      <ListItemText
        primary={notification.title}
        secondary={notification.content}
      />
    </ListItem>
  );
};

let mapStateToProps = (state, ownProps) => {
  let { id } = ownProps;
  return {
    notification: state.account.notifications.byId[id],
  };
};
export default connect(mapStateToProps)(NotificationListItem);
