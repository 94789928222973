import React from "react";
import LockIcon from "@material-ui/icons/Lock";
import { accountTypes } from "../../../store/constants";
import { makeStyles, Paper, Typography } from "@material-ui/core";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(5),
    width: "100%",
    height: 64,
    backgroundColor: theme.palette.primary.main,
  },
}));

const withPaymentLock = (WrappedComponent) => {
  const Gate = (props) => {
    const classes = useStyles();
    const { isPaid, accountType, ...rest} = props;

    const hasAccess =
      accountType === accountTypes.mmDdiAdmin ||
      accountType === accountTypes.mmPsychologist ||
      accountType === accountTypes.superAdmin ||
      accountType === accountTypes.mmChiefPsychologist;

    if (!hasAccess && isPaid === false) {
      return (
        <Paper className={classes.root} elevation={2} square>
          <LockIcon style={{ color: "#fff", marginRight: 16 }} />
          <Typography variant="subtitle2" style={{ color: "#fff" }}>
            This is a paid feature. Please contact us to get access
          </Typography>
        </Paper>
      );
    }

    return <WrappedComponent {...rest} accountType={accountType} />;
  };

  let mapStateToProps = (state) => {
    const coachId = state.ui.coach.selectedCoachId;

    return {
      isPaid: state.coach.basic[coachId].isPaid,
      accountType: state.account.accountType,
    };
  };

  return connect(mapStateToProps)(Gate);
};

export default withPaymentLock;
