import React, { useContext } from "react";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import { accountTypes, consentTypes, statusTypes } from "../../../../store/constants";
import { ActionContext } from "../../../../contexts";
import { connect } from "react-redux";
import { ToggleButton } from "../../../shared/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "none",
    width: 48,
    height: 36,
    "&.Mui-selected": {
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
    },
  },
}));

const MarkAsReviewCompleteToggleButton = (props) => {
  let classes = useStyles();
  let { playerId, accountType, loading, consent, status } = props;
  let { playerActions } = useContext(ActionContext);

  let hasAuthorizedToMarkReviewComplete =
    accountType === accountTypes.mmPsychologist;

  let hasStatus =
    status === statusTypes.psychReviewPending ||
    status === statusTypes.completed ||
    status === statusTypes.consentPending;

  let enableReview = hasAuthorizedToMarkReviewComplete && hasStatus;

  let nextStatus =
    consent === consentTypes.received
      ? statusTypes.completed
      : statusTypes.consentPending;

  let isSelected =
    status === statusTypes.completed || status === statusTypes.consentPending;

  let handleChange = () => {
    if (isSelected) {
      playerActions.markReviewIncomple(
        playerId,
        statusTypes.psychReviewPending
      );
    } else {
      playerActions.markReviewCompleted(playerId, nextStatus);
    }
  };

  if (enableReview) {
    return (
      <div style={{ marginLeft: 8 }}>
        <ToggleButton
          icon={<AssignmentTurnedInIcon />}
          loading={loading}
          selected={isSelected}
          onChange={handleChange}
          classes={{ root: classes.root }}
        />
      </div>
    );
  }

  return null;
};

let mapStateToProps = (state, ownProps) => {
  let { playerId } = ownProps;

  return {
    accountType: state.account.accountType,
    loading:
      state.ui.player.loadingMarkAsReviewCompleted[playerId] ||
      state.ui.player.loadingMarkAsReviewIncompleted[playerId],
  };
};

export default connect(mapStateToProps)(MarkAsReviewCompleteToggleButton);
