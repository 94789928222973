import React from "react";

const createContext = () => {
  const Context = React.createContext();

  const Provider = ({ value, children }) => {
    return <Context.Provider value={value}>{children}</Context.Provider>;
  };

  return [Context, Provider];
};

export default createContext;
