import React, { useState } from "react";
import Delete from "./DeleteIcon";
import { Box, makeStyles, Modal, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        flexDirection: "column",
        width: 350,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 8,
        padding: theme.spacing(2, 4, 3),
        outline: 'none',
    },
    modalContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
    heading: {
        fontSize: theme.fontSize.md,
        fontWeight: 500,
        color: theme.palette.text.primary,
        fontFamily: theme.fontFamily.robotoCondensed,
    },
    title: {
        fontWeight: 800,
        color: theme.palette.error.main,
    },
    secondaryBtn: {
        display: "flex",
        alignItems: "center",
        marginRight: "1rem",
        fontSize: "13px",
        fontFamily: theme.fontFamily.robotoCondensed,
        color: theme.palette.text.primary,
        fontWeight: 700,
        cursor: "pointer",
        textTransform: "uppercase",
    },
    primaryBtn: {
        display: "flex",
        alignItems: "center",
        marginRight: "1rem",
        fontSize: "13px",
        fontFamily: theme.fontFamily.robotoCondensed,
        color: theme.palette.error.main,
        fontWeight: 700,
        cursor: "pointer",
        textTransform: "uppercase",
    },
}));

const DeleteButtonModal = ({ title, handleDelete }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(prevState => !prevState);
    };

    return (
        <>
            <Box onClick={handleClick}>
                <Delete />
            </Box>
            <Modal
                open={open}
                onClose={handleClick}
            >
                <Box className={classes.modalContainer}>
                    <Box className={classes.modal}>
                        <Typography className={classes.heading}>
                            Are you sure you want to delete <span className={classes.title}>{title}</span>
                        </Typography>
                        <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={handleClick} className={classes.secondaryBtn}>No</Button>
                            <Button onClick={handleDelete} className={classes.primaryBtn}>Yes</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default DeleteButtonModal;
