import React, { useContext } from "react";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ReportDialog from "./ReportDialog";
import { ActionContext } from "../../../../contexts";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    btnPrimary: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '1rem',
        fontSize: '13px',
        fontFamily: theme.fontFamily.robotoCondensed,
        color: theme.colors.primaryNavy,
        fontWeight: 700,
        cursor: 'pointer',
        textTransform: 'uppercase',
    },
}));

const Reports = (props) => {
  const { playerId, status } = props;
  const { dialogActions } = useContext(ActionContext);
  const classes = useStyles();

  const handleClick = () => {
    dialogActions.openDialog(
      <ReportDialog playerId={playerId} status={status} />,
      {
        maxWidth: "xs",
      }
    );
  };

  return (
    <div
        className={classes.btnPrimary}
        startIcon={<AssessmentIcon />}
        onClick={handleClick}
        disableElevation
    >
        Reports
    </div>
  );
};

export default Reports;
