import React, { useState } from "react";
import { connect } from "react-redux";
import { Tabs } from "../../../shared/Tabs";
import ComingSoonImg from "../../../coach/CommingSoonImg";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
    container: {
        display: "flex",
        alignItems: "start",
        flexGrow: 1,
        flexDirection: "column",
        overflowX: 'auto',
        width: 'auto',
        [theme.breakpoints.down(1380)]: {
            maxWidth: 700,
        },
        [theme.breakpoints.down(1280)]: {
            maxWidth: 600,
        }
    },
}))

let CommunicationStyles = () => {
  let [selectedIndex, setSelectedIndex] = useState(0);
  const classes = useStyle();
  const communicationLabels = ['Your Athlete', 'About Dominant Styles', 'About Influential Styles', 'About Steady Styles', 'About Compliant Styles']

  let handleTabChange = (e, index) => {
    setSelectedIndex(index);
  };
  return (
      <div className={classes.container}>
          <Tabs
              labels={communicationLabels}
              value={selectedIndex}
              onChange={handleTabChange}
          />
          <div style={{width: '100%'}}>
              <ComingSoonImg/>
          </div>
      </div>
  );
};

let mapStateToProps = () => {

    return {};
};

export default connect(mapStateToProps)(CommunicationStyles);
