import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, makeStyles, Paper, Typography } from "@material-ui/core";

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  article: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  articleHeading: {
    display: "flex",
    flex: 1,
    padding: theme.spacing(2),
    height: 70,
  },
  expansionPanel: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    width: "100%",
  },
  articleHeadingDetails: {
    padding: theme.spacing(0, 2),
    height: '4rem',
    display: 'flex',
    alignItems: 'center'

  },
  title: {
    color: theme.palette.text.primary,
    fontSize: 19,
    fontWeight: 800,
    fontFamily: theme.fontFamily.prometo,
    textTransform: 'uppercase',
  },
  caption: {
    color: theme.palette.text.primary,
    fontSize: 13,
    fontWeight: 600,
    fontFamily: theme.fontFamily.robotoCondensed,
  },
}));

const Article = ({ children, title, details }) => {
  let classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={0} variant="outlined" className={classes.article}>
        <ExpansionPanel
          elevation={0}
          classes={{ root: classes.expansionPanel }}
          defaultExpanded={true}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.articleHeading}
            classes={{ root: classes.expansionPanel }}
          >
            <Typography variant="h6" className={classes.title}>{title}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.articleHeadingDetails}>
            <Typography variant="caption" className={classes.caption}>{details}</Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        {children}
      </Paper>
    </div>
  );
};

export default Article;
