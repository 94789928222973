import Avatar from "@material-ui/core/Avatar";
import { blue } from "@material-ui/core/colors";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Autorenew from "@material-ui/icons/Autorenew";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import SaveIcon from "@material-ui/icons/SaveOutlined";
import fileDownload from "js-file-download";
import React, { useContext } from "react";
import { connect } from "react-redux";
import { ActionContext } from "../../../../contexts";
import CircularProgress from "../../../shared/CircularProgress";
import { accountTypes, statusTypes } from "../../../../store/constants";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  enabled: {
    color: theme.palette.success.main,
  },
  pending: {
    color: theme.palette.warning.main,
  },
}));

const ReportDialog = (props) => {
  const classes = useStyles();

  const { playerActions } = useContext(ActionContext);

  const {
    status,
    closeDialog,
    reportSettings,
    exportingPsycologistReport,
    playerId,
    playerName,
    accountType,
    exportingFullReport,
    exportingActionPlanReport,
    exportingExecutiveSummaryReport,
    regeneratingFullReport,
    regeneratingActionPlanReport,
    regeneratingExecutiveSummaryReport,
  } = props;

  const isAdmin =
    accountType === accountTypes.superAdmin ||
    accountType === accountTypes.mmDdiAdmin ||
    accountType === accountTypes.mmPsychologist;

  const isFullReportEnabled = isAdmin ? true : reportSettings.fullReport;
  const isExecutiveSummaryReportEnabled = isAdmin
    ? true
    : reportSettings.executiveSummaryReport;

  // Action plan validation
  const isAcionPlanReportAllowed = isAdmin
    ? true
    : reportSettings.actionPlanReport;

  const isActionPlanReportReady =
    status === statusTypes.consentPending || status === statusTypes.completed;
  const isActionPlanReportEnabled =
    isAcionPlanReportAllowed && isActionPlanReportReady;

  const handleCloseDialog = () => {
    closeDialog();
  };

  const handleFullReport = () => {
    playerActions.exportFullReport(playerId, playerName, (data, name) => {
      return () => {
        fileDownload(data, name);
      };
    });
  };

  const handleActionPlanReport = () => {
    playerActions.exportActionPlanReport(playerId, playerName, (data, name) => {
      return () => {
        fileDownload(data, name);
      };
    });
  };

  const handleExecutiveSummaryReport = () => {
    playerActions.exportExecutiveSummaryReport(
      playerId,
      playerName,
      (data, name) => {
        return () => {
          fileDownload(data, name);
        };
      }
    );
  };

  const handleRegenerateFullReport = () => {
    playerActions.regenerateFullReport(playerId);
  };

  const handleRegenerateActionPlanReport = () => {
    playerActions.regenerateActionPlanReport(playerId);
  };

  const handleRegenerateExecutiveSummaryReport = () => {
    playerActions.regenerateExecutiveSummaryReport(playerId);
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", background: '#cdcdcd' }}>
        <DialogTitle id="simple-dialog-title" style={{ flex: 1 }}>
          Download Reports
        </DialogTitle>
        <div style={{ margin: "8px 16px 8px 8px" }}>
          <IconButton onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <List>
        {/* Full Report */}
        <ListItem
          disabled={
            !isFullReportEnabled ||
            regeneratingFullReport ||
            exportingFullReport
          }
          onClick={handleFullReport}
          button
        >
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              {exportingFullReport ? (
                <CircularProgress color="primary" />
              ) : (
                <SaveIcon />
              )}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Full Report"
            secondary={"Ready"}
            secondaryTypographyProps={{
              className: classes.enabled,
            }}
          />

          <ListItemSecondaryAction>
            <Tooltip title="Regenerate full report">
              <IconButton
                color="primary"
                onClick={handleRegenerateFullReport}
                disabled={
                  !isFullReportEnabled ||
                  regeneratingFullReport ||
                  exportingFullReport
                }
              >
                {regeneratingFullReport ? (
                  <CircularProgress color="primary" />
                ) : (
                  <Autorenew />
                )}
              </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        </ListItem>

        {/* Executive Summary Report */}
        <ListItem
          disabled={
            !isExecutiveSummaryReportEnabled ||
            exportingExecutiveSummaryReport ||
            regeneratingExecutiveSummaryReport
          }
          onClick={handleExecutiveSummaryReport}
          button
        >
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              {exportingPsycologistReport ? (
                <CircularProgress color="primary" />
              ) : (
                <SaveIcon />
              )}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Executive Summary Report"
            secondary={"Ready"}
            secondaryTypographyProps={{
              className: classes.enabled,
            }}
          />
          <ListItemSecondaryAction>
            <Tooltip title="Regenerate executive summary report">
              <IconButton
                color="primary"
                onClick={handleRegenerateExecutiveSummaryReport}
                disabled={
                  !isExecutiveSummaryReportEnabled ||
                  regeneratingExecutiveSummaryReport ||
                  exportingExecutiveSummaryReport
                }
              >
                {regeneratingExecutiveSummaryReport ? (
                  <CircularProgress color="primary" />
                ) : (
                  <Autorenew />
                )}
              </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        </ListItem>

        {/* Action Plan Report */}
        <ListItem
          disabled={
            !isActionPlanReportEnabled ||
            exportingActionPlanReport ||
            regeneratingActionPlanReport
          }
          onClick={handleActionPlanReport}
          button
        >
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              {exportingPsycologistReport ? (
                <CircularProgress color="primary" />
              ) : (
                <SaveIcon />
              )}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Action Plan Report"
            secondary={isActionPlanReportReady ? "Ready" : "Pending"}
            secondaryTypographyProps={{
              className: isActionPlanReportReady
                ? classes.enabled
                : classes.pending,
            }}
          />
          <ListItemSecondaryAction>
            <Tooltip title="Regenerate actio plan report">
              <IconButton
                color="primary"
                onClick={handleRegenerateActionPlanReport}
                disabled={
                  !isActionPlanReportEnabled ||
                  regeneratingActionPlanReport ||
                  exportingActionPlanReport
                }
              >
                {regeneratingActionPlanReport ? (
                  <CircularProgress color="primary" />
                ) : (
                  <Autorenew />
                )}
              </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { playerId } = ownProps;

  return {
    accountType: state.account.accountType,
    reportSettings: state.player.reportSettings[playerId],
    playerName: state.player.basic[playerId]["name"],
    exportingFullReport: state.ui.player.exportingFullReport[playerId] || false,
    exportingActionPlanReport:
      state.ui.player.exportingActionPlanReport[playerId] || false,
    exportingExecutiveSummaryReport:
      state.ui.player.exportingExecutiveSummaryReport[playerId] || false,
    regeneratingFullReport:
      state.ui.player.regeneratingFullReport[playerId] || false,
    regeneratingActionPlanReport:
      state.ui.player.regeneratingActionPlanReport[playerId] || false,
    regeneratingExecutiveSummaryReport:
      state.ui.player.regeneratingExecutiveSummaryReport[playerId] || false,
  };
};
export default connect(mapStateToProps)(ReportDialog);
