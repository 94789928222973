import React from "react";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/RateReviewOutlined";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import RadialBar from "./RadialBar";
import { connect } from "react-redux";
import { round } from "../../../utils";
import { makeStyles } from "@material-ui/core/styles";

let roundTo1PrecisionPoint = (value) => {
  return round(value, 1);
};

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 16,
  },
  details: {
    display: "flex",
    flex: 1,
    paddingRight: theme.spacing(1),
  },
}));

let Comment = (props) => {
  let { comment } = props;

  return (
    <ListItem>
      <ListItemText secondary={comment.content} />
    </ListItem>
  );
};

let mapStateToPropsComments = (state, ownProps) => {
  let { id } = ownProps;

  return {
    comment: state.player.risk.comments[id],
  };
};

Comment = connect(mapStateToPropsComments)(Comment);

const ChartAndDetails = (props) => {
  let classes = useStyles();
  let { options } = props;
  let {
    id,
    diagnosis,
    score = 0,
    comments = [],
    onComment,
    editable = false,
  } = props;

  let color = "#fc3b2d";
  if (score <= 33) {
    color = "#93E566";
  } else if (score <= 66) {
    color = "#fc952d";
  }

  let handleComment = (id) => {
    return () => {
      onComment(id);
    };
  };

  return (
    <div className={classes.root}>
      <div style={{ width: 8 }}></div>
      <RadialBar
        score={roundTo1PrecisionPoint(score)}
        options={options}
        color={color}
        width="100"
      />

      <div className={classes.details}>
        <div
          style={{ display: "flex", flex: 1, justifyContent: "space-between" }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle2" style={{ paddingLeft: 16 }}>
              {diagnosis}
            </Typography>

            <List dense>
              {comments.map((comment, index) => {
                return <Comment key={index} id={comment} />;
              })}
            </List>
          </div>
          <div style={{ width: 32, minWidth: 32 }}>
            {editable ? (
              <IconButton
                size="small"
                style={{ marginRight: 8 }}
                onClick={handleComment(id)}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

let mapStateToProps = (state, ownProps) => {
  let { playerId, categoryId } = ownProps;

  return {
    comments: state.player.risk.categoryComments[`${playerId}.${categoryId}`],
  };
};
export default connect(mapStateToProps)(ChartAndDetails);
