import React, {useContext, useEffect} from "react";
import { Field } from "formik";
import { TextField, Select } from "formik-material-ui";
import { Grid, FormControl, InputLabel, makeStyles, MenuItem, Typography } from "@material-ui/core";
import {connect, useSelector} from "react-redux";
import { Autocomplete } from "formik-material-ui-lab";
import MuiTextField from "@material-ui/core/TextField";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { ActionContext } from "../../../contexts";
import FormHelperText from "@material-ui/core/FormHelperText";

let useStyles = makeStyles((theme) => ({
  heading: {
    maxWidth: 480,
    fontWeight: 500,
    lineHeight: "60px",
    color: theme.palette.text.primary,
    fontFamily: theme.fontFamily.prometo,
  },
  form: {
    paddingTop: theme.spacing(1),
    maxWidth: '100%',
  },
}));

let CreateCoachBackground = (props) => {
  let classes = useStyles();
  const { errors, touched, values, countriesData, stateData, unitData, allConferenceByType, sportByConference } = props;
  let actions = useContext(ActionContext);
  let { sportsActions, conferenceActions } = actions;
  let finalUnits = unitData.map(u => u.unit_name);
  const accountState = useSelector((state) => state.account.accountData);
  const current_team = accountState.team !== "MM-DDI";

  useEffect(() => {
    if (values.conferenceType.length > 0) {
      conferenceActions.getConferenceByType(values.conferenceType);
    }
  }, []);

  useEffect(() => {
    if (values.conference.length > 0) {
      let conference = allConferenceByType && allConferenceByType.find(conference => conference.name === values.conference);
      if (conference) {
        conferenceActions.getSportsByConferenceId(conference.id);
      }
    } else {
      conferenceActions.resetSportsByConferenceId();
    }
  }, [allConferenceByType, conferenceActions, values.conference]);

  useEffect(() => {
    if (values.sport.length > 0) {
      sportsActions.getSportUnitDetails(sportByConference._id);
      sportsActions.getSportPositionDetails(sportByConference._id);
    }
  }, [sportByConference._id, sportsActions, values.sport.length]);

  let conferenceByType = allConferenceByType && allConferenceByType.reduce((obj, item) => {
    obj[item.name] = item.name;
    return obj;
  }, {});

  const handleCountryName = (value) => {
    values.homeCountry = value;
    conferenceActions.getStatesByCountryName(value);
  }

  const handleConferenceType = (value) => {
    values.conferenceType = value;
    conferenceActions.getConferenceByType(value);
  }

  const handleConference = (value) => {
    values.conference = value;
    values.sport = "";
    values.unit = "";
    values.positions = "";
    if (value !== null) {
      let conference = allConferenceByType && allConferenceByType.find(conference => conference.name === value);
      conferenceActions.getSportsByConferenceId(conference.id);
    } else {
      conferenceActions.resetSportsByConferenceId();
    }
  };

  const handleSportChange = (value) => {
    values.sport = value;
    values.unit = "";
    if (value !== null) {
      sportsActions.getSportUnitDetails(sportByConference._id);
    } else {
      sportsActions.resetUnitsApi()
    }
  }

  const handleUnitChange = (value) => {
    values.unit = value
  };

  const handleCoachType = (value) => {
    values.coachType = value
  };

  let finalSport = {
    name: sportByConference.name,
  };

  const raceEthnicityType = [
    'American Indian/Alaska Native',
    'Asian',
    'Black/African American',
    'Hispanic/Latino',
    'Native Hawaiian/Pacific Islander',
    'White',
    'Unknown/Prefer Not to Identify',
  ];

  const relationshipStatus = [
    'Single',
    'Married',
    'Unknown'
  ];

  const conferenceTypes = {
    "Professional": "Professional",
    "Collegiate": "Collegiate"
  };

  const coachType = {
    "Current Employee": "Current Employee",
    "Prospect Hire": "Prospect Hire"
  }

  return (
    <React.Fragment>
      <Typography variant="h5" className={classes.heading}>
        Let's get some background info
      </Typography>
      <div className={classes.form}>
        <Grid container spacing={3}>
          <Grid item lg={6} sm={12}>
            <Field
              name="homeCountry"
              required
              component={Autocomplete}
              options={Object.keys(countriesData)}
              renderInput={(props) => {
                return (
                  <MuiTextField
                    variant="outlined"
                    label="Home Country"
                    placeholder="Home Country"
                    error={touched["homeCountry"] && !!errors["homeCountry"]}
                    helperText={<FormHelperText style={{ color: 'red' }}>{errors["homeCountry"]}</FormHelperText>}
                    required
                    {...props}
                  />
                );
              }}
              onChange={(e, value) => {
                handleCountryName(value)
              }}

              renderOption={(value, { inputValue }) => {
                const matches = match(value, inputValue);
                const parts = parse(value, matches);

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
            />
          </Grid>

          <Grid item lg={6} sm={12}>
            <Field
              name="homeState"
              component={Autocomplete}
              options={Object.keys(stateData) || {}}
              renderInput={(props) => {
                return (
                  <MuiTextField
                    variant="outlined"
                    label="Home State"
                    placeholder="Home State"
                    error={touched["homeState"] && !!errors["homeState"]}
                    helperText={errors["homeState"]}
                    {...props}
                  />
                );
              }}

              renderOption={(value, { inputValue }) => {
                const matches = match(value, inputValue);
                const parts = parse(value, matches);

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
            />
          </Grid>

          <Grid item sm={12}>
            <Field
              name="hometown"
              label="Hometown"
              placeholder="Hometown"
              component={TextField}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item lg={6} sm={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="gender-label">
                Gender *
              </InputLabel>
              <Field
                component={Select}
                name="gender"
                label="Gender"
                placeholder="Gender"
                labelId="gender-label"
                inputProps={{
                  id: "gender",
                }}
                required
              >
                <MenuItem value="Men's Coach">Men's Coach</MenuItem>
                <MenuItem value="Women's Coach">Women's Coach</MenuItem>
              </Field>
              {touched["gender"] && !!errors["gender"] ? (
                <FormHelperText style={{ color: 'red' }}>{errors["gender"]}</FormHelperText>
              ) : null}
            </FormControl>
          </Grid>

          <Grid item lg={6} sm={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="race-label">
                Race/Ethnicity *
              </InputLabel>
              <Field
                component={Select}
                name="race"
                label="Race/Ethnicity *"
                placeholder="Race/Ethnicity"
                labelId="race-label"
                inputProps={{
                  id: "race",
                }}
              >
                {
                  raceEthnicityType.map(label => (
                      <MenuItem value={label}>{label}</MenuItem>
                  ))
                }
              </Field>
              {touched["race"] && !!errors["race"] ? (
                <FormHelperText style={{ color: 'red' }}>{errors["race"]}</FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item sm={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="relationship-label">
                Relationship Status
              </InputLabel>
              <Field
                component={Select}
                name="relationship"
                label="Relationship Status"
                placeholder="Status"
                labelId="relationship-label"
                inputProps={{
                  id: "relationship",
                }}
              >
                {
                  relationshipStatus.map(label => (
                    <MenuItem value={label}>{label}</MenuItem>
                  ))
                }
              </Field>
            </FormControl>
          </Grid>
          <Grid item lg={6} sm={12}>
            <Field
              name="conferenceType"
              required
              component={Autocomplete}
              options={Object.keys(conferenceTypes)}
              disabled={values.conferenceType.length > 0 && current_team}
              renderInput={(props) => {
                return (
                  <MuiTextField
                    variant="outlined"
                    label="League"
                    placeholder="League"
                    error={touched["conferenceType"] && !!errors["conferenceType"]}
                    helperText={<FormHelperText style={{ color: 'red' }}>{errors["conferenceType"]}</FormHelperText>}
                    required
                    {...props}
                  />
                );
              }}
              onChange={(e, value) => {
                handleConferenceType(value)
              }}

              renderOption={(value, { inputValue }) => {
                const matches = match(value, inputValue);
                const parts = parse(value, matches);

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
            />
          </Grid>

          <Grid item lg={6} sm={12}>
            <Field
              name="conference"
              required
              component={Autocomplete}
              options={Object.keys(conferenceByType)}
              disabled={values.conferenceType.length > 0 && current_team}
              renderInput={(props) => {
                return (
                  <MuiTextField
                    variant="outlined"
                    label="Conference"
                    placeholder="Conference"
                    error={touched["conference"] && !!errors["conference"]}
                    helperText={<FormHelperText style={{ color: 'red' }}>{errors["conference"]}</FormHelperText>}
                    required
                    {...props}
                  />
                );
              }}
              onChange={(e, value) => {
                handleConference(value)
              }}
              renderOption={(value, { inputValue }) => {
                const matches = match(value, inputValue);
                const parts = parse(value, matches);

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
            />
          </Grid>

          <Grid item lg={6} sm={12}>
            <Field
              name="sport"
              required
              component={Autocomplete}
              options={Object.values(finalSport)}
              disabled={values.conferenceType.length > 0 && current_team}
              renderInput={(props) => {
                return (
                  <MuiTextField
                    variant="outlined"
                    label="Sport"
                    placeholder="Sport"
                    error={touched["sport"] && !!errors["sport"]}
                    helperText={<FormHelperText style={{ color: 'red' }}>{errors["sport"]}</FormHelperText>}
                    required
                    {...props}
                  />
                );
              }}
              onChange={(e, value) => {
                handleSportChange(value)
              }}

              renderOption={(value, { inputValue }) => {
                const matches = match(value, inputValue);
                const parts = parse(value, matches);

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
            />
          </Grid>

          <Grid item lg={6} sm={12}>
            <Field
              name="unit"
              required
              component={Autocomplete}
              options={Object.values(finalUnits)}
              renderInput={(props) => {
                return (
                  <MuiTextField
                    variant="outlined"
                    label="Unit"
                    placeholder="Unit"
                    error={touched["unit"] && !!errors["unit"]}
                    helperText={<FormHelperText style={{ color: 'red' }}>{errors["unit"]}</FormHelperText>}
                    required
                    {...props}
                  />
                );
              }}
              onChange={(e, value) => {
                handleUnitChange(value)
              }}

              renderOption={(value, { inputValue }) => {
                const matches = match(value, inputValue);
                const parts = parse(value, matches);

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Field
                name="coachType"
                required
                component={Autocomplete}
                options={Object.keys(coachType)}
                renderInput={(props) => {
                  return (
                      <MuiTextField
                          variant="outlined"
                          label="Coach Type"
                          placeholder="Coach Type"
                          error={
                              touched["coachType"] && !!errors["coachType"]
                          }
                          helperText={
                            <FormHelperText style={{ color: "red" }}>
                              {errors["coachType"]}
                            </FormHelperText>
                          }
                          required
                          {...props}
                      />
                  );
                }}
                onChange={(e, value) => {
                  handleCoachType(value);
                }}
                renderOption={(value, { inputValue }) => {
                  const matches = match(value, inputValue);
                  const parts = parse(value, matches);

                  return (
                      <div>
                        {parts.map((part, index) => (
                            <span
                                key={index}
                                style={{ fontWeight: part.highlight ? 700 : 400 }}
                            >
                        {part.text}
                      </span>
                        ))}
                      </div>
                  );
                }}
            />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

let mapStateToProps = (state) => {
  return {
    sportsData: state.sports.sportsListData,
    countriesData: state.conference.allCountries,
    stateData: state.conference.allStateByCountry,
    unitData: state.sports.unitsBySportId,
    allConferenceByType: state.conference.allConferenceByType,
    sportByConference: state.conference.sportByConference,
  };
};

export default connect(mapStateToProps)(CreateCoachBackground);
