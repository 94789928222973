import React from "react";
import MuiButton from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

let useStyles = makeStyles((theme) => ({
  root: {},
}));

const Progress = () => {
  return (
    <Fade in={true} style={{ transitionDelay: "700ms" }} unmountOnExit>
      <CircularProgress color="primary" size={24} thickness={6} />
    </Fade>
  );
};

const ButtonLoading = ({ loading = false, name }) => {
  return loading ? <Progress /> : name;
};

const Button = ({
  variant = "contained",
  loading = false,
  children = null,
  disabled = false,
  className,
  ...rest
}) => {
  let classes = useStyles();

  return (
    <MuiButton
      className={clsx(classes.root, className)}
      disabled={loading || disabled}
      variant={variant}
      {...rest}
    >
      <ButtonLoading loading={loading} name={children} />
    </MuiButton>
  );
};

export default Button;
