import { combineReducers } from "redux";
import * as types from "./dashboardTypes";

const stats = (
  state = {
    all: 0,
    completed: 0,
    consentPending: 0,
    reviewPending: 0,
    approvalPending: 0,
    error: 0,
    rejected: 0,
    allTeams: 0,
    proReports: 0,
    psychReview: 0,
    remainingReports: 0,
    baseReportCompleted: 0,
    processing: 0
  },
  action
) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_DASHBOARD_STATS_SUCCESS: {
      return {
        all: payload.all,
        completed: payload.completed,
        consentPending: payload.consentPending,
        reviewPending: payload.reviewPending,
        approvalPending: payload.approvalPending,
        error: payload.error,
        rejected: payload.rejected,
        allTeams: payload.allTeams,
        proReports: payload.proReports,
        psychReview: payload.psychReview,
        remainingReports: payload.remainingReports,
        baseReportCompleted: payload.baseReportCompleted,
        processing: payload.processing
      };
    }
    default:
      return state;
  }
};

const liteCandidateReducer = (state = [], action) => {
  const { payload } = action;
  if (action.type === types.GET_LITE_PLAYERS_INFO_SUCCESS) return payload;
  return state
};

const accountListReducer = (state = [], action) => {
  const { payload } = action;
  if (action.type === types.GET_ACCOUNT_NAMES_INFO_SUCCESS) return payload;
  return state
};

const todaysUpdate = (state = {}, action) => {
  const { payload } = action
  if (action.type === types.GET_TODAYS_UPDATE_SUCCESS) return payload;
  return state
}

const updateDashboardFilter = (state = {
  searchText: undefined,
  searchSport: undefined,
}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.SELECT_DASHBOARD_FILTER: {
      return {
        ...state,
        [payload.key]: payload.value,
      };
    }

    case types.RESET_DASHBOARD_FILTER: {
      return {
          searchText: undefined,
          searchSport: undefined,
      };
    }

    default:
      return state;
  }
};

const updateAccountFilter = (state = {
  searchText: undefined,
  searchTeam: undefined,
}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.SELECT_ACCOUNT_FILTER: {
      return {
        ...state,
        [payload.key]: payload.value,
      };
    }

    case types.RESET_ACCOUNT_FILTER: {
      return {
          searchText: undefined,
          searchSport: undefined,
      };
    }

    default:
      return state;
  }
};

const staticPlayData = (state = {}, action) => {
  switch (action.type) {
    case types.DUMMY_STATIC_PLAYERS_LIST: {
      return {
        count: {
          "total_reports": 10,
          "remaining_reports": 2,
          "pro_reports_requested": 5,
        },
        bcaDashboard: {
          "all": 12,
          "completed": 7,
          "consentPending": 1,
          "reviewPending": 1,
          "approvalPending": 3,
          "error": 0,
          "rejected": 0,
        },
        players:
          [{
            athleteType: "Roster",
            conference: "NFL",
            conference_type: "Professional",
            consent: "Pending",
            consent_form_id: "N/A",
            created_on: "08/18/2022, 06:05:39",
            dob: "11/12/1985",
            email: "rohit@gmail.com",
            first_name: "Rohit",
            full_name: "Rohit Sharma",
            gender: "Men's Sports",
            homeCountry: "United States",
            homeState: "Alaska",
            hometown: "N/A",
            image_url: "http://127.0.0.1:3434/export/default_profile_pic",
            instagram: "rohit4u",
            instagram_source_ln: "en",
            is_paid: false,
            last_name: "Sharma",
            linkedin: "rohit.sharma",
            linkedin_source_ln: "en",
            links: [

            ],
            notes: {
              blocks: [
                {
                  data: {},
                  depth: 0,
                  entityRanges: [],
                  inlineStyleRanges: [],
                  key: "9sqau",
                  text: "Indian Capatin ",
                  type: "unstyled"
                }
              ],
              entityMap: {

              }
            },
            position: "",
            possible_duplicate_info: {
              duplicate_candidate: {

              },
              duplicate_candidate_id: "",
              is_possible_duplicate: false
            },
            race: "Asian",
            relationship_status: "Married",
            reviewed_on: "",
            risk: "low",
            school: "N/A",
            sport: "",
            status: "Approval Pending",
            team: "Arizona Cardinals",
            twitter: "rohit",
            twitter_source_ln: "en",
            unit: "Offense",
            whatsapp: "N/A",
            _id: "9cf009f4-f1ad-41ee-a406-a591e2a56f35"
          },
          {
            athleteType: "Roster",
            conference: "NFL",
            conference_type: "Professional",
            consent: "Pending",
            consent_form_id: "N/A",
            created_on: "08/18/2022, 06:05:39",
            dob: "11/12/1985",
            email: "rohit@gmail.com",
            first_name: "Rohit",
            full_name: "Rohit Sharma",
            gender: "Men's Sports",
            homeCountry: "United States",
            homeState: "Alaska",
            hometown: "N/A",
            image_url: "http://127.0.0.1:3434/export/default_profile_pic",
            instagram: "rohit4u",
            instagram_source_ln: "en",
            is_paid: false,
            last_name: "Sharma",
            linkedin: "rohit.sharma",
            linkedin_source_ln: "en",
            links: [

            ],
            notes: {
              blocks: [
                {
                  data: {},
                  depth: 0,
                  entityRanges: [],
                  inlineStyleRanges: [],
                  key: "9sqau",
                  text: "Indian Capatin ",
                  type: "unstyled"
                }
              ],
              entityMap: {

              }
            },
            position: "",
            possible_duplicate_info: {
              duplicate_candidate: {

              },
              duplicate_candidate_id: "",
              is_possible_duplicate: false
            },
            race: "Asian",
            relationship_status: "Married",
            reviewed_on: "",
            risk: "low",
            school: "N/A",
            sport: "Football",
            status: "Approval Pending",
            team: "Arizona Cardinals",
            twitter: "rohit",
            twitter_source_ln: "en",
            unit: "Offense",
            whatsapp: "N/A",
            _id: "9cf009f4-f1ad-41ee-a406-a591e2a56f35"
          }
          ]
      };
    }

    default:
      return state;
  }
};



export default combineReducers({
  stats,
  liteCandidateReducer,
  updateDashboardFilter,
  staticPlayData,
  todaysUpdate,
  accountListReducer,
  updateAccountFilter,
});
