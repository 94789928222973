import {Box, makeStyles, Typography} from "@material-ui/core";
import {Article} from "../shared/Article";

let useStyles = makeStyles((theme) => ({
    centerDataAlign: {
        display: 'flex',
        justifyContent: 'center',
        margin: '3rem 0px',
        maxWidth: '100%',
    },
    comingSoonText: {
        width: '315px',
        height: '150px',
        flexGrow: 0,
        margin: '42px 0 41px',
        fontFamily: theme.fontFamily.prometo,
        fontSize: '60px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.07,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: theme.palette.primary.main,
    },
}))

function ComingSoonImg() {
    let classes = useStyles();

    return (
        <Article>
            <Box className={classes.centerDataAlign}>
                <Typography className={classes.comingSoonText}>
                    Coming Soon !
                </Typography>
            </Box>
        </Article>
    )
}

export default ComingSoonImg;