import {makeStyles} from "@material-ui/core";

let useStyles = makeStyles((theme) => ({
    inputInput: {
        padding: '8px 15px',
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        maxWidth: 180,
        borderRadius: 5,
        color: theme.palette.text.disabled,
        backgroundColor: theme.colors.veryLightGrey,
        fontFamily: theme.fontFamily.robotoCondensed,
        fontWeight: 500,
        border: 'none',
    },
    sportOptions: {
        background: theme.palette.common.white,
        border: `1px solid ${theme.palette.common.white}`,
        padding: 20,
        fontSize: 15,
        color: theme.palette.text.primary,
        fontFamily: theme.fontFamily.robotoCondensed,
    },
}));

const DropDown = ({ data, onChange, name, placeValue, value }) => {
    const classes = useStyles();

    return (
        <select
            className={classes.inputInput}
            value={value}
            onChange={onChange}
            name={ name }
        >
            <option value="" disabled selected hidden>{ placeValue }</option>
            {
                Object.values(data) && Object.values(data).map((list, i) =>
                    <option
                        className={classes.sportOptions}
                        key={i}
                        value={data[list]}>
                        {data[list]}
                    </option>
                )
            }
        </select>
    )
};

export default DropDown;