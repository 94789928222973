import { blue } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import React, { useContext } from "react";
import { connect } from "react-redux";
import { ActionContext } from "../../../../contexts";
import { Button, DialogActions } from "@material-ui/core";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    enabled: {
        color: theme.palette.success.main,
    },
    pending: {
        color: theme.palette.warning.main,
    },
    btnPrimary: {
        color: theme.palette.primary.main,
        border: '1px solid',
        height: '30px',
        padding: '10px',
        marginRight: '10px',
        borderRadius: '20px',
        '&:hover': {
            background: theme.palette.primary.main,
            color: '#fff'
        },
    },
    btnSecondary: {
        color: theme.palette.secondary.main,
        border: '1px solid',
        height: '30px',
        padding: '10px',
        marginRight: '10px',
        borderRadius: '20px',
        '&:hover': {
            background: theme.palette.secondary.main,
            color: '#fff'
        }
    },
}));

const RequestPlusDialog = (props) => {
    const classes = useStyles();

    const { loadingReqPlanReports, alreadyRequestedPlusReport, requestedReportData } = props

    const { coachActions } = useContext(ActionContext);

    const {
        closeDialog,
        coachId,
    } = props;


    const handleCloseDialog = () => {
        closeDialog();
    };

    const handleSave = () => {
        coachActions.postReqPlanReports(coachId)
    }

    useEffect(() => {
        if (loadingReqPlanReports) {
            handleCloseDialog()
        }
    }, [loadingReqPlanReports])



    return (
        <React.Fragment>
            <div style={{ display: "flex", background: '#cdcdcd', justifyContent: 'flex-end' }}>
                {/* <DialogTitle id="simple-dialog-title" style={{ flex: 1 }}>
                    Confirmation
                </DialogTitle> */}
                <div style={{ margin: "8px 16px 8px 8px", display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton onClick={handleCloseDialog}>
                        <CloseIcon style={{ color: "blue" }} />
                    </IconButton>
                </div>
            </div>

            <div style={{ display: "flex", justifyContent: 'center' }}>
                {
                    // has been requested
                    // basicInfo?.isPlusReport ?
                    alreadyRequestedPlusReport ?
                        <div>
                            <h3 style={{
                                display: "flex", justifyContent: 'center', height: '3rem',
                                color: '#0070b9',
                                fontSize: '1rem',
                                fontWeight: 700,
                                // alignItems: 'center'
                            }}>
                                Request Plus Report has been requested
                            </h3>
                            <span>
                                {/* <b>Requested By : </b> {basicInfo.plusReportData?.request_by ? basicInfo.plusReportData?.request_by : "--"} */}
                                <b>Requested By : </b> {requestedReportData?.request_by ? requestedReportData?.request_by : "--"}

                            </span>
                            <div>
                                {/* <b>Requested On: </b>{basicInfo.plusReportData?.request_on ? basicInfo.plusReportData?.request_on : "--"} */}
                                <b>Requested On: </b>{requestedReportData?.request_on ? requestedReportData?.request_on : "--"}

                            </div>
                        </div>
                        :
                        <h3 style={{
                            display: "flex", justifyContent: 'center', height: '7rem',
                            color: '#0070b9',
                            fontSize: '1.5rem',
                            fontWeight: 700,
                            alignItems: 'center'
                        }}>Would you like to Request Plus Report?</h3>
                }
            </div>

            <div>
                <DialogActions>
                    {
                        // basicInfo?.isPlusReport ?
                        alreadyRequestedPlusReport ?
                            <Button className={classes.btnSecondary} onClick={handleCloseDialog}>Cancel</Button>
                            :
                            <>
                                <Button className={classes.btnSecondary} onClick={handleCloseDialog}>No</Button>
                                <Button className={classes.btnPrimary} disabled={alreadyRequestedPlusReport} onClick={handleSave}>Send Request</Button>
                            </>
                    }
                </DialogActions>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {

    return {
        loadingReqPlanReports: state.ui.coach.loadingReqPlanReports,
        alreadyRequestedPlusReport: state.ui.coach.alreadyRequestedPlusReport,
        requestedReportData: state.coach.requestedReportData,
    };
};
export default connect(mapStateToProps)(RequestPlusDialog);
