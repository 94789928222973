import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { Formik } from "formik";
import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import config from "../../../config";
import { ActionContext } from "../../../contexts";
import { errorProps } from "../../../utils";
import Button from "../../shared/Button";
import TextField from "../../shared/TextField";

let useStyles = makeStyles((theme) => ({
  heading: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: theme.spacing(4),
  },
  headingTitle: {
    paddingBottom: theme.spacing(2),
    fontWeight: 800,
  },
  main: {},
  inputs: {
    paddingBottom: theme.spacing(8),
  },
  buttons: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  link: {
    cursor: "pointer",
  },
}));

let Verify = () => {
  let classes = useStyles();
  let history = useHistory();
  let actions = useContext(ActionContext);
  let verifyState = useSelector((state) => state.ui.account.verify);

  let { error, email } = verifyState;

  useEffect(() => {
    if (email === null) {
      handleRedirectToSignin();
    }
  }, []);

  let handleRedirectToSignin = () => {
    history.push("/signin");
  };

  let handleFormSubmit = (values) => {
    let { email } = verifyState;
    let data = {
      email,
      verification_code: values.verification,
    };

    let { accountActions } = actions;

    let handleRedirectToDashboard = () => {
      history.push("/");
    };

    accountActions.verify(data, handleRedirectToDashboard);
  };

  let handleResendVerification = (values) => {
    let { email } = verifyState;
    let data = {
      email,
    };

    let { accountActions } = actions;

    accountActions.resendVerification(data);
  };

  return (
    <React.Fragment>
      <div className={classes.heading}>
        <Typography
          variant="h5"
          className={classes.headingTitle}
          color="primary"
        >
          {config.appName}
        </Typography>
        <Typography variant="h5" style={{ marginBottom: 8 }}>
          Verify your account
        </Typography>
        <Typography variant="caption" color="textSecondary">
          Please enter the verification code we sent you to your email address
        </Typography>
        {error ? <Alert severity="info">{error}</Alert> : null}
      </div>
      <div className={classes.main}>
        <Formik
          initialValues={{ verification: "" }}
          //   validationSchema={verifySchema}
          onSubmit={handleFormSubmit}
        >
          {(formik) => {
            let {
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              touched,
            } = formik;
            return (
              <form onSubmit={handleSubmit} noValidate>
                <div className={classes.inputs}>
                  <Grid container spacing={3}>
                    <Grid item sm={12}>
                      <TextField
                        name="verification"
                        label="Verification code"
                        value={values.verification}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        required
                        autoFocus
                        {...errorProps(errors, touched, "verification")}
                      />
                    </Grid>
                    <Grid item sm={12} container justify="flex-start">
                      <Typography
                        variant="caption"
                        color="primary"
                        className={classes.link}
                        onClick={handleResendVerification}
                      >
                        Resend the code
                      </Typography>
                    </Grid>
                  </Grid>
                </div>

                <div className={classes.buttons}>
                  <Button type="submit" color="primary" size="large">
                    Verify
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </React.Fragment>
  );
};

export default Verify;
