import React, { useEffect, useContext } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { connect, useDispatch } from "react-redux";
import appConfig from "../../config";
import ComingSoonImg from '../coach/CommingSoonImg.js'
import { ActionContext } from "../../contexts";
import Skeleton from "@material-ui/lab/Skeleton";
import { Paper, List, ListItem, Typography, Container } from '@material-ui/core';
import { formatDate } from "../../utils";
import moment from "moment";

let useStyles = makeStyles((theme) => ({
    root: {
        height: `calc(100vh - 50px)`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: theme.palette.background.default,
    },
    container: {
        paddingTop: theme.spacing(5),
    },
    article: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
    },
    articleHeading: {
        padding: theme.spacing(2, 2, 1, 2),
    },
    articleContent: {},
    contentItem: {
        padding: theme.spacing(2),
        display: "flex",
    },
    contentLabel: {
        textTransform: "uppercase",
        fontSize: 11,
        fontWeight: 700,
        width: 176,
        marginRight: theme.spacing(2),
    },
    contentValue: {
        flex: 1,
        textTransform: "capitalize",
    },
    contentValue2: {
        flex: 1,
    },
    avatar: {
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
}));


function Update(props) {

    let classes = useStyles();
    let { isDrawerOpen, loading, todaysUpdate } = props;
    let { dashboardActions } = useContext(ActionContext);

    let drawerWidth = isDrawerOpen
        ? appConfig.drawer.maxWidth
        : appConfig.drawer.minWidth;

    useEffect(() => {
        dashboardActions.getTodaysUpdate()
    }, [])


    return (
        <React.Fragment>
            <div className={classes.root} style={{ paddingLeft: drawerWidth }}>
                <Container maxWidth="md" className={classes.container}>
                    <Paper elevation={0} variant="outlined" className={classes.article}>
                        <div className={classes.articleHeading}>
                            <Typography variant="h6">Today's Reports</Typography>
                        </div>
                        <div className={classes.articleHeading}>
                            <Typography style={{ fontWeight: 500, fontSize: "14px" }}>Report upto {moment(new Date()).format("hh:mm A")}</Typography>
                        </div>
                        <List>
                            <ListItem className={classes.contentItem}>
                                <Typography
                                    variant="button"
                                    color="textSecondary"
                                    className={classes.contentLabel}
                                >
                                    New Names Added to the System
                                </Typography>
                                <Typography variant="body1" className={classes.contentValue}>
                                {loading ? <Skeleton animation="wave" /> : todaysUpdate && todaysUpdate.NewCandidates}
                                </Typography>
                            </ListItem>
                            <ListItem className={classes.contentItem}>
                                <Typography
                                    variant="button"
                                    color="textSecondary"
                                    className={classes.contentLabel}
                                >
                                    Completed Base Reports
                                </Typography>
                                <Typography variant="body1" className={classes.contentValue2}>
                                    {loading ? <Skeleton animation="wave" /> : todaysUpdate && todaysUpdate.CompletedBaseReports}
                                </Typography>
                            </ListItem>
                            <ListItem className={classes.contentItem}>
                                <Typography
                                    variant="button"
                                    color="textSecondary"
                                    className={classes.contentLabel}
                                >
                                    New Plus Requests
                                </Typography>
                                <Typography variant="body1" className={classes.contentValue2}>
                                    {loading ? <Skeleton animation="wave" /> : todaysUpdate && todaysUpdate.NewPlusRequests}
                                </Typography>
                            </ListItem>

                            <ListItem className={classes.contentItem}>
                                <Typography
                                    variant="button"
                                    color="textSecondary"
                                    className={classes.contentLabel}
                                >
                                    Completed Plus Reports
                                </Typography>
                                <Typography variant="body1" className={classes.contentValue2}>
                                    {loading ? <Skeleton animation="wave" /> : todaysUpdate && todaysUpdate.CompletedPlusReports}
                                </Typography>
                            </ListItem>
                        </List>
                    </Paper>
                </Container>
            </div >
        </React.Fragment >
    )
}

let mapStateToProps = (state) => {
    return {
        loading: state.ui.dashboard.loadTodaysUpdate,
        todaysUpdate: state.dashboard.todaysUpdate,
    }
}

export default connect(mapStateToProps)(Update)