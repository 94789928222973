import React, { useEffect, useContext } from "react";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import CssBaseline from "@material-ui/core/CssBaseline";
import Snackbar from "../../shared/Snackbar";
import { ConfirmProvider } from "material-ui-confirm";
import { ActionContext } from "../../../contexts";
import { Dialog } from "../../shared";
import { PublicRoute } from "../../shared";
import { Login } from "../../account";
import { Switch, useRouteMatch, Route, Redirect } from "react-router-dom";
import { DashboardLayout } from "../Layouts";
import { connect } from "react-redux";

let NotFound = () => <h1>Page Not Found</h1>;

let Root = ({ appConfig, isAuthenticated }) => {
  let match = useRouteMatch({
    path: [
      "/players",
      "/consent",
      "/profile-finder",
      "/account/settings",
      "/account/notifications",
      "/conference",
      "/sports-positions",
      "/coach",
      "/team-overview",
      "/athlete-management",
      "/more-info",
      "/create-athlete",
      "/create-coach",
      "/create-user",
      "/update-user",
      "/todays-update",
      "/account-management",
      "/player-brain",
      "/coach-brain",
      "/assessment-comment/update/:commentId/create-attribute",
      "/assessment-comment/update/:commentId/update-attribute/:attributeId",
      "/assessment-comments",
      "/assessment-comment/create",
      "/assessment-comment/update/:commentId",
      "/",
    ],
    strict: true,
  });

  let actions = useContext(ActionContext);
  let { accountActions } = actions;

  useEffect(() => {
    accountActions.getUserProfile();
  }, []);

  const renderDashboard = () => {
    return isAuthenticated ? <DashboardLayout /> : <Redirect to="/signin" />;
  };

  return (
    <ThemeProvider theme={appConfig.theme}>
      <CssBaseline />
      <ConfirmProvider>
        <Switch>
          <PublicRoute path="/signin" children={Login} />,
          {/* <PublicRoute path="/signup" children={Register} exact /> */}
          <Route path="/404" component={NotFound} exact />
          {match.isExact ? renderDashboard() : <NotFound />}
        </Switch>
        <Dialog />
        <Snackbar />
      </ConfirmProvider>
    </ThemeProvider>
  );
};

let mapStateToProps = (state) => {
  return {
    isAuthenticated: state.account.isAuthenticated,
  };
};

export default connect(mapStateToProps)(Root);
