import { combineReducers } from "redux";
import * as types from "./assessmentCommentTypes";

const getAllAssessmentCommentData = (state = [], action) => {
    const { payload } = action;
    if (action.type === types.GET_ALL_ASSESSMENT_COMMENTS_SUCCESS) return payload;
    return state;
};

const selectedAssessmentCommentIdData = (state = {}, action) => {
    const { payload } = action;
    switch (action.type) {
        case types.GET_ASSESSMENT_COMMENT_ID_SUCCESS: {
            return payload;
        }

        case types.RESET_ASSESSMENT_COMMENT_ID: {
            return {};
        }

        default: return state;
    }
};

const selectedAssessmentCommentData = (state = [], action) => {
    const { payload } = action;
    if (action.type === types.GET_ASSESSMENT_COMMENT_BY_ID_SUCCESS) return payload;
    return state;
};

const getAllAssessmentCommentAttributesData = (state = [], action) => {
    const { payload } = action;
    if (action.type === types.GET_ALL_ASSESSMENT_COMMENT_ATTRIBUTES_SUCCESS) return payload;
    return state;
};

const selectedCommentAttributeData = (state = {}, action) => {
    const { payload } = action;
    if (action.type === types.GET_COMMENT_ATTRIBUTE_BY_ID_SUCCESS) return payload;
    return state;
};

export default combineReducers({
    getAllAssessmentCommentData,
    selectedAssessmentCommentIdData,
    selectedAssessmentCommentData,
    getAllAssessmentCommentAttributesData,
    selectedCommentAttributeData,
});