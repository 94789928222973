import { useCallback } from 'react';

const useStatusColor = () => {
    const getBackgroundColor = useCallback((status) => {
        switch (status) {
            case 'Completed':
                return '#e0f5ea';
            case 'Approval Pending':
                return '#cfe3f2';
            case 'Rejected':
                return '#EBE2E9';
            case 'Processing':
                return '#fbf3d9';
            case 'Psych Review Pending':
                return '#f2f2f2';
            case 'Consent Pending':
                return '#cccccc';
            case 'Error':
                return '#fddfdf';
            case 'Base Report Completed':
                return '#BDBDBE';
            default:
                return '#ffffff';
        }
    }, []);

    const getColor = useCallback((status) => {
        switch (status) {
            case 'Completed':
                return '#00ae4d';
            case 'Approval Pending':
                return '#1075bf';
            case 'Rejected':
                return '#BC5353';
            case 'Processing':
                return '#e7b618';
            case 'Psych Review Pending':
                return '#666666';
            case 'Consent Pending':
                return '#000000';
            case 'Error':
                return '#f33a3a';
            case 'Base Report Completed':
                return '#1A73E8';
            default:
                return '#ffffff';
        }
    }, []);

    const getColorName = useCallback((color) => {
        switch (color) {
            case "#002839" :
                return "Dark Blue";
            case "#FFB71B":
                return "Yellow";
            case "#0070B9":
                return "Blue";
            case "#058AE0":
                return "Light Blue";
            case "#8EC158":
                return 'Green';
            default:
                return null;
        }
    }, []);

    const getHexValue = useCallback((color) => {
        switch (color) {
            case 'Dark Blue':
                return '#002839';
            case 'Yellow':
                return '#FFB71B';
            case 'Blue':
                return '#0070B9';
            case "Light Blue":
                return "#058AE0";
            case 'Green':
                return '#8EC158';
            default:
                return null;
        }
    }, []);

    return {
        getBackgroundColor,
        getColor,
        getColorName,
        getHexValue
    };
};

export default useStatusColor;
