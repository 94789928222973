import React, {useState} from "react";
import { connect } from "react-redux";
import SportsList from "./SportsList";
import UnitDetails from "./SportsList/UnitDetails";
import {Container} from "../../shared";
import {Modal} from "../../shared";
import SportForm from "./SportForm";
import UnitForm from "./UnitForm";
import PositionForm from "./PositionForm";

function SportsMain(props) {
    const [open, setOpen] = useState(false);
    let { selectedSportsPageId, formName } = props;

    const handleModalClick = () => {
        setOpen(prevState => !prevState);
    };

    const renderContent = () => {
        switch (formName) {
            case 'sport':
                return <SportForm setOpen={setOpen}/>
            case 'unit':
                return <UnitForm setOpen={setOpen}/>
            case 'position':
                return <PositionForm setOpen={setOpen}/>
            default:
                return null;
        }
    };

    return (
        <Container>
            <Modal open={open} onClose={handleModalClick}>
                {renderContent()}
            </Modal>
            {
                selectedSportsPageId === "list" ?
                    <SportsList setOpen={setOpen}/>
                    :
                    <UnitDetails setOpen={setOpen}/>
            }
        </Container>
    )
}

let mapStateToProps = (state) => {
    return {
        selectedSportsPageId: state.ui.sports.selectedSportsPageId,
        formName: state.ui.sports.selectedFormName,
    };
};
export default connect(mapStateToProps)(SportsMain);