import React, { useContext, useEffect } from "react";
import TextField from "../../shared/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Button from "../../shared/Button";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Alert from "@material-ui/lab/Alert";
import { useSelector } from "react-redux";
import { ActionContext } from "../../../contexts";
import { errorProps } from "../../../utils";
import { object, string } from "yup";
import {Form, Formik} from "formik";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MentalMetrixWhiteLogo from "../../components/images/mentalMetrixWhiteLogo/MentalMetrixWhiteLogo";
import { accountTypes } from '../../../store/constants';

let loginSchema = object().shape({
  email: string().email().required("Enter email address"),
  password: string().max(200).required("Enter password"),
});

let useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: theme.spacing(3),
  },
  title: {
    fontFamily: theme.fontFamily.prometo,
    fontWeight: 800,
    fontSize: theme.fontSize.lg,
    color: theme.colors.primaryNavy,
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(2)
  },
  buttons: {
    marginTop: theme.spacing(3),
    backgroundColor: theme.colors.primaryGreen,
    width: '150px',
    fontFamily: theme.fontFamily.prometo,
    textTransform: 'uppercase'
  },
  link: {
    fontFamily: theme.fontFamily.robotoCondensed,
    cursor: "pointer",
    color: theme.colors.primaryBlue
  },
  label: {
    color: theme.colors.primaryNavy,
    fontWeight: 800,
    fontFamily: theme.fontFamily.robotoCondensed,
    textTransform: 'uppercase'
  },
  inputFields: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: 330,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '80%',
  },
  heading: {
    fontFamily: theme.fontFamily.prometo,
    fontSize: theme.fontSize.md,
    fontWeight: 900,
    marginBottom: 8,
    color: theme.colors.primaryGreen,
    lineHeight: 1.2,
    padding: '10px 0',
    textTransform: 'uppercase',
    paddingLeft: theme.spacing(2),
  },
  signinContainer: {
    width: 385,
    minHeight: 377,
    backgroundColor: theme.colors.white,
    borderTop: `${theme.colors.primaryBlue} 5px solid`,
    borderRadius: 10,
    padding: '2em',
    justifyContent: 'center',
    [theme.breakpoints.up(1240)]: {
      marginLeft: '2rem',
    },
    [theme.breakpoints.up(1440)]: {
      marginLeft: '4rem',
    },
    [theme.breakpoints.up(1680)]: {
      marginLeft: '6rem',
    },
    [theme.breakpoints.up(1920)]: {
      marginLeft: '8rem',
    }
  },
  intoText: {
    fontFamily: theme.fontFamily.textPlan,
    fontSize: '1.2rem',
    color: theme.colors.white,
    paddingLeft: theme.spacing(2),
  }
}));

let Login = () => {
  let classes = useStyles();
  let history = useHistory();
  let { accountActions } = useContext(ActionContext);
  let loginState = useSelector((state) => state.ui.account.login);

  let { error, loading: isSubmitting } = loginState;

  let [isPasswordVisible, setPasswordVisibility] = React.useState(false);

  let inputPasswordType = isPasswordVisible ? "text" : "password";

  let handlePasswordVisibility = () => {
    setPasswordVisibility(!isPasswordVisible);
  };

  let handleRedirectToForgotPassword = () => {
    history.push("/signin/recovery");
  };

  let handleFormSubmit = (values, helpers) => {
    let data = {
      email: values.email,
      password: values.password,
      remember_me: false,
    };

    let handleSuccess = (accessToken, refreshToken, accountType) => {
      localStorage.setItem("@accessToken", accessToken);
      localStorage.setItem("@refreshToken", refreshToken);
      localStorage.setItem("@accountType", accountType);
      localStorage.removeItem("@appAlert");

      if (
        accountType === accountTypes.colPositionCoach ||
        accountType === accountTypes.colPsychologist ||
        accountType === accountTypes.proPositionCoach ||
        accountType === accountTypes.proScouts ||
        accountType === accountTypes.proSecurity ||
        accountType === accountTypes.proPsychologist
      ) {
        history.push("/athlete-management");
      } else {
        history.push("/");
      }
    };

    let handleFail = () => {
      helpers.setValues({ password: "", email: values.email });
      helpers.setTouched({});
    };

    accountActions.signIn(data, handleSuccess, handleFail);
  };

  let handleErrorClose = () => {
    accountActions.resetLoginError();
  };

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("@appAlert");
      accountActions.resetAppAlert();
    };
  }, []);

  useEffect(() => {
    if (error && error.length > 0) {
      setTimeout(() => {
        handleErrorClose();
      }, 3500);
    }
  }, [error]);

  return (
      <Box className={classes.container}>
        <article style={{
          width: 408,
          height: 250
        }}>
          <MentalMetrixWhiteLogo />
          <Typography className={classes.heading}>
            Mental Metrix is your personal portal to player reports, 24/7.
          </Typography>
          <Box className={classes.intoText}>
            <Typography variant="caption">
              With behavioral and social insight reports at your fingertips, you
              can quickly and easily find information you need to evaluate the fit
              of each player to navigate risk, maximize strengths and develop
              untapped potential for your team. You now have access to reports on
              all players your team requested.
            </Typography>
          </Box>
        </article>
        <Box className={classes.signinContainer}>
          <Box>
            <Typography variant="h5" className={classes.title}>
              Sign in to your account
            </Typography>
            {error ? (
                <Box className={classes.alert}>
                  <Alert severity="error" onClose={handleErrorClose}>
                    {error}
                  </Alert>
                </Box>
              ) : null}
            </Box>
          <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={loginSchema}
              onSubmit={handleFormSubmit}
          >
            {(formik) => {
              let {
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                errors,
                touched,
              } = formik;

              return (
                  <Form onSubmit={handleSubmit} noValidate >
                    <Box>
                      <label htmlFor="email" className={classes.label}>Email Address *</label>
                      <TextField
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={isSubmitting}
                          fullWidth
                          required
                          {...errorProps(errors, touched, "email")}
                          className={classes.inputFields}
                      />
                    </Box>
                    <Box>
                      <label htmlFor="password" className={classes.label}>Password *</label>
                      <TextField
                          name="password"
                          type={inputPasswordType}
                          value={values.password}
                          disabled={isSubmitting}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={handlePasswordVisibility}>
                                    {isPasswordVisible ? (
                                        <Visibility/>
                                    ) : (
                                        <VisibilityOff/>
                                    )}
                                  </IconButton>
                                </InputAdornment>
                            ),
                          }}
                          fullWidth
                          required
                          {...errorProps(errors, touched, "password")}
                          className={classes.inputFields}
                      />
                    </Box>
                    <Typography
                        variant="caption"
                        color="primary"
                        className={classes.link}
                        onClick={handleRedirectToForgotPassword}
                    >
                      Forgot Password?
                    </Typography>
                    <Box style={{
                      display: "flex",
                      justifyContent: "center",
                    }}>
                      <Button
                          type="submit"
                          color="primary"
                          loading={isSubmitting}
                          disableElevation
                          className={classes.buttons}
                      >
                        Sign in
                      </Button>
                    </Box>
                  </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
  )
};

export default Login;
