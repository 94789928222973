import { combineReducers } from "redux";
import * as types from "./dashboardTypes";

const loadingStats = (state = false, action) => {
  switch (action.type) {
    case types.REQUEST_GET_DASHBOARD_STATS: {
      return true;
    }

    case types.GET_DASHBOARD_STATS_SUCCESS:
    case types.GET_DASHBOARD_STATS_FAILED: {
      return false;
    }

    default:
      return state;
  }
};

const loadTodaysUpdate = (state = false, action) => {
  switch (action.type) {
    case types.GET_TODAYS_UPDATE_REQUEST: {
      return true;
    }

    case types.GET_TODAYS_UPDATE_SUCCESS:
    case types.GET_TODAYS_UPDATE_FAILED: {
      return false;
    }

    default:
      return state;
  }
};

const selectedCardId = (state = null, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.SELECT_DASHBOARD_CARD: {
      return payload;
    }

    case types.DESELECT_DASHBOARD_CARD: {
      return null;
    }

    default:
      return state;
  }
};

const liteDetailsLoader = (state = false, action) => {
  switch (action.type) {
    case types.GET_LITE_PLAYERS_INFO_REQUEST: {
      return true;
    }

    case types.GET_LITE_PLAYERS_INFO_SUCCESS:
    case types.GET_LITE_PLAYERS_INFO_FAILED: {
      return false;
    }

    default:
      return state;
  }
};

const loadingAccounts = (state = false, action) => {
  switch (action.type) {
    case types.GET_ACCOUNT_NAMES_INFO_REQUEST: {
      return true;
    }

    case types.GET_ACCOUNT_NAMES_INFO_SUCCESS:
    case types.GET_ACCOUNT_NAMES_INFO_FAILED: {
      return false;
    }

    default:
      return state;
  }
};

export default combineReducers({
  loadingStats,
  liteDetailsLoader,
  selectedCardId,
  loadTodaysUpdate,
  loadingAccounts,
});
