export const accountTypes = {
  superAdmin: "SUPER_ADMIN",
  mmDdiAdmin: "MM_DDI_ADMIN",
  mmPsychologist: "MM_PSYCHOLOGIST",
  mmChiefPsychologist: "MM_CHIEF_PSYCHOLOGIST",
  orgAdmin: "ORG_ADMIN",
  orgUser: "ORG_USER",
  orgPsychologist: "ORG_PSYCHOLOGIST",
  candidate: "CANDIDATE",
  colAthleticDirector : "COL_ATHLETIC_DIRECTOR",
  colHeadCoach : "COL_HEAD_COACH",
  colPositionCoach : "COL_POSITION_COACH",
  colPsychologist : "COL_PSYCHOLOGIST",
  proGeneralManager : "PRO_GENERAL_MANAGER",
  proHeadCoach : "PRO_HEAD_COACH",
  proPositionCoach : "PRO_POSITION_COACH",
  proScouts : "PRO_SCOUTS",
  proSecurity : "PRO_SECURITY",
  proPsychologist : "PRO_PSYCHOLOGIST"
};

export const statusTypes = {
  default: "status",
  completed: "Completed",
  approvalPending: "Approval Pending",
  rejected: "Rejected",
  processing: "Processing",
  psychReviewPending: "Psych Review Pending",
  consentPending: "Consent Pending",
  error: "Error",
  psychReview: "Psych Review",
  baseReportCompleted : "Base Report Completed"
};

export const riskTypes = {
  default: "risk",
  high: "high",
  low: "low",
  moderate: "moderate",
};

export const consentTypes = {
  default: "consent",
  pending: "Pending",
  received: "Received",
  revoked: "Revoked",
};

export const chartColors = {
  red: "#f44336",
  orange: "#ff9800",
  yellow: "#fdd835",
  green: "#4caf50",
};

export const sourceTypes = {
  newspaper: "newspaper",
  youtube: "youtube",
  instagram: "instagram",
  twitter: "twitter",
};

export const reportTypes = {
  fullReport: "fullReport",
  executiveSummaryReport: "executiveSummaryReport",
  actionPlanReport: "actionPlanReport",
};

export const sourceLanguageTypes = {
  en: "English",
  es: "Spanish",
  fr: "French",
};
