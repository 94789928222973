import {Box, Grid, makeStyles, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";
import {connect} from "react-redux";
import React, {useContext, useEffect, useState} from "react";
import {ActionContext} from "../../contexts";
import {Container, HeaderButton, SearchBox, Title} from "../shared";
import {Link} from "react-router-dom";
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import CircularProgress from "../shared/CircularProgress";

let useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        paddingBottom: '1rem',
    },
    headerBtn: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '1rem',
        fontSize: '13px',
        fontFamily: theme.fontFamily.robotoCondensed,
        color: theme.palette.text.primary,
        fontWeight: 700,
        cursor: 'pointer',
        textTransform: 'uppercase',
        textDecoration: 'none',
    },
    noComments: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1rem',
        color: theme.palette.error.main,
        fontFamily: theme.fontFamily.prometo,
        fontWeight: 700,
        fontSize: '1.1rem',
    },
    rowAlignment: {
        border: 'solid 1px #f2f2f2',
        borderRadius: 8,
        backgroundColor: theme.palette.common.white,
        marginBottom: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        padding: '0.8rem 0.5rem',
        width: '100%',
    },
    tableHead: {
        borderBottom: 'solid 1px #f2f2f2',
        backgroundColor: theme.palette.common.white,
        marginBottom: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        padding: '0.8rem 0.5rem',
        width: '100%',
    },
    fullName: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        height: '1.2em',
        whiteSpace: 'nowrap',
        fontWeight: '700',
        fontFamily: theme.fontFamily.robotoCondensed,
        color: theme.palette.text.primary,
        textTransform: 'capitalize',
        textAlign: 'center',
    },
    dataTableAlignment: {
        fontWeight: '300',
        display: 'flex',
        color: theme.palette.text.primary,
        fontFamily: theme.fontFamily.robotoCondensed,
        fontSize: 14,
        justifyContent: 'center',
        textTransform: 'capitalize',
    },
    iconColor: {
        color: theme.palette.text.primary,
    },
}));

const AssessmentComments = (props) => {
    const { assessmentCommentActions } = useContext(ActionContext);
    const { assessmentCommentData, loading } = props;
    const classes = useStyles();
    const [value, setValue ] = useState('');

    useEffect(() => {
        assessmentCommentActions.getAssessmentCommentList();
    }, []);

    function handleViewAllChange() {
        assessmentCommentActions.getAssessmentCommentList();
    }

    const handleSearch = (event) => {
        const search = event.target.value;
        setValue(search);
    };

    const filterData = value.length > 0
        ? assessmentCommentData?.filter((item) => (
            item.title.toLowerCase().includes(value.toLowerCase()) ||
            item.main_attribute.toLowerCase().includes(value.toLowerCase()) ||
            item.sport.toLowerCase().includes(value.toLowerCase())
        ))
        : assessmentCommentData;

    return (
        <Container>
            <div className={classes.header}>
                <Title title='Assessment Attributes' />
                <div style={{ display: 'flex' }}>
                    <Link
                        to='/assessment-comment/create'
                        className={classes.headerBtn}
                    >
                        Create Attribute
                    </Link>
                    <HeaderButton
                        onclick={handleViewAllChange}
                        title='View All'
                    />
                    <SearchBox
                        placeholder="Search Assessment Comment"
                        value={value}
                        onChange={(e) => handleSearch(e)}
                        style={{ marginRight: '-1rem' }}
                    />
                </div>
            </div>
           <TableContainer>
               <Grid container style={{ padding: '1rem 0px' }}>
                   {
                       loading && loading ?
                           <Box style={{display: 'flex', justifyContent: 'center'}}>
                               <CircularProgress size={72} thickness={3} color="primary"/>
                           </Box> :
                           (filterData && filterData.length === 0 ? (
                                   <Typography className={classes.noComments}>
                                       No Assessment Comments Found
                                   </Typography>
                           ) : (
                               <Grid container>
                                   <Grid item xs={12}>
                                       <TableHead className={classes.tableHead}>
                                           <Grid container>
                                               <Grid
                                                   item
                                                   xs={3}
                                                   className={classes.fullName}
                                                   style={{ textAlign: 'start' }}
                                               >
                                                   title
                                               </Grid>
                                               <Grid item xs={3} className={classes.fullName}>sport</Grid>
                                               <Grid item xs={2} className={classes.fullName}>start age</Grid>
                                               <Grid item xs={2} className={classes.fullName}>end age</Grid>
                                               <Grid item xs={2} className={classes.fullName}>edit</Grid>
                                           </Grid>
                                       </TableHead>
                                       {
                                           filterData && filterData?.map((result, index) => (
                                               <TableRow className={classes.rowAlignment} key={index}>
                                                   <Grid
                                                       item
                                                       xs={3}
                                                       className={classes.fullName}
                                                       style={{ textAlign: 'start' }}
                                                   >
                                                       {result.title} - {result.main_attribute}
                                                   </Grid>
                                                   <Grid item xs={3} className={classes.dataTableAlignment}>
                                                       {result.sport}
                                                   </Grid>
                                                   <Grid item xs={2} className={classes.dataTableAlignment}>
                                                       {result.age_start}
                                                   </Grid>
                                                   <Grid item xs={2} className={classes.dataTableAlignment}>
                                                       {result.age_end}
                                                   </Grid>
                                                   <Grid item xs={2} className={classes.dataTableAlignment}>
                                                       <Link to={`/assessment-comment/update/${result._id}`}>
                                                           <CreateOutlinedIcon className={classes.iconColor}/>
                                                       </Link>
                                                   </Grid>
                                               </TableRow>
                                           ))
                                       }
                                   </Grid>
                               </Grid>
                           ))
                   }
               </Grid>
           </TableContainer>
        </Container>
    )
};

let mapStateToProps = (state) => {
    return {
        assessmentCommentData: state.assessmentComments.getAllAssessmentCommentData,
        loading: state.ui.assessmentComments.getAssessmentCommentsLoading,
    };
};

export default connect(mapStateToProps)(AssessmentComments);