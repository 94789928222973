import {Box, makeStyles, Modal} from "@material-ui/core";
import React from "react";

let useStyle = makeStyles((theme) => ({
    modalContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
    modal: {
        display: "flex",
        flexDirection: "column",
        width: 650,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 8,
        padding: theme.spacing(2),
        outline: 'none',
    },
}));

const ModalContainer = ({open, onClose, children}) => {
    const classes = useStyle();
    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <div className={classes.modalContainer}>
                <Box className={classes.modal}>
                    {children}
                </Box>
            </div>
        </Modal>
    )
};

export default ModalContainer;