import {FormControl, FormHelperText, FormLabel, Grid, InputLabel, MenuItem} from "@material-ui/core";
import {connect} from "react-redux";
import {Container, FormEditor, RunDashedLogo, Title} from "../../shared";
import React, {useContext, useEffect, useState} from "react";
import {ActionContext} from "../../../contexts";
import {Field, Form, Formik} from "formik";
import {number, object, string} from "yup";
import {snackbarActions} from "../../shared/Snackbar";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import {Select, TextField} from "formik-material-ui";
import {GlobalCss} from "../../../config/GlobalCss";
import clsx from "clsx";
import Button from "../../shared/Button";
import {EditorState, convertToRaw} from 'draft-js';
import { draftToMarkdown } from 'markdown-draft-js';

let useStyles = makeStyles((theme) => ({
    form: {
        paddingTop: theme.spacing(2),
        maxWidth: 800,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    content: {
        display: "flex",
        justifyContent: "end",
        margin: '2rem 0rem 0rem 0rem'
    },
    btnPrimary: {
        color: theme.palette.common.white,
        padding: '10px',
        backgroundColor: theme.colors.primaryNavy,
        width: '13rem',
        borderRadius: '7px',
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            color: theme.colors.primaryNavy,
            border: `1px solid ${theme.colors.primaryNavy}`,
        },
        textTransform: 'uppercase',
    },
    btnSecondary: {
        color: theme.palette.secondary.main,
        border: '1px solid',
        width: '13rem',
        padding: '10px',
        marginRight: '10px',
        borderRadius: '7px',
        '&:hover': {
            background: theme.palette.secondary.main,
            color: theme.palette.common.white,
        },
        textTransform: 'uppercase',
    },
}));

const CreateAssessmentComment = (props) => {
    const { assessmentCommentActions, sportsActions } = useContext(ActionContext);
    const { error, sportsData } = props;
    const history = useHistory();
    const classes = useStyles();
    let [descriptionEditorState, setDescriptionEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        sportsActions.getSportsList();
    }, []);

    const handleFormSubmit = ( values ) => {
        const descriptionContentState = descriptionEditorState.getCurrentContent();
        const descriptionRawContent = convertToRaw(descriptionContentState);
        const markdown = draftToMarkdown(descriptionRawContent)

        let data = {
            title: values.title,
            main_attribute: values.main_attribute.toLowerCase(),
            age_start: values.age_start,
            age_end: values.age_end,
            sport: values.sport,
            description: markdown,
        };

        assessmentCommentActions.createAssessmentComment(data);

        snackbarActions.showSnackbar(
            'Assessment comment was successfully created.'
        );

        history.push('/assessment-comments')
    };


    let handleErrorClose = () => {
        assessmentCommentActions.resetAssessmentCommentError();
    };

    useEffect(() => {
        if (error && error.length > 0) {
            setTimeout(() => {
                handleErrorClose();
            }, 3500);
        }
    }, [error]);

    const renderError = (error) => {
        if (typeof error === 'string') {
            return error;
        } else if (Array.isArray(error)) {
            return error.join(', ');
        } else if (typeof error === 'object' && error !== null) {
            return JSON.stringify(error);
        }
        return '';
    };

    const handleClose = () => {
        history.push("/assessment-comments");
    }

    const mainAttributes = [
        'Leadership',
        'Composure',
        'Character',
        'Coachability',
    ];

    return (
        <Container>
            <Grid container spacing={1}>
                <Grid item xs={8} style={{ height: '100%', minHeight: '600px', maxHeight: 'auto' }}>
                    <div style={{margin: '0rem 3rem'}}>
                        <Formik
                            initialValues={{
                                title: '',
                                main_attribute: '',
                                age_start: 0,
                                age_end: 0,
                                sports: '',
                                description: '',
                            }}
                            validator={object().shape({
                                title: string()
                                    .trim()
                                    .matches(
                                        /^[a-zA-Z0-9]+(([a-zA-Z0-9 ])?[a-zA-Z0-9]*)*$/,
                                        "Enter a valid title, no special characters allowed."
                                    )
                                    .required("A title is required."),
                                main_attribute: string().nullable().required('Select a main attribute.'),
                                age_start: number().nullable().required('Select a start age'),
                                age_end: number().nullable().required('Select a end age'),
                                sport: string().nullable().required('Select a sport'),
                            })}
                            onSubmit={(values, setSubmitting,  resetForm) => {
                                handleFormSubmit(values)
                                setSubmitting(false)
                                resetForm()
                            }}
                        >
                            {(formik) => {
                                let { errors, touched } = formik;

                                return (
                                    <Form autoComplete='off'>
                                        <GlobalCss />
                                        <div className={classes.form}>
                                            <Title title="Create Attribute"/>
                                            {error ? (
                                                <Grid item>
                                                    <Alert severity='error' onClose={handleErrorClose}>
                                                        {error}
                                                    </Alert>
                                                </Grid>
                                            ) : null}
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <Field
                                                        name="title"
                                                        label="Title"
                                                        placeholder="Title"
                                                        component={TextField}
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        autoFocus
                                                        style={{marginTop: '1rem'}}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}
                                                      style={{display: 'flex', justifyContent: 'space-between'}}>
                                                    <FormControl variant="outlined" style={{width: '49%'}}>
                                                        <InputLabel htmlFor="mainAttribute-label">
                                                            Main Attribute *
                                                        </InputLabel>
                                                        <Field
                                                            component={Select}
                                                            name="main_attribute"
                                                            label="Main Attribute *"
                                                            placeholder="Main Attribute *"
                                                            inputProps={{
                                                                id: "main_attribute",
                                                            }}
                                                            required
                                                        >
                                                            {mainAttributes &&
                                                                mainAttributes.map((list) => (
                                                                    <MenuItem key={list} value={list}>
                                                                        {list}
                                                                    </MenuItem>
                                                                ))}
                                                        </Field>
                                                        {touched["main_attribute"] && !!errors["main_attribute"] && (
                                                            <FormHelperText>{renderError(errors["main_attribute"])}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                    <FormControl variant="outlined" style={{width: '49%'}}>
                                                        <InputLabel htmlFor="sport-label">
                                                            Sport *
                                                        </InputLabel>
                                                        <Field
                                                            component={Select}
                                                            name="sport"
                                                            label="Sport *"
                                                            placeholder="Sport *"
                                                            inputProps={{
                                                                id: "sport",
                                                            }}
                                                            required
                                                        >
                                                            <MenuItem key='all' value='all'>
                                                                All
                                                            </MenuItem>
                                                            {sportsData &&
                                                                sportsData.map((list) => (
                                                                    <MenuItem key={list.name} value={list.name}>
                                                                        {list.name}
                                                                    </MenuItem>
                                                                ))}
                                                        </Field>
                                                        {touched["sport"] && !!errors["sport"] && (
                                                            <FormHelperText>{renderError(errors["sport"])}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}
                                                      style={{display: 'flex', justifyContent: 'space-between'}}>
                                                    <Field
                                                        name="age_start"
                                                        label="Age Group Starting"
                                                        placeholder="Age Group Starting"
                                                        component={TextField}
                                                        type='number'
                                                        variant="outlined"
                                                        required
                                                        autoFocus
                                                        style={{width: '49%'}}
                                                    />
                                                    <Field
                                                        name="age_end"
                                                        label="Age Group Ending"
                                                        placeholder="Age Group Ending"
                                                        component={TextField}
                                                        type='number'
                                                        variant="outlined"
                                                        required
                                                        autoFocus
                                                        style={{width: '49%'}}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl fullWidth>
                                                        <FormLabel style={{marginBottom: '5px'}}>Description</FormLabel>
                                                        <FormEditor editorState={descriptionEditorState} onEditorStateChange={setDescriptionEditorState} />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <div className={clsx(classes.content)}>
                                                <Button
                                                    className={classes.btnSecondary}
                                                    type="button"
                                                    variant="text"
                                                    onClick={handleClose}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    className={classes.btnPrimary}
                                                    type="submit"
                                                >
                                                    Create
                                                </Button>
                                            </div>
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <RunDashedLogo />
                </Grid>
            </Grid>
        </Container>
    )
};

let mapStateToProps = (state) => {

    return {
        error: state.ui.assessmentComments.error,
        sportsData: state.sports.sportsListData,
    };
};

export default connect(mapStateToProps)(CreateAssessmentComment);