import React, { useContext } from "react";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import { accountTypes, statusTypes } from "../../../../store/constants";
import { ActionContext } from "../../../../contexts";
import { connect } from "react-redux";
import { ToggleButton } from "../../../shared/Button";
import { makeStyles } from "@material-ui/core/styles";
import MarkAsReviewDialoge from "./MarkAsReviewCompleteDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "none",
    width: 48,
    height: 36,
    "&.Mui-selected": {
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
    },
  },
}));

const MarkAsReviewCompleteToggleButton = (props) => {
  let classes = useStyles();
  let { coachId, accountType, loading, status } = props;
  let { dialogActions, coachActions } = useContext(ActionContext);

  let hasAuthorizedToMarkReviewComplete =
    accountType === accountTypes.mmPsychologist || accountType === accountTypes.mmChiefPsychologist;

  let hasStatus =
    (accountType === accountTypes.mmChiefPsychologist) ?
      status === statusTypes.completed ||
      status === statusTypes.consentPending ||
      status === statusTypes.psychReviewPending ||
      status === statusTypes.psychReview :
      status === statusTypes.psychReviewPending ||
      status === statusTypes.psychReview ||
      status === statusTypes.baseReportCompleted

  let enableReview = hasAuthorizedToMarkReviewComplete && hasStatus;

  let nextStatus = statusTypes.completed

  let isSelected =
    status === statusTypes.completed || status === statusTypes.consentPending;

  let phyStatus =
    status === statusTypes.psychReviewPending || status === statusTypes.psychReview || status === statusTypes.baseReportCompleted;

  let handleChange = () => {
    if (accountType === accountTypes.mmChiefPsychologist) {
      if (isSelected) {
        coachActions.markReviewIncomple(
          coachId,
          statusTypes.psychReview
        );
      }
      else if (status === statusTypes.psychReview) {
        dialogActions.openDialog(
          <MarkAsReviewDialoge coachId={coachId} status={statusTypes.completed} accountType={accountTypes.mmChiefPsychologist} />,
          {
            maxWidth: "xs",
          }
        );

      }
      else {
        coachActions.markReviewCompleted(coachId, nextStatus);
      }
    }
    else if (accountType === accountTypes.mmPsychologist && phyStatus) {
      dialogActions.openDialog(
        <MarkAsReviewDialoge coachId={coachId} status={statusTypes.psychReview} accountType={accountTypes.mmPsychologist} />,
        {
          maxWidth: "xs",
        }
      );

    }
  };

  if (enableReview) {
    return (
      <div style={{ marginLeft: 8 }}>
        <ToggleButton
          icon={<AssignmentTurnedInIcon />}
          loading={loading}
          selected={isSelected}
          onChange={handleChange}
          classes={{ root: classes.root }}
        />
      </div>
    );
  }

  return null;
};

let mapStateToProps = (state, ownProps) => {
  let { coachId } = ownProps;

  return {
    accountType: state.account.accountType,
    loading:
      state.ui.coach.loadingMarkAsReviewCompleted[coachId] ||
      state.ui.coach.loadingMarkAsReviewIncompleted[coachId],
  };
};

export default connect(mapStateToProps)(MarkAsReviewCompleteToggleButton);
