import React, { useEffect, useContext } from "react";
import clsx from "clsx";
import CircularProgress from "../../../shared/CircularProgress";
import PlayerDetails from "./PlayerDetails";
import getConfig from "../../config";
import { ActionContext } from "../../../../contexts";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

let useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex",
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.colors.veryLightGrey,
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  loading: {
    justifyContent: "center",
    alignItems: "center",
  },
}));

const PlayerDetailsContainer = (props) => {
  let classes = useStyles();
  let { coachActions } = useContext(ActionContext);
  let { coachId, loadingCoach, accountType, closeDialog = (f) => f, fromTeam = false } = props;

  let { pages } = getConfig(accountType);

  useEffect(() => {
    return () => {
      coachActions.deselectCoach();
    };
  }, []);

  if (loadingCoach[coachId] && loadingCoach[coachId] === true) {
    return (
      <div className={clsx(classes.root, classes.loading)}>
        <CircularProgress size={72} thickness={3} color="primary" />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <PlayerDetails
        coachId={coachId}
        pages={Object.values(pages)}
        onClose={closeDialog}
        fromTeam ={ fromTeam }
      />
    </div>
  );
};

let mapStateToProps = (state, ownProps) => {
  let { coachId } = ownProps;

  return {
    loadingCoach: state.ui.coach.loadingCoach,
    accountType: state.account.accountType,
    status: state.coach.basic[coachId],
  };
};

export default connect(mapStateToProps)(PlayerDetailsContainer);
