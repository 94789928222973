import { Checkbox, FormControlLabel, FormGroup, makeStyles, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Button from "../../../shared/Button";

let useStyles = makeStyles((theme) => ({
  root: {
    height: 56,
    borderTop: `1px solid ${theme.palette.divider}`,
    display: "flex",
    alignItems: "center",
    padding: "0px 24px",
    justifyContent: "space-between",
    marginLeft: -1,
    marginRight: -1,
  },
  text: {
    flex: 1,
    color: theme.palette.text.primary,
    fontFamily: theme.fontFamily.robotoCondensed,
  },
  checkbox: {
    color: theme.palette.text.primary,
  },
  btnPrimary: {
    color: theme.palette.common.white,
    padding: 5,
    backgroundColor: theme.colors.primaryNavy,
    width: '8rem',
    marginRight: 10,
    borderRadius: 7,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.colors.primaryNavy,
      border: `1px solid ${theme.colors.primaryNavy}`,
    },
    textTransform: 'uppercase',
  },
  btnSecondary: {
    color: theme.palette.secondary.main,
    border: '1px solid',
    width: '8rem',
    padding: 5,
    borderRadius: 7,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    textTransform: 'uppercase',
  },
}));

const Approve = (props) => {
  let classes = useStyles();
  let [copyArchive, setCopyArchive] = useState(false);
  let [copyAnalysis, setCopyAnalysis] = useState(false);
  let [copyReviews, setCopyReviews] = useState(false);

  let {
    showDuplicateActions,
    onApprove,
    onReject,
    approvingCandidateProfile,
    rejectingCandidateProfile,
  } = props;

  let handleCopyArchive = (e) => setCopyArchive(e.target.checked);
  let handleCopyReviews = (e) => setCopyReviews(e.target.checked);
  let handleCopyAnalysis = (e) => setCopyAnalysis(e.target.checked);

  let handleApprove = () => {
    onApprove(copyArchive, copyReviews, copyAnalysis);
  };

  let handleReject = () => {
    onReject();
  };

  useEffect(() => {
    setCopyAnalysis(copyArchive && copyAnalysis);
    setCopyReviews(copyArchive && copyReviews);
  }, [copyArchive]);

  return (
    <div className={classes.root}>
      <div style={{ flex: 1 }}>
        {showDuplicateActions ? (
          <FormGroup row>
            <Tooltip title="Copy archives from original record">
              <FormControlLabel
                control={
                  <Checkbox
                    name="copyArchives"
                    color="primary"
                    className={classes.checkbox}
                    checked={copyArchive}
                    onChange={handleCopyArchive}
                    disabled={
                      approvingCandidateProfile || rejectingCandidateProfile
                    }
                  />
                }
                label="Copy Archives"
              />
            </Tooltip>

            <Tooltip title="Copy analysis from original record">
              <FormControlLabel
                control={
                  <Checkbox
                    name="copyAnalysis"
                    color="primary"
                    className={classes.checkbox}
                    checked={copyAnalysis}
                    onChange={handleCopyAnalysis}
                    disabled={
                      approvingCandidateProfile ||
                      rejectingCandidateProfile ||
                      Boolean(!copyArchive)
                    }
                  />
                }
                label="Copy Analysis"
              />
            </Tooltip>

            <Tooltip title="Copy reviews from original record">
              <FormControlLabel
                control={
                  <Checkbox
                    name="copyReviews"
                    color="primary"
                    className={classes.checkbox}
                    checked={copyReviews}
                    onChange={handleCopyReviews}
                    disabled={
                      approvingCandidateProfile ||
                      rejectingCandidateProfile ||
                      Boolean(!copyArchive)
                    }
                  />
                }
                label="Copy Reviews"
              />
            </Tooltip>
          </FormGroup>
        ) : null}
      </div>

      <div>
        <Button
          disableElevation
          className={classes.btnPrimary}
          onClick={handleApprove}
          loading={approvingCandidateProfile}
          disabled={rejectingCandidateProfile}
        >
          Approve
        </Button>
      </div>
      <div>
        <Button
          disableElevation
          className={classes.btnSecondary}
          onClick={handleReject}
          loading={rejectingCandidateProfile}
          disabled={approvingCandidateProfile}
        >
          Reject
        </Button>
      </div>
    </div>
  );
};

export default Approve;
