import React from "react";
import MuiToggleButton from "@material-ui/lab/ToggleButton";
import CircularProgress from "../CircularProgress";

const ToggleButton = (props) => {
  const {
    selected,
    loading = false,
    disabled = false,
    onChange = (f) => f,
    icon = "",
    ...rest
  } = props;

  const isDisabled = loading || disabled;

  const handleChange = () => {
    onChange(!selected);
  };

  return (
    <MuiToggleButton
      value="check"
      selected={selected}
      onChange={handleChange}
      disabled={isDisabled}
      {...rest}
    >
      {loading ? <CircularProgress color="primary" /> : icon}
    </MuiToggleButton>
  );
};

export default ToggleButton;
