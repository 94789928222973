import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import Verify from "./Verify";
import Alert from "@material-ui/lab/Alert";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import LoginAthleteBackgroundImage from "assets/login-athlete.webp"

let LoginContainer = (props) => {
  let { appAlert } = props;

  return (
      <section style={{
          backgroundImage: `url(${LoginAthleteBackgroundImage})`,
          height: '100vh',
          width: '100%',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          display: 'flex',
          justifyContent: 'center'
      }}>
          {appAlert && <Alert severity="warning">{appAlert}</Alert>}
          <Switch>
              <Route path="/signin/recovery" exact component={ForgotPassword} />
              <Route path="/signin/verify" exact component={Verify} />
              <Route path="/signin" exact component={Login} />
              <Redirect to="/notfound" />
          </Switch>
      </section>
  )
};

const mapStateToProps = (state) => {
  return {
    appAlert: state.ui.account.appAlert,
  };
};

export default connect(mapStateToProps)(LoginContainer);
