import React from "react";
import { Typography, Grid, InputLabel, MenuItem, FormControl } from "@material-ui/core";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "../../../shared/Editor";
import { Field } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Select } from "formik-material-ui";
import { connect } from "react-redux";

let useStyles = makeStyles((theme) => ({
  heading: {
    // marginTop: theme.spacing(3),
    // marginBottom: theme.spacing(3),
    maxWidth: 480,
    fontWeight: 500,
    lineHeight: "60px",
  },
  form: {
    paddingTop: theme.spacing(1),
    maxWidth: '100%',
    marginRight: '1rem',
  },
  editor: {
    padding: theme.spacing(0, 1),
    border: "1px solid #f1f1f1",
  },
}));

let CreateCoachNotes = (props) => {
  let classes = useStyles();
  const { sportsData } = props;

  let [editorState, setEditorState] = React.useState(EditorState.createEmpty());

  let onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    props.setFieldValue("notes", convertToRaw(editorState.getCurrentContent()));
  };

  return (
    <React.Fragment>
      <Typography variant="h4" className={classes.heading}>
        Is there more to add?
      </Typography>
      <div className={classes.form}>
        <Editor
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          placeholder="Begin typing..."
          classes={{ editor: classes.editor }}
        />

        <Field name="notes">
          {({ field, form: { touched, errors }, meta, ...rest }) => {
            return (
              <div>
                <input
                  style={{ display: "none" }}
                  type="text"
                  placeholder="Email"
                  name="notes"
                  {...field}
                />
              </div>
            );
          }}
        </Field>

        {/* <Grid container spacing={3}>
          <Grid item lg={5} sm={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="sport-label">
                Sport Type
              </InputLabel>
              <Field
                component={Select}
                name="sportId"
                label="Sport Type"
                placeholder="Sport"
                labelId="sport-label"
                inputProps={{
                  id: "sportId",
                }}
              >
                {
                  sportsData && sportsData.map(list =>
                    <MenuItem value={list._id}>{list.name}</MenuItem>
                  )
                }
              </Field>
            </FormControl>
          </Grid>

          <Grid item sm={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="coach_type-label">
                Coach Type
              </InputLabel>
              <Field
                component={Select}
                name="coach_type"
                label="Coach Type"
                placeholder="Type"
                labelId="coach_type-label"
                inputProps={{
                  id: "coach_type",
                }}
              >
                <MenuItem value="Current Employee">Current Employee</MenuItem>
                <MenuItem value="Prospect Hire">Prospect Hire</MenuItem>
              </Field>
            </FormControl>
          </Grid>
        </Grid> */}
      </div>
    </React.Fragment>
  );
};
// export default CreateCoachNotes;
let mapStateToProps = (state) => {
  return {
    sportsData: state.sports.sportsListData,
  };
};
export default connect(mapStateToProps)(CreateCoachNotes);
