import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import sidebarImage from "../../../../assets/info.png";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import { useConfirm } from "material-ui-confirm";
import { makeStyles } from "@material-ui/core/styles";

let useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    height: "100%",
    maxHeight: "100%",
    overflow: "hidden",
  },
  header: {
    position: "absolute",
    top: 0,
    display: "flex",
    width: "100%",
    minHeight: theme.spacing(12),
    paddingLeft: theme.spacing(9),
    paddingTop: theme.spacing(10),
    alignItems: "center",
  },
  header_button: {
    marginRight: theme.spacing(2),
  },
  page: {
    marginLeft: theme.spacing(3),
    left: 0,
    right: 0,
    flex: 1,
  },
  page_content: {
    display: "flex",
    paddingTop: theme.spacing(20),
  },
  page_main: {
    display: "flex",
    flexDirection: "column",
    minWidth: 782,
    paddingLeft: theme.spacing(15),
    paddingBottom: theme.spacing(2),
    maxHeight: 500,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: 7,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#fff",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  page_header: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  page_form: {
    paddingTop: theme.spacing(1),
  },
  sidebar: {
    position: "absolute",
    top: 0,
    right: 0,
    height: "100%",
    width: 320,
    backgroundColor: theme.palette.primary.main,
    backgroundAttachment: "scroll",
    backgroundPosition: "right bottom",
    backgroundSize: "100% 100%",
    zIndex: theme.zIndex.appBar,
  },
  sidebar_image: {
    position: "fixed",
    right: 0,
    top: 240,
    pointerEvents: "none",
  },
}));

const CreateCoachDialogLayout = (props) => {
  let classes = useStyles();
  let confirm = useConfirm();
  let { children, onClose, disableClose, step } = props;

  let handleCloseDialog = () => {
    if (step > 0) {
      confirm({
        title: "Exit Coach Creation?",
        description:
          "You are about to exit the process. Changes you have done will not be saved.  Are you sure?",
        confirmationText: "Yes",
      })
        .then(() => {
          onClose();
        })
        .catch(() => {});
    } else {
      onClose();
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <IconButton
          disabled={disableClose}
          className={classes.header_button}
          onClick={handleCloseDialog}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" color="textSecondary">
          Coach Creation (Step {step + 1} of 4)
        </Typography>
      </div>
      <div className={classes.page}>
        <div className={classes.page_content}>
          <div className={classes.page_main}>
            {children}
            <Hidden mdDown>
              <div className={classes.sidebar}>
                <div className={classes.sidebar_image}>
                  <img src={sidebarImage} alt="sidebar" width={550} />
                </div>
              </div>
            </Hidden>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCoachDialogLayout;
