import { Grid, InputAdornment, makeStyles, Typography } from "@material-ui/core";
import AtIcon from "@material-ui/icons/AlternateEmail";
import Alert from "@material-ui/lab/Alert";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { connect } from "react-redux";

let useStyles = makeStyles((theme) => ({
  heading: {
    maxWidth: 480,
    fontWeight: 500,
    lineHeight: "60px",
    color: theme.palette.text.primary,
    fontFamily: theme.fontFamily.prometo,
  },
  form: {
    paddingTop: theme.spacing(1),
    maxWidth: '100%',
  },
  alert:{
    color: theme.palette.text.primary,
    fontFamily: theme.fontFamily.robotoCondensed,
    marginBottom: '1rem',
    borderRadius: 8,
  },
}));

let CreateCoachSocialMedia = () => {
  let classes = useStyles();

  return (
    <div>
      <Typography variant="h5" className={classes.heading}>
        Let's input Social Media
      </Typography>

      <div className={classes.form}>
        <Alert severity="info" className={classes.alert}>
          Please enter at least Twitter/Instagram account in order to continue
        </Alert>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Field
              name="whatsApp"
              label="Phone"
              placeholder="Phone"
              component={TextField}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">Twitter</Typography>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item md={12} xs={12}>
              <Field
                name="twitter"
                label="Twitter handle"
                placeholder="Handle"
                component={TextField}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AtIcon style={{ color: "#b1b1b1" }} />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>

          </Grid>
          <Grid item>
            <Typography variant="subtitle2">Instagram</Typography>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item md={12} xs={12}>
              <Field
                name="instagram"
                label="Instagram handle"
                placeholder="Handle"
                component={TextField}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AtIcon style={{ color: "#b1b1b1" }} />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>

          </Grid>
          <Grid item>
            <Typography variant="subtitle2">Linkedin</Typography>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item md={12} xs={12}>
              <Field
                name="linkedin"
                label="Linkedin handle"
                placeholder="Handle"
                component={TextField}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AtIcon style={{ color: "#b1b1b1" }} />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>

          </Grid>

          <Grid item>
            <Typography variant="subtitle2">YouTube</Typography>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item md={12} xs={12}>
              <Field
                name="youtube"
                label="Youtube handle"
                placeholder="Channel"
                component={TextField}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AtIcon style={{ color: "#b1b1b1" }} />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>

          </Grid>

          <Grid item>
            <Typography variant="subtitle2">Print News</Typography>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item md={12} xs={12}>
              <Field
                name="printNews"
                label="printNews handle"
                placeholder="Enter Print News"
                component={TextField}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>

        </Grid>
      </div>
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    teams: state.ui.player.teams,
  };
};

export default connect(mapStateToProps)(CreateCoachSocialMedia);
