import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./rootReducer";

/**
 * Redux store configuraions
 * @param {*} preloadedState
 */
let configureStore = (preloadedState) => {
  let middlewares = [thunkMiddleware];

  let composeEnhancers =
    typeof window === "object" &&
    process.env.NODE_ENV !== "production" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        })
      : compose;

  let enhancer = composeEnhancers(applyMiddleware(...middlewares));
  let store = createStore(rootReducer, preloadedState, enhancer);

  return store;
};

export default configureStore();
