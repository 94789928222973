import apiHandler, {getAuthHeader} from "./apiHandler";

export const createUser = async (user) => {
  try {
    let headers = getAuthHeader();
    const response = await apiHandler.post("/auth/register", user, { headers });

    return response.data;
  } catch (err) {
    throw new Error("Failed to create user");
  }
};

export const resendVerification = async (email) => {
  try {
    const response = await apiHandler.post(
      "/auth/verification_code/resend",
      email
    );

    return response.data;
  } catch (err) {
    throw new Error("Failed to resend the verification code");
  }
};

export const confirmEmail = async (confirmation) => {
  try {
    const response = await apiHandler.post(
      "/auth/verification_code/verify",
      confirmation
    );

    return response.data;
  } catch (err) {
    throw new Error("Failed to confirm email");
  }
};

export const authenticate = async (loginData) => {
  try {
    const response = await apiHandler.post("/auth/login", loginData);

    return response.data;
  } catch (err) {
    throw new Error("Login failed");
  }
};

export const resetPassword = async (email) => {
  try {
    const response = await apiHandler.post("/auth/reset/password", email);

    return response.data;
  } catch (err) {
    throw new Error("Password Reset failed");
  }
};

export const changePassword = async (data) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.post("/auth/change-password", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.message);
      return Promise.reject(err);
    }

    return response.data;
  } catch (err) {
    throw new Error("Change Password failed");
  }
};

export const signOut = async () => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.post(
      "/auth/logout/access",
      {},
      { headers }
    );

    if (response.data.status !== "Success") {
      const err = new Error(response.data.message);
      return Promise.reject(err);
    }

    return response.data;
  } catch (e) {
    throw new Error("Sign out attempt failed");
  }
};

export const deleteAccount = async (password) => {
  try {
    const data = { password };
    const headers = getAuthHeader();
    const response = await apiHandler.post("/auth/delete/account", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.message);
      return Promise.reject(err);
    }

    return response.data;
  } catch (e) {
    throw new Error("Sign out attempt failed");
  }
};

export const getProfile = async () => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get("/profile/info", { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.message);
      return Promise.reject(err);
    }

    return response.data.info;
  } catch (err) {
    throw new Error(err);
  }
};

export const getUserTypes = async () => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get("/user_types", { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.message);
      return Promise.reject(err);
    }

    return response.data.info["user_types"].reduce((obj, type) => {
      obj[type.code] = {
        ...type,
      };

      return obj;
    }, {});
  } catch (err) {
    throw new Error(err);
  }
};

export const getAccountById = async (user_id) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/account/${user_id}`, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.message);
      return Promise.reject(err);
    }

    return response.data.info.account;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateUserAccount = async (user_id, data) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.put(`/account/update/${user_id}`, data, {headers});

    if (response.data.status !== "Success") {
      const err = new Error(response.data.message);
      return Promise.reject(err);
    }

    return response.data.info.account;
  } catch (err) {
    throw new Error(err);
  }
};


export const resetPasswordByAdmin = async (email) => {
    try {
        const headers = getAuthHeader();
        const response = await apiHandler.post(`/auth/reset/password/${email}`, email, {headers});

        if (response.data.status !== "Success") {
            const err = new Error(response.data.message);
            return Promise.reject(err);
        }

        return response.data;
    } catch (err) {
        throw new Error(err);
    }
};

export const deleteAccountByAdmin = async (user_id) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.delete(`/delete/account/${user_id}`, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.message);
      return Promise.reject(err);
    }

    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

const user = {
  createUser,
  resendVerification,
  confirmEmail,
  authenticate,
  resetPassword,
  changePassword,
  signOut,
  deleteAccount,
  getProfile,
  getUserTypes,
  getAccountById,
  updateUserAccount,
  resetPasswordByAdmin,
  deleteAccountByAdmin,
};

export default user;
