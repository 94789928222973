import React, { useState, useContext } from "react";
import ExpandLessIcon from "@material-ui/icons/ExpandMore";
import ExpandMoreIcon from "@material-ui/icons/ExpandLess";
import Approve from "./Approve";
import CoachDuplicateInfo from "./PlayerDuplicateInfo";
import { ActionContext } from "../../../../contexts";
import { statusTypes, accountTypes } from "../../../../store/constants";
import { connect } from "react-redux";
import { IconButton, makeStyles, Typography } from "@material-ui/core";

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px -30px 77px -14px rgba(0,0,0,0.09)",
    zIndex: 1100,
  },
  heading: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2, 3),
    color: theme.palette.text.primary,
    fontFamily: theme.fontFamily.robotoCondensed,
  },
  duplicates: {
    display: "flex",
    justifyContent: "space-around",
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    fontFamily: theme.fontFamily.robotoCondensed,
  },
}));

const ExpandButton = (props) => {
  let { expanded = true, onChange } = props;

  let handleChange = (state) => {
    return () => {
      onChange(state);
    };
  };

  if (expanded) {
    return (
      <IconButton onClick={handleChange(false)}>
        <ExpandLessIcon />
      </IconButton>
    );
  }

  return (
    <IconButton onClick={handleChange(true)}>
      <ExpandMoreIcon />
    </IconButton>
  );
};

const ApprovePanel = (props) => {
  let classes = useStyles();
  let {
    analysisStatus,
    hasDuplicate,
    originalCoach,
    currentCoach,
    coachId,
    possiblePlayerId,
    approvingCoachProfile,
    rejectingCoachProfile,
    accountType,
    updateFilter
  } = props;

  let [expanded, setExpanded] = useState(true);
  let { snackbarActions, coachActions } = useContext(ActionContext);

  let hasAccountPrivileges =
    accountType === accountTypes.superAdmin || accountType === accountTypes.mmDdiAdmin ||
    accountType === accountTypes.mmPsychologist || accountType === accountTypes.mmChiefPsychologist;

  let showApprovePane =
    hasAccountPrivileges && analysisStatus === statusTypes.approvalPending;

  let handleExpand = (state) => {
    setExpanded(state);
  };

  let handleApproval = (copyArchives, copyReviews, copyAnalysis) => {
    let data = {
      coach_id: coachId,
      copy_archive: copyArchives,
      copy_reviews: copyReviews,
      copy_analysis: copyAnalysis,
      matching_coach_id: possiblePlayerId,
    };

    let success = () => {
      onSuccessApi()
      snackbarActions.showSnackbar("Coach Approved Successfully");
    };

    coachActions.approveCoachProfile(data, success);
  };

  let handleReject = () => {
    let success = () => {
      onSuccessApi()
      snackbarActions.showSnackbar("Coach Rejected");
    };

    let data = {
      coach_id: coachId,
    };

    coachActions.rejectCoachProfile(data, success);
  };

  let onSuccessApi = () => {
    let model = {
      conference: updateFilter.conference ? [updateFilter.conference] : [],
      sport: updateFilter.sport ? [updateFilter.sport] : [],
      unit: updateFilter.unit ? [updateFilter.unit] : [],
      coachType: updateFilter.coachType === "All" ? [] : [updateFilter.coachType],
      conferenceType: updateFilter.conferenceType === "All" ? [] : [updateFilter.conferenceType]
    }

    coachActions.getAllFilterCoaches(model)
  }

  if (!showApprovePane) {
    return null;
  }

  return (
    <div className={classes.root}>
      {hasDuplicate ? (
        <div>
          <div className={classes.heading}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="subtitle1">Possible duplicates</Typography>
            </div>
            <ExpandButton expanded={expanded} onChange={handleExpand} />
          </div>

          {expanded ? (
            <div className={classes.duplicates}>
              <CoachDuplicateInfo coach={currentCoach} />
              <CoachDuplicateInfo coach={originalCoach} original />
            </div>
          ) : null}
        </div>
      ) : null}
      <Approve
        onApprove={handleApproval}
        onReject={handleReject}
        showDuplicateActions={hasDuplicate}
        approvingCoachProfile={approvingCoachProfile}
        rejectingCoachProfile={rejectingCoachProfile}
      />
    </div>
  );
};

let mapStateToProps = (state, ownProps) => {
  let { coachId } = ownProps;
  let basicInfo = state.coach.basic[coachId];
  let contactInfo = state.coach.contact[coachId];
  let possibleDuplicateInfo = state.coach.possibleDuplicateInfo[coachId];

  let original = {
    id: possibleDuplicateInfo["possiblePlayerId"],
    name: possibleDuplicateInfo["possiblePlayerName"],
    birthday: possibleDuplicateInfo["possiblePlayerBirthday"],
    school: possibleDuplicateInfo["possiblePlayerSchool"],
    team: possibleDuplicateInfo["possiblePlayerTeam"],
    email: possibleDuplicateInfo["possiblePlayerEmail"],
    phone: possibleDuplicateInfo["possiblePlayerPhone"],
    createdDate: possibleDuplicateInfo["possiblePlayerCreatedOn"],
    reviewedDate: possibleDuplicateInfo["possiblePlayerReviewedOn"],
  };

  let current = {
    id: coachId,
    name: basicInfo["name"],
    birthday: basicInfo["birthday"],
    school: basicInfo["school"],
    team: basicInfo["team"],
    email: contactInfo["email"],
    phone: contactInfo["phone"],
  };

  return {
    analysisStatus: basicInfo.status,
    hasDuplicate: possibleDuplicateInfo.isPossibleDuplicate,
    originalCoach: original,
    currentCoach: current,
    possiblePlayerId: possibleDuplicateInfo["possiblePlayerId"],
    approvingCoachProfile:
      state.ui.coach.approvingCoachProfile[coachId],
    rejectingCoachProfile:
      state.ui.coach.rejectingCoachProfile[coachId],
    accountType: state.account.accountType,
    updateFilter: state.coach.updateCoachFilter
  };
};

export default connect(mapStateToProps)(ApprovePanel);
